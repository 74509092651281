export const upcomingcards = [
                            {
                                "lastUpdated": "2024-04-04T13:39:06.904Z",
                                "file": "https://cdn.cerealapis.com/post/8803e9cb-5c8d-490a-8428-248c7d147706/upload-1710943257558.webp",
                                "access": "inherit",
                                "slug": "ff1-000",
                                "createdAt": "2024-03-20T14:01:03.729Z",
                                "bgImage": "https://cdn.cerealapis.com/post/ca901047-ad62-4813-8855-e5ce74d85cc8/upload-1712237869841.png",
                                "postStatus": "published",
                                "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                "client": "elestrals_image",
                                "bgColor": "#CBC8DE",
                                "publishedAt": "2024-03-20T13:58:38.088Z",
                                "id": "image__95d6e8cf-0e0f-4b15-8d95-8aa6652d7ca1",
                                "tags": [
                                ],
                                "excerpt": "FF1-000",
                                "title": "Kryoscorch",
                                "type": "image",
                                "sentEmail": true,
                                "seriesData": {
                                    "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                    "access": "public",
                                    "category": "Card Set",
                                    "slug": "frostfall",
                                    "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                    "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "videoCount": 109,
                                    "seriesType": "photo-album",
                                    "title": "Frostfall"
                                }
                            },
                            {
                                "file": "https://cdn.cerealapis.com/post/5f1baceb-8d54-40cd-8354-6b59e0c4d66c/upload-1710943399349.webp",
                                "collections": {
                                },
                                "type1": "frost",
                                "bgImage": "https://cdn.cerealapis.com/post/e6ee0e2f-3fab-4303-8799-2b6ef4bf4c45/upload-1710954692510.png",
                                "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                "bgColor": "#CBC8DE",
                                "rarity": "common",
                                "id": "image__a0d4cda0-3e54-4ba0-9658-5216074eb610",
                                "tags": [
                                ],
                                "author": "c9bca660-a1a2-4f5f-ad4d-2219b4711d70",
                                "lastUpdated": "2024-03-20T17:55:32.473Z",
                                "access": "inherit",
                                "slug": "flurrmine",
                                "createdAt": "2024-03-20T14:03:27.271Z",
                                "postStatus": "published",
                                "client": "elestrals_image",
                                "publishedAt": "2024-03-20T14:02:36.565Z",
                                "subclass2": "ethereal",
                                "subclass1": "brute",
                                "description": "Frost Spirit",
                                "excerpt": "FF1-001",
                                "title": "Flurrmine",
                                "type": "image",
                                "sentEmail": true,
                                "seriesData": {
                                    "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                    "access": "public",
                                    "category": "Card Set",
                                    "slug": "frostfall",
                                    "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                    "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "videoCount": 109,
                                    "seriesType": "photo-album",
                                    "title": "Frostfall"
                                },
                                "authorData": {
                                    "logoImage": "https://cdn.cerealapis.com/author/41e617c1-385b-40d4-81d8-ef751178fe80/upload-1710356710691.png",
                                    "category": "Artist",
                                    "slug": "kosta",
                                    "bgImage": "https://cdn.cerealapis.com/author/6f207590-82f5-462e-94fa-6c1e70938055/upload-1710356710702.png",
                                    "id": "c9bca660-a1a2-4f5f-ad4d-2219b4711d70",
                                    "videoCount": 8,
                                    "title": "Kosta"
                                }
                            },
                            {
                                "lastUpdated": "2024-03-20T17:25:53.432Z",
                                "file": "https://cdn.cerealapis.com/post/8f767c5f-172e-4453-aade-300a74cd6a17/upload-1710943448212.webp",
                                "type1": "earth",
                                "access": "inherit",
                                "slug": "teratlas",
                                "createdAt": "2024-03-20T14:04:17.415Z",
                                "bgImage": "https://cdn.cerealapis.com/post/69ee5680-6864-4da2-a157-169580ca2330/upload-1710955230826.png",
                                "postStatus": "published",
                                "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                "client": "elestrals_image",
                                "bgColor": "#B7C03A",
                                "publishedAt": "2024-03-20T14:03:39.894Z",
                                "rarity": "common",
                                "subclass2": "golem",
                                "subclass1": "insectoid",
                                "id": "image__90e91f4c-3923-4602-91b2-3ace1f7872fb",
                                "description": "Earth Spirit",
                                "tags": [
                                ],
                                "excerpt": "FF1-002",
                                "title": "Teratlas",
                                "type": "image",
                                "sentEmail": true,
                                "author": "c9bca660-a1a2-4f5f-ad4d-2219b4711d70",
                                "seriesData": {
                                    "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                    "access": "public",
                                    "category": "Card Set",
                                    "slug": "frostfall",
                                    "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                    "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "videoCount": 109,
                                    "seriesType": "photo-album",
                                    "title": "Frostfall"
                                },
                                "authorData": {
                                    "logoImage": "https://cdn.cerealapis.com/author/41e617c1-385b-40d4-81d8-ef751178fe80/upload-1710356710691.png",
                                    "category": "Artist",
                                    "slug": "kosta",
                                    "bgImage": "https://cdn.cerealapis.com/author/6f207590-82f5-462e-94fa-6c1e70938055/upload-1710356710702.png",
                                    "id": "c9bca660-a1a2-4f5f-ad4d-2219b4711d70",
                                    "videoCount": 8,
                                    "title": "Kosta"
                                }
                            },
                            {
                                "lastUpdated": "2024-03-20T17:29:34.412Z",
                                "file": "https://cdn.cerealapis.com/post/137f59e6-c224-4272-a747-4028c199e5df/upload-1710943939123.webp",
                                "type1": "fire",
                                "access": "inherit",
                                "slug": "vipyro",
                                "createdAt": "2024-03-20T14:12:28.737Z",
                                "bgImage": "https://cdn.cerealapis.com/post/341e89f8-326b-4be9-932b-ac66bb80eb99/upload-1710955753825.png",
                                "postStatus": "published",
                                "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                "client": "elestrals_image",
                                "bgColor": "#C63E2C",
                                "publishedAt": "2024-03-20T14:11:38.971Z",
                                "rarity": "common",
                                "subclass2": "eldritch",
                                "subclass1": "dragon",
                                "id": "image__c9dbccfd-22b7-4671-a4fc-e1733598c39b",
                                "description": "Fire Spirit",
                                "tags": [
                                ],
                                "excerpt": "FF1-003",
                                "title": "Vipyro",
                                "type": "image",
                                "sentEmail": true,
                                "author": "c9bca660-a1a2-4f5f-ad4d-2219b4711d70",
                                "seriesData": {
                                    "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                    "access": "public",
                                    "category": "Card Set",
                                    "slug": "frostfall",
                                    "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                    "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "videoCount": 109,
                                    "seriesType": "photo-album",
                                    "title": "Frostfall"
                                },
                                "authorData": {
                                    "logoImage": "https://cdn.cerealapis.com/author/41e617c1-385b-40d4-81d8-ef751178fe80/upload-1710356710691.png",
                                    "category": "Artist",
                                    "slug": "kosta",
                                    "bgImage": "https://cdn.cerealapis.com/author/6f207590-82f5-462e-94fa-6c1e70938055/upload-1710356710702.png",
                                    "id": "c9bca660-a1a2-4f5f-ad4d-2219b4711d70",
                                    "videoCount": 8,
                                    "title": "Kosta"
                                }
                            },
                            {
                                "lastUpdated": "2024-03-20T17:37:10.467Z",
                                "file": "https://cdn.cerealapis.com/post/b12d8313-4774-45e4-aa14-28ffa64423af/upload-1710944010771.webp",
                                "type1": "water",
                                "access": "inherit",
                                "slug": "leviaphin",
                                "createdAt": "2024-03-20T14:13:35.445Z",
                                "bgImage": "https://cdn.cerealapis.com/post/6d452907-dacb-491f-b101-2873bfbacecf/upload-1710956210108.png",
                                "postStatus": "published",
                                "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                "client": "elestrals_image",
                                "bgColor": "#6997C4",
                                "publishedAt": "2024-03-20T14:12:56.127Z",
                                "rarity": "common",
                                "subclass2": "ethereal",
                                "subclass1": "oceanic",
                                "id": "image__130fe7aa-b95e-4601-a538-e5d28050cd42",
                                "description": "Water Spirit",
                                "tags": [
                                ],
                                "excerpt": "FF1-004",
                                "title": "Leviaphin",
                                "type": "image",
                                "sentEmail": true,
                                "author": "c9bca660-a1a2-4f5f-ad4d-2219b4711d70",
                                "seriesData": {
                                    "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                    "access": "public",
                                    "category": "Card Set",
                                    "slug": "frostfall",
                                    "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                    "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "videoCount": 109,
                                    "seriesType": "photo-album",
                                    "title": "Frostfall"
                                },
                                "authorData": {
                                    "logoImage": "https://cdn.cerealapis.com/author/41e617c1-385b-40d4-81d8-ef751178fe80/upload-1710356710691.png",
                                    "category": "Artist",
                                    "slug": "kosta",
                                    "bgImage": "https://cdn.cerealapis.com/author/6f207590-82f5-462e-94fa-6c1e70938055/upload-1710356710702.png",
                                    "id": "c9bca660-a1a2-4f5f-ad4d-2219b4711d70",
                                    "videoCount": 8,
                                    "title": "Kosta"
                                }
                            },
                            {
                                "lastUpdated": "2024-03-20T17:40:20.366Z",
                                "file": "https://cdn.cerealapis.com/post/8b0327b0-1168-4ed1-9a93-4809a1a4dc07/upload-1710944068242.webp",
                                "type1": "thunder",
                                "access": "inherit",
                                "slug": "zaptor",
                                "createdAt": "2024-03-20T14:14:36.993Z",
                                "bgImage": "https://cdn.cerealapis.com/post/701d7411-4460-4032-8575-40bfa9b75a99/upload-1710956400890.png",
                                "postStatus": "published",
                                "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                "client": "elestrals_image",
                                "bgColor": "#E3AB21",
                                "publishedAt": "2024-03-20T14:13:51.064Z",
                                "rarity": "common",
                                "subclass2": "dragon",
                                "subclass1": "archaic",
                                "id": "image__98060ef4-a795-40a2-977b-6e50fc54d3c9",
                                "description": "Thunder Spirit",
                                "tags": [
                                ],
                                "excerpt": "FF1-005",
                                "title": "Zaptor",
                                "type": "image",
                                "sentEmail": true,
                                "author": "c9bca660-a1a2-4f5f-ad4d-2219b4711d70",
                                "seriesData": {
                                    "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                    "access": "public",
                                    "category": "Card Set",
                                    "slug": "frostfall",
                                    "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                    "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "videoCount": 109,
                                    "seriesType": "photo-album",
                                    "title": "Frostfall"
                                },
                                "authorData": {
                                    "logoImage": "https://cdn.cerealapis.com/author/41e617c1-385b-40d4-81d8-ef751178fe80/upload-1710356710691.png",
                                    "category": "Artist",
                                    "slug": "kosta",
                                    "bgImage": "https://cdn.cerealapis.com/author/6f207590-82f5-462e-94fa-6c1e70938055/upload-1710356710702.png",
                                    "id": "c9bca660-a1a2-4f5f-ad4d-2219b4711d70",
                                    "videoCount": 8,
                                    "title": "Kosta"
                                }
                            },
                            {
                                "lastUpdated": "2024-03-26T17:23:54.331Z",
                                "file": "https://cdn.cerealapis.com/post/c281c9a5-a98f-46c8-82d5-90758f158076/upload-1710944179445.webp",
                                "type1": "wind",
                                "access": "inherit",
                                "slug": "lycarus",
                                "createdAt": "2024-03-20T14:16:29.038Z",
                                "bgImage": "https://cdn.cerealapis.com/post/a752498c-8938-423f-a4ec-bd0ccc3368c5/upload-1710957286613.png",
                                "postStatus": "published",
                                "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                "client": "elestrals_image",
                                "bgColor": "#ACBCC1",
                                "publishedAt": "2024-03-20T14:15:34.518Z",
                                "rarity": "common",
                                "subclass2": "ethereal",
                                "subclass1": "brute",
                                "id": "image__b18899fa-2d67-4aa4-89d4-c578c01b306d",
                                "description": "Wind Spirit",
                                "tags": [
                                ],
                                "excerpt": "FF1-006",
                                "title": "Lycarus",
                                "type": "image",
                                "sentEmail": true,
                                "author": "c9bca660-a1a2-4f5f-ad4d-2219b4711d70",
                                "seriesData": {
                                    "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                    "access": "public",
                                    "category": "Card Set",
                                    "slug": "frostfall",
                                    "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                    "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "videoCount": 109,
                                    "seriesType": "photo-album",
                                    "title": "Frostfall"
                                },
                                "authorData": {
                                    "logoImage": "https://cdn.cerealapis.com/author/41e617c1-385b-40d4-81d8-ef751178fe80/upload-1710356710691.png",
                                    "category": "Artist",
                                    "slug": "kosta",
                                    "bgImage": "https://cdn.cerealapis.com/author/6f207590-82f5-462e-94fa-6c1e70938055/upload-1710356710702.png",
                                    "id": "c9bca660-a1a2-4f5f-ad4d-2219b4711d70",
                                    "videoCount": 8,
                                    "title": "Kosta"
                                }
                            },
                            {
                                "lastUpdated": "2024-03-22T16:36:49.397Z",
                                "file": "https://cdn.cerealapis.com/post/58d4696f-4865-4cab-9d3f-a3b40bbb9004/upload-1710944237282.webp",
                                "type1": "frost",
                                "access": "inherit",
                                "slug": "kryoling",
                                "createdAt": "2024-03-20T14:17:22.067Z",
                                "defense": "3",
                                "bgImage": "https://cdn.cerealapis.com/post/8d63bf2a-56a3-44e1-b856-3f7407c199c0/upload-1711125407858.png",
                                "postStatus": "published",
                                "attack": "1",
                                "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                "client": "elestrals_image",
                                "bgColor": "#CBC8DE",
                                "publishedAt": "2024-03-20T14:16:38.973Z",
                                "rarity": "common",
                                "id": "image__0eb2337b-68a5-4f03-beea-54b71131dca2",
                                "description": "``` embed\nThis Kryoling gets +1 \u003cspan class=\"elestrals-font\"\u003eJ\u003c/span\u003e for each \u003cspan class=\"elestrals-font\"\u003eI\u003c/span\u003e in your Underworld. When this Kryoling has 6 or more \u003cspan class=\"elestrals-font\"\u003eJ\u003c/span\u003e during your Main Phase you must either destroy it or Special Ascend it to Kryovyrn from your hand or deck.\n```",
                                "tags": [
                                ],
                                "excerpt": "FF1-007",
                                "title": "Kryoling",
                                "type": "image",
                                "sentEmail": true,
                                "author": "c9bca660-a1a2-4f5f-ad4d-2219b4711d70",
                                "seriesData": {
                                    "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                    "access": "public",
                                    "category": "Card Set",
                                    "slug": "frostfall",
                                    "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                    "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "videoCount": 109,
                                    "seriesType": "photo-album",
                                    "title": "Frostfall"
                                },
                                "authorData": {
                                    "logoImage": "https://cdn.cerealapis.com/author/41e617c1-385b-40d4-81d8-ef751178fe80/upload-1710356710691.png",
                                    "category": "Artist",
                                    "slug": "kosta",
                                    "bgImage": "https://cdn.cerealapis.com/author/6f207590-82f5-462e-94fa-6c1e70938055/upload-1710356710702.png",
                                    "id": "c9bca660-a1a2-4f5f-ad4d-2219b4711d70",
                                    "videoCount": 8,
                                    "title": "Kosta"
                                }
                            },
                            {
                                "lastUpdated": "2024-04-01T14:45:10.595Z",
                                "file": "https://cdn.cerealapis.com/post/44ae10b3-fedd-4137-8324-68a0c20813cb/upload-1710944339752.webp",
                                "access": "inherit",
                                "slug": "ff1-008",
                                "createdAt": "2024-03-20T14:19:07.197Z",
                                "bgImage": "https://cdn.cerealapis.com/post/f84cbb33-993b-4613-90b7-59057a61ebf6/upload-1711982709221.png",
                                "postStatus": "published",
                                "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                "client": "elestrals_image",
                                "bgColor": "#CBC8DE",
                                "publishedAt": "2024-03-20T14:18:18.942Z",
                                "id": "image__19d77c8a-dbe7-4810-b639-a595f6c0bf35",
                                "tags": [
                                ],
                                "excerpt": "FF1-008",
                                "title": "Kryovyrn",
                                "type": "image",
                                "sentEmail": true,
                                "seriesData": {
                                    "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                    "access": "public",
                                    "category": "Card Set",
                                    "slug": "frostfall",
                                    "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                    "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "videoCount": 109,
                                    "seriesType": "photo-album",
                                    "title": "Frostfall"
                                }
                            },
                            {
                                "lastUpdated": "2024-04-01T14:45:58.381Z",
                                "file": "https://cdn.cerealapis.com/post/f362140c-59df-4fc0-a2d4-806aa81f7e53/upload-1710944386957.webp",
                                "access": "inherit",
                                "slug": "ff1-009",
                                "createdAt": "2024-03-20T14:19:51.593Z",
                                "bgImage": "https://cdn.cerealapis.com/post/7f811314-5f8c-4391-9f00-384c5a26557b/upload-1711982757360.png",
                                "postStatus": "published",
                                "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                "client": "elestrals_image",
                                "bgColor": "#CBC8DE",
                                "publishedAt": "2024-03-20T14:19:16.374Z",
                                "id": "image__0b36fb52-556c-40f8-a253-564b58a14de3",
                                "tags": [
                                ],
                                "excerpt": "FF1-009",
                                "title": "Kryoscorch",
                                "type": "image",
                                "sentEmail": true,
                                "seriesData": {
                                    "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                    "access": "public",
                                    "category": "Card Set",
                                    "slug": "frostfall",
                                    "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                    "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "videoCount": 109,
                                    "seriesType": "photo-album",
                                    "title": "Frostfall"
                                }
                            },
                            {
                                "lastUpdated": "2024-03-22T16:42:46.232Z",
                                "file": "https://cdn.cerealapis.com/post/0e3d74a3-48d2-4b20-96a0-7e9a39601f1d/upload-1710944436214.webp",
                                "access": "inherit",
                                "slug": "catarant",
                                "createdAt": "2024-03-20T14:20:40.925Z",
                                "bgImage": "https://cdn.cerealapis.com/post/6098951e-edcd-4aa5-9041-d26915702566/upload-1711125678818.png",
                                "postStatus": "published",
                                "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                "client": "elestrals_image",
                                "bgColor": "#CBC8DE",
                                "publishedAt": "2024-03-20T14:20:06.664Z",
                                "id": "image__881aa984-5c34-4a7c-8d20-91f08186c81e",
                                "tags": [
                                ],
                                "excerpt": "FF1-010",
                                "title": "Catarant",
                                "type": "image",
                                "sentEmail": true,
                                "seriesData": {
                                    "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                    "access": "public",
                                    "category": "Card Set",
                                    "slug": "frostfall",
                                    "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                    "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "videoCount": 109,
                                    "seriesType": "photo-album",
                                    "title": "Frostfall"
                                }},
                                {
                                    "lastUpdated": "2024-03-22T16:42:36.671Z",
                                    "file": "https://cdn.cerealapis.com/post/3cc13b93-af34-442b-a027-eb1f4ae08c99/upload-1710944603172.webp",
                                    "access": "inherit",
                                    "slug": "eyewoolly",
                                    "createdAt": "2024-03-20T14:23:27.542Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/08b5a53f-9866-438a-9588-3299e2111731/upload-1711125755225.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T14:22:43.378Z",
                                    "id": "image__1c636dc8-cdab-4e6a-b966-af47ff6b5737",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-011",
                                    "title": "Eyewoolly",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-22T16:46:46.539Z",
                                    "file": "https://cdn.cerealapis.com/post/82d3772c-7cb2-455a-a1c4-3f5f6390f7a9/upload-1710944657161.webp",
                                    "access": "inherit",
                                    "slug": "snowattle",
                                    "createdAt": "2024-03-20T14:24:21.748Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/d181fa03-b626-410f-a03a-0d4ecb5ee2ae/upload-1711126005399.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T14:23:35.428Z",
                                    "id": "image__8b733608-c610-425b-ab0e-036762136e6b",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-012",
                                    "title": "Snowattle",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-22T17:07:25.122Z",
                                    "file": "https://cdn.cerealapis.com/post/3b3dacf1-ba7d-4a8f-b2e0-fb45126ad879/upload-1710944732366.webp",
                                    "access": "inherit",
                                    "slug": "penguwatt",
                                    "createdAt": "2024-03-20T14:25:37.166Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/df6fb2f8-1716-451d-8ae1-41f67ef8946d/upload-1711127243181.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T14:24:49.933Z",
                                    "id": "image__c0a01c91-836a-4808-b5a0-c9dd3f118ead",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-013",
                                    "title": "Penguwatt",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-22T17:08:19.118Z",
                                    "file": "https://cdn.cerealapis.com/post/3a335697-52c1-4240-b328-988c96444964/upload-1710944788584.webp",
                                    "access": "inherit",
                                    "slug": "emperowatt",
                                    "createdAt": "2024-03-20T14:26:36.509Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/2f581b29-fe3a-4524-8eaf-0598666ec389/upload-1711127297928.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T14:25:54.524Z",
                                    "id": "image__0f8aca59-86a7-455e-8da5-87a7b3fd408b",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-014",
                                    "title": "Emperowatt",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-22T17:10:56.132Z",
                                    "file": "https://cdn.cerealapis.com/post/23431ea9-e9ce-4c71-b0e1-fff96ff33aab/upload-1710944846060.webp",
                                    "access": "inherit",
                                    "slug": "ursicle",
                                    "createdAt": "2024-03-20T14:27:33.944Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/c63319e2-83a3-4a41-9fd3-c5b136262a66/upload-1711127454816.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T14:26:48.759Z",
                                    "id": "image__9cd4552d-7ad0-45d7-9254-09f5cb32409e",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-015",
                                    "title": "Ursicle",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-22T17:16:58.808Z",
                                    "file": "https://cdn.cerealapis.com/post/f084e65b-4068-4800-a09f-84065ba3272b/upload-1710944932939.webp",
                                    "access": "inherit",
                                    "slug": "urscoat",
                                    "createdAt": "2024-03-20T14:28:57.687Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/f77f8f07-8ec1-4f50-a9d3-df9d64f38c84/upload-1711127817629.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T14:28:17.065Z",
                                    "id": "image__2f4a18f4-768a-463e-b6c3-b66772bab034",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-016",
                                    "title": "Urscoat",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-22T17:17:31.293Z",
                                    "file": "https://cdn.cerealapis.com/post/e20d3bee-4a07-4126-96d9-454515b753a7/upload-1710944972158.webp",
                                    "access": "inherit",
                                    "slug": "abominursa",
                                    "createdAt": "2024-03-20T14:29:36.578Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/cdbe3d8e-e4c1-439a-a64f-44d7fb9bcfa3/upload-1711127850114.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T14:29:05.418Z",
                                    "id": "image__ce9fa4eb-ca82-4412-a363-a1db6faaa729",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-017",
                                    "title": "Abominursa",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-22T17:19:44.696Z",
                                    "file": "https://cdn.cerealapis.com/post/3c3b3e2e-c54f-4b29-b74c-21f543193244/upload-1710945079863.webp",
                                    "access": "inherit",
                                    "slug": "glaciursa",
                                    "createdAt": "2024-03-20T14:31:27.608Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/40b452a3-3ef4-4a89-85ae-702158801947/upload-1711127982957.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T14:30:54.085Z",
                                    "id": "image__4e5736b6-cf33-41cb-96e0-c016ad3ee030",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-018",
                                    "title": "Glaciursa",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-26T17:03:50.634Z",
                                    "file": "https://cdn.cerealapis.com/post/ef67a94f-8495-4c45-85ee-10211255f957/upload-1711128032351.webp",
                                    "access": "inherit",
                                    "slug": "ff1-019",
                                    "createdAt": "2024-03-20T14:31:49.837Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/769d3f61-c498-41b9-a88c-a40b6216be24/upload-1711472629054.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T14:31:36.726Z",
                                    "id": "image__85ad2dab-f98e-4bb2-a78b-92f2dc6235c0",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-019",
                                    "title": "Tadfloe",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-26T17:05:37.728Z",
                                    "file": "https://cdn.cerealapis.com/post/5a55de07-7911-45b7-bdba-5b7d2edac949/upload-1710945193743.webp",
                                    "access": "inherit",
                                    "slug": "ff1-020",
                                    "createdAt": "2024-03-20T14:33:21.549Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/c19e842c-87b7-4ebd-aa4f-ceea41933ccd/upload-1711472661051.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T14:32:44.730Z",
                                    "id": "image__d807c353-0d4a-4ad1-9960-293ba501f258",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-020",
                                    "title": "Permaphrog",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-22T17:22:09.819Z",
                                    "file": "https://cdn.cerealapis.com/post/fcc200b4-a804-4894-8182-f2540696b1ea/upload-1710945251315.webp",
                                    "access": "inherit",
                                    "slug": "frosmite",
                                    "createdAt": "2024-03-20T14:34:16.352Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/6a1ebff6-9862-4eb7-b89b-f7aa5f865313/upload-1711128128543.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T14:33:41.002Z",
                                    "id": "image__bcecf760-8559-42a9-8be1-288e2b4a643d",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-021",
                                    "title": "Frosmite",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-04-01T04:32:45.196Z",
                                    "file": "https://cdn.cerealapis.com/post/f1c7aedf-ae46-44e1-a71b-6b4cca014d18/upload-1710945294388.webp",
                                    "access": "inherit",
                                    "slug": "ff1-022",
                                    "createdAt": "2024-03-20T14:35:02.353Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/ec3f8fb4-9395-48f9-837c-8d75c4b03e2d/upload-1711945964332.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T14:34:24.185Z",
                                    "id": "image__e089e367-d0f1-4ca3-b801-85cfc97dc261",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-022",
                                    "title": "Polarachne",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-04-03T15:27:39.723Z",
                                    "file": "https://cdn.cerealapis.com/post/c444e630-9cec-4f29-8e72-5765fee37146/upload-1710945330736.webp",
                                    "access": "inherit",
                                    "slug": "ff1-023",
                                    "createdAt": "2024-03-20T14:35:37.965Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/8c104221-4119-4875-b4d7-fc44f1d4a680/upload-1712158040444.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T14:35:08.692Z",
                                    "id": "image__36a9880d-c0a0-4b97-b59b-a78896cc2d03",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-023",
                                    "title": "Morphrost - Lycan",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-04-03T15:37:26.172Z",
                                    "file": "https://cdn.cerealapis.com/post/92e637a1-95aa-462a-aff0-1b139dedf70a/upload-1710945363329.webp",
                                    "access": "inherit",
                                    "slug": "ff1-024",
                                    "createdAt": "2024-03-20T14:36:07.856Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/cc17cf85-0244-4f14-ac68-33fad41090a9/upload-1712158644554.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T14:35:42.245Z",
                                    "id": "image__0cd4aea5-5b7f-45f2-817f-39b6edc1dbd2",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-024",
                                    "title": "Morphrost - Indus",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-04-03T15:38:11.364Z",
                                    "file": "https://cdn.cerealapis.com/post/e6b19caa-e44f-43de-bd69-ecb72232a76f/upload-1710945420083.webp",
                                    "access": "inherit",
                                    "slug": "ff1-025",
                                    "createdAt": "2024-03-20T14:37:08.193Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/84b86562-909c-481b-8c33-8745dbd7021f/upload-1712158690210.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T14:36:32.846Z",
                                    "id": "image__00f6fa49-4647-4b39-8a0e-aecee5b0ab56",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-025",
                                    "title": "Morphrost - Abominable",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-04-03T15:38:49.406Z",
                                    "file": "https://cdn.cerealapis.com/post/cd3d596e-cdb1-4e13-b410-629c14a4654a/upload-1710945463162.webp",
                                    "access": "inherit",
                                    "slug": "ff1-026",
                                    "createdAt": "2024-03-20T14:37:47.768Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/b6044dd4-814b-4b43-bf86-53590f7dcf1f/upload-1712158728324.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T14:37:19.655Z",
                                    "id": "image__7516ad81-2d93-46e9-abc3-9c311668d3fe",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-026",
                                    "title": "Morphrost - Terror",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-22T17:23:50.894Z",
                                    "file": "https://cdn.cerealapis.com/post/d5507eb0-e070-4fa5-95ad-cf9c45e304c6/upload-1710945524088.webp",
                                    "access": "inherit",
                                    "slug": "frigicub",
                                    "createdAt": "2024-03-20T14:38:51.147Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/5b1708a2-3a81-47c1-95ce-64739507dd81/upload-1711128229511.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T14:38:19.011Z",
                                    "id": "image__f359469b-deda-46c2-b99d-b805269c49e5",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-027",
                                    "title": "Frigicub",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-22T17:26:13.304Z",
                                    "file": "https://cdn.cerealapis.com/post/b25bfae5-8722-4a14-9bea-89a2f96b98aa/upload-1710945575033.webp",
                                    "access": "inherit",
                                    "slug": "shiveroar",
                                    "createdAt": "2024-03-20T14:39:42.651Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/110ea9d0-c158-4d7d-9139-ced5e1d59469/upload-1711128372028.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T14:39:05.734Z",
                                    "id": "image__79826a2a-524a-4aba-bc7c-2c12161366ec",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-028",
                                    "title": "Shiveroar",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T18:37:50.448Z",
                                    "file": "https://cdn.cerealapis.com/post/87d0e353-cdb0-43de-bcdd-ab6b12a8cafa/upload-1710945624306.webp",
                                    "access": "inherit",
                                    "slug": "arctaurus",
                                    "createdAt": "2024-03-20T14:40:31.903Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/88888d85-27cd-472a-99fa-0e97c63a3881/upload-1711305468472.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T14:39:55.292Z",
                                    "id": "image__e6a2523a-5858-4655-9a57-19f81b2972b7",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-029",
                                    "title": "Arctaurus",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T18:38:43.242Z",
                                    "file": "https://cdn.cerealapis.com/post/49d1d952-2229-43f3-bf0f-ed1271782efe/upload-1710945669524.webp",
                                    "access": "inherit",
                                    "slug": "chillalotl",
                                    "createdAt": "2024-03-20T14:41:17.587Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/b25c965b-670a-4296-a2ab-444ff0506c7d/upload-1711305521579.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T14:40:46.572Z",
                                    "id": "image__1b06e21f-a036-4130-a663-d52ab9a71cad",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-030",
                                    "title": "Chillalotl",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T18:39:23.253Z",
                                    "file": "https://cdn.cerealapis.com/post/7627f70c-955b-4101-a234-89f9fefbc03e/upload-1710945719492.webp",
                                    "access": "inherit",
                                    "slug": "emphrostix",
                                    "createdAt": "2024-03-20T14:42:06.708Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/ab38c1e7-8a83-4818-a7c0-0adbbc116a8e/upload-1711305559216.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T14:41:27.205Z",
                                    "id": "image__06116758-2be3-4d86-9d68-d0f4c5cffe7b",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-031",
                                    "title": "Emphrostix",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-04-04T13:40:54.770Z",
                                    "file": "https://cdn.cerealapis.com/post/ec1e8541-9d2f-4ae6-a88c-460aa5a219d5/upload-1710945797744.webp",
                                    "access": "inherit",
                                    "slug": "ff1-032",
                                    "createdAt": "2024-03-20T14:43:22.828Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/bcab0e5f-7fa2-444e-b56d-3da67c5a2005/upload-1712238053814.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T14:42:43.518Z",
                                    "id": "image__19b7442c-67ff-4f03-a6e2-37ea103ba1bc",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-032",
                                    "title": "Moralith",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T18:40:13.010Z",
                                    "file": "https://cdn.cerealapis.com/post/55513fbf-a65a-45d0-9065-509a8d10e304/upload-1710945853645.webp",
                                    "access": "inherit",
                                    "slug": "paleoth",
                                    "createdAt": "2024-03-20T14:44:18.531Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/567b7c4d-63a2-4f96-80fb-4319f8c4e001/upload-1711305611839.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#B7C03A",
                                    "publishedAt": "2024-03-20T14:43:42.663Z",
                                    "id": "image__7fc3a0b8-4976-425e-8eb6-e6037b49006b",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-033",
                                    "title": "Paleoth",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T18:47:09.096Z",
                                    "file": "https://cdn.cerealapis.com/post/1822eb8a-91a3-4642-8681-aa86d5c2112d/upload-1710945905988.webp",
                                    "access": "inherit",
                                    "slug": "neandersloth",
                                    "createdAt": "2024-03-20T14:45:13.442Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/31d0cd07-041f-4439-ae73-8a9f14af3bcb/upload-1711306028005.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#B7C03A",
                                    "publishedAt": "2024-03-20T14:44:40.829Z",
                                    "id": "image__f77fa73b-c223-4f36-923a-a6186a72bf99",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-034",
                                    "title": "Neandersloth",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T18:47:49.009Z",
                                    "file": "https://cdn.cerealapis.com/post/b66e06c6-2ea5-46b8-a97b-d9e56996b26e/upload-1710946005451.webp",
                                    "access": "inherit",
                                    "slug": "cyanectar",
                                    "createdAt": "2024-03-20T14:46:54.867Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/387bd97f-a3a5-4adf-a772-588346b94e95/upload-1711306067573.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#B7C03A",
                                    "publishedAt": "2024-03-20T14:46:18.698Z",
                                    "id": "image__4b2f1eb6-e7b7-4a1d-81e6-f6b44339787b",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-035",
                                    "title": "Cyanectar",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T18:48:19.392Z",
                                    "file": "https://cdn.cerealapis.com/post/5d6575ef-86b2-4dcc-baec-445720a279a8/upload-1710946066485.webp",
                                    "access": "inherit",
                                    "slug": "waspivy",
                                    "createdAt": "2024-03-20T14:47:51.285Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/ceacc537-6b0f-449a-91c3-176f24f2c4cb/upload-1711306097850.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#B7C03A",
                                    "publishedAt": "2024-03-20T14:47:19.045Z",
                                    "id": "image__460dc78f-943f-4f3a-9b0d-3ceaa69000f4",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-036",
                                    "title": "Waspivy",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T18:50:49.478Z",
                                    "file": "https://cdn.cerealapis.com/post/2e839eac-d711-4da3-80e1-a1758570199f/upload-1710946116371.webp",
                                    "access": "inherit",
                                    "slug": "girafflora",
                                    "createdAt": "2024-03-20T14:48:41.221Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/6fbccdd8-74c9-4962-b966-d8ce2504c26d/upload-1711306247997.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#B7C03A",
                                    "publishedAt": "2024-03-20T14:48:07.360Z",
                                    "id": "image__f658164d-29e6-4895-a545-b01bdcffb266",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-037",
                                    "title": "Girafflora",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T18:52:00.913Z",
                                    "file": "https://cdn.cerealapis.com/post/9e6e6f00-b466-4e75-94cc-0926441e1478/upload-1710946224099.webp",
                                    "access": "inherit",
                                    "slug": "gigafflora",
                                    "createdAt": "2024-03-20T14:50:28.547Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/7eb3defd-10d9-4ce9-ab8c-2592cb71c849/upload-1711306317748.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#B7C03A",
                                    "publishedAt": "2024-03-20T14:49:52.415Z",
                                    "id": "image__4e25dfda-750b-4633-9898-a9fa246259bb",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-038",
                                    "title": "Gigafflora",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T18:53:34.911Z",
                                    "file": "https://cdn.cerealapis.com/post/dab89f9e-c9be-4819-88ff-f22dda90a4f6/upload-1710946366970.webp",
                                    "access": "inherit",
                                    "slug": "pandzer",
                                    "createdAt": "2024-03-20T14:52:54.001Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/8fd21b35-d804-41dd-90e4-65308bc93c3b/upload-1711306413692.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#B7C03A",
                                    "publishedAt": "2024-03-20T14:52:13.709Z",
                                    "id": "image__ced0d0e8-81ec-4526-a0ae-633e6ba3963e",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-039",
                                    "title": "Pandzer",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-04-02T15:19:53.150Z",
                                    "file": "https://cdn.cerealapis.com/post/8f19e948-22bf-42fb-bc3d-92544ddb9436/upload-1710946427282.webp",
                                    "access": "inherit",
                                    "slug": "ff1-040",
                                    "createdAt": "2024-03-20T14:53:55.158Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/fe302501-b591-4493-9e97-c6b096812a9a/upload-1712071140436.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#B7C03A",
                                    "publishedAt": "2024-03-20T14:53:01.732Z",
                                    "id": "image__232813bc-813e-419c-a1f3-e252c9935d2a",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-040",
                                    "title": "Mosstacean",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-04-02T15:19:36.258Z",
                                    "file": "https://cdn.cerealapis.com/post/cd42cacc-fd32-4793-8a4b-11febbd8fe7e/upload-1710946478103.webp",
                                    "access": "inherit",
                                    "slug": "ff1-041",
                                    "createdAt": "2024-03-20T14:54:45.857Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/257589ec-587d-444a-af69-97c9964fed08/upload-1712071175339.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#B7C03A",
                                    "publishedAt": "2024-03-20T14:54:12.051Z",
                                    "id": "image__0647631a-cbe8-4edf-a9cc-d24cf8fca919",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-041",
                                    "title": "Cyprong",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-04-04T13:41:36.433Z",
                                    "file": "https://cdn.cerealapis.com/post/62b8e810-b1a2-4b3e-8bd2-767c22482ece/upload-1710946515633.webp",
                                    "access": "inherit",
                                    "slug": "ff1-042",
                                    "createdAt": "2024-03-20T14:55:20.059Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/758feb4c-a207-4848-9872-7c063c717e45/upload-1712238095473.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#B7C03A",
                                    "publishedAt": "2024-03-20T14:54:52.518Z",
                                    "id": "image__e06542af-b55d-4369-83df-93bc0c3ac903",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-042",
                                    "title": "Verdalith",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T20:00:57.406Z",
                                    "file": "https://cdn.cerealapis.com/post/b12bef64-1479-4f3d-a4b6-d1bc79ce2613/upload-1710946567635.webp",
                                    "access": "inherit",
                                    "slug": "ff1-043",
                                    "createdAt": "2024-03-20T14:56:14.538Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/8eb678a3-9246-44d3-80e6-5510699bf90d/upload-1711310455860.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#C63E2C",
                                    "publishedAt": "2024-03-20T14:55:43.311Z",
                                    "id": "image__e4fa4d10-4b83-4231-a7c6-70322cec9514",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-043",
                                    "title": "Smokee",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T20:01:30.416Z",
                                    "file": "https://cdn.cerealapis.com/post/70b51128-8c04-463d-a337-60ad0e954360/upload-1710946619813.webp",
                                    "access": "inherit",
                                    "slug": "ff1-044",
                                    "createdAt": "2024-03-20T14:57:05.142Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/f7e73b15-9dea-4f2c-9e8a-56145de2041e/upload-1711310477799.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#C63E2C",
                                    "publishedAt": "2024-03-20T14:56:36.309Z",
                                    "id": "image__2a80d6d5-31d6-49ae-9584-4360dfcbedbf",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-044",
                                    "title": "Caprefume",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T18:54:36.560Z",
                                    "file": "https://cdn.cerealapis.com/post/89afd333-e0cc-477b-84d0-9a36b1101e41/upload-1710946659950.webp",
                                    "access": "inherit",
                                    "slug": "komagma",
                                    "createdAt": "2024-03-20T14:57:46.565Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/773dfc9a-f52d-4188-b2b0-a781ef3909d0/upload-1711306475466.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#C63E2C",
                                    "publishedAt": "2024-03-20T14:57:08.347Z",
                                    "id": "image__961144a4-0596-4e84-a32c-0708418c19d2",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-045",
                                    "title": "Komagma",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T18:55:37.295Z",
                                    "file": "https://cdn.cerealapis.com/post/bdfab818-44f0-46de-a422-dd581ee346c3/upload-1710946730318.webp",
                                    "access": "inherit",
                                    "slug": "exaltflare",
                                    "createdAt": "2024-03-20T14:58:56.377Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/e2c45595-0116-4eb6-a2ce-6ddda0598895/upload-1711306535239.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#C63E2C",
                                    "publishedAt": "2024-03-20T14:58:14.639Z",
                                    "id": "image__1ed48ee3-425f-4556-9a25-01678ccc1256",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-046",
                                    "title": "Exaltflare",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-04-04T13:42:25.689Z",
                                    "file": "https://cdn.cerealapis.com/post/38e51d9d-2e9f-439a-bd88-e605aa20479a/upload-1710946800158.webp",
                                    "access": "inherit",
                                    "slug": "ff1-047",
                                    "createdAt": "2024-03-20T15:00:05.866Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/0a152828-7b33-4fd3-9339-1c14d5c817c0/upload-1712238144729.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#C63E2C",
                                    "publishedAt": "2024-03-20T14:59:34.725Z",
                                    "id": "image__e74c0efe-ff50-4b35-8237-d66c34242568",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-047",
                                    "title": "Lavalith",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T18:57:43.646Z",
                                    "file": "https://cdn.cerealapis.com/post/04ede7fc-3209-408f-a745-d2c7975b5f99/upload-1710946966397.webp",
                                    "access": "inherit",
                                    "slug": "drataqua",
                                    "createdAt": "2024-03-20T15:02:51.891Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/c2b83b94-1ade-426b-9007-9e0804788c40/upload-1711306662534.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#6997C4",
                                    "publishedAt": "2024-03-20T15:02:16.040Z",
                                    "id": "image__79e08de4-5359-4e25-a40b-3d3204362d39",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-048",
                                    "title": "Dratagua",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T18:58:52.578Z",
                                    "file": "https://cdn.cerealapis.com/post/8dba56d6-1908-480e-8c93-c34a12dfdbe5/upload-1710947091861.webp",
                                    "access": "inherit",
                                    "slug": "lochagon",
                                    "createdAt": "2024-03-20T15:05:02.582Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/163586b4-2dfe-4fd2-a899-6a588e45bba9/upload-1711306731260.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#6997C4",
                                    "publishedAt": "2024-03-20T15:04:25.303Z",
                                    "id": "image__32f58163-30b2-4aa6-b4c6-51db38b62cd5",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-049",
                                    "title": "Lochagon",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:00:59.376Z",
                                    "file": "https://cdn.cerealapis.com/post/4261b311-5a14-4606-a055-c98e1eed22f3/upload-1710947232552.webp",
                                    "access": "inherit",
                                    "slug": "corebral",
                                    "createdAt": "2024-03-20T15:07:21.078Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/e2259e28-e4b1-4865-948d-0f3d88dd73fc/upload-1711306858217.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#6997C4",
                                    "publishedAt": "2024-03-20T15:06:48.578Z",
                                    "id": "image__5c801505-e8c9-4efa-b276-35e7a0d4bb96",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-050",
                                    "title": "Corebral",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-04-01T04:30:46.541Z",
                                    "file": "https://cdn.cerealapis.com/post/2a819f49-5bde-4887-8bc2-c6afb74c96d8/upload-1710947301762.webp",
                                    "access": "inherit",
                                    "slug": "ff1-051",
                                    "createdAt": "2024-03-20T15:08:28.223Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/78bbfe5b-e605-4b6f-9e8c-10d300fbbb74/upload-1711945845302.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#6997C4",
                                    "publishedAt": "2024-03-20T15:07:51.360Z",
                                    "id": "image__2ce76475-195a-4ece-b731-d892494c562a",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-051",
                                    "title": "Corebrum",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T20:02:20.933Z",
                                    "file": "https://cdn.cerealapis.com/post/c8dd4286-3d00-4a11-b37f-bea8ea9e123c/upload-1710947352082.webp",
                                    "access": "inherit",
                                    "slug": "narpoon",
                                    "createdAt": "2024-03-20T15:09:17.864Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/d413e259-969b-48b5-9740-3c0db31101bf/upload-1711310539302.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#6997C4",
                                    "publishedAt": "2024-03-20T15:08:45.885Z",
                                    "id": "image__80391831-6661-4526-a304-a3af085a60eb",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-052",
                                    "title": "Narpoon",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-04-01T04:32:02.737Z",
                                    "file": "https://cdn.cerealapis.com/post/52a18e1c-79b4-4356-aeb0-6c7bde7d2df7/upload-1710947412062.webp",
                                    "access": "inherit",
                                    "slug": "ff1-053",
                                    "createdAt": "2024-03-20T15:10:17.200Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/38649f88-e073-4c8c-aa20-dbf85c2fb53f/upload-1711945921815.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#6997C4",
                                    "publishedAt": "2024-03-20T15:09:32.364Z",
                                    "id": "image__9a17d8b8-6bb5-4982-8123-2ea2ad82d5ce",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-053",
                                    "title": "Whalance",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-04-01T04:31:20.948Z",
                                    "file": "https://cdn.cerealapis.com/post/46e67811-dec4-4f35-8581-c2e52078c0d4/upload-1710947506452.webp",
                                    "access": "inherit",
                                    "slug": "ff1-054",
                                    "createdAt": "2024-03-20T15:11:51.316Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/7e2b45c5-2987-4b69-a0cc-17a7c67bad97/upload-1711945879971.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#6997C4",
                                    "publishedAt": "2024-03-20T15:11:20.038Z",
                                    "id": "image__c6846002-3133-489f-82c6-b6cb5bc74f4b",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-054",
                                    "title": "Bubbloon",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:02:28.236Z",
                                    "file": "https://cdn.cerealapis.com/post/6f858898-ea14-4633-8b47-eba379bf003d/upload-1710947818463.webp",
                                    "access": "inherit",
                                    "slug": "funglow",
                                    "createdAt": "2024-03-20T15:17:05.809Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/2cda1fb8-ba08-459a-8e8c-97bbf0062ad2/upload-1711306947027.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#E3AB21",
                                    "publishedAt": "2024-03-20T15:11:56.081Z",
                                    "id": "image__9987a3da-c411-4f56-8c84-266f3d0e99d6",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-055",
                                    "title": "Funglow",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:02:52.851Z",
                                    "file": "https://cdn.cerealapis.com/post/d8c407e6-3b20-41d5-a0d4-de8d91e3a18c/upload-1710947855104.webp",
                                    "access": "inherit",
                                    "slug": "brontoglow",
                                    "createdAt": "2024-03-20T15:17:38.545Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/d61ace64-db6f-4bd5-8c4b-8a775cc9544f/upload-1711306971715.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#E3AB21",
                                    "publishedAt": "2024-03-20T15:17:08.247Z",
                                    "id": "image__9d56becb-c32a-46cf-b26e-20658f2750ae",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-056",
                                    "title": "Brontoglow",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:03:24.416Z",
                                    "file": "https://cdn.cerealapis.com/post/cbba2ef3-e04e-4cc7-8f26-f172b91dfc1e/upload-1710947908080.webp",
                                    "access": "inherit",
                                    "slug": "raiceros",
                                    "createdAt": "2024-03-20T15:18:36.141Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/731d4718-684c-4f8c-85ec-72a03a238525/upload-1711307003258.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#E3AB21",
                                    "publishedAt": "2024-03-20T15:17:53.696Z",
                                    "id": "image__d489e4b0-cf29-41fa-81d8-739d4162bad5",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-057",
                                    "title": "Raiceros",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:04:02.764Z",
                                    "file": "https://cdn.cerealapis.com/post/59adb92d-43c0-45ec-8daf-7ff64fb9711e/upload-1710948016168.webp",
                                    "access": "inherit",
                                    "slug": "raiceridge",
                                    "createdAt": "2024-03-20T15:20:20.853Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/8ebea2c9-8a47-454c-900f-833eadb2f3d0/upload-1711307041126.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#E3AB21",
                                    "publishedAt": "2024-03-20T15:19:46.127Z",
                                    "id": "image__4db2ee79-6ae7-4247-8cf5-8d786caa6ae9",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-058",
                                    "title": "Raiceridge",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:04:37.709Z",
                                    "file": "https://cdn.cerealapis.com/post/3d068676-bcfc-4557-9db2-529fd1c3d09a/upload-1710948330157.webp",
                                    "access": "inherit",
                                    "slug": "platypulse",
                                    "createdAt": "2024-03-20T15:25:38.106Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/8e7b5635-d2d8-40a6-b3f9-b9a32fb82cc6/upload-1711307076370.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#E3AB21",
                                    "publishedAt": "2024-03-20T15:21:19.307Z",
                                    "id": "image__c311ed59-99bf-46ec-9eb0-edb5966a3f4a",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-059",
                                    "title": "Platypulse",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:05:34.141Z",
                                    "file": "https://cdn.cerealapis.com/post/68528eff-782a-4466-8d5d-ef0aa130a80b/upload-1710948364631.webp",
                                    "access": "inherit",
                                    "slug": "nemisail",
                                    "createdAt": "2024-03-20T15:26:12.932Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/b94caa3a-68f5-4f37-b350-a09a35e9efec/upload-1711307132802.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#ACBCC1",
                                    "publishedAt": "2024-03-20T15:25:44.489Z",
                                    "id": "image__c2c4ac72-3f77-4423-b599-3f51d7f81e4e",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-060",
                                    "title": "Nemisail",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-04-01T19:13:03.223Z",
                                    "file": "https://cdn.cerealapis.com/post/7b41284a-9293-4dcb-baf0-1357b0cab4e6/upload-1710948448236.webp",
                                    "access": "inherit",
                                    "slug": "ff1-061",
                                    "createdAt": "2024-03-20T15:27:36.351Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/6f2e580a-bb5b-4236-9dd3-bec73fa2ef6b/upload-1711998782280.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#ACBCC1",
                                    "publishedAt": "2024-03-20T15:26:52.061Z",
                                    "id": "image__dc0f490c-4dbd-4d24-afe2-f1f88b886942",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-061",
                                    "title": "Pterrosail",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:06:19.767Z",
                                    "file": "https://cdn.cerealapis.com/post/23eb94f6-f150-4ea0-9cfb-a67ca19353b6/upload-1710948513601.webp",
                                    "access": "inherit",
                                    "slug": "findust",
                                    "createdAt": "2024-03-20T15:28:41.579Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/6051c2e9-e1f7-4cd7-a154-acd51ec158c5/upload-1711307178542.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#ACBCC1",
                                    "publishedAt": "2024-03-20T15:28:05.336Z",
                                    "id": "image__8b134c1f-10ee-41e8-86d2-817247e0bd4b",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-062",
                                    "title": "Findust",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:06:59.059Z",
                                    "file": "https://cdn.cerealapis.com/post/97768a95-4a65-4b62-8bda-896d0627c7a0/upload-1710949068400.webp",
                                    "access": "inherit",
                                    "slug": "hiboofin",
                                    "createdAt": "2024-03-20T15:37:54.904Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/b884f732-3476-429d-ac12-971de979837c/upload-1711307217816.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#ACBCC1",
                                    "publishedAt": "2024-03-20T15:37:23.697Z",
                                    "id": "image__fcf2e3fb-53a2-4ed4-8758-8c14f48604df",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-063",
                                    "title": "Hiboofin",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:07:41.955Z",
                                    "file": "https://cdn.cerealapis.com/post/3b5d0a2a-dfb1-4009-9fe4-05624dc75852/upload-1710949183125.webp",
                                    "access": "inherit",
                                    "slug": "nimbug",
                                    "createdAt": "2024-03-20T15:39:48.559Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/cf09c649-8963-4407-a030-0b4f195ac8dc/upload-1711307260520.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#ACBCC1",
                                    "publishedAt": "2024-03-20T15:39:12.485Z",
                                    "id": "image__df7ae949-91a7-4db8-88a9-3b8d2992c034",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-064",
                                    "title": "Nimbug",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:08:17.857Z",
                                    "file": "https://cdn.cerealapis.com/post/64c95a77-3e57-475a-b195-8fca04bee8a0/upload-1710949245355.webp",
                                    "access": "inherit",
                                    "slug": "charcoon",
                                    "createdAt": "2024-03-20T15:40:53.231Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/22833481-ed49-43a8-9371-882415ff3952/upload-1711307296528.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#ACBCC1",
                                    "publishedAt": "2024-03-20T15:39:56.822Z",
                                    "id": "image__e4b087f2-0b75-4d68-8f58-ff00a5f55f40",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-065",
                                    "title": "Charcoon",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:09:39.269Z",
                                    "file": "https://cdn.cerealapis.com/post/434940ab-a3a6-4da9-9345-40fe44d5cb50/upload-1710949339263.webp",
                                    "access": "inherit",
                                    "slug": "flammamoth",
                                    "createdAt": "2024-03-20T15:42:27.691Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/7265c620-c23a-477f-ba6e-5b767c53779e/upload-1711307378121.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#ACBCC1",
                                    "publishedAt": "2024-03-20T15:41:49.263Z",
                                    "id": "image__f0f56593-e822-4601-9c70-9d7daa579a29",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-066",
                                    "title": "Flammamoth",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-04-01T19:11:00.689Z",
                                    "file": "https://cdn.cerealapis.com/post/d1905f18-f34d-4de2-9b62-e5b81996240a/upload-1710949379850.webp",
                                    "access": "inherit",
                                    "slug": "ff1-067",
                                    "createdAt": "2024-03-20T15:43:07.994Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/9b91373d-1af3-4796-813c-0cf8eed5a205/upload-1711998659367.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#ACBCC1",
                                    "publishedAt": "2024-03-20T15:42:33.361Z",
                                    "id": "image__6ddd9b99-9dc0-48d8-bce5-088aad1df9f6",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-067",
                                    "title": "Cockatoodle",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-04-01T19:12:00.659Z",
                                    "file": "https://cdn.cerealapis.com/post/3f728b12-2076-45ea-ad63-c871d9939c81/upload-1710949422133.webp",
                                    "access": "inherit",
                                    "slug": "ff1-068",
                                    "createdAt": "2024-03-20T15:43:47.188Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/7fbd4f5a-4473-4057-960e-04485a302185/upload-1711998719439.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#ACBCC1",
                                    "publishedAt": "2024-03-20T15:43:13.204Z",
                                    "id": "image__d1dfee5a-0e9f-4e13-a2eb-2304ebec681f",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-068",
                                    "title": "Dracodoodle",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:10:43.819Z",
                                    "file": "https://cdn.cerealapis.com/post/6f976924-e1ef-4d45-a78d-9bde0a0f28e9/upload-1710949454029.webp",
                                    "access": "inherit",
                                    "slug": "galaxair",
                                    "createdAt": "2024-03-20T15:44:22.128Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/a585f907-7099-4da9-b5df-6a03616005aa/upload-1711307441785.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#ACBCC1",
                                    "publishedAt": "2024-03-20T15:43:49.598Z",
                                    "id": "image__faf6016b-359a-482e-aaac-c8e68cfa0eb5",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-069",
                                    "title": "Galaxair",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:11:40.219Z",
                                    "file": "https://cdn.cerealapis.com/post/60a4a237-ce9a-4cd5-bfb4-c2368a6d1c52/upload-1710949520506.webp",
                                    "access": "inherit",
                                    "slug": "khione",
                                    "createdAt": "2024-03-20T15:45:28.120Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/525561d6-26fe-43c2-900d-8b7e4ee39184/upload-1711307498834.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T15:44:52.743Z",
                                    "runeType": "divine",
                                    "id": "image__af6c4c1d-11dd-4bad-924f-69e7100bfdbc",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-070",
                                    "title": "Khione",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:13:39.543Z",
                                    "file": "https://cdn.cerealapis.com/post/f3283ac0-a954-4538-91d5-664f1d76be1f/upload-1710949572667.webp",
                                    "access": "inherit",
                                    "slug": "scepter-of-khione",
                                    "createdAt": "2024-03-20T15:46:17.807Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/6c3ed74c-650e-4a2c-99b1-84e30fee998a/upload-1711307618017.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T15:45:42.144Z",
                                    "runeType": "artifact",
                                    "id": "image__0babf453-b58d-4666-a3d4-1a1311c8a0f8",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-071",
                                    "title": "Scepter of Khione",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:14:44.692Z",
                                    "file": "https://cdn.cerealapis.com/post/6c7e6eb3-27f4-4cf2-8c22-f36629a76bcc/upload-1710949706449.webp",
                                    "access": "inherit",
                                    "slug": "avalanche",
                                    "createdAt": "2024-03-20T15:48:34.226Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/e49799de-8873-4b2d-8a97-bff516da9968/upload-1711307682961.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T15:47:52.870Z",
                                    "runeType": "invoke",
                                    "id": "image__65ec4867-759c-4cab-bc5c-9774d3f55f4a",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-072",
                                    "title": "Avalanche",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:16:54.995Z",
                                    "file": "https://cdn.cerealapis.com/post/2ee4986c-6191-4c02-a4ac-1b04f61528a8/upload-1710949804260.webp",
                                    "access": "inherit",
                                    "slug": "blizzard",
                                    "createdAt": "2024-03-20T15:50:14.183Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/42f33c5a-8ee1-48d9-8ab7-d5112c6cfe87/upload-1711307813683.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T15:49:38.365Z",
                                    "runeType": "invoke",
                                    "id": "image__75150588-c2fc-4859-8aef-6d7b433379cf",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-073",
                                    "title": "Blizzard",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:38:26.317Z",
                                    "file": "https://cdn.cerealapis.com/post/6e384991-0a3a-430d-848a-d693ad06d2cd/upload-1710949865155.webp",
                                    "access": "inherit",
                                    "slug": "mountains-of-boreas",
                                    "createdAt": "2024-03-20T15:51:14.084Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/22cfbb5e-6823-4466-98b6-1e61509de352/upload-1711309104657.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T15:50:27.793Z",
                                    "id": "image__3852c438-2097-4f28-98b3-f82c88cca427",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-074",
                                    "title": "Mountains of Boreas",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-26T16:38:30.649Z",
                                    "file": "https://cdn.cerealapis.com/post/caaaa247-83b6-4c8d-8070-b18a8441ec15/upload-1710949940750.webp",
                                    "access": "inherit",
                                    "slug": "ff1-075",
                                    "createdAt": "2024-03-20T15:52:30.627Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/9dc55799-32cb-4934-8dee-5f3e673448fc/upload-1711471109665.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "publishedAt": "2024-03-20T15:51:55.334Z",
                                    "id": "image__e00e9b10-cee5-43d9-b27c-4f3ef1ed66ce",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-075",
                                    "title": "Circle the Sky",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-27T16:13:59.847Z",
                                    "file": "https://cdn.cerealapis.com/post/a2a8f37f-d015-4ef4-bb14-0d7e45791eb4/upload-1710950020067.webp",
                                    "access": "inherit",
                                    "slug": "ff1-076",
                                    "createdAt": "2024-03-20T15:53:48.324Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/95e62fce-8c52-4874-9ef2-866ee8e12976/upload-1711556038825.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#B7C03A",
                                    "publishedAt": "2024-03-20T15:53:13.630Z",
                                    "id": "image__6615ce11-78b1-4caa-8d55-13593bde28ec",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-076",
                                    "title": "Ambrosia - Cornucopia",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:39:46.027Z",
                                    "file": "https://cdn.cerealapis.com/post/5128e518-82cf-4461-b3f6-fafd64a97ebd/upload-1710950064185.webp",
                                    "access": "inherit",
                                    "slug": "stoke-the-forge",
                                    "createdAt": "2024-03-20T15:54:32.197Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/c4b4def8-f41e-420d-b2bb-43f0d88bc710/upload-1711309182265.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#C63E2C",
                                    "publishedAt": "2024-03-20T15:53:58.200Z",
                                    "id": "image__91a720eb-c939-4229-a2f1-0e6eb365081c",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-077",
                                    "title": "Stoke the Forge",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:40:19.011Z",
                                    "file": "https://cdn.cerealapis.com/post/d9b8ccb9-6911-4b29-9c99-3c8157387dc5/upload-1710950106676.webp",
                                    "access": "inherit",
                                    "slug": "rise-from-the-depths",
                                    "createdAt": "2024-03-20T15:55:12.092Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/a2fcb003-5248-4610-aac1-34a81e46a33a/upload-1711309217873.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#6997C4",
                                    "publishedAt": "2024-03-20T15:54:42.874Z",
                                    "id": "image__276459c3-64db-4a1c-ae51-d722efae2428",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-078",
                                    "title": "Rise from the Depths",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:41:06.688Z",
                                    "file": "https://cdn.cerealapis.com/post/4b078532-06db-4981-ac04-20f5d2214d72/upload-1710950204256.webp",
                                    "access": "inherit",
                                    "slug": "race-to-the-top",
                                    "createdAt": "2024-03-20T15:56:54.235Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/0ea7c229-cbc4-4297-a0c5-dab0adcc2ce4/upload-1711309265568.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#E3AB21",
                                    "publishedAt": "2024-03-20T15:56:11.173Z",
                                    "id": "image__eeff770e-9291-468d-b8bb-d479b80502df",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-079",
                                    "title": "Race to the Top",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:41:35.534Z",
                                    "file": "https://cdn.cerealapis.com/post/f2bda371-9072-46d5-86c3-3bba8a88dbe5/upload-1710950252108.webp",
                                    "access": "inherit",
                                    "slug": "tower-of-the-winds",
                                    "createdAt": "2024-03-20T15:57:37.429Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/347ddde3-241d-45cd-b09c-b9b445303af0/upload-1711309294503.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#ACBCC1",
                                    "publishedAt": "2024-03-20T15:57:05.820Z",
                                    "id": "image__28f7c37f-5b73-4a83-8148-19d7b9636ac1",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-080",
                                    "title": "Tower of the Winds",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:42:07.404Z",
                                    "file": "https://cdn.cerealapis.com/post/3e395531-a51a-4b97-a21b-d7c7cf6565c5/upload-1710950369193.webp",
                                    "access": "inherit",
                                    "slug": "sandstorm",
                                    "createdAt": "2024-03-20T15:59:38.309Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/ae8ca226-c43d-4c15-9665-d2262f828084/upload-1711309326323.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#ACBCC1",
                                    "publishedAt": "2024-03-20T15:57:56.754Z",
                                    "runeType": "invoke",
                                    "id": "image__ff286b37-8e02-4ce9-8d02-a70cbff0acd6",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-081",
                                    "title": "Sandstorm",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-26T16:52:21.952Z",
                                    "file": "https://cdn.cerealapis.com/post/7d6ec9c0-56e0-4f20-82f5-9ba589a17318/upload-1710950422672.webp",
                                    "access": "inherit",
                                    "slug": "ff1-082",
                                    "createdAt": "2024-03-20T16:00:28.712Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/2e4cbaee-823c-46d9-ba54-2cba6143e101/upload-1711471940314.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "publishedAt": "2024-03-20T15:59:49.257Z",
                                    "id": "image__dada4d40-c195-46c8-a32a-ac62a23a14e1",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-082",
                                    "title": "Poisoned Nectar",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T20:02:56.214Z",
                                    "file": "https://cdn.cerealapis.com/post/7901f5c5-d879-4a88-8b68-c9f46f9f7548/upload-1710950468447.webp",
                                    "access": "inherit",
                                    "slug": "ff1-083",
                                    "createdAt": "2024-03-20T16:01:14.287Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/53718475-cb83-4d5f-a930-18338c024211/upload-1711310575080.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "publishedAt": "2024-03-20T16:00:38.640Z",
                                    "id": "image__33a1cf35-8dcd-4423-943b-323c91f87906",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-083",
                                    "title": "Divine Blessing",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:42:35.087Z",
                                    "file": "https://cdn.cerealapis.com/post/45d3995a-93e7-4a44-9bdf-92079473adee/upload-1710950530535.webp",
                                    "access": "inherit",
                                    "slug": "chains-of-prometheus",
                                    "createdAt": "2024-03-20T16:02:19.867Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/0e8160b9-070f-4adb-878e-f15223092974/upload-1711309353920.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#C63E2C",
                                    "publishedAt": "2024-03-20T16:01:31.706Z",
                                    "id": "image__bc9a26c3-218c-434d-a90e-daf4573bc94e",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-084",
                                    "title": "Chains of Prometheus",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:43:06.114Z",
                                    "file": "https://cdn.cerealapis.com/post/8e451f28-959a-4cd6-92c5-7c8556b99ccc/upload-1710950581797.webp",
                                    "access": "inherit",
                                    "slug": "ursa-den",
                                    "createdAt": "2024-03-20T16:03:10.288Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/fa52f04f-5327-47dc-b5a8-50a635a5877c/upload-1711309383662.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#C63E2C",
                                    "publishedAt": "2024-03-20T16:02:25.190Z",
                                    "id": "image__e576833f-f2a8-41be-9677-8887b8dc2218",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-085",
                                    "title": "Ursa Den",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:43:42.047Z",
                                    "file": "https://cdn.cerealapis.com/post/1bb07c2b-d085-4a25-8456-84332b3c9cb3/upload-1710950674213.webp",
                                    "access": "inherit",
                                    "slug": "foloi-forest",
                                    "createdAt": "2024-03-20T16:04:39.388Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/7768ef20-8137-4c13-8f4f-d88a0c967b28/upload-1711309420652.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#B7C03A",
                                    "publishedAt": "2024-03-20T16:03:44.377Z",
                                    "id": "image__cd48a63a-c72e-47af-b135-20c07107071a",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-086",
                                    "title": "Foloi Forest",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:44:35.748Z",
                                    "file": "https://cdn.cerealapis.com/post/4b3bf2c8-a3e7-443f-a06f-044b3e96337a/upload-1710950715761.webp",
                                    "access": "inherit",
                                    "slug": "volcanic-forge",
                                    "createdAt": "2024-03-20T16:05:23.786Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/242d5987-35a7-47a0-a723-a62582bdc90f/upload-1711309474348.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#C63E2C",
                                    "publishedAt": "2024-03-20T16:04:44.637Z",
                                    "id": "image__839ad223-851e-4f5b-9866-04a6e1ab5d02",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-087",
                                    "title": "Volcanic Forge",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:46:22.507Z",
                                    "file": "https://cdn.cerealapis.com/post/d68bb317-5995-449a-843d-e4092facfab4/upload-1710951034660.webp",
                                    "access": "inherit",
                                    "slug": "atlantis",
                                    "createdAt": "2024-03-20T16:10:39.723Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/8fa65135-577b-4446-8e4a-47dd869ad753/upload-1711309581296.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#6997C4",
                                    "publishedAt": "2024-03-20T16:10:01.484Z",
                                    "id": "image__82a00919-3862-4b92-b9ee-d03738c04ed8",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-088",
                                    "title": "Atlantis",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:48:25.852Z",
                                    "file": "https://cdn.cerealapis.com/post/46d8b1c2-75ad-4ea3-95cc-ec6678f2ac45/upload-1710951130094.webp",
                                    "access": "inherit",
                                    "slug": "mt-olympus",
                                    "createdAt": "2024-03-20T16:12:18.269Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/64690420-d959-41c2-a218-bcb0ffc70737/upload-1711309702674.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#E3AB21",
                                    "publishedAt": "2024-03-20T16:11:43.909Z",
                                    "id": "image__ecda1934-d530-4322-b26b-69793b1f3e47",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-089",
                                    "title": "Mt. Olympus",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:49:10.531Z",
                                    "file": "https://cdn.cerealapis.com/post/7ef8070a-19e7-40ce-a6ec-762750c28fa5/upload-1710951172988.webp",
                                    "access": "inherit",
                                    "slug": "island-of-aeolia",
                                    "createdAt": "2024-03-20T16:12:58.248Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/bc4a2f94-e6d6-44e3-b4f3-74994374a066/upload-1711309749279.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#ACBCC1",
                                    "publishedAt": "2024-03-20T16:12:25.495Z",
                                    "id": "image__0289d802-349b-4711-8174-493edeb7622e",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-090",
                                    "title": "Island of Aeolia",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-20T16:14:21.206Z",
                                    "file": "https://cdn.cerealapis.com/post/412973e1-49c1-46ce-80b7-ededa4573758/upload-1710951251587.webp",
                                    "access": "inherit",
                                    "slug": "ff1-091",
                                    "createdAt": "2024-03-20T16:14:21.205Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/a09bf43b-c0ab-4489-9146-0256f64ffd05/upload-1710951243362.webp",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "publishedAt": "2024-03-20T16:13:38.186Z",
                                    "id": "image__f6a1ef95-175b-46a8-84c5-fcd505291cdc",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-091",
                                    "title": "FF1-091",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:51:58.012Z",
                                    "file": "https://cdn.cerealapis.com/post/3c8f6c4f-894a-4008-8f00-37d5db79fd7b/upload-1710951345627.webp",
                                    "access": "inherit",
                                    "slug": "glaciursa",
                                    "createdAt": "2024-03-20T16:15:57.744Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/82d76958-ac33-4aef-a308-5697f6a41d63/upload-1711309916581.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T16:15:22.146Z",
                                    "id": "image__38c505fc-0586-4ac9-892f-8c20adf0bcca",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-092",
                                    "title": "Glaciursa",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-20T16:17:55.228Z",
                                    "file": "https://cdn.cerealapis.com/post/acebe182-6026-41ed-b27b-db58b6bbcbde/upload-1710951467022.webp",
                                    "access": "inherit",
                                    "slug": "ff1-093",
                                    "createdAt": "2024-03-20T16:17:55.224Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/0bdca5d9-82fd-4ce4-8435-9132ebb1af70/upload-1710951459593.webp",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "publishedAt": "2024-03-20T16:17:23.059Z",
                                    "id": "image__ce0657bd-fc70-4151-a9b8-e22340e8cfab",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-093",
                                    "title": "FF1-093",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-20T16:18:34.949Z",
                                    "file": "https://cdn.cerealapis.com/post/c59954af-955a-4b90-88b3-869fff6f0a26/upload-1710951509507.webp",
                                    "access": "inherit",
                                    "slug": "ff1-094",
                                    "createdAt": "2024-03-20T16:18:34.948Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/4e472254-6f10-4967-94d2-96b6d2b868cd/upload-1710951499044.webp",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "publishedAt": "2024-03-20T16:18:04.862Z",
                                    "id": "image__f515bb66-fd21-4761-a81e-bcf26c8c36bd",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-094",
                                    "title": "FF1-094",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-04-04T13:44:38.901Z",
                                    "file": "https://cdn.cerealapis.com/post/44e50d03-fbd5-47fb-bf3f-7193e0cb57af/upload-1710951563705.webp",
                                    "access": "inherit",
                                    "slug": "ff1-095",
                                    "createdAt": "2024-03-20T16:19:28.804Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/3a9dd978-756c-4816-b402-ebd6f6136857/upload-1712238233406.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#6997C4",
                                    "publishedAt": "2024-03-20T16:19:00.322Z",
                                    "id": "image__83275c58-281d-4cb2-b988-d498ce82f44c",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-095",
                                    "title": "Lochagon",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:52:49.628Z",
                                    "file": "https://cdn.cerealapis.com/post/6800b483-9e54-4343-9cb8-bb39dd223ac3/upload-1710951611513.webp",
                                    "access": "inherit",
                                    "slug": "flammomoth",
                                    "createdAt": "2024-03-20T16:20:20.389Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/c05907f7-0a9d-4ec8-a529-e0fbdc207cf4/upload-1711309966412.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#ACBCC1",
                                    "publishedAt": "2024-03-20T16:19:42.531Z",
                                    "id": "image__af594dc3-373b-4e08-a125-8da16ed681e8",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-096",
                                    "title": "Flammamoth",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:53:35.472Z",
                                    "file": "https://cdn.cerealapis.com/post/d823245f-c523-46ae-a9be-b55f74961d9f/upload-1710952024092.webp",
                                    "access": "inherit",
                                    "slug": "flurrmine",
                                    "createdAt": "2024-03-20T16:27:08.942Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/84409853-2dc5-4dce-8197-e06525899aa2/upload-1711310013984.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-20T16:26:33.691Z",
                                    "id": "image__efa9675d-92fd-439a-83ee-98f43e3095b6",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-097",
                                    "title": "Flurrmine",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:54:42.213Z",
                                    "file": "https://cdn.cerealapis.com/post/04dd39f9-54ec-4af4-bd5a-87af602d6dda/upload-1710952307777.webp",
                                    "access": "inherit",
                                    "slug": "teratlas",
                                    "createdAt": "2024-03-20T16:31:56.854Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/6f79ee89-7d64-49c5-9eff-f09c5d8b6be6/upload-1711310080691.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#B7C03A",
                                    "publishedAt": "2024-03-20T16:31:22.750Z",
                                    "id": "image__d0a2ed0a-5f79-4da2-a08a-2f8628b3ac98",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-098",
                                    "title": "Teratlas",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:55:14.396Z",
                                    "file": "https://cdn.cerealapis.com/post/59c8d305-19b7-43c3-910e-a8d5ffe673be/upload-1710952387092.webp",
                                    "access": "inherit",
                                    "slug": "vipyro",
                                    "createdAt": "2024-03-20T16:33:15.172Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/cbb7c874-2df1-403b-8cdc-1851f31bbd6b/upload-1711310113324.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#C63E2C",
                                    "publishedAt": "2024-03-20T16:32:42.307Z",
                                    "id": "image__13338195-98b4-41d2-8db4-0dc0d00bbb04",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-099",
                                    "title": "Vipyro",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:56:02.330Z",
                                    "file": "https://cdn.cerealapis.com/post/2aecdf05-6c77-447a-a76f-e89889230c19/upload-1710952434883.webp",
                                    "access": "inherit",
                                    "slug": "leviaphin",
                                    "createdAt": "2024-03-20T16:34:00.453Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/594b95b0-9c46-41e9-9421-376aa9e707ae/upload-1711310161013.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#6997C4",
                                    "publishedAt": "2024-03-20T16:33:26.945Z",
                                    "id": "image__ffb6cedd-bfad-4891-b026-6fb85b79d017",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-100",
                                    "title": "Leviaphin",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:56:29.974Z",
                                    "file": "https://cdn.cerealapis.com/post/c480527d-5fae-43d1-bd4c-ebd5ee8cb46f/upload-1710952490203.webp",
                                    "access": "inherit",
                                    "slug": "zaptor",
                                    "createdAt": "2024-03-20T16:34:55.522Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/25f58662-c79b-4226-b102-73d35c04af53/upload-1711310188407.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#E3AB21",
                                    "publishedAt": "2024-03-20T16:34:24.043Z",
                                    "id": "image__3eccb89f-6319-4bed-9b29-5e826e9b059e",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-101",
                                    "title": "Zaptor",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-24T19:57:09.015Z",
                                    "file": "https://cdn.cerealapis.com/post/021a146e-c0ba-4e84-9e07-b35548f4391c/upload-1710952528262.webp",
                                    "access": "inherit",
                                    "slug": "lycarus",
                                    "createdAt": "2024-03-20T16:35:37.284Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/690a7ae7-316b-4887-b1b5-ace14935c3df/upload-1711310227596.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#ACBCC1",
                                    "publishedAt": "2024-03-20T16:35:04.509Z",
                                    "id": "image__c3c75e62-4acd-45cb-b835-b9917fcf5261",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-102",
                                    "title": "Lycarus",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-26T16:35:31.652Z",
                                    "file": "https://cdn.cerealapis.com/post/a3891bd5-c0fe-4f78-ba70-ef57a9ffd1b3/upload-1711470930753.webp",
                                    "access": "inherit",
                                    "slug": "ursicle",
                                    "createdAt": "2024-03-26T16:35:31.632Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/13c5ab30-21e9-4975-a9a5-1ba7d775e1f9/upload-1711470929633.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-26T16:34:11.087Z",
                                    "id": "image__f5f412c7-a07f-45de-8609-76ad7173358e",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-103",
                                    "title": "Ursicle",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-26T16:36:40.515Z",
                                    "file": "https://cdn.cerealapis.com/post/4239f3ad-098e-4e7b-b34b-230a9c985a5b/upload-1711470999662.webp",
                                    "access": "inherit",
                                    "slug": "urscoat",
                                    "createdAt": "2024-03-26T16:36:40.490Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/3a4100fc-9c60-40c5-8bd2-27cd02be23cb/upload-1711470997471.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-26T16:35:50.422Z",
                                    "id": "image__072730ba-f2f8-4675-a23e-726a732849f2",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-104",
                                    "title": "Urscoat",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-26T16:55:02.158Z",
                                    "file": "https://cdn.cerealapis.com/post/ed521f8c-b70a-440a-91e0-c7f97b280a03/upload-1711471057140.webp",
                                    "access": "inherit",
                                    "slug": "abominursa",
                                    "createdAt": "2024-03-26T16:37:38.045Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/4f374c02-7c9a-40bf-a30c-2d88a8ccc71e/upload-1711472101058.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-26T16:36:59.524Z",
                                    "id": "image__3bcee5a0-5ed9-40eb-ac95-30b39d0ab300",
                                    "tags": [
                                    ],
                                    "excerpt": "FF1-105",
                                    "title": "Abominursa",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-26T17:07:58.312Z",
                                    "file": "https://cdn.cerealapis.com/post/b7fd5e62-635e-4733-8499-2f2dd8b37af3/upload-1711472877476.webp",
                                    "access": "inherit",
                                    "slug": "findust",
                                    "createdAt": "2024-03-26T17:07:58.311Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/70af16a0-8071-45f8-8d6c-b79c4c5a1803/upload-1711472875410.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#ACBCC1",
                                    "publishedAt": "2024-03-26T17:06:35.235Z",
                                    "id": "image__bc65ad13-e89d-43b2-b6b7-07290ebfe831",
                                    "tags": [
                                    ],
                                    "excerpt": "XX/99",
                                    "title": "Findust",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-03-26T17:08:55.582Z",
                                    "file": "https://cdn.cerealapis.com/post/75e17581-8a8d-43cc-8efd-51cf4a8562c7/upload-1711472934768.webp",
                                    "access": "inherit",
                                    "slug": "arctaurus",
                                    "createdAt": "2024-03-26T17:08:55.562Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/d1741f69-26da-488e-9ccd-79a49fb36b99/upload-1711472933920.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#CBC8DE",
                                    "publishedAt": "2024-03-26T17:08:12.693Z",
                                    "id": "image__c04d11e4-1ae2-4f99-9b06-3c9fdc27ed4d",
                                    "tags": [
                                    ],
                                    "excerpt": "XX/99",
                                    "title": "Arctaurus",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                },
                                {
                                    "lastUpdated": "2024-04-04T13:47:00.111Z",
                                    "file": "https://cdn.cerealapis.com/post/103e24d1-a642-4a41-883e-06686503a03f/upload-1712238419458.webp",
                                    "access": "inherit",
                                    "slug": "lochagon",
                                    "createdAt": "2024-04-04T13:47:00.110Z",
                                    "bgImage": "https://cdn.cerealapis.com/post/83b83ff9-6534-4610-8aea-0508d0473333/upload-1712238418759.png",
                                    "postStatus": "published",
                                    "series": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                    "client": "elestrals_image",
                                    "bgColor": "#6997C4",
                                    "publishedAt": "2024-04-04T13:44:50.584Z",
                                    "id": "image__e4803519-733b-4e91-862f-f48bc3e075e4",
                                    "tags": [
                                    ],
                                    "excerpt": "XX/99",
                                    "title": "Lochagon",
                                    "type": "image",
                                    "sentEmail": true,
                                    "seriesData": {
                                        "logoImage": "https://cdn.cerealapis.com/series/81437d5c-339b-4e54-8b7c-fd3b01e1e67c/upload-1710942558904.png",
                                        "access": "public",
                                        "category": "Card Set",
                                        "slug": "frostfall",
                                        "bgImage": "https://cdn.cerealapis.com/series/978cdf40-12fe-423e-8f1c-d3f89f2089da/upload-1712069693612.jpg",
                                        "id": "ff2c16e6-eb53-45f4-bdd5-91ccea68d70d",
                                        "videoCount": 109,
                                        "seriesType": "photo-album",
                                        "title": "Frostfall"
                                    }
                                }
                            ]
export const cards = [
      {
          "file": "https://cdn.cerealapis.com/post/e2d08f1c-6b34-43fb-8def-a9d8b144a660/upload-1671726210209.webp",
          "collections": {},
          "type1": "wind",
          "type2": "wind",
          "type3": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/fe28e581-8b4d-4afa-8a2f-d80c0481071e/upload-1682804184089.webp",
          "attack": "15",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "full-art",
          "id": "image__2465aec8-e8b3-4b99-a7a1-a10794e1c48f",
          "tags": [],
          "author": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
          "lastUpdated": "2023-04-29T21:36:25.629Z",
          "access": "public",
          "slug": "penterror-full-art",
          "createdAt": "2022-11-07T21:12:23.494Z",
          "defense": "8",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T04:00:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "dragon",
          "description": "When this Penterror is destroyed by an opponent’s card effect you can Special Cast any number of Hydrake and Twindra from your hand or deck in Attack Position.",
          "excerpt": "BS1-000",
          "title": "Penterror",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/6810b997-e024-4b4b-b43a-4e8864c1e586/upload-1666822760398.png",
              "category": "Artist",
              "slug": "diego-monster",
              "bgImage": "https://cdn.cerealapis.com/author/45070d0c-4dd8-491c-a56a-8a164afa9400/upload-1666822760394.png",
              "id": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
              "videoCount": 17,
              "title": "Diego Monster "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/eaeda9e5-2905-4ac0-95cd-57b8dd1aa7d9/upload-1671726242030.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/18941787-e3d0-47b3-a24d-1a083470bd32/upload-1671726241069.webp",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "common",
          "id": "image__8b10dfd8-cfa6-43e7-8dd1-73a5ceeace3b",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2023-02-01T19:43:13.403Z",
          "access": "public",
          "slug": "teratlas",
          "createdAt": "2022-09-09T01:07:35.263Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T04:05:00.000Z",
          "subclass2": "golem",
          "subclass1": "insectoid",
          "description": "``` embed\nEarth Spirit <span class=\"elestrals-font\">E</span>\n```",
          "excerpt": "BS1-001",
          "sentEmail": true,
          "title": "Teratlas",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/3d0a3002-1243-4bc2-8e34-173a91049978/upload-1671726266344.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/29a88095-2d75-49be-868f-af31890141d9/upload-1671726265142.webp",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "common",
          "id": "image__9dfa4525-9c9f-45b5-b215-91ee8c9c0677",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2022-12-22T17:59:56.798Z",
          "access": "public",
          "slug": "vipyro",
          "createdAt": "2022-09-09T02:18:24.698Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T04:10:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "dragon",
          "description": "``` embed\nFire Spirit <span class=\"elestrals-font\">F</span>\n```",
          "excerpt": "BS1-002",
          "sentEmail": true,
          "title": "Vipyro",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/35bb0fb7-5242-4ab0-b50b-76ac0a82b4c8/upload-1671726298561.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/0446225b-6da2-4735-82cd-84910a26e1c9/upload-1671726297539.webp",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "common",
          "id": "image__cad0def3-95ce-4f1c-8958-6188207ee507",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2022-12-22T18:00:17.144Z",
          "access": "public",
          "slug": "leviaphin",
          "createdAt": "2022-09-09T02:26:29.951Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T04:15:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "oceanic",
          "description": "``` embed\nWater Spirit <span class=\"elestrals-font\">W</span>\n```",
          "excerpt": "BS1-003",
          "sentEmail": true,
          "title": "Leviaphin",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/15c427a5-f3e2-4d8e-a38c-6724aa8e0d5a/upload-1671726316003.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/784adf9e-599b-442f-a2e3-c5a54ad695f8/upload-1671726315017.webp",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "common",
          "id": "image__780d3641-50d5-4a78-8227-547fa3e31f89",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2022-12-22T18:00:34.077Z",
          "access": "public",
          "slug": "zaptor",
          "createdAt": "2022-09-09T02:30:56.416Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T04:20:00.000Z",
          "subclass2": "dragon",
          "subclass1": "archaic",
          "description": "``` embed\nThunder Spirit <span class=\"elestrals-font\">T</span>\n```",
          "excerpt": "BS1-004",
          "sentEmail": true,
          "title": "Zaptor",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/a8726f92-e6c2-4624-8915-c9bbce4da75d/upload-1671726332987.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/f5e27d21-2612-4444-973e-b8b7c13b381c/upload-1671726332007.webp",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "common",
          "id": "image__484d6958-04c9-4d22-b77f-99646015e06c",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2022-12-22T18:00:55.519Z",
          "access": "public",
          "slug": "lycarus",
          "createdAt": "2022-09-09T02:32:06.431Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T04:25:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "brute",
          "description": "``` embed\nWind Spirit <span class=\"elestrals-font\">A</span>\n```",
          "excerpt": "BS1-005",
          "sentEmail": true,
          "title": "Lycarus",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/7b7f0dd9-c27c-4f53-804f-3aeda7d26339/upload-1671726350691.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/3a1baee1-79c1-4ba7-8445-5337ae126d60/upload-1671726349591.webp",
          "attack": "2",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "common",
          "id": "image__71a4b8cb-6b22-4f26-af91-22808ca93cc5",
          "tags": [],
          "author": "5b6d4f73-9074-42bf-ba13-55a2bc79a3be",
          "lastUpdated": "2022-12-22T18:01:20.348Z",
          "access": "public",
          "slug": "drataya",
          "createdAt": "2022-10-27T08:27:15.013Z",
          "defense": "4",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T04:30:00.000Z",
          "subclass2": "dragon",
          "subclass1": "dryad",
          "description": "When a player Casts Ambrosia you can draw 2 cards.",
          "excerpt": "BS1-006",
          "sentEmail": true,
          "title": "Drataya",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/f9180a82-e3e4-4c6f-b7ae-d8ecc3e0a86f/upload-1666824288560.png",
              "category": "Artist",
              "slug": "zeta-strokes",
              "bgImage": "https://cdn.cerealapis.com/author/0f3bcf55-23be-41f6-b956-d426b822defc/upload-1666824288588.png",
              "id": "5b6d4f73-9074-42bf-ba13-55a2bc79a3be",
              "videoCount": 3,
              "title": "Zeta Strokes  "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/fb16628b-0224-4213-9ca4-de63ab707385/upload-1671726370269.webp",
          "collections": {},
          "type1": "earth",
          "type2": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/7f70a079-53a7-4b00-91da-1be154eb5b51/upload-1671726369245.webp",
          "attack": "7",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "rare",
          "id": "image__fcbc7b05-e2d1-43f5-b803-6fa9ffd4c3eb",
          "tags": [],
          "author": "5b6d4f73-9074-42bf-ba13-55a2bc79a3be",
          "lastUpdated": "2022-12-22T18:01:39.554Z",
          "access": "public",
          "slug": "ladogon",
          "createdAt": "2022-10-27T20:06:22.231Z",
          "defense": "8",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T04:35:00.000Z",
          "subclass2": "dragon",
          "subclass1": "dryad",
          "description": "When a player Casts Ambrosia or Golden Apple of Discord you can target and destroy a card.",
          "excerpt": "BS1-007",
          "sentEmail": true,
          "title": "Ladogon",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/f9180a82-e3e4-4c6f-b7ae-d8ecc3e0a86f/upload-1666824288560.png",
              "category": "Artist",
              "slug": "zeta-strokes",
              "bgImage": "https://cdn.cerealapis.com/author/0f3bcf55-23be-41f6-b956-d426b822defc/upload-1666824288588.png",
              "id": "5b6d4f73-9074-42bf-ba13-55a2bc79a3be",
              "videoCount": 3,
              "title": "Zeta Strokes  "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/b4ae38f7-d42e-4ea8-89bd-8f4cd366c5c0/upload-1671726394620.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/68f85b12-e2a2-4b8d-9bbf-d90726af578f/upload-1671726393616.webp",
          "attack": "2",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "common",
          "id": "image__0ab2619a-70d8-4d16-a9b2-ca5c50722d52",
          "tags": [],
          "author": "06f6337e-75f8-4837-a14b-bb73e880382e",
          "lastUpdated": "2022-12-22T18:01:54.333Z",
          "access": "public",
          "slug": "rummagem",
          "createdAt": "2022-10-27T20:14:42.423Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T04:40:00.000Z",
          "subclass2": "brute",
          "subclass1": "golem",
          "description": "``` embed\nWhen you Normal Cast this Rummagem you can Search your deck for an Elestral with Enchantment Cost <span class=\"elestrals-font\">E</span> and add it to your hand.\n```",
          "excerpt": "BS1-008",
          "sentEmail": true,
          "title": "Rummagem",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/696727cb-6e52-4f00-b2b5-b5ee9b967d3e/upload-1666823423451.png",
              "category": "Artist",
              "slug": "giovanni-n4nin4ni-aguilar",
              "bgImage": "https://cdn.cerealapis.com/author/49850f39-8072-4bd7-8776-15508c33f3d7/upload-1666823423402.png",
              "id": "06f6337e-75f8-4837-a14b-bb73e880382e",
              "videoCount": 53,
              "title": "Giovanni Aguilar"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/4dbce8ca-12ed-4749-9d39-8ff5ae7622fe/upload-1671726414412.webp",
          "collections": {},
          "type1": "earth",
          "type2": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/0262996a-b219-43f4-a648-5fb4324b79a2/upload-1671726413357.webp",
          "attack": "4",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "uncommon",
          "id": "image__d96963aa-a09c-41d9-b6a2-d7ec64253a10",
          "tags": [],
          "author": "06f6337e-75f8-4837-a14b-bb73e880382e",
          "lastUpdated": "2022-12-22T18:02:28.414Z",
          "access": "public",
          "slug": "scavagem",
          "createdAt": "2022-10-27T20:17:02.993Z",
          "defense": "6",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T04:45:00.000Z",
          "subclass2": "behemoth",
          "subclass1": "golem",
          "description": "``` embed\nWhen you Ascend into this Scavagem you can Expend <span class=\"elestrals-font\">E</span> in order to Search your deck for up to 2 cards with Enchantment Cost <span class=\"elestrals-font\">E</span> or <span class=\"elestrals-font\">E</span><span class=\"elestrals-font\">E</span> and add them to your hand.\n```",
          "excerpt": "BS1-009",
          "sentEmail": true,
          "title": "Scavagem",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/696727cb-6e52-4f00-b2b5-b5ee9b967d3e/upload-1666823423451.png",
              "category": "Artist",
              "slug": "giovanni-n4nin4ni-aguilar",
              "bgImage": "https://cdn.cerealapis.com/author/49850f39-8072-4bd7-8776-15508c33f3d7/upload-1666823423402.png",
              "id": "06f6337e-75f8-4837-a14b-bb73e880382e",
              "videoCount": 53,
              "title": "Giovanni Aguilar"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/21e72504-20fe-412d-8954-d6af72bfdaa9/upload-1671726444186.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/6a1c68e9-64ef-4164-8f8e-c3adf1ea347a/upload-1671726443165.webp",
          "attack": "1",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "uncommon",
          "id": "image__c4365aeb-7c0f-4eae-9d69-ad16135ae498",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2022-12-22T18:02:40.897Z",
          "access": "public",
          "slug": "spinymph",
          "defense": "5",
          "createdAt": "2022-10-27T20:17:57.102Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T04:50:00.000Z",
          "subclass1": "insectoid",
          "description": "``` embed\nWhen this Spinymph is destroyed in battle you can Cast it as an Artifact Empowering the Elestral that destroyed it with the effect \"The Empowered Elestral has 0 base <span class=\"elestrals-font\">J</span>.”\n```",
          "excerpt": "BS1-010",
          "sentEmail": true,
          "title": "Spinymph",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/8c6fbe12-b53d-4052-a6fe-2153d8ab06f7/upload-1671726464705.webp",
          "collections": {},
          "type1": "earth",
          "type2": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/9226f052-dc0e-4969-b12a-f64cee79fbd0/upload-1682804615222.webp",
          "attack": "1",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "holo-rare",
          "id": "image__8003bdb6-21b7-4c39-bd06-04654a5373eb",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2023-04-29T21:43:36.321Z",
          "access": "public",
          "slug": "spinosect",
          "createdAt": "2022-10-27T20:18:26.511Z",
          "defense": "9",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T04:55:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "insectoid",
          "description": "``` embed\nThis Spinosect can attack in Defense Position. If it does, use its <span class=\"elestrals-font\">O</span> for calculations.\n```",
          "excerpt": "BS1-011",
          "sentEmail": true,
          "title": "Spinosect",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/de7d15e5-c471-4ef2-add5-8006a1442948/upload-1671726485921.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/f3878bec-65ed-4b3c-b381-d5ddfc568270/upload-1671726484894.webp",
          "attack": "1",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "common",
          "id": "image__79376fe1-c56b-4310-8d2b-a6cbb37628de",
          "tags": [],
          "author": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
          "lastUpdated": "2022-12-30T23:32:45.941Z",
          "access": "public",
          "slug": "clovie",
          "createdAt": "2022-10-27T20:19:02.772Z",
          "defense": "4",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T05:00:00.000Z",
          "subclass2": "dryad",
          "subclass1": "archaic",
          "description": "``` embed\nWhen this Clovie receives 1 or more <span class=\"elestrals-font\">E</span> you can draw a card.\n```",
          "excerpt": "BS1-012",
          "sentEmail": true,
          "title": "Clovie",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4c88d483-9e81-4854-942f-97df6f95299e/upload-1666824047475.png",
              "category": "Artist",
              "slug": "phoebe-the-last-shaymin-sumner-twisk",
              "bgImage": "https://cdn.cerealapis.com/author/a0c334f7-fbb6-426d-88ca-2915f35213e4/upload-1666824047501.png",
              "id": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
              "videoCount": 33,
              "title": "Phoebe Sumner-Twisk"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/f528af07-6745-4eee-8cdc-ae8129dda504/upload-1671726502140.webp",
          "collections": {},
          "type1": "earth",
          "type2": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/5ff25274-c44c-4fdc-aa9c-c4eb130b8f79/upload-1671726501135.webp",
          "attack": "6",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "uncommon",
          "id": "image__44d03884-c1b7-406b-a26a-0ee9c65ff6c5",
          "tags": [],
          "author": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
          "lastUpdated": "2022-12-22T18:03:45.811Z",
          "access": "public",
          "slug": "sakurasaur",
          "createdAt": "2022-10-27T20:19:39.114Z",
          "defense": "8",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T05:05:00.000Z",
          "subclass2": "dryad",
          "subclass1": "archaic",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">E</span> in order to draw 2 cards.\n```",
          "excerpt": "BS1-013",
          "sentEmail": true,
          "title": "Sakurasaur",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4c88d483-9e81-4854-942f-97df6f95299e/upload-1666824047475.png",
              "category": "Artist",
              "slug": "phoebe-the-last-shaymin-sumner-twisk",
              "bgImage": "https://cdn.cerealapis.com/author/a0c334f7-fbb6-426d-88ca-2915f35213e4/upload-1666824047501.png",
              "id": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
              "videoCount": 33,
              "title": "Phoebe Sumner-Twisk"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/136cccf9-c8e9-49e5-bed7-90fade404536/upload-1671726551324.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/d95024b3-979c-4360-b987-13b286dd1bdf/upload-1671726550312.webp",
          "attack": "3",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "rare",
          "id": "image__c63bd1a6-d229-44ce-9e8b-6e5ffa420365",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2022-12-22T18:04:05.136Z",
          "access": "public",
          "slug": "tectaurus",
          "createdAt": "2022-10-27T20:24:17.067Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T05:10:00.000Z",
          "subclass2": "behemoth",
          "subclass1": "golem",
          "description": "``` embed\nThis Tectaurus gets +1 <span class=\"elestrals-font\">J</span> for each Enchanting <span class=\"elestrals-font\">E</span> on the field.\n```",
          "excerpt": "BS1-014",
          "sentEmail": true,
          "title": "Tectaurus",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/4627285e-ba20-48a9-bb80-219954be4340/upload-1671726566910.webp",
          "collections": {},
          "type1": "earth",
          "type2": "water",
          "bgImage": "https://cdn.cerealapis.com/post/8f31cf3f-245c-4100-8792-ef03b909039a/upload-1671726565890.webp",
          "attack": "3",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "uncommon",
          "id": "image__a5585bda-d187-4706-9b73-3ece72149d16",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2022-12-22T18:04:15.743Z",
          "access": "public",
          "slug": "barabog",
          "createdAt": "2022-10-27T20:24:49.413Z",
          "defense": "7",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T05:15:00.000Z",
          "subclass2": "dryad",
          "subclass1": "aquatic",
          "description": "``` embed\nWhen an opponent Casts an Elestral or Rune without <span class=\"elestrals-font\">E</span> or <span class=\"elestrals-font\">W</span> they must Expend <span class=\"elestrals-font\">X</span>.\n```",
          "excerpt": "BS1-015",
          "sentEmail": true,
          "title": "Barabog",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/502b32d5-ff4d-449e-b2d2-e9d58c8d09bf/upload-1671726582374.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/c774f2fe-a773-4f0d-9c02-10c36cc39363/upload-1671726581136.webp",
          "attack": "1",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "uncommon",
          "id": "image__d096f350-b9d1-49ac-a8fc-8f00d261c912",
          "tags": [],
          "author": "3067a712-8791-4a4e-a6dd-cc9e4b263e94",
          "lastUpdated": "2022-12-22T18:04:26.469Z",
          "access": "public",
          "slug": "equilynx",
          "createdAt": "2022-10-27T20:27:11.128Z",
          "defense": "4",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T05:20:00.000Z",
          "subclass2": "dryad",
          "subclass1": "archaic",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">E</span> in order to force an opponent to reveal their hand until the End Phase.\n```",
          "excerpt": "BS1-016",
          "sentEmail": true,
          "title": "Titanostalk",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4a4adc1b-2836-4e52-86af-50a54227c185/upload-1666824162136.png",
              "category": "Artist",
              "slug": "victor-vcreateart-rosales",
              "bgImage": "https://cdn.cerealapis.com/author/5210982f-5535-48b5-884b-cdfb1d03de56/upload-1666824162133.png",
              "id": "3067a712-8791-4a4e-a6dd-cc9e4b263e94",
              "videoCount": 10,
              "title": "Victor Rosales"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/81522375-4926-428f-b31d-bba2babf9766/upload-1671726598239.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/9eb95791-4a98-459c-8235-c9bcedb2d9db/upload-1671726597278.webp",
          "attack": "4",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "rare",
          "id": "image__3f6b6c39-09c0-4a85-aa8b-00d2058a4b00",
          "tags": [],
          "author": "75489aa3-accc-4053-ab04-30a1d49d13bb",
          "lastUpdated": "2022-12-30T23:37:20.831Z",
          "access": "public",
          "slug": "vysceris",
          "createdAt": "2022-10-27T20:28:01.513Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T05:25:00.000Z",
          "subclass1": "dryad",
          "description": "``` embed\nYou can Nexus up to <span class=\"elestrals-font\">E</span><span class=\"elestrals-font\">E</span>. When you do, you can target and destroy a Rune.\n```",
          "excerpt": "BS1-017",
          "sentEmail": true,
          "title": "Equilynx",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/292635bf-0ef8-46f5-8583-dad865383c11/upload-1666719837086.png",
              "category": "Artist",
              "slug": "amalry",
              "bgImage": "https://cdn.cerealapis.com/author/080d7ae5-d84a-4885-a0d2-3443fa849520/upload-1666719837080.png",
              "id": "75489aa3-accc-4053-ab04-30a1d49d13bb",
              "videoCount": 18,
              "title": "Amalry"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/bf6ed7a0-3494-423d-bb67-ef3c3e871a72/upload-1671726622985.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/581d992a-0c5b-4b06-9108-a2be2106efaf/upload-1682804954486.webp",
          "attack": "4",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "holo-rare",
          "id": "image__15f83235-3ced-4821-8c21-37be2209f5b6",
          "tags": [],
          "author": "88185685-0ac3-4a6c-b976-0aa8dc3403fe",
          "lastUpdated": "2023-04-29T21:49:15.769Z",
          "access": "public",
          "slug": "pandicine",
          "createdAt": "2022-10-27T20:30:01.526Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T05:30:00.000Z",
          "subclass1": "insectoid",
          "description": "``` embed\nThis Vysceris gains +1 <span class=\"elestrals-font\">J</span> for each Vysceris on the field. When this Vysceris destroys an Elestral in battle you can Special Cast a Vysceris from your hand or deck in Defense Position.\n```",
          "excerpt": "BS1-018",
          "sentEmail": true,
          "title": "Vysceris",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/f881a1cf-d017-4d34-b833-9c5dc9fe7eab/upload-1666823969818.png",
              "category": "Artist",
              "slug": "marcel-bromojumbo-romo",
              "bgImage": "https://cdn.cerealapis.com/author/aa6415a1-f484-4a75-988b-80db7af2fe56/upload-1666823969899.png",
              "id": "88185685-0ac3-4a6c-b976-0aa8dc3403fe",
              "videoCount": 14,
              "title": "Marcel Romo "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/923bfe37-cd48-4288-8541-786c9e562dd6/upload-1671726643300.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/b60c7060-8987-42eb-b47a-77597bcc6b04/upload-1682805011533.webp",
          "attack": "1",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "holo-rare",
          "id": "image__3a22e739-b715-43bb-b809-80aab328a33d",
          "tags": [],
          "author": "06f6337e-75f8-4837-a14b-bb73e880382e",
          "lastUpdated": "2023-04-29T21:50:12.548Z",
          "access": "public",
          "slug": "necruff",
          "createdAt": "2022-10-27T20:30:46.977Z",
          "defense": "4",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T05:35:00.000Z",
          "subclass1": "brute",
          "description": "``` embed\nYou can Disenchant <span class=\"elestrals-font\">E</span> from this Pandicine in order to return an Invoke Rune from your Underworld to your hand.\n```",
          "excerpt": "BS1-019",
          "sentEmail": true,
          "title": "Pandicine",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/696727cb-6e52-4f00-b2b5-b5ee9b967d3e/upload-1666823423451.png",
              "category": "Artist",
              "slug": "giovanni-n4nin4ni-aguilar",
              "bgImage": "https://cdn.cerealapis.com/author/49850f39-8072-4bd7-8776-15508c33f3d7/upload-1666823423402.png",
              "id": "06f6337e-75f8-4837-a14b-bb73e880382e",
              "videoCount": 53,
              "title": "Giovanni Aguilar"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/31c0b6da-4264-4ea4-9e94-8160f738012b/upload-1671726662982.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/55cc653d-86bb-4d6c-b4a3-f7e39decf672/upload-1671726661936.webp",
          "attack": "5",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "common",
          "id": "image__5b6c1585-1a5e-40ce-b669-4bb01b3dd9a7",
          "tags": [],
          "author": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
          "lastUpdated": "2022-12-22T18:07:07.255Z",
          "access": "public",
          "slug": "blazerus",
          "createdAt": "2022-10-27T20:31:13.127Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T05:40:00.000Z",
          "subclass2": "brute",
          "subclass1": "eldritch",
          "description": "``` embed\nWhen this Necruff destroys an opponent’s Elestral in battle that opponent must Expend <span class=\"elestrals-font\">X</span>.\n```",
          "excerpt": "BS1-020",
          "sentEmail": true,
          "title": "Necruff",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/9abbfa57-fadc-42d9-bd52-3907b145d6ff/upload-1662720081319.jpeg",
              "category": "Artist",
              "slug": "jonny-de-oliviera",
              "bgImage": "https://cdn.cerealapis.com/author/d6271a98-e34d-4b1a-843c-45b03f86abb2/upload-1662720081310.jpeg",
              "id": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
              "videoCount": 22,
              "title": "Jonny De Oliviera"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/f5c3c057-dc11-4c17-aa37-30b15fdc8651/upload-1671726681601.webp",
          "collections": {},
          "type1": "fire",
          "type2": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/d7d5a79d-96d7-4096-a909-57ea97159bd0/upload-1671726680542.webp",
          "attack": "7",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "uncommon",
          "id": "image__d8db7c5d-5718-48ef-a169-0ca0c720d6bf",
          "tags": [],
          "author": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
          "lastUpdated": "2022-12-22T18:07:21.009Z",
          "access": "public",
          "slug": "trifernal",
          "createdAt": "2022-10-27T20:32:00.903Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T05:45:00.000Z",
          "subclass2": "behemoth",
          "subclass1": "eldritch",
          "description": "This Blazerus can attack twice during your Battle Phase. When this Blazerus destroys 2 Elestrals in the same Battle Phase you can Special Ascend this Blazerus to Trifernal from your hand or deck.",
          "excerpt": "BS1-021",
          "sentEmail": true,
          "title": "Blazerus",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/9abbfa57-fadc-42d9-bd52-3907b145d6ff/upload-1662720081319.jpeg",
              "category": "Artist",
              "slug": "jonny-de-oliviera",
              "bgImage": "https://cdn.cerealapis.com/author/d6271a98-e34d-4b1a-843c-45b03f86abb2/upload-1662720081310.jpeg",
              "id": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
              "videoCount": 22,
              "title": "Jonny De Oliviera"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/a0c6224a-a925-4d94-8477-a4f32a9b5ec8/upload-1671726700915.webp",
          "collections": {},
          "type1": "fire",
          "type2": "fire",
          "type3": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/f2604fb6-cc3c-41d6-9575-e412d04b9d1b/upload-1671726699879.webp",
          "attack": "14",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "rare",
          "id": "image__e8886fbe-0fcd-47dd-9faf-5a450c1e7a93",
          "featured": false,
          "tags": [],
          "author": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
          "lastUpdated": "2022-12-30T23:58:29.322Z",
          "access": "public",
          "slug": "urscout",
          "createdAt": "2022-10-27T20:32:45.113Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T05:50:00.000Z",
          "subclass2": "behemoth",
          "subclass1": "eldritch",
          "description": "``` embed\nWhen an opponent Casts an Elestral you can Expend <span class=\"elestrals-font\">F</span> in order to give it -3 <span class=\"elestrals-font\">O</span> until your next End Phase. If that Elestral’s <span class=\"elestrals-font\">O</span> is reduced to 0 by this effect, it is destroyed.\n```",
          "excerpt": "BS1-022",
          "sentEmail": true,
          "title": "Trifernal",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/9abbfa57-fadc-42d9-bd52-3907b145d6ff/upload-1662720081319.jpeg",
              "category": "Artist",
              "slug": "jonny-de-oliviera",
              "bgImage": "https://cdn.cerealapis.com/author/d6271a98-e34d-4b1a-843c-45b03f86abb2/upload-1662720081310.jpeg",
              "id": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
              "videoCount": 22,
              "title": "Jonny De Oliviera"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/3ace400d-83ce-448d-8a38-92554c03de53/upload-1671726732736.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/5f32cc69-705b-43d3-9000-b18a248c1a46/upload-1674241912490.webp",
          "attack": "1",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "common",
          "id": "image__9f44ea9a-62a4-4155-a564-65e635f8d261",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2023-01-24T08:05:20.529Z",
          "access": "public",
          "slug": "titanostalk",
          "createdAt": "2022-10-27T20:32:11.020Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T05:55:00.000Z",
          "description": "When this Urscout is destroyed in battle can Special Cast an [URSA] that costs 1 Spirit from your hand or deck.",
          "excerpt": "BS1-023",
          "sentEmail": true,
          "title": "Urscout",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/6118cadd-b65a-4778-9e0c-a20b4a991d06/upload-1671726763241.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/40b2179f-9cb5-4a33-8cc5-75b9ed2e9b88/upload-1674241955963.webp",
          "attack": "2",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "common",
          "id": "image__50f1c51f-dd1f-4fda-92ed-7eb446f5f4f8",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2023-02-12T11:27:23.611Z",
          "access": "public",
          "slug": "ursmog",
          "createdAt": "2022-10-27T20:33:12.023Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T06:00:00.000Z",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">F</span> in order to Special Cast an [URSA] Elestral that costs 1 Spirit from your hand.\n```",
          "excerpt": "BS1-024",
          "sentEmail": true,
          "title": "Ursmog",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/3115ed2d-15c3-4d35-9ec0-98c1db8d482c/upload-1671726781102.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/177604d9-3b1f-43a9-bf1d-f640bf199f5d/upload-1674241997960.webp",
          "attack": "3",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "common",
          "id": "image__6f97bfe1-eb6c-48b4-9eb6-b2048d757103",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2023-02-01T19:32:29.681Z",
          "access": "public",
          "slug": "ursear",
          "createdAt": "2022-10-27T20:33:34.931Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T06:05:00.000Z",
          "description": "When this Ursear destroys an Elestral in battle you can Special Cast an [URSA] Elestral that costs 1 Spirit from your hand or deck.",
          "excerpt": "BS1-025",
          "sentEmail": true,
          "title": "Ursear",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/76d49706-a94b-4821-9c8a-e7e1cd950ad3/upload-1671726794407.webp",
          "collections": {},
          "type1": "fire",
          "type2": "fire",
          "type3": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/15a53289-8eb4-44bc-9758-6fb179a2e5ba/upload-1674242034049.webp",
          "attack": "12",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "rare",
          "id": "image__680f7934-5627-4561-a186-c3d486ed48d5",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2023-01-24T08:09:05.692Z",
          "access": "public",
          "slug": "majursa",
          "createdAt": "2022-10-27T20:34:05.396Z",
          "defense": "12",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T06:10:00.000Z",
          "description": "``` embed\nThis Majursa gets +2 <span class=\"elestrals-font\">J</span> for each other [URSA] Elestral on your field. Your [URSA] Elestrals that cost 1 Spirit cannot be attacked and cannot be targeted by an opponent’s card effect.\n```",
          "excerpt": "BS1-026",
          "sentEmail": true,
          "title": "Majursa",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/9b5b514f-8439-4c1e-823d-5524497df2c9/upload-1671726810647.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/e7d4e603-6ea0-4743-a230-3d372d8bf4d3/upload-1671726809620.webp",
          "attack": "1",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "common",
          "id": "image__c5647950-31fa-4974-8d75-67305a40add3",
          "tags": [],
          "author": "ecb243ac-0c18-4061-9598-5a3b3da7e8bf",
          "lastUpdated": "2022-12-22T18:10:11.118Z",
          "access": "public",
          "slug": "ignectar",
          "createdAt": "2022-10-27T20:34:45.503Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T06:15:00.000Z",
          "subclass1": "insectoid",
          "description": "``` embed\nWhen you Normal Cast this Ignectar you can Special Cast up to 2 Ignectar from your hand or deck in Attack Position with <span class=\"elestrals-font\">F</span>. If you do you must skip your next Battle Phase.\n```",
          "excerpt": "BS1-027",
          "sentEmail": true,
          "title": "Ignectar",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/afa0ef97-2446-4ba0-86f2-70f380e86363/upload-1666822843305.png",
              "category": "Artist",
              "slug": "dragonith",
              "bgImage": "https://cdn.cerealapis.com/author/69a6a8f4-b3ad-481c-a2a6-dea8e9811f46/upload-1666822843306.png",
              "id": "ecb243ac-0c18-4061-9598-5a3b3da7e8bf",
              "videoCount": 5,
              "title": "Dragonith "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/1b0b4a3d-468b-498d-b196-ed1e2c2036dd/upload-1671726842202.webp",
          "collections": {},
          "type1": "fire",
          "type2": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/7e9a9c19-6bcc-4da1-a5ef-b3014519b534/upload-1671726840922.webp",
          "attack": "5",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "uncommon",
          "id": "image__fc2ffd6b-bbd8-4ac5-a7fd-b96bee95d18f",
          "tags": [],
          "author": "ecb243ac-0c18-4061-9598-5a3b3da7e8bf",
          "lastUpdated": "2022-12-31T00:08:43.463Z",
          "access": "public",
          "slug": "waspyre",
          "createdAt": "2022-10-27T20:35:09.305Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T06:20:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "insectoid",
          "description": "``` embed\nYou can Disenchant <span class=\"elestrals-font\">F</span> from an Ignectar or Waspyre to force an opponent to Expend <span class=\"elestrals-font\">X</span><span class=\"elestrals-font\">X</span>. This Waspyre's effect can be activated any number of times on your turn.\n```",
          "excerpt": "BS1-028",
          "sentEmail": true,
          "title": "Waspyre",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/afa0ef97-2446-4ba0-86f2-70f380e86363/upload-1666822843305.png",
              "category": "Artist",
              "slug": "dragonith",
              "bgImage": "https://cdn.cerealapis.com/author/69a6a8f4-b3ad-481c-a2a6-dea8e9811f46/upload-1666822843306.png",
              "id": "ecb243ac-0c18-4061-9598-5a3b3da7e8bf",
              "videoCount": 5,
              "title": "Dragonith "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/873f3f17-002c-4ce9-8475-e114ebc98b38/upload-1671726854249.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/07333a7f-6394-4859-ad0d-3e3ae750ba85/upload-1671726853278.webp",
          "attack": "3",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "common",
          "id": "image__20998e2d-2fa9-4fdc-b93e-1a1afb53457a",
          "tags": [],
          "author": "06f6337e-75f8-4837-a14b-bb73e880382e",
          "lastUpdated": "2022-12-22T18:10:47.321Z",
          "access": "public",
          "slug": "warmite",
          "createdAt": "2022-10-27T20:35:53.116Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T06:25:00.000Z",
          "subclass1": "insectoid",
          "description": "``` embed\nYou can target an opponent’s Elestral and give it -4 <span class=\"elestrals-font\">J</span> until the End Phase.\n```",
          "excerpt": "BS1-029",
          "sentEmail": true,
          "title": "Warmite",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/696727cb-6e52-4f00-b2b5-b5ee9b967d3e/upload-1666823423451.png",
              "category": "Artist",
              "slug": "giovanni-n4nin4ni-aguilar",
              "bgImage": "https://cdn.cerealapis.com/author/49850f39-8072-4bd7-8776-15508c33f3d7/upload-1666823423402.png",
              "id": "06f6337e-75f8-4837-a14b-bb73e880382e",
              "videoCount": 53,
              "title": "Giovanni Aguilar"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/971de569-665c-4f7f-b224-ad9a3243bfac/upload-1671726867534.webp",
          "collections": {},
          "type1": "fire",
          "type2": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/dd9dd752-98f2-4d8b-89c5-2f8a6d2d9094/upload-1671726866519.webp",
          "attack": "4",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "rare",
          "id": "image__4902efda-9843-4da8-a496-79e1b11e74f3",
          "tags": [],
          "author": "06f6337e-75f8-4837-a14b-bb73e880382e",
          "lastUpdated": "2022-12-22T18:11:01.774Z",
          "access": "public",
          "slug": "flarachne",
          "createdAt": "2022-10-27T20:36:21.231Z",
          "defense": "8",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T06:30:00.000Z",
          "subclass1": "insectoid",
          "description": "``` embed\nAs long as this Flarachne is Enchanted Elestrals cannot attack the turn they are Cast unless they are <span class=\"elestrals-font\">F</span>-Enchanted.\n```",
          "excerpt": "BS1-030",
          "sentEmail": true,
          "title": "Flarachne",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/696727cb-6e52-4f00-b2b5-b5ee9b967d3e/upload-1666823423451.png",
              "category": "Artist",
              "slug": "giovanni-n4nin4ni-aguilar",
              "bgImage": "https://cdn.cerealapis.com/author/49850f39-8072-4bd7-8776-15508c33f3d7/upload-1666823423402.png",
              "id": "06f6337e-75f8-4837-a14b-bb73e880382e",
              "videoCount": 53,
              "title": "Giovanni Aguilar"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/6881f0ca-ecd0-4ddc-ae44-867fd9f5393a/upload-1671726881039.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/8390ab84-9b5d-4081-9b76-56a9329b7f6d/upload-1671726880017.webp",
          "attack": "3",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "uncommon",
          "id": "image__61820bf6-876a-479c-8cb5-21b3f323b4a9",
          "tags": [],
          "author": "88185685-0ac3-4a6c-b976-0aa8dc3403fe",
          "lastUpdated": "2022-12-31T00:12:39.369Z",
          "access": "public",
          "slug": "kindleo",
          "createdAt": "2022-10-27T20:37:02.398Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T06:35:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "brute",
          "description": "``` embed\nYou can Nexus up to <span class=\"elestrals-font\">F</span><span class=\"elestrals-font\">F</span>. When you do, you can target and destroy an opponent’s Elestral.\n```",
          "excerpt": "BS1-031",
          "sentEmail": true,
          "title": "Kindleo",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/f881a1cf-d017-4d34-b833-9c5dc9fe7eab/upload-1666823969818.png",
              "category": "Artist",
              "slug": "marcel-bromojumbo-romo",
              "bgImage": "https://cdn.cerealapis.com/author/aa6415a1-f484-4a75-988b-80db7af2fe56/upload-1666823969899.png",
              "id": "88185685-0ac3-4a6c-b976-0aa8dc3403fe",
              "videoCount": 14,
              "title": "Marcel Romo "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/81a22889-6dea-419c-8761-0db6ac4d84b6/upload-1671726894626.webp",
          "collections": {},
          "type1": "fire",
          "type2": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/776bfb62-a83e-45c8-b8eb-4c8be4b04e28/upload-1682805396000.webp",
          "attack": "7",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "holo-rare",
          "id": "image__931cfeda-eaec-4586-b827-49622f1e06f2",
          "tags": [],
          "author": "88185685-0ac3-4a6c-b976-0aa8dc3403fe",
          "lastUpdated": "2023-04-29T21:56:37.196Z",
          "access": "public",
          "slug": "leonite",
          "createdAt": "2022-10-27T20:37:55.497Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T06:40:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "behemoth",
          "description": "As long as this Leonite is in Attack Position players cannot Cast Invoke Runes.",
          "excerpt": "BS1-032",
          "sentEmail": true,
          "title": "Leonite",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/f881a1cf-d017-4d34-b833-9c5dc9fe7eab/upload-1666823969818.png",
              "category": "Artist",
              "slug": "marcel-bromojumbo-romo",
              "bgImage": "https://cdn.cerealapis.com/author/aa6415a1-f484-4a75-988b-80db7af2fe56/upload-1666823969899.png",
              "id": "88185685-0ac3-4a6c-b976-0aa8dc3403fe",
              "videoCount": 14,
              "title": "Marcel Romo "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/c44d6487-df38-47e2-a636-4c94132e7a5a/upload-1671726908749.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/791469ac-00ad-4819-90ac-e87cca8e63a3/upload-1671726907575.webp",
          "attack": "3",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "uncommon",
          "id": "image__4fbf7c13-f350-4daf-a902-be366d4accb2",
          "tags": [],
          "author": "19eb7b3b-3b39-464e-9a88-427e8f289558",
          "lastUpdated": "2022-12-22T18:12:16.291Z",
          "access": "public",
          "slug": "sinder",
          "createdAt": "2022-10-27T20:49:49.007Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T06:45:00.000Z",
          "subclass1": "eldritch",
          "description": "``` embed\nWhen this Sinder receives 1 or more <span class=\"elestrals-font\">F</span> your opponents must send the top 3 cards from their deck to their Underworld.\n```",
          "excerpt": "BS1-033",
          "title": "Sinder",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/29f7dfd3-2003-4e21-932b-31d442bb668c/upload-1666966185555.png",
              "category": "Artist",
              "slug": "elite4",
              "id": "19eb7b3b-3b39-464e-9a88-427e8f289558",
              "videoCount": 42,
              "title": "Élite4"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/91ae0f0f-6ba8-4657-af97-d3f9e01af3d5/upload-1671726934887.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/7c585927-1e12-4eb3-9c5b-20df65bd7ab8/upload-1682805431418.webp",
          "attack": "3",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "holo-rare",
          "id": "image__66ed132a-1cc3-441b-bc9f-8b0635bc4500",
          "tags": [],
          "author": "19eb7b3b-3b39-464e-9a88-427e8f289558",
          "lastUpdated": "2023-04-29T21:57:12.353Z",
          "access": "public",
          "slug": "emphyrix",
          "createdAt": "2022-10-27T20:50:59.394Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T06:50:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "avian",
          "description": "``` embed\nWhen this Emphyrix is destroyed in battle or by an opponent's card effect you can Expend <span class=\"elestrals-font\">F</span>. If you do, during this turn's End Phase you can Special Cast it.\n```",
          "excerpt": "BS1-034",
          "title": "Emphyrix",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/29f7dfd3-2003-4e21-932b-31d442bb668c/upload-1666966185555.png",
              "category": "Artist",
              "slug": "elite4",
              "id": "19eb7b3b-3b39-464e-9a88-427e8f289558",
              "videoCount": 42,
              "title": "Élite4"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/a88d97f6-f4f6-451e-8f06-79bc949fd960/upload-1671726948728.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/edf61c09-7027-45ea-8f10-736c060c30be/upload-1671726947829.webp",
          "attack": "3",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "rare",
          "id": "image__9a570b37-fdf2-4009-8c6e-aae1b8f567dd",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2022-12-22T18:12:40.332Z",
          "access": "public",
          "slug": "volcaries",
          "createdAt": "2022-10-27T20:51:37.336Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T06:55:00.000Z",
          "subclass2": "brute",
          "subclass1": "ethereal",
          "description": "``` embed\nThis Volcaries gets +1 <span class=\"elestrals-font\">J</span> for each Enchanting <span class=\"elestrals-font\">F</span> on the field.\n```",
          "excerpt": "BS1-035",
          "title": "Volcaries",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/281f245e-c529-4af1-b9b1-dcef5b864b0b/upload-1671726962658.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/af5147e0-37ae-43a0-b9be-5cd4b6c480b1/upload-1671726961660.webp",
          "attack": "2",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "common",
          "id": "image__84b08810-5cc8-4c07-a4dc-3e94134db978",
          "tags": [],
          "author": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
          "lastUpdated": "2022-12-31T00:21:14.402Z",
          "access": "public",
          "slug": "krakkid",
          "createdAt": "2022-10-27T20:52:30.192Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T07:00:00.000Z",
          "subclass1": "oceanic",
          "description": "When you Cast this Krakkid you can target an opponent’s Elestral. That Elestral cannot attack and its effect is negated as long as this Krakkid is Enchanted.",
          "excerpt": "BS1-036",
          "title": "Krakkid",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4c88d483-9e81-4854-942f-97df6f95299e/upload-1666824047475.png",
              "category": "Artist",
              "slug": "phoebe-the-last-shaymin-sumner-twisk",
              "bgImage": "https://cdn.cerealapis.com/author/a0c334f7-fbb6-426d-88ca-2915f35213e4/upload-1666824047501.png",
              "id": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
              "videoCount": 33,
              "title": "Phoebe Sumner-Twisk"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/572693a9-bd72-44b0-8e3c-b439553bc6ed/upload-1671726987716.webp",
          "collections": {},
          "type1": "water",
          "type2": "water",
          "bgImage": "https://cdn.cerealapis.com/post/1502fefa-4410-4c67-a0ea-3fd9358a3f54/upload-1682805452903.webp",
          "attack": "5",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "holo-rare",
          "id": "image__17a62476-ff02-4bd4-856d-6c63a0b52793",
          "tags": [],
          "author": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
          "lastUpdated": "2023-04-29T21:57:33.959Z",
          "access": "public",
          "slug": "krakking",
          "createdAt": "2022-10-27T20:52:52.052Z",
          "defense": "8",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T07:05:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "oceanic",
          "description": "When you Cast this Krakking you can target up to 2 unoccupied slots in an opponent’s Elestral Row. Those Elestral slots cannot be used as long as this Krakking is Enchanted.",
          "excerpt": "BS1-037",
          "title": "Krakking",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4c88d483-9e81-4854-942f-97df6f95299e/upload-1666824047475.png",
              "category": "Artist",
              "slug": "phoebe-the-last-shaymin-sumner-twisk",
              "bgImage": "https://cdn.cerealapis.com/author/a0c334f7-fbb6-426d-88ca-2915f35213e4/upload-1666824047501.png",
              "id": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
              "videoCount": 33,
              "title": "Phoebe Sumner-Twisk"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/0bf81a65-60d6-43d6-ab90-1627b8c8dee6/upload-1671727005788.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/e4e6c2e4-1aec-4555-8945-1e7fead0dd91/upload-1671727004657.webp",
          "attack": "2",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "common",
          "id": "image__50468a93-4058-484a-9c8d-6503a71f8e7e",
          "tags": [],
          "author": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
          "lastUpdated": "2022-12-22T18:13:18.519Z",
          "access": "public",
          "slug": "sluggle",
          "createdAt": "2022-10-27T20:53:24.318Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T07:10:00.000Z",
          "subclass1": "oceanic",
          "description": "``` embed\nWhen you Cast this Sluggle you can return up to <span class=\"elestrals-font\">W</span><span class=\"elestrals-font\">W</span><span class=\"elestrals-font\">W</span> from your Underworld to your Spirit Deck.\n```",
          "excerpt": "BS1-038",
          "title": "Sluggle",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4c88d483-9e81-4854-942f-97df6f95299e/upload-1666824047475.png",
              "category": "Artist",
              "slug": "phoebe-the-last-shaymin-sumner-twisk",
              "bgImage": "https://cdn.cerealapis.com/author/a0c334f7-fbb6-426d-88ca-2915f35213e4/upload-1666824047501.png",
              "id": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
              "videoCount": 33,
              "title": "Phoebe Sumner-Twisk"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/3e7e46c1-870a-4af0-8aa4-91b8634ee27c/upload-1671727021712.webp",
          "collections": {},
          "type1": "water",
          "type2": "water",
          "bgImage": "https://cdn.cerealapis.com/post/307cb039-8806-4a4e-9007-b15af583113b/upload-1671727020681.webp",
          "attack": "4",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "uncommon",
          "id": "image__e69619de-26a6-4c8c-add9-7b4be8daba2c",
          "tags": [],
          "author": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
          "lastUpdated": "2022-12-22T18:13:30.866Z",
          "access": "public",
          "slug": "glauby",
          "createdAt": "2022-10-27T20:53:50.127Z",
          "defense": "8",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T07:15:00.000Z",
          "subclass1": "oceanic",
          "description": "``` embed\nDuring your Main Phase you can Special Ascend this Glauby to Majesea from your hand or deck if it is <span class=\"elestrals-font\">W</span><span class=\"elestrals-font\">W</span><span class=\"elestrals-font\">W</span>-Enchanted.\n```",
          "excerpt": "BS1-039",
          "title": "Glauby",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4c88d483-9e81-4854-942f-97df6f95299e/upload-1666824047475.png",
              "category": "Artist",
              "slug": "phoebe-the-last-shaymin-sumner-twisk",
              "bgImage": "https://cdn.cerealapis.com/author/a0c334f7-fbb6-426d-88ca-2915f35213e4/upload-1666824047501.png",
              "id": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
              "videoCount": 33,
              "title": "Phoebe Sumner-Twisk"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/a3430775-2337-4132-9772-bdac79163eff/upload-1671727037115.webp",
          "collections": {},
          "type1": "water",
          "type2": "water",
          "type3": "water",
          "bgImage": "https://cdn.cerealapis.com/post/d70b9bf4-dd55-444c-940f-2d7f77f9d1ee/upload-1671727036134.webp",
          "attack": "6",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "rare",
          "id": "image__dc8210c1-297a-47a8-ba0e-ca8ecb78e058",
          "tags": [],
          "author": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
          "lastUpdated": "2022-12-31T00:33:08.282Z",
          "access": "public",
          "slug": "majesea",
          "createdAt": "2022-10-27T20:54:13.712Z",
          "defense": "14",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T07:20:00.000Z",
          "subclass2": "dragon",
          "subclass1": "oceanic",
          "description": "``` embed\nThis Majesea gets +1 <span class=\"elestrals-font\">J</span> for each Enchanting <span class=\"elestrals-font\">W</span> on your field. <span class=\"elestrals-font\">W</span>-Enchanted Elestrals cannot be targeted by an opponent’s Elestral effect.\n```",
          "excerpt": "BS1-040",
          "title": "Majesea",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4c88d483-9e81-4854-942f-97df6f95299e/upload-1666824047475.png",
              "category": "Artist",
              "slug": "phoebe-the-last-shaymin-sumner-twisk",
              "bgImage": "https://cdn.cerealapis.com/author/a0c334f7-fbb6-426d-88ca-2915f35213e4/upload-1666824047501.png",
              "id": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
              "videoCount": 33,
              "title": "Phoebe Sumner-Twisk"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/011fd9f4-aa68-41c0-8da2-d93b350d0c43/upload-1671727054211.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/fdcc691b-e5ce-4c6a-bca2-c6959e9693b5/upload-1671727053093.webp",
          "attack": "2",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "uncommon",
          "id": "image__01ff0b86-3861-4e43-9953-72e137bf602b",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2022-12-22T18:13:59.989Z",
          "access": "public",
          "slug": "foamee",
          "createdAt": "2022-10-27T20:54:39.908Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T07:25:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "oceanic",
          "description": "``` embed\nWhen this Foamee is destroyed in battle you can Special Cast an Elestral with Enchantment Cost <span class=\"elestrals-font\">W</span> from your deck in Defense Position.\n```",
          "excerpt": "BS1-041",
          "title": "Foamee",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/a9334e28-1b13-458b-87bc-fe51feced8fa/upload-1671727067821.webp",
          "collections": {},
          "type1": "water",
          "type2": "water",
          "bgImage": "https://cdn.cerealapis.com/post/325524d1-bf9a-4da1-b5a4-02492ff3224e/upload-1671727066742.webp",
          "attack": "7",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "rare",
          "id": "image__20d15566-0b78-423c-b182-bbdfafe4ab3c",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2022-12-22T18:14:11.011Z",
          "access": "public",
          "slug": "capregal",
          "createdAt": "2022-10-27T20:55:04.149Z",
          "defense": "4",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T07:30:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "oceanic",
          "description": "``` embed\nWhen this Capregal destroys an Elestral in battle you can Special Cast an Elestral with Enchantment Cost <span class=\"elestrals-font\">W</span> from your Underworld in Defense Position.\n```",
          "excerpt": "BS1-042",
          "title": "Capregal",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/bf77fc1c-5c72-4977-a938-1c21267c694e/upload-1671727082760.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/465996f6-f7bf-4581-9456-e3011d473bf7/upload-1671727081831.webp",
          "attack": "2",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "common",
          "id": "image__09cae0fb-8464-41b6-8acc-ec29be069be1",
          "featured": false,
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2022-12-22T18:14:22.329Z",
          "access": "public",
          "slug": "typhlant",
          "createdAt": "2022-10-27T20:55:36.702Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T07:35:00.000Z",
          "subclass2": "brute",
          "subclass1": "aquatic",
          "description": "When you Cast this Typhlant you can discard a card from an opponent’s hand at random.",
          "excerpt": "BS1-043",
          "title": "Typhlant",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/5ca05d4b-169e-47ce-85c3-9552b06af84f/upload-1671727098781.webp",
          "collections": {},
          "type1": "water",
          "type2": "water",
          "bgImage": "https://cdn.cerealapis.com/post/90fc6eb2-9570-4107-bc38-5074b9767ea6/upload-1671727097755.webp",
          "attack": "6",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "uncommon",
          "id": "image__3ee46dc0-727a-41ec-a5a4-7e81b1e69317",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2022-12-22T18:14:34.955Z",
          "access": "public",
          "slug": "evevory",
          "createdAt": "2022-10-27T20:56:08.684Z",
          "defense": "8",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T07:40:00.000Z",
          "subclass2": "behemoth",
          "subclass1": "aquatic",
          "description": "``` embed\nThis Eyevory gets +2 <span class=\"elestrals-font\">J</span> for every Typhlant in your Underworld. You can Expend <span class=\"elestrals-font\">W</span> in order to Special Cast a Typhlant from your Underworld in Attack Position.\n```",
          "excerpt": "BS1-044",
          "title": "Eyevory",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/e6f755b6-d2a2-470c-9e0b-619c421f5314/upload-1671727120101.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/d631bfaa-e57b-47ab-b321-6615d7806066/upload-1671727119187.webp",
          "attack": "2",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "common",
          "id": "image__40a5f448-3519-4d07-807f-818fce0b3ffc",
          "tags": [],
          "author": "15c176d6-97df-497d-aef3-15f737c972a9",
          "lastUpdated": "2022-12-22T18:14:49.291Z",
          "access": "public",
          "slug": "smoltuga",
          "createdAt": "2022-10-27T20:56:40.163Z",
          "defense": "5",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T07:45:00.000Z",
          "subclass1": "oceanic",
          "description": "``` embed\nWhen you Normal Cast this Smoltuga you can Search your deck for an Elestral with Enchantment Cost <span class=\"elestrals-font\">F</span> and add it to your hand.\n```",
          "excerpt": "BS1-045",
          "title": "Smoltuga",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b516689-1021-4652-b56a-bfbef2e05441/upload-1666822702354.png",
              "category": "Artist",
              "slug": "dennis-artworks218-fuhrmann",
              "bgImage": "https://cdn.cerealapis.com/author/90b70451-0371-4db5-beab-5d46f38e07bd/upload-1666822702346.png",
              "id": "15c176d6-97df-497d-aef3-15f737c972a9",
              "videoCount": 22,
              "title": "Dennis Fuhrmann"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/3eeb872b-dae6-4653-8b3e-0174d66eaa50/upload-1671727137910.webp",
          "collections": {},
          "type1": "water",
          "type2": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/4aa00a90-025e-44d3-aef2-0eb327edeee4/upload-1671727136904.webp",
          "attack": "4",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "rare",
          "id": "image__8f655192-d295-4498-ac98-fcb0cf46a07f",
          "tags": [],
          "author": "15c176d6-97df-497d-aef3-15f737c972a9",
          "lastUpdated": "2022-12-22T18:15:01.596Z",
          "access": "public",
          "slug": "krakatuga",
          "createdAt": "2022-10-27T20:57:06.566Z",
          "defense": "9",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T07:50:00.000Z",
          "subclass2": "golem",
          "subclass1": "oceanic",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">F</span><span class=\"elestrals-font\">F</span> to force an opponent to Expend <span class=\"elestrals-font\">X</span><span class=\"elestrals-font\">X</span><span class=\"elestrals-font\">X</span>. When a player Casts Eruption you can target and destroy a card. \n```",
          "excerpt": "BS1-046",
          "title": "Krakatuga",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b516689-1021-4652-b56a-bfbef2e05441/upload-1666822702354.png",
              "category": "Artist",
              "slug": "dennis-artworks218-fuhrmann",
              "bgImage": "https://cdn.cerealapis.com/author/90b70451-0371-4db5-beab-5d46f38e07bd/upload-1666822702346.png",
              "id": "15c176d6-97df-497d-aef3-15f737c972a9",
              "videoCount": 22,
              "title": "Dennis Fuhrmann"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/4cef54d7-72da-445d-9bba-684f290c75da/upload-1671727152877.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/2f7fdf1a-cb05-40bf-ae96-fdf17017c6a6/upload-1671727151848.webp",
          "attack": "3",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "common",
          "id": "image__4c464600-e007-4b22-b862-e307212633ef",
          "tags": [],
          "author": "06f6337e-75f8-4837-a14b-bb73e880382e",
          "lastUpdated": "2022-12-22T18:15:13.726Z",
          "access": "public",
          "slug": "verutaqua",
          "createdAt": "2022-10-27T20:57:29.163Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T07:55:00.000Z",
          "subclass1": "oceanic",
          "description": "When this Verutaqua enters battle with a Defense Position Elestral you can negate that Elestral’s effect and destroy it. If you do this Verutaqua deals no damage this battle.",
          "excerpt": "BS1-047",
          "title": "Verutaqua",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/696727cb-6e52-4f00-b2b5-b5ee9b967d3e/upload-1666823423451.png",
              "category": "Artist",
              "slug": "giovanni-n4nin4ni-aguilar",
              "bgImage": "https://cdn.cerealapis.com/author/49850f39-8072-4bd7-8776-15508c33f3d7/upload-1666823423402.png",
              "id": "06f6337e-75f8-4837-a14b-bb73e880382e",
              "videoCount": 53,
              "title": "Giovanni Aguilar"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/fc4ddf05-0ded-4cb6-9422-87e657be0517/upload-1671727176014.webp",
          "collections": {},
          "type1": "water",
          "type2": "water",
          "bgImage": "https://cdn.cerealapis.com/post/8c48ed57-1957-4da5-9b81-30778a30414f/upload-1671727175035.webp",
          "attack": "6",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "uncommon",
          "id": "image__4dd1c998-2aba-4e40-b688-1c841e306658",
          "tags": [],
          "author": "06f6337e-75f8-4837-a14b-bb73e880382e",
          "lastUpdated": "2022-12-22T18:15:23.427Z",
          "access": "public",
          "slug": "javelantis",
          "createdAt": "2022-10-27T20:57:49.865Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T08:00:00.000Z",
          "subclass1": "oceanic",
          "description": "``` embed\nWhen this Javelantis destroys an opponent's Defense Position Elestral in battle that opponent must Expend <span class=\"elestrals-font\">X</span><span class=\"elestrals-font\">X</span><span class=\"elestrals-font\">X</span>.\n```",
          "excerpt": "BS1-048",
          "title": "Javelantis",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/696727cb-6e52-4f00-b2b5-b5ee9b967d3e/upload-1666823423451.png",
              "category": "Artist",
              "slug": "giovanni-n4nin4ni-aguilar",
              "bgImage": "https://cdn.cerealapis.com/author/49850f39-8072-4bd7-8776-15508c33f3d7/upload-1666823423402.png",
              "id": "06f6337e-75f8-4837-a14b-bb73e880382e",
              "videoCount": 53,
              "title": "Giovanni Aguilar"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/a5462b8a-9c93-44e6-be98-fc443da877a8/upload-1671727189524.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/c89de8f1-9c11-488f-be66-659ae36fd39d/upload-1671727188488.webp",
          "attack": "1",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "common",
          "id": "image__4b98087f-4979-410e-82bc-1dd9dc148a67",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2022-12-22T18:15:34.217Z",
          "access": "public",
          "slug": "tadpuff",
          "createdAt": "2022-10-27T20:58:17.040Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T08:05:00.000Z",
          "subclass1": "aquatic",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">W</span> in order to Special Cast this Tadpuff from your hand in Attack Position with <span class=\"elestrals-font\">W</span>.\n```",
          "excerpt": "BS1-049",
          "title": "Tadpuff",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/c7d5495e-2694-4b8c-ae1d-0a3053fa127d/upload-1671727203765.webp",
          "collections": {},
          "type1": "water",
          "type2": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/ef1ea6c8-2676-4b59-891c-ef7b649b180b/upload-1671727202652.webp",
          "attack": "4",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "uncommon",
          "id": "image__b34be204-445e-454b-b457-ad55b39db83d",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2022-12-22T18:15:48.001Z",
          "access": "public",
          "slug": "zephrog",
          "createdAt": "2022-10-27T20:58:38.395Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T08:10:00.000Z",
          "subclass1": "aquatic",
          "description": "This Zephrog can attack an opponent’s Spirit Deck directly. When this Zephrog deals damage to an opponent you can look at that opponent’s hand and discard a card.",
          "excerpt": "BS1-050",
          "title": "Zephrog",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/9678c58a-79f8-4414-b3a7-062a29eb7d79/upload-1671727225650.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/cbaf6c74-9e40-482f-a546-dc8ea71bba9f/upload-1671727224688.webp",
          "attack": "1",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "rare",
          "id": "image__98abe301-02bd-4662-8d55-a26e7649bdcf",
          "tags": [],
          "author": "88185685-0ac3-4a6c-b976-0aa8dc3403fe",
          "lastUpdated": "2022-12-22T18:16:01.373Z",
          "access": "public",
          "slug": "moustacean",
          "createdAt": "2022-10-27T20:59:08.116Z",
          "defense": "6",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T08:15:00.000Z",
          "subclass1": "oceanic",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">W</span> in order to swap this Moustacean’s <span class=\"elestrals-font\">J</span> with its <span class=\"elestrals-font\">O</span> until the End Phase. This effect can also be activated when an opponent Normal Casts an Elestral.\n```",
          "excerpt": "BS1-051",
          "title": "Moustacean",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/f881a1cf-d017-4d34-b833-9c5dc9fe7eab/upload-1666823969818.png",
              "category": "Artist",
              "slug": "marcel-bromojumbo-romo",
              "bgImage": "https://cdn.cerealapis.com/author/aa6415a1-f484-4a75-988b-80db7af2fe56/upload-1666823969899.png",
              "id": "88185685-0ac3-4a6c-b976-0aa8dc3403fe",
              "videoCount": 14,
              "title": "Marcel Romo "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/770d323e-4e0a-491e-b797-eb6b8d2fc766/upload-1671727241516.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/10b4284c-ea1c-46c6-beda-f5ccaf76c9ee/upload-1671727240377.webp",
          "attack": "2",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "uncommon",
          "id": "image__8c634bba-8d48-4fb8-adf6-bccf79a70815",
          "tags": [],
          "author": "88185685-0ac3-4a6c-b976-0aa8dc3403fe",
          "lastUpdated": "2022-12-22T18:16:11.637Z",
          "access": "public",
          "slug": "galaxea",
          "createdAt": "2022-10-27T20:59:37.240Z",
          "defense": "5",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T08:20:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "oceanic",
          "description": "``` embed\nAs long as this Galaxea is Enchanted swap each <span class=\"elestrals-font\">W</span>-Enchanted Elestral’s <span class=\"elestrals-font\">J</span> with its <span class=\"elestrals-font\">O</span>.\n```",
          "excerpt": "BS1-052",
          "title": "Galaxea",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/f881a1cf-d017-4d34-b833-9c5dc9fe7eab/upload-1666823969818.png",
              "category": "Artist",
              "slug": "marcel-bromojumbo-romo",
              "bgImage": "https://cdn.cerealapis.com/author/aa6415a1-f484-4a75-988b-80db7af2fe56/upload-1666823969899.png",
              "id": "88185685-0ac3-4a6c-b976-0aa8dc3403fe",
              "videoCount": 14,
              "title": "Marcel Romo "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/46f362fd-43b0-43f4-b6d7-d9edba3d2274/upload-1671727255558.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/e43861e3-6174-4208-a65c-6a4e3bef8bb4/upload-1682805467356.webp",
          "attack": "2",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "holo-rare",
          "id": "image__bd232b01-0028-4721-87f9-0348eb876a8b",
          "tags": [],
          "author": "7a5cbe37-98d0-46e5-8f84-93398fa88214",
          "lastUpdated": "2023-04-29T21:57:48.348Z",
          "access": "public",
          "slug": "apheros",
          "createdAt": "2022-10-27T21:03:33.142Z",
          "defense": "4",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T08:25:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "aquatic",
          "description": "``` embed\nYou can Nexus up to <span class=\"elestrals-font\">W</span><span class=\"elestrals-font\">W</span>. When you do, you can draw a card for each <span class=\"elestrals-font\">W</span> that a card receives this way.\n```",
          "excerpt": "BS1-053",
          "title": "Apheros",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/d54f297d-ac0b-4595-acfb-1d5bb448c7ad/upload-1666824216088.jpg",
              "category": "Artist",
              "slug": "villiam-boom",
              "bgImage": "https://cdn.cerealapis.com/author/3bd2fb84-40fe-4db4-b7ad-7ace9d264ce8/upload-1666824216015.jpg",
              "id": "7a5cbe37-98d0-46e5-8f84-93398fa88214",
              "videoCount": 10,
              "title": "Villiam Boom "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/cfe9c170-81ed-4815-92f0-b4d16c3a6217/upload-1671727272237.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/d40be692-27a3-4487-aa40-8b79546dc8ad/upload-1671727271292.webp",
          "attack": "2",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "uncommon",
          "id": "image__dc083832-2535-423b-9468-198fe60c9903",
          "tags": [],
          "author": "8194695f-8d92-491e-b87b-c977a16a317e",
          "lastUpdated": "2022-12-22T18:16:36.364Z",
          "access": "public",
          "slug": "boombatt",
          "createdAt": "2022-10-27T21:04:16.628Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T08:30:00.000Z",
          "subclass1": "eldritch",
          "description": "``` embed\nYou can Disenchant <span class=\"elestrals-font\">T</span> from this Boombatt in order to target and destroy an opponent’s Elestral.\n```",
          "excerpt": "BS1-054",
          "title": "Boombatt",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/a7719a4e-ee26-4bf7-8839-2887320a2a0a/upload-1666823882654.png",
              "category": "Artist",
              "slug": "luan-m-resende",
              "bgImage": "https://cdn.cerealapis.com/author/7d33a277-e7a3-423f-8661-3fdc94b69e7d/upload-1666823882721.png",
              "id": "8194695f-8d92-491e-b87b-c977a16a317e",
              "videoCount": 20,
              "title": "Luan M. Resende"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/df0af2d1-a301-4d56-b538-d07c602b8339/upload-1671727286463.webp",
          "collections": {},
          "type1": "thunder",
          "type2": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/8dc27e63-c643-414e-a6be-1b13f643b314/upload-1682805500720.webp",
          "attack": "7",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "holo-rare",
          "id": "image__a5b08390-497f-4e48-a354-836e478484db",
          "tags": [],
          "author": "8194695f-8d92-491e-b87b-c977a16a317e",
          "lastUpdated": "2023-04-29T21:58:21.738Z",
          "access": "public",
          "slug": "sonicore",
          "createdAt": "2022-10-27T21:04:44.918Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T08:35:00.000Z",
          "subclass2": "behemoth",
          "subclass1": "eldritch",
          "description": "This Sonicore’s Cast cannot be chained by Counter Runes. As long as this Sonicore is in Attack Position players cannot Cast Counter Runes.",
          "excerpt": "BS1-055",
          "title": "Sonicore",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/a7719a4e-ee26-4bf7-8839-2887320a2a0a/upload-1666823882654.png",
              "category": "Artist",
              "slug": "luan-m-resende",
              "bgImage": "https://cdn.cerealapis.com/author/7d33a277-e7a3-423f-8661-3fdc94b69e7d/upload-1666823882721.png",
              "id": "8194695f-8d92-491e-b87b-c977a16a317e",
              "videoCount": 20,
              "title": "Luan M. Resende"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/1a793110-29b8-4c1c-b469-852f17216fde/upload-1671727324599.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/96901547-0d13-4ad1-9833-f2ccacd5cf5b/upload-1671727323660.webp",
          "attack": "3",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "common",
          "id": "image__503bf3d8-aadd-4ae3-a4e7-676aeee09f61",
          "tags": [],
          "author": "ecb243ac-0c18-4061-9598-5a3b3da7e8bf",
          "lastUpdated": "2022-12-22T18:16:56.951Z",
          "access": "public",
          "slug": "ampup",
          "createdAt": "2022-10-27T21:05:11.781Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T08:40:00.000Z",
          "subclass1": "brute",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">T</span> and discard this Ampup in order to Search your deck for up to 2 Ampup and add them to your hand.\n```",
          "excerpt": "BS1-056",
          "title": "Ampup",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/afa0ef97-2446-4ba0-86f2-70f380e86363/upload-1666822843305.png",
              "category": "Artist",
              "slug": "dragonith",
              "bgImage": "https://cdn.cerealapis.com/author/69a6a8f4-b3ad-481c-a2a6-dea8e9811f46/upload-1666822843306.png",
              "id": "ecb243ac-0c18-4061-9598-5a3b3da7e8bf",
              "videoCount": 5,
              "title": "Dragonith "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/a18685a2-4d1c-49c0-a274-a3bae5c09648/upload-1671727343154.webp",
          "collections": {},
          "type1": "thunder",
          "type2": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/5c3a0730-d048-4deb-9ca9-0263e6816859/upload-1671727342156.webp",
          "attack": "6",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "uncommon",
          "id": "image__7f12bbe7-a9ad-460a-8440-8d7e05843785",
          "tags": [],
          "author": "8194695f-8d92-491e-b87b-c977a16a317e",
          "lastUpdated": "2022-12-31T00:54:23.877Z",
          "access": "public",
          "slug": "lycavolt",
          "createdAt": "2022-10-27T21:05:43.522Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T08:45:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "behemoth",
          "description": "``` embed\nThis Lycavolt gets +2 <span class=\"elestrals-font\">J</span> for each Ampup in your Underworld. When this Lycavolt destroys an opponent’s Elestral in battle you can look at that opponent's hand and discard a card from it.\n```",
          "excerpt": "BS1-057",
          "title": "Lycavolt",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/a7719a4e-ee26-4bf7-8839-2887320a2a0a/upload-1666823882654.png",
              "category": "Artist",
              "slug": "luan-m-resende",
              "bgImage": "https://cdn.cerealapis.com/author/7d33a277-e7a3-423f-8661-3fdc94b69e7d/upload-1666823882721.png",
              "id": "8194695f-8d92-491e-b87b-c977a16a317e",
              "videoCount": 20,
              "title": "Luan M. Resende"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/63f5aad9-e458-4908-be91-067068506ed4/upload-1671727355369.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/7f236f03-18c8-46d4-a46d-84eea0afd9bf/upload-1671727354351.webp",
          "attack": "4",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "common",
          "id": "image__44179972-b45d-45c6-a9e7-ee96e5f83717",
          "tags": [],
          "author": "8194695f-8d92-491e-b87b-c977a16a317e",
          "lastUpdated": "2022-12-22T18:17:21.291Z",
          "access": "public",
          "slug": "elechik",
          "createdAt": "2022-10-27T21:06:03.282Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T08:50:00.000Z",
          "subclass1": "avian",
          "description": "When you Normal Cast this Elechik you can Search your deck for a Divine Rune and add it to your hand.",
          "excerpt": "BS1-058",
          "title": "Elechik",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/a7719a4e-ee26-4bf7-8839-2887320a2a0a/upload-1666823882654.png",
              "category": "Artist",
              "slug": "luan-m-resende",
              "bgImage": "https://cdn.cerealapis.com/author/7d33a277-e7a3-423f-8661-3fdc94b69e7d/upload-1666823882721.png",
              "id": "8194695f-8d92-491e-b87b-c977a16a317e",
              "videoCount": 20,
              "title": "Luan M. Resende"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/ddb4dc90-0d7d-4529-ba56-1ffb1c53d86b/upload-1671727369876.webp",
          "collections": {},
          "type1": "thunder",
          "type2": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/034155e4-9221-42b7-b72d-ef0e99e18693/upload-1671727368857.webp",
          "attack": "5",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "uncommon",
          "id": "image__9a698991-cd4e-4f8d-8fe9-4256932c4c55",
          "tags": [],
          "author": "8194695f-8d92-491e-b87b-c977a16a317e",
          "lastUpdated": "2022-12-31T00:58:01.571Z",
          "access": "public",
          "slug": "griffuse",
          "createdAt": "2022-10-27T21:06:23.817Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T08:55:00.000Z",
          "subclass2": "brute",
          "subclass1": "avian",
          "description": "``` embed\nYou can Nexus <span class=\"elestrals-font\">T</span> or <span class=\"elestrals-font\">A</span> from a Divine Rune. When you do, you can Special Ascend this Griffuse to Voltempest from your hand or deck.\n```",
          "excerpt": "BS1-059",
          "title": "Griffuse",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/a7719a4e-ee26-4bf7-8839-2887320a2a0a/upload-1666823882654.png",
              "category": "Artist",
              "slug": "luan-m-resende",
              "bgImage": "https://cdn.cerealapis.com/author/7d33a277-e7a3-423f-8661-3fdc94b69e7d/upload-1666823882721.png",
              "id": "8194695f-8d92-491e-b87b-c977a16a317e",
              "videoCount": 20,
              "title": "Luan M. Resende"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/b07dda0e-19c4-4728-9e73-4e25e978ce3e/upload-1671727384366.webp",
          "collections": {},
          "type1": "thunder",
          "type2": "thunder",
          "type3": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/45988de6-3640-49bf-a992-b2191ddd3217/upload-1682805514106.webp",
          "attack": "13",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "holo-rare",
          "id": "image__55cfa424-c8ad-4822-9424-1f7dc0ab26dc",
          "tags": [],
          "author": "8194695f-8d92-491e-b87b-c977a16a317e",
          "lastUpdated": "2023-04-29T21:58:35.160Z",
          "access": "public",
          "slug": "voltempest",
          "createdAt": "2022-10-27T21:06:48.565Z",
          "defense": "10",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T09:00:00.000Z",
          "subclass2": "behemoth",
          "subclass1": "avian",
          "description": "``` embed\nYou can Disenchant <span class=\"elestrals-font\">T</span> or <span class=\"elestrals-font\">A</span> from this Voltempest to force an opponent to Expend <span class=\"elestrals-font\">X</span><span class=\"elestrals-font\">X</span><span class=\"elestrals-font\">X</span>. As long as Zeus is Enchanted this Voltempest cannot be targeted by an opponent’s card effect.\n```",
          "excerpt": "BS1-060",
          "title": "Voltempest",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/a7719a4e-ee26-4bf7-8839-2887320a2a0a/upload-1666823882654.png",
              "category": "Artist",
              "slug": "luan-m-resende",
              "bgImage": "https://cdn.cerealapis.com/author/7d33a277-e7a3-423f-8661-3fdc94b69e7d/upload-1666823882721.png",
              "id": "8194695f-8d92-491e-b87b-c977a16a317e",
              "videoCount": 20,
              "title": "Luan M. Resende"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/4fafc8f7-f845-45fe-917b-9f59b8b70be4/upload-1671727400813.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/b33e34f4-3590-4709-bc07-f137c4df738d/upload-1671727399819.webp",
          "attack": "4",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "uncommon",
          "id": "image__425de068-d88c-4e0b-869b-6f2e5f1aba20",
          "tags": [],
          "author": "3067a712-8791-4a4e-a6dd-cc9e4b263e94",
          "lastUpdated": "2022-12-22T18:18:05.775Z",
          "access": "public",
          "slug": "sparkitt",
          "createdAt": "2022-10-27T21:07:17.945Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T09:05:00.000Z",
          "subclass2": "archaic",
          "subclass1": "brute",
          "description": "``` embed\nYou can Nexus up to <span class=\"elestrals-font\">T</span><span class=\"elestrals-font\">T</span>. When you do you can look at an opponent’s hand and discard a card.\n```",
          "excerpt": "BS1-061",
          "title": "Sparkitt",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4a4adc1b-2836-4e52-86af-50a54227c185/upload-1666824162136.png",
              "category": "Artist",
              "slug": "victor-vcreateart-rosales",
              "bgImage": "https://cdn.cerealapis.com/author/5210982f-5535-48b5-884b-cdfb1d03de56/upload-1666824162133.png",
              "id": "3067a712-8791-4a4e-a6dd-cc9e4b263e94",
              "videoCount": 10,
              "title": "Victor Rosales"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/eb30c217-789b-4b29-8d90-7d71f27a028e/upload-1671727437514.webp",
          "collections": {},
          "type1": "thunder",
          "type2": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/de0c1aca-8f79-4963-9c80-2a22da08c824/upload-1671727436565.webp",
          "attack": "8",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "rare",
          "id": "image__326cd069-c7d3-4a7a-8231-4be3095d47de",
          "tags": [],
          "author": "3067a712-8791-4a4e-a6dd-cc9e4b263e94",
          "lastUpdated": "2022-12-22T18:18:17.780Z",
          "access": "public",
          "slug": "gatobolt",
          "createdAt": "2022-10-27T21:07:40.026Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T09:10:00.000Z",
          "subclass2": "archaic",
          "subclass1": "behemoth",
          "description": "``` embed\nYou can target a <span class=\"elestrals-font\">T</span>-Enchanted Elestral and give it +1 <span class=\"elestrals-font\">J</span> and +1 <span class=\"elestrals-font\">O</span> for each <span class=\"elestrals-font\">T</span> Enchanting this Gatobolt until the End Phase.\n```",
          "excerpt": "BS1-062",
          "title": "Gatobolt",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4a4adc1b-2836-4e52-86af-50a54227c185/upload-1666824162136.png",
              "category": "Artist",
              "slug": "victor-vcreateart-rosales",
              "bgImage": "https://cdn.cerealapis.com/author/5210982f-5535-48b5-884b-cdfb1d03de56/upload-1666824162133.png",
              "id": "3067a712-8791-4a4e-a6dd-cc9e4b263e94",
              "videoCount": 10,
              "title": "Victor Rosales"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/c390e014-322c-4747-81fe-5d403b55e916/upload-1671727461546.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/042aac89-0ad6-4b9a-a1ea-ee038d3e2637/upload-1671727460476.webp",
          "attack": "2",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "common",
          "id": "image__fec2ef22-4ef8-4fa6-940b-350b45ecec17",
          "tags": [],
          "author": "75489aa3-accc-4053-ab04-30a1d49d13bb",
          "lastUpdated": "2022-12-22T18:18:31.257Z",
          "access": "public",
          "slug": "quackle",
          "createdAt": "2022-10-27T21:08:05.939Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T09:15:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "avian",
          "description": "``` embed\nWhen you Cast this Quackle you can return up to <span class=\"elestrals-font\">T</span><span class=\"elestrals-font\">T</span><span class=\"elestrals-font\">T</span> from your Underworld to your Spirit Deck.\n```",
          "excerpt": "BS1-063",
          "title": "Quackle",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/292635bf-0ef8-46f5-8583-dad865383c11/upload-1666719837086.png",
              "category": "Artist",
              "slug": "amalry",
              "bgImage": "https://cdn.cerealapis.com/author/080d7ae5-d84a-4885-a0d2-3443fa849520/upload-1666719837080.png",
              "id": "75489aa3-accc-4053-ab04-30a1d49d13bb",
              "videoCount": 18,
              "title": "Amalry"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/6ed887ed-e8f6-4209-a3b3-be98794d0971/upload-1671727476415.webp",
          "collections": {},
          "type1": "thunder",
          "type2": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/041a7c45-ca35-4fb8-b651-c26ed0cd9929/upload-1671727475433.webp",
          "attack": "4",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "uncommon",
          "id": "image__feace8f4-5e2a-441e-aabb-11cfa51bc95b",
          "tags": [],
          "author": "75489aa3-accc-4053-ab04-30a1d49d13bb",
          "lastUpdated": "2022-12-22T18:18:42.834Z",
          "access": "public",
          "slug": "cygnetrik",
          "createdAt": "2022-10-27T21:08:29.677Z",
          "defense": "6",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T09:20:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "avian",
          "description": "``` embed\nYou can reveal 12 <span class=\"elestrals-font\">T</span> from your Spirit Deck in order to Special Ascend this Cygnetrik to Ohmperial from your hand or deck.\n```",
          "excerpt": "BS1-064",
          "title": "Cygnetrik",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/292635bf-0ef8-46f5-8583-dad865383c11/upload-1666719837086.png",
              "category": "Artist",
              "slug": "amalry",
              "bgImage": "https://cdn.cerealapis.com/author/080d7ae5-d84a-4885-a0d2-3443fa849520/upload-1666719837080.png",
              "id": "75489aa3-accc-4053-ab04-30a1d49d13bb",
              "videoCount": 18,
              "title": "Amalry"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/a71fbd0f-50a3-4f03-bd03-e23a25ac6c80/upload-1671727492038.webp",
          "collections": {},
          "type1": "thunder",
          "type2": "thunder",
          "type3": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/62a503f6-51c9-416b-9bcb-5d30da18f2a7/upload-1671727490904.webp",
          "attack": "8",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "rare",
          "id": "image__441c9b42-a45e-4c6f-9f16-ef18969d25b8",
          "tags": [],
          "author": "75489aa3-accc-4053-ab04-30a1d49d13bb",
          "lastUpdated": "2022-12-22T18:18:53.886Z",
          "access": "public",
          "slug": "ohmperial",
          "createdAt": "2022-10-27T21:08:49.646Z",
          "defense": "12",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T09:25:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "avian",
          "description": "``` embed\nThis Ohmperial gets +1 <span class=\"elestrals-font\">J</span> for each <span class=\"elestrals-font\">T</span> in your Spirit Deck. You can Expend <span class=\"elestrals-font\">T</span> in order to target and destroy a card.\n```",
          "excerpt": "BS1-065",
          "title": "Ohmperial",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/292635bf-0ef8-46f5-8583-dad865383c11/upload-1666719837086.png",
              "category": "Artist",
              "slug": "amalry",
              "bgImage": "https://cdn.cerealapis.com/author/080d7ae5-d84a-4885-a0d2-3443fa849520/upload-1666719837080.png",
              "id": "75489aa3-accc-4053-ab04-30a1d49d13bb",
              "videoCount": 18,
              "title": "Amalry"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/bc51ea93-24b8-4b40-99d4-4f1c63bf7f48/upload-1671727516785.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/f59dd7a6-79e8-4d41-ac66-9e6f4bea28fd/upload-1671727515797.webp",
          "attack": "4",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "common",
          "id": "image__2d05a554-5617-4207-aa3f-1130d1bb1607",
          "tags": [],
          "author": "06f6337e-75f8-4837-a14b-bb73e880382e",
          "lastUpdated": "2022-12-31T01:02:58.206Z",
          "access": "public",
          "slug": "jolten",
          "createdAt": "2022-10-27T21:09:24.261Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T09:30:00.000Z",
          "subclass1": "ethereal",
          "description": "When you Normal Cast this Jolten you can Search your deck for a Stadium Rune and add it to your hand.",
          "excerpt": "BS1-066",
          "title": "Jolten",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/696727cb-6e52-4f00-b2b5-b5ee9b967d3e/upload-1666823423451.png",
              "category": "Artist",
              "slug": "giovanni-n4nin4ni-aguilar",
              "bgImage": "https://cdn.cerealapis.com/author/49850f39-8072-4bd7-8776-15508c33f3d7/upload-1666823423402.png",
              "id": "06f6337e-75f8-4837-a14b-bb73e880382e",
              "videoCount": 53,
              "title": "Giovanni Aguilar"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/f108dabc-14f4-4298-a93f-12e88f9b8694/upload-1671727529409.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/9012718c-548d-4de3-9c02-255e232005c0/upload-1682805528790.webp",
          "attack": "2",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "holo-rare",
          "id": "image__7a727656-0562-40ff-af4c-25c932ad72f2",
          "featured": false,
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2023-04-29T21:58:49.660Z",
          "access": "public",
          "slug": "raiceros",
          "createdAt": "2022-10-27T21:09:57.287Z",
          "defense": "4",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T09:35:00.000Z",
          "subclass1": "brute",
          "description": "``` embed\nAs long as this Raiceros is Enchanted Elestrals cannot attack the turn they are Cast unless they are <span class=\"elestrals-font\">T</span>-Enchanted.\n```",
          "excerpt": "BS1-067",
          "title": "Raiceros",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/41c26293-40b1-4e0c-9bd3-deb649b75123/upload-1671727545098.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/a8d62aac-fdaa-4eb2-a5a2-246b4f600eaf/upload-1671727544105.webp",
          "attack": "2",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "uncommon",
          "id": "image__ff51c2dd-0739-41e8-a25d-459d3fc8e330",
          "tags": [],
          "author": "06f6337e-75f8-4837-a14b-bb73e880382e",
          "lastUpdated": "2022-12-22T18:19:26.236Z",
          "access": "public",
          "slug": "astrabbit",
          "createdAt": "2022-10-27T21:10:35.422Z",
          "defense": "5",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T09:40:00.000Z",
          "subclass1": "ethereal",
          "description": "``` embed\nWhen this Astrabbit receives 1 or more <span class=\"elestrals-font\">T</span> you can look at the top 3 cards of your deck, then add 1 of them to your hand and shuffle the rest into your deck.\n```",
          "excerpt": "BS1-068",
          "title": "Astrabbit",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/696727cb-6e52-4f00-b2b5-b5ee9b967d3e/upload-1666823423451.png",
              "category": "Artist",
              "slug": "giovanni-n4nin4ni-aguilar",
              "bgImage": "https://cdn.cerealapis.com/author/49850f39-8072-4bd7-8776-15508c33f3d7/upload-1666823423402.png",
              "id": "06f6337e-75f8-4837-a14b-bb73e880382e",
              "videoCount": 53,
              "title": "Giovanni Aguilar"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/c0c0917b-6916-4cf2-b025-7f6c6269440a/upload-1671727557737.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/8e3326e6-2d50-4ecf-82ad-cac4d2f1a14f/upload-1671727556578.webp",
          "attack": "4",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "uncommon",
          "id": "image__6d1166b7-9afe-47c6-a44f-4c53f37cb1f8",
          "tags": [],
          "author": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
          "lastUpdated": "2022-12-22T18:19:36.298Z",
          "access": "public",
          "slug": "aeromare",
          "createdAt": "2022-10-27T21:11:04.889Z",
          "defense": "5",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T09:45:00.000Z",
          "subclass1": "ethereal",
          "description": "When you Ascend this Aeromare you can Cast it as an Artifact Empowering the Ascension with the effect “The Empowered Elestral cannot be targeted or destroyed by an opponent’s Elestral effect.”",
          "excerpt": "BS1-069",
          "title": "Aeromare",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/6810b997-e024-4b4b-b43a-4e8864c1e586/upload-1666822760398.png",
              "category": "Artist",
              "slug": "diego-monster",
              "bgImage": "https://cdn.cerealapis.com/author/45070d0c-4dd8-491c-a56a-8a164afa9400/upload-1666822760394.png",
              "id": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
              "videoCount": 17,
              "title": "Diego Monster "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/e607bab6-5081-414d-9768-12b804129725/upload-1671727570162.webp",
          "collections": {},
          "type1": "wind",
          "type2": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/02ced484-9c63-4ec4-bd60-dee50ddd0669/upload-1671727568715.webp",
          "attack": "7",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "rare",
          "id": "image__5a47464a-84b1-43ef-a838-87c6b5b94922",
          "tags": [],
          "author": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
          "lastUpdated": "2022-12-22T18:19:47.379Z",
          "access": "public",
          "slug": "glydesdale",
          "createdAt": "2022-10-27T21:11:37.202Z",
          "defense": "5",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T09:50:00.000Z",
          "subclass1": "ethereal",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">A</span> in order to give this Glydesdale +5 <span class=\"elestrals-font\">J</span> until the End Phase. As long as this Glydesdale is Empowered by Aeromare it can attack twice each Battle Phase.\n```",
          "excerpt": "BS1-070",
          "title": "Glydesdale",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/6810b997-e024-4b4b-b43a-4e8864c1e586/upload-1666822760398.png",
              "category": "Artist",
              "slug": "diego-monster",
              "bgImage": "https://cdn.cerealapis.com/author/45070d0c-4dd8-491c-a56a-8a164afa9400/upload-1666822760394.png",
              "id": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
              "videoCount": 17,
              "title": "Diego Monster "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/5fa6b886-397f-4c2d-a3b8-f65ae605f3f2/upload-1671727584540.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/bc3f7e3f-8f12-4ecb-8d19-02e7d4c0b10b/upload-1671727583504.webp",
          "attack": "1",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "common",
          "id": "image__8e049e74-299d-45a3-938d-5de3611ea075",
          "featured": false,
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2022-12-22T18:19:58.178Z",
          "access": "public",
          "slug": "nimbug",
          "createdAt": "2022-10-27T21:12:02.773Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T09:55:00.000Z",
          "subclass1": "insectoid",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">A</span> in order to Special Ascend this Nimbug to Cirrucoon from your hand or deck.\n```",
          "excerpt": "BS1-071",
          "title": "Nimbug",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/36175ff5-72e6-49b1-b2f4-6a6ccec16d55/upload-1671727597831.webp",
          "collections": {},
          "type1": "wind",
          "type2": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/8885a6ba-66dc-4e0f-acdf-4e716f26bf4e/upload-1671727596829.webp",
          "attack": "1",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "uncommon",
          "id": "image__6f38ee30-3b8a-405e-832f-72aab7eefa10",
          "featured": false,
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2022-12-22T18:20:08.117Z",
          "access": "public",
          "slug": "cirrucoon",
          "createdAt": "2022-10-27T21:12:38.120Z",
          "defense": "6",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T10:00:00.000Z",
          "subclass1": "insectoid",
          "description": "``` embed\nDuring your Draw Phase you can Expend <span class=\"elestrals-font\">A</span> in order to Special Ascend this Cirrucoon to Stratomoth from your hand or deck.\n```",
          "excerpt": "BS1-072",
          "title": "Cirrucoon",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/0582bf91-10f3-4839-af94-8a60713493b4/upload-1671727613477.webp",
          "collections": {},
          "type1": "wind",
          "type2": "wind",
          "type3": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/ad678f33-2a4e-4416-99fb-804ddc0f3852/upload-1682805551886.webp",
          "attack": "9",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "holo-rare",
          "id": "image__c8debc3f-5902-4075-9cbd-d981e55d7c26",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2023-04-29T21:59:12.818Z",
          "access": "public",
          "slug": "stratomoth",
          "createdAt": "2022-10-27T21:13:02.709Z",
          "defense": "5",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T10:05:00.000Z",
          "subclass1": "insectoid",
          "description": "``` embed\nThis Stratomoth gets +1 <span class=\"elestrals-font\">J</span> for each Nimbug and Cirrucoon on your field and in your Underworld. You can Expend <span class=\"elestrals-font\">A</span> in order to return each of your opponent's Runes to their hand.\n```",
          "excerpt": "BS1-073",
          "title": "Stratomoth",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/3f48c219-2233-47d8-87c4-f6106a4862e4/upload-1671727626717.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/cd004081-944e-4225-9d36-09a30260ffd9/upload-1671727625739.webp",
          "attack": "2",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "common",
          "id": "image__5d538b03-0000-4bfa-85d3-0594b0130598",
          "tags": [],
          "author": "19eb7b3b-3b39-464e-9a88-427e8f289558",
          "lastUpdated": "2022-12-22T18:20:31.549Z",
          "access": "public",
          "slug": "peagust",
          "createdAt": "2022-10-27T21:13:37.380Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T10:10:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "avian",
          "description": "``` embed\nWhen this Peagust receives 1 or more <span class=\"elestrals-font\">A</span> you can target and destroy a Rune.\n```",
          "excerpt": "BS1-074",
          "title": "Peagust",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/29f7dfd3-2003-4e21-932b-31d442bb668c/upload-1666966185555.png",
              "category": "Artist",
              "slug": "elite4",
              "id": "19eb7b3b-3b39-464e-9a88-427e8f289558",
              "videoCount": 42,
              "title": "Élite4"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/0c0d2d2b-0416-466e-8b74-86fb594a78d0/upload-1671727641491.webp",
          "collections": {},
          "type1": "wind",
          "type2": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/2f643a01-e054-40d4-b1c2-436d99c6c210/upload-1671727640432.webp",
          "attack": "7",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "rare",
          "id": "image__3194ffc7-5095-4201-befc-d6d8cb2e8032",
          "tags": [],
          "author": "19eb7b3b-3b39-464e-9a88-427e8f289558",
          "lastUpdated": "2022-12-31T01:22:14.786Z",
          "access": "public",
          "slug": "fowlicane",
          "createdAt": "2022-10-27T21:14:00.224Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T10:15:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "avian",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">A</span> in order to destroy all Runes or Expend <span class=\"elestrals-font\">A</span><span class=\"elestrals-font\">A</span> in order to destroy each of your opponent's Runes.\n```",
          "excerpt": "BS1-075",
          "title": "Fowlicane",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/29f7dfd3-2003-4e21-932b-31d442bb668c/upload-1666966185555.png",
              "category": "Artist",
              "slug": "elite4",
              "id": "19eb7b3b-3b39-464e-9a88-427e8f289558",
              "videoCount": 42,
              "title": "Élite4"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/29fdd2fd-71d7-4d41-9320-db550ead4ce4/upload-1671727657237.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/a9c0ce0e-0359-44b3-b3f1-17e3d9c9ccc1/upload-1671727656177.webp",
          "attack": "5",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "common",
          "id": "image__9189bc7e-2349-4087-9e24-1a4415d84706",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2023-08-05T20:07:48.986Z",
          "access": "public",
          "slug": "soarlet",
          "createdAt": "2022-10-27T21:14:22.550Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T10:20:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "brute",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">A</span> in order to change the Position of an Elestral unless it is <span class=\"elestrals-font\">A</span>-Enchanted.\n```",
          "excerpt": "BS1-076",
          "title": "Soarlet",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/4814f4db-0776-445a-b055-881c74099911/upload-1671727672287.webp",
          "collections": {},
          "type1": "wind",
          "type2": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/2d46256e-cf37-40b5-93ae-da9b52efd0bd/upload-1671727671282.webp",
          "attack": "8",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "uncommon",
          "id": "image__3e2d4151-0cbd-41c0-9bb5-00f81e99b032",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2022-12-22T18:21:04.077Z",
          "access": "public",
          "slug": "chrysoar",
          "createdAt": "2022-10-27T21:14:48.206Z",
          "defense": "4",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T10:25:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "behemoth",
          "description": "``` embed\nThis Chrysoar can attack an opponent’s Spirit Deck directly. When this Chrysoar deals damage to an opponent you can return up to <span class=\"elestrals-font\">A</span><span class=\"elestrals-font\">A</span> from your Underworld to your Spirit Deck.\n```",
          "excerpt": "BS1-077",
          "title": "Chrysoar",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/e81c521e-2146-44ad-a897-15a54a686da3/upload-1671727685334.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/eda11c91-2ef5-4efa-bcf7-3e12406c731d/upload-1671727684302.webp",
          "attack": "3",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "uncommon",
          "id": "image__f473f140-d8fa-43b4-b525-fc2bf4daa1ad",
          "tags": [],
          "author": "06f6337e-75f8-4837-a14b-bb73e880382e",
          "lastUpdated": "2022-12-22T18:21:17.253Z",
          "access": "public",
          "slug": "carryon",
          "createdAt": "2022-10-27T21:15:09.507Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T10:30:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "insectoid",
          "description": "When this Carryon is destroyed in battle you can target the Elestral that destroyed it, then take control of that Elestral and move it to your Elestral Row until the End Phase.",
          "excerpt": "BS1-078",
          "title": "Carryon",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/696727cb-6e52-4f00-b2b5-b5ee9b967d3e/upload-1666823423451.png",
              "category": "Artist",
              "slug": "giovanni-n4nin4ni-aguilar",
              "bgImage": "https://cdn.cerealapis.com/author/49850f39-8072-4bd7-8776-15508c33f3d7/upload-1666823423402.png",
              "id": "06f6337e-75f8-4837-a14b-bb73e880382e",
              "videoCount": 53,
              "title": "Giovanni Aguilar"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/7cb474c8-a101-4543-aded-5546f407611e/upload-1671727698297.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/91461fbd-0e9f-48e2-b2ec-b5d254120e83/upload-1671727697046.webp",
          "attack": "3",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "uncommon",
          "id": "image__93c9d145-c6d1-414e-a53d-4e3f87c6c5ea",
          "tags": [],
          "author": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
          "lastUpdated": "2022-12-22T18:21:31.563Z",
          "access": "public",
          "slug": "peliquarius",
          "createdAt": "2022-10-27T21:15:30.527Z",
          "defense": "4",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T10:35:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "avian",
          "description": "``` embed\nWhen you Normal Cast this Peliquarius you can Search your deck for an Elestral with Enchantment Cost <span class=\"elestrals-font\">W</span> and add it to your hand.\n```",
          "excerpt": "BS1-079",
          "title": "Peliquarius",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4c88d483-9e81-4854-942f-97df6f95299e/upload-1666824047475.png",
              "category": "Artist",
              "slug": "phoebe-the-last-shaymin-sumner-twisk",
              "bgImage": "https://cdn.cerealapis.com/author/a0c334f7-fbb6-426d-88ca-2915f35213e4/upload-1666824047501.png",
              "id": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
              "videoCount": 33,
              "title": "Phoebe Sumner-Twisk"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/883d8719-a3e6-4515-8fd3-652df834e2a9/upload-1671727711566.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/e930a689-d31f-4491-a1e0-4feebc808edd/upload-1671727710551.webp",
          "attack": "3",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "rare",
          "id": "image__68c1d997-6c31-45fb-a1fc-3fd823e10415",
          "tags": [],
          "author": "5717b454-98e6-4b9c-bad3-fc1bfefa4d26",
          "lastUpdated": "2022-12-31T01:26:03.752Z",
          "access": "public",
          "slug": "exaltair",
          "createdAt": "2022-10-27T21:16:03.051Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T10:40:00.000Z",
          "subclass1": "avian",
          "description": "``` embed\nYou can Nexus up to <span class=\"elestrals-font\">A</span><span class=\"elestrals-font\">A</span>. When you do, an opponent must Expend <span class=\"elestrals-font\">X</span><span class=\"elestrals-font\">X</span>.\n```",
          "excerpt": "BS1-080",
          "title": "Exaltair",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/e0fa7325-1c5d-450c-a14b-e200e191f99b/upload-1666822059355.png",
              "category": "Artist",
              "slug": "francis-adam-adam-fegarido",
              "bgImage": "https://cdn.cerealapis.com/author/adfeb389-b9f3-44c4-8cad-6375cd20b30f/upload-1666822060202.png",
              "id": "5717b454-98e6-4b9c-bad3-fc1bfefa4d26",
              "videoCount": 6,
              "title": "Francis Fegarido "
          }
      },
      {
          "lastUpdated": "2023-04-29T21:59:26.239Z",
          "file": "https://cdn.cerealapis.com/post/33c9b70f-1a28-4f66-a63d-188f194910a7/upload-1671728123283.webp",
          "collections": {},
          "type1": "earth",
          "access": "public",
          "slug": "demeter",
          "createdAt": "2022-10-27T21:16:52.026Z",
          "bgImage": "https://cdn.cerealapis.com/post/ec8b3e4a-afa4-4814-af57-369266a58611/upload-1682805565353.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "bgColor": "#B7C03A",
          "publishedAt": "2022-01-01T10:45:00.000Z",
          "rarity": "full-art",
          "runeType": "divine",
          "description": "``` embed\nWhen you Cast this Demeter you can Enchant up to <span class=\"elestrals-font\">E</span><span class=\"elestrals-font\">E</span><span class=\"elestrals-font\">E</span> to it. You can Disenchant <span class=\"elestrals-font\">E</span> from this Demeter in order to give +3 <span class=\"elestrals-font\">J</span> and +3 <span class=\"elestrals-font\">O</span> to an Elestral until the End Phase.\n```",
          "id": "image__4dcba158-f430-47ef-901f-0c975945d047",
          "excerpt": "BS1-081",
          "tags": [],
          "author": "15c176d6-97df-497d-aef3-15f737c972a9",
          "title": "Demeter",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b516689-1021-4652-b56a-bfbef2e05441/upload-1666822702354.png",
              "category": "Artist",
              "slug": "dennis-artworks218-fuhrmann",
              "bgImage": "https://cdn.cerealapis.com/author/90b70451-0371-4db5-beab-5d46f38e07bd/upload-1666822702346.png",
              "id": "15c176d6-97df-497d-aef3-15f737c972a9",
              "videoCount": 22,
              "title": "Dennis Fuhrmann"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/22464f9b-656e-4080-a538-ebc670fd99cc/upload-1671728139573.webp",
          "collections": {},
          "type1": "earth",
          "type2": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/0843173b-d4f9-4d0d-9d6b-8475f3c708d6/upload-1671728138404.webp",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "uncommon",
          "id": "image__873d3d61-8d39-4e3a-ad3f-c52fc8f292a1",
          "tags": [],
          "author": "962db316-931a-4cb5-bdd1-9330d8486272",
          "lastUpdated": "2023-03-19T18:33:53.043Z",
          "access": "public",
          "slug": "scythe-of-demeter",
          "createdAt": "2022-10-27T21:17:16.867Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T10:50:00.000Z",
          "runeType": "artifact",
          "description": "``` embed\nThe Empowered Elestral gets +2 <span class=\"elestrals-font\">J</span> and +3 <span class=\"elestrals-font\">O</span>. When the Empowered Elestral destroys an Elestral in battle you can return <span class=\"elestrals-font\">E</span> from your Underworld to your Spirit Deck. If Demeter is Enchanted you can return <span class=\"elestrals-font\">E</span><span class=\"elestrals-font\">E</span> instead.\n```",
          "excerpt": "BS1-082",
          "title": "Scythe of Demeter",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b314a3d-73f8-4458-b16c-b821c4f915f3/upload-1666823695029.png",
              "category": "Artist",
              "slug": "lucas-kosta-costa",
              "bgImage": "https://cdn.cerealapis.com/author/3ebe38de-02f2-496d-a11f-337103ee7347/upload-1666823695026.png",
              "id": "962db316-931a-4cb5-bdd1-9330d8486272",
              "videoCount": 20,
              "title": "Lucas Costa"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/f259dbcd-7f98-41f1-986b-fba247868297/upload-1671728158692.webp",
          "collections": {},
          "type1": "earth",
          "type2": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/be769f76-bd38-4658-a7b6-809f1f2a8c81/upload-1671728157591.webp",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "uncommon",
          "id": "image__873bf644-5812-4bcf-a9aa-deae8be40a8c",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2022-12-22T18:22:38.476Z",
          "access": "public",
          "slug": "earthquake",
          "createdAt": "2022-10-27T21:17:40.864Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T10:55:00.000Z",
          "runeType": "invoke",
          "description": "Target and destroy an Elestral.",
          "excerpt": "BS1-083",
          "title": "Earthquake",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "lastUpdated": "2022-12-22T18:22:48.392Z",
          "file": "https://cdn.cerealapis.com/post/2537df55-64f1-42d4-9f1e-b6629a7025b6/upload-1671728177641.webp",
          "collections": {},
          "type1": "earth",
          "access": "public",
          "slug": "foloi-forest",
          "createdAt": "2022-10-27T21:18:22.426Z",
          "bgImage": "https://cdn.cerealapis.com/post/a4900d62-f5a7-419d-a603-c44047408dbf/upload-1671728176491.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "bgColor": "#B7C03A",
          "publishedAt": "2022-01-01T11:00:00.000Z",
          "rarity": "common",
          "runeType": "stadium",
          "description": "``` embed\nElestrals get +1 <span class=\"elestrals-font\">J</span> and +1 <span class=\"elestrals-font\">O</span> for each <span class=\"elestrals-font\">E</span> Enchanting them.\n```",
          "id": "image__4b1e3b3a-b7e2-48cb-ae06-7aa76def0891",
          "excerpt": "BS1-084",
          "tags": [],
          "author": "19eb7b3b-3b39-464e-9a88-427e8f289558",
          "title": "Foloi Forest",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/29f7dfd3-2003-4e21-932b-31d442bb668c/upload-1666966185555.png",
              "category": "Artist",
              "slug": "elite4",
              "id": "19eb7b3b-3b39-464e-9a88-427e8f289558",
              "videoCount": 42,
              "title": "Élite4"
          }
      },
      {
          "lastUpdated": "2023-04-29T21:59:38.562Z",
          "file": "https://cdn.cerealapis.com/post/cfee4efa-caec-4875-ac51-692468bc5bf2/upload-1671728196244.webp",
          "collections": {},
          "type1": "fire",
          "access": "public",
          "slug": "hephaestus",
          "createdAt": "2022-10-27T21:18:48.607Z",
          "bgImage": "https://cdn.cerealapis.com/post/933572f7-df60-4c41-8aed-a34c795fcc33/upload-1682805577682.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "bgColor": "#C63E2C",
          "publishedAt": "2022-01-01T11:05:00.000Z",
          "rarity": "full-art",
          "runeType": "divine",
          "description": "``` embed\nWhen you Cast this Hephaestus you can Enchant up to <span class=\"elestrals-font\">F</span><span class=\"elestrals-font\">F</span><span class=\"elestrals-font\">F</span> to it. You can Disenchant <span class=\"elestrals-font\">F</span> from this Hephaestus in order to Search your deck for an Artifact and add it to your hand.\n```",
          "id": "image__fb8c1dcf-9274-48bb-ae66-8b9f07d42910",
          "excerpt": "BS1-085",
          "tags": [],
          "author": "15c176d6-97df-497d-aef3-15f737c972a9",
          "title": "Hephaestus",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b516689-1021-4652-b56a-bfbef2e05441/upload-1666822702354.png",
              "category": "Artist",
              "slug": "dennis-artworks218-fuhrmann",
              "bgImage": "https://cdn.cerealapis.com/author/90b70451-0371-4db5-beab-5d46f38e07bd/upload-1666822702346.png",
              "id": "15c176d6-97df-497d-aef3-15f737c972a9",
              "videoCount": 22,
              "title": "Dennis Fuhrmann"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/c176583c-f67d-47b4-bcec-16ed82ac9aa0/upload-1671728211738.webp",
          "collections": {},
          "type1": "fire",
          "type2": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/ddce2875-d1a8-438a-a2ff-66709ddf413d/upload-1671728210768.webp",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "uncommon",
          "id": "image__789803ee-f346-48b2-b15d-a0afdb325d0a",
          "tags": [],
          "author": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
          "lastUpdated": "2022-12-22T18:23:17.636Z",
          "access": "public",
          "slug": "hammer-of-hephaestus",
          "createdAt": "2022-10-27T21:19:15.824Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T11:10:00.000Z",
          "runeType": "artifact",
          "description": "``` embed\nThe Empowered Elestral gets +1 <span class=\"elestrals-font\">J</span> for each Enchanted Rune on your field. As long as Hephaestus is Enchanted the Empowered Elestral cannot be targeted by an opponent’s Elestral effect.\n```",
          "excerpt": "BS1-086",
          "title": "Hammer of Hephaestus",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/9abbfa57-fadc-42d9-bd52-3907b145d6ff/upload-1662720081319.jpeg",
              "category": "Artist",
              "slug": "jonny-de-oliviera",
              "bgImage": "https://cdn.cerealapis.com/author/d6271a98-e34d-4b1a-843c-45b03f86abb2/upload-1662720081310.jpeg",
              "id": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
              "videoCount": 22,
              "title": "Jonny De Oliviera"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/9223b762-b0f2-4519-84ef-72641433e6aa/upload-1671728244507.webp",
          "collections": {},
          "type1": "fire",
          "type2": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/576426ee-47c5-4cea-8d0e-94bb809dd901/upload-1671728242967.webp",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "uncommon",
          "id": "image__ee41806d-1529-488c-a83f-70c5c228e4fe",
          "tags": [],
          "author": "15c176d6-97df-497d-aef3-15f737c972a9",
          "lastUpdated": "2022-12-22T18:23:27.084Z",
          "access": "public",
          "slug": "eruption",
          "createdAt": "2022-10-27T21:19:38.362Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T11:15:00.000Z",
          "runeType": "invoke",
          "description": "``` embed\nAn opponent must Expend <span class=\"elestrals-font\">X</span><span class=\"elestrals-font\">X</span><span class=\"elestrals-font\">X</span>. If they control a <span class=\"elestrals-font\">F</span>-Enchanted Elestral they must Expend <span class=\"elestrals-font\">X</span> instead.\n```",
          "excerpt": "BS1-087",
          "title": "Eruption",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b516689-1021-4652-b56a-bfbef2e05441/upload-1666822702354.png",
              "category": "Artist",
              "slug": "dennis-artworks218-fuhrmann",
              "bgImage": "https://cdn.cerealapis.com/author/90b70451-0371-4db5-beab-5d46f38e07bd/upload-1666822702346.png",
              "id": "15c176d6-97df-497d-aef3-15f737c972a9",
              "videoCount": 22,
              "title": "Dennis Fuhrmann"
          }
      },
      {
          "lastUpdated": "2022-12-22T18:23:37.293Z",
          "file": "https://cdn.cerealapis.com/post/0357a331-8965-45e1-bd8c-2fab5c18e7df/upload-1671728260696.webp",
          "collections": {},
          "type1": "fire",
          "access": "public",
          "slug": "volcanic-forge",
          "createdAt": "2022-10-27T21:19:56.522Z",
          "bgImage": "https://cdn.cerealapis.com/post/bccf49d0-dd51-48cb-8d56-9510969a841d/upload-1671728259692.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "bgColor": "#C63E2C",
          "publishedAt": "2022-01-01T11:20:00.000Z",
          "rarity": "common",
          "runeType": "stadium",
          "description": "``` embed\nElestrals get +1 <span class=\"elestrals-font\">J</span> and +1 <span class=\"elestrals-font\">O</span> for each <span class=\"elestrals-font\">F</span> Enchanting them.\n```",
          "id": "image__8dbf800a-d706-4cbf-b038-b6eb9e19b030",
          "excerpt": "BS1-088",
          "tags": [],
          "author": "19eb7b3b-3b39-464e-9a88-427e8f289558",
          "title": "Volcanic Forge",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/29f7dfd3-2003-4e21-932b-31d442bb668c/upload-1666966185555.png",
              "category": "Artist",
              "slug": "elite4",
              "id": "19eb7b3b-3b39-464e-9a88-427e8f289558",
              "videoCount": 42,
              "title": "Élite4"
          }
      },
      {
          "lastUpdated": "2023-04-29T22:00:38.101Z",
          "file": "https://cdn.cerealapis.com/post/dbbcca74-13ec-4d65-9e24-7e1ca3daaf24/upload-1671728274917.webp",
          "collections": {},
          "type1": "water",
          "access": "public",
          "slug": "poseidon",
          "createdAt": "2022-10-27T21:20:20.127Z",
          "bgImage": "https://cdn.cerealapis.com/post/c66016b3-0cd0-4b1d-876d-bd3a14f9bf18/upload-1682805637091.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "bgColor": "#6997C4",
          "publishedAt": "2022-01-01T11:25:00.000Z",
          "rarity": "full-art",
          "runeType": "divine",
          "description": "``` embed\nWhen you Cast this Poseidon you can Enchant up to <span class=\"elestrals-font\">W</span><span class=\"elestrals-font\">W</span><span class=\"elestrals-font\">W</span> to it. You can Nexus <span class=\"elestrals-font\">W</span> from this Poseidon.\n```",
          "id": "image__f5ca9ba2-fadb-43c6-90e4-8640f7cbf739",
          "excerpt": "BS1-089",
          "tags": [],
          "author": "15c176d6-97df-497d-aef3-15f737c972a9",
          "title": "Poseidon",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b516689-1021-4652-b56a-bfbef2e05441/upload-1666822702354.png",
              "category": "Artist",
              "slug": "dennis-artworks218-fuhrmann",
              "bgImage": "https://cdn.cerealapis.com/author/90b70451-0371-4db5-beab-5d46f38e07bd/upload-1666822702346.png",
              "id": "15c176d6-97df-497d-aef3-15f737c972a9",
              "videoCount": 22,
              "title": "Dennis Fuhrmann"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/7c48d3c5-9e45-4829-ab2b-9219ef17cdeb/upload-1671728290264.webp",
          "collections": {},
          "type1": "water",
          "type2": "water",
          "bgImage": "https://cdn.cerealapis.com/post/c5606894-d630-4ada-b167-1735a2e5c35a/upload-1671728289301.webp",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "uncommon",
          "id": "image__e823702b-7e83-42c8-b0ee-13029b5a886e",
          "tags": [],
          "author": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
          "lastUpdated": "2022-12-22T18:24:04.615Z",
          "access": "public",
          "slug": "trident-of-poseidon",
          "createdAt": "2022-10-27T21:20:52.388Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T11:30:00.000Z",
          "runeType": "artifact",
          "description": "``` embed\nThe Empowered Elestral gets +2 <span class=\"elestrals-font\">J</span> and cannot be targeted by Counter Runes. As long as Poseidon is Enchanted opponents cannot Cast Counter Runes.\n```",
          "excerpt": "BS1-090",
          "title": "Trident of Poseidon",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4c88d483-9e81-4854-942f-97df6f95299e/upload-1666824047475.png",
              "category": "Artist",
              "slug": "phoebe-the-last-shaymin-sumner-twisk",
              "bgImage": "https://cdn.cerealapis.com/author/a0c334f7-fbb6-426d-88ca-2915f35213e4/upload-1666824047501.png",
              "id": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
              "videoCount": 33,
              "title": "Phoebe Sumner-Twisk"
          }
      },
      {
          "lastUpdated": "2022-12-22T18:24:15.452Z",
          "file": "https://cdn.cerealapis.com/post/bb55a179-6408-493b-92a3-7f5fbec5959f/upload-1671728304892.webp",
          "collections": {},
          "type1": "water",
          "access": "public",
          "slug": "tsunami",
          "createdAt": "2022-10-27T21:21:15.268Z",
          "bgImage": "https://cdn.cerealapis.com/post/4f5883cd-9e90-4baa-8653-d35773149e00/upload-1671728303912.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "bgColor": "#6997C4",
          "publishedAt": "2022-01-01T11:35:00.000Z",
          "rarity": "uncommon",
          "runeType": "counter",
          "description": "``` embed\nChange all Elestrals to Defense Position unless they are <span class=\"elestrals-font\">W</span>-Enchanted.\n```",
          "id": "image__6d9ef4f6-9d5b-4786-8d62-a8ed2f499150",
          "excerpt": "BS1-091",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "title": "Tsunami",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "lastUpdated": "2022-12-22T18:24:24.968Z",
          "file": "https://cdn.cerealapis.com/post/7e11771b-b590-4030-883a-f41fdfe4d54b/upload-1671728327131.webp",
          "collections": {},
          "type1": "water",
          "access": "public",
          "slug": "atlantis",
          "createdAt": "2022-10-27T21:21:33.909Z",
          "bgImage": "https://cdn.cerealapis.com/post/3f1d3542-7cfa-46a4-b730-7155175ae001/upload-1671728326176.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "bgColor": "#6997C4",
          "publishedAt": "2022-01-01T11:40:00.000Z",
          "rarity": "common",
          "runeType": "stadium",
          "description": "``` embed\nElestrals get +1 <span class=\"elestrals-font\">J</span> and +1 <span class=\"elestrals-font\">O</span> for each <span class=\"elestrals-font\">W</span> Enchanting them.\n```",
          "id": "image__67203f6d-773e-47e1-bf8e-2d017a1bb629",
          "excerpt": "BS1-092",
          "tags": [],
          "author": "19eb7b3b-3b39-464e-9a88-427e8f289558",
          "title": "Atlantis",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/29f7dfd3-2003-4e21-932b-31d442bb668c/upload-1666966185555.png",
              "category": "Artist",
              "slug": "elite4",
              "id": "19eb7b3b-3b39-464e-9a88-427e8f289558",
              "videoCount": 42,
              "title": "Élite4"
          }
      },
      {
          "lastUpdated": "2023-04-29T22:01:00.665Z",
          "file": "https://cdn.cerealapis.com/post/360d0194-50ec-4f2a-ae3f-a28ffe2b1978/upload-1671728340137.webp",
          "collections": {},
          "type1": "thunder",
          "access": "public",
          "slug": "zeus",
          "createdAt": "2022-10-27T21:22:00.563Z",
          "bgImage": "https://cdn.cerealapis.com/post/446daa18-7290-44c6-a57b-a32ee8e293df/upload-1682805659611.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "bgColor": "#E3AB21",
          "publishedAt": "2022-01-01T11:45:00.000Z",
          "rarity": "full-art",
          "runeType": "divine",
          "description": "``` embed\nWhen you Cast this Zeus you can Enchant up to <span class=\"elestrals-font\">T</span><span class=\"elestrals-font\">T</span><span class=\"elestrals-font\">T</span> to it. You can Disenchant <span class=\"elestrals-font\">T</span> from this Zeus in order to target an opponent's Elestral and give it -3 <span class=\"elestrals-font\">J</span> and -3 <span class=\"elestrals-font\">O</span> until the End Phase.\n```",
          "id": "image__d93c4c42-c709-4909-b97c-122e4ac1cd15",
          "excerpt": "BS1-093",
          "tags": [],
          "author": "15c176d6-97df-497d-aef3-15f737c972a9",
          "title": "Zeus",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b516689-1021-4652-b56a-bfbef2e05441/upload-1666822702354.png",
              "category": "Artist",
              "slug": "dennis-artworks218-fuhrmann",
              "bgImage": "https://cdn.cerealapis.com/author/90b70451-0371-4db5-beab-5d46f38e07bd/upload-1666822702346.png",
              "id": "15c176d6-97df-497d-aef3-15f737c972a9",
              "videoCount": 22,
              "title": "Dennis Fuhrmann"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/84a4d955-2b28-4dc5-8ffa-bada8d545413/upload-1671728355849.webp",
          "collections": {},
          "type1": "thunder",
          "type2": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/2de830da-5eab-4e7a-ac8f-a02ab42bdf1e/upload-1671728354820.webp",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "uncommon",
          "id": "image__fcdf0c61-49ae-4795-b8cf-fb10c683092f",
          "tags": [],
          "author": "19eb7b3b-3b39-464e-9a88-427e8f289558",
          "lastUpdated": "2022-12-22T18:24:47.188Z",
          "access": "public",
          "slug": "thunderbolt-of-zeus",
          "createdAt": "2022-10-27T21:22:19.369Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T11:50:00.000Z",
          "runeType": "artifact",
          "description": "``` embed\nThe Empowered Elestral gets +2 <span class=\"elestrals-font\">J</span>. When the Empowered Elestral destroys an opponent’s Elestral in battle that opponent must Expend <span class=\"elestrals-font\">X</span>. If Zeus is Enchanted, that opponent must Expend <span class=\"elestrals-font\">X</span><span class=\"elestrals-font\">X</span> instead.\n```",
          "excerpt": "BS1-094",
          "title": "Thunderbolt of Zeus",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/29f7dfd3-2003-4e21-932b-31d442bb668c/upload-1666966185555.png",
              "category": "Artist",
              "slug": "elite4",
              "id": "19eb7b3b-3b39-464e-9a88-427e8f289558",
              "videoCount": 42,
              "title": "Élite4"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/d257376c-143d-401d-97a4-795ed0bee1ce/upload-1671728370981.webp",
          "collections": {},
          "type1": "thunder",
          "type2": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/66076ae9-53bb-474c-8401-d35cb05b8067/upload-1671728369880.webp",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "uncommon",
          "id": "image__73158487-3722-492e-b730-91952f1303cf",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2022-12-22T18:24:56.773Z",
          "access": "public",
          "slug": "thunderstorm",
          "createdAt": "2022-10-27T21:22:42.428Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T11:55:00.000Z",
          "runeType": "invoke",
          "description": "Target and destroy a Rune.",
          "excerpt": "BS1-095",
          "title": "Thunderstorm",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "lastUpdated": "2022-12-22T18:25:07.467Z",
          "file": "https://cdn.cerealapis.com/post/3ba75fd9-ad50-44f0-95df-ea0aa5b9f450/upload-1671728391679.webp",
          "collections": {},
          "type1": "thunder",
          "access": "public",
          "slug": "mount-olympus",
          "createdAt": "2022-10-27T21:23:07.010Z",
          "bgImage": "https://cdn.cerealapis.com/post/99daa1a2-ae6e-46be-be20-d29fcd54c8cc/upload-1671728390581.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "bgColor": "#E3AB21",
          "publishedAt": "2022-01-01T12:00:00.000Z",
          "rarity": "common",
          "runeType": "stadium",
          "description": "``` embed\nElestrals get +1 <span class=\"elestrals-font\">J</span> and +1 <span class=\"elestrals-font\">O</span> for each <span class=\"elestrals-font\">T</span> Enchanting them.\n```",
          "id": "image__cdbadb74-e7e1-40fd-8c75-84070d154088",
          "excerpt": "BS1-096",
          "tags": [],
          "author": "75489aa3-accc-4053-ab04-30a1d49d13bb",
          "title": "Mt. Olympus",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/292635bf-0ef8-46f5-8583-dad865383c11/upload-1666719837086.png",
              "category": "Artist",
              "slug": "amalry",
              "bgImage": "https://cdn.cerealapis.com/author/080d7ae5-d84a-4885-a0d2-3443fa849520/upload-1666719837080.png",
              "id": "75489aa3-accc-4053-ab04-30a1d49d13bb",
              "videoCount": 18,
              "title": "Amalry"
          }
      },
      {
          "lastUpdated": "2022-12-22T18:25:17.076Z",
          "file": "https://cdn.cerealapis.com/post/8a56bcab-df53-400c-a2f9-a05388200979/upload-1671728407911.webp",
          "collections": {},
          "type1": "wind",
          "access": "public",
          "slug": "aeolus",
          "createdAt": "2022-10-27T21:23:34.631Z",
          "bgImage": "https://cdn.cerealapis.com/post/41c3540b-b652-4920-a72b-7021344a11ae/upload-1671728406829.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "bgColor": "#ACBCC1",
          "publishedAt": "2022-01-01T12:05:00.000Z",
          "rarity": "full-art",
          "runeType": "divine",
          "description": "``` embed\nWhen you Cast this Aeolus you can Enchant up to <span class=\"elestrals-font\">A</span><span class=\"elestrals-font\">A</span><span class=\"elestrals-font\">A</span><span class=\"elestrals-font\">A</span> to it. You can Disenchant <span class=\"elestrals-font\">A</span><span class=\"elestrals-font\">A</span> from this Aeolus in order to Search your deck for an Elestral with Enchantment Cost <span class=\"elestrals-font\">A</span> and add it to your hand.\n```",
          "id": "image__91e1c33b-be45-4dd2-b07f-11f1a64fda8c",
          "excerpt": "BS1-097",
          "tags": [],
          "author": "15c176d6-97df-497d-aef3-15f737c972a9",
          "title": "Aeolus",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b516689-1021-4652-b56a-bfbef2e05441/upload-1666822702354.png",
              "category": "Artist",
              "slug": "dennis-artworks218-fuhrmann",
              "bgImage": "https://cdn.cerealapis.com/author/90b70451-0371-4db5-beab-5d46f38e07bd/upload-1666822702346.png",
              "id": "15c176d6-97df-497d-aef3-15f737c972a9",
              "videoCount": 22,
              "title": "Dennis Fuhrmann"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/ec9a55b5-f3c6-496f-89ed-a359ba6ea3ce/upload-1671728420380.webp",
          "collections": {},
          "type1": "wind",
          "type2": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/63149e78-bee1-4a2c-8315-181f29a1c40f/upload-1671728419426.webp",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "uncommon",
          "id": "image__d8f6ec5e-ebc3-4a58-bdbd-543ceffcc189",
          "tags": [],
          "author": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
          "lastUpdated": "2022-12-22T18:25:29.970Z",
          "access": "public",
          "slug": "bag-of-winds",
          "createdAt": "2022-10-27T21:23:54.883Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T12:10:00.000Z",
          "runeType": "artifact",
          "description": "``` embed\nThe Empowered Elestral gets +2 <span class=\"elestrals-font\">O</span> and cannot be targeted by an opponent’s Rune. When Aeolus is Enchanted and the Empowered Elestral destroys an Elestral in battle you can target and destroy a Rune.\n```",
          "excerpt": "BS1-098",
          "title": "Bag of Winds",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/9abbfa57-fadc-42d9-bd52-3907b145d6ff/upload-1662720081319.jpeg",
              "category": "Artist",
              "slug": "jonny-de-oliviera",
              "bgImage": "https://cdn.cerealapis.com/author/d6271a98-e34d-4b1a-843c-45b03f86abb2/upload-1662720081310.jpeg",
              "id": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
              "videoCount": 22,
              "title": "Jonny De Oliviera"
          }
      },
      {
          "lastUpdated": "2022-12-31T01:58:40.621Z",
          "file": "https://cdn.cerealapis.com/post/684f4565-f5c1-4a1c-a561-a302e4bacd09/upload-1671728433908.webp",
          "collections": {},
          "type1": "wind",
          "access": "public",
          "slug": "tornado",
          "createdAt": "2022-10-27T21:24:15.261Z",
          "bgImage": "https://cdn.cerealapis.com/post/93603639-e1b0-46db-a17e-aa1564ae5907/upload-1671728432922.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "bgColor": "#ACBCC1",
          "publishedAt": "2022-01-01T12:15:00.000Z",
          "rarity": "uncommon",
          "runeType": "invoke",
          "description": "Target an opponent's Rune and return it to their hand.",
          "id": "image__3389b814-501b-430c-b177-14a62f1c091d",
          "excerpt": "BS1-099",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "title": "Tornado",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "lastUpdated": "2022-12-22T18:25:54.041Z",
          "file": "https://cdn.cerealapis.com/post/2b438b73-23c9-4a5a-bbf3-46e63d60ee22/upload-1671728454793.webp",
          "collections": {},
          "type1": "wind",
          "access": "public",
          "slug": "island-of-aeolia",
          "createdAt": "2022-10-27T21:24:32.677Z",
          "bgImage": "https://cdn.cerealapis.com/post/39390026-5567-4f33-8049-946bd3c10f8c/upload-1671728453522.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "bgColor": "#ACBCC1",
          "publishedAt": "2022-01-01T12:20:00.000Z",
          "rarity": "common",
          "runeType": "stadium",
          "description": "``` embed\nElestrals get +1 <span class=\"elestrals-font\">J</span> and +1 <span class=\"elestrals-font\">O</span> for each <span class=\"elestrals-font\">A</span> Enchanting them.\n```",
          "id": "image__2df20a80-d3dc-43d6-b25b-c9110bd38bee",
          "excerpt": "BS1-100",
          "tags": [],
          "author": "19eb7b3b-3b39-464e-9a88-427e8f289558",
          "title": "Island of Aeolia",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/29f7dfd3-2003-4e21-932b-31d442bb668c/upload-1666966185555.png",
              "category": "Artist",
              "slug": "elite4",
              "id": "19eb7b3b-3b39-464e-9a88-427e8f289558",
              "videoCount": 42,
              "title": "Élite4"
          }
      },
      {
          "lastUpdated": "2023-04-29T22:01:20.518Z",
          "file": "https://cdn.cerealapis.com/post/c4cfa35e-6bcf-4dcb-8ea2-ed58e6b4c9e4/upload-1671728478183.webp",
          "collections": {},
          "type1": "rainbow",
          "type2": "rainbow",
          "access": "public",
          "slug": "nectar-of-the-gods",
          "createdAt": "2022-10-27T21:24:52.155Z",
          "bgImage": "https://cdn.cerealapis.com/post/fb8b7237-3102-4362-aa71-2643b8b7a5b9/upload-1682805679660.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T12:25:00.000Z",
          "rarity": "holo-rare",
          "runeType": "invoke",
          "description": "Draw 2 cards.",
          "id": "image__2816d527-ed7f-4f83-866c-9d26108b4b80",
          "excerpt": "BS1-101",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "title": "Nectar of the Gods",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "lastUpdated": "2023-04-29T22:01:35.048Z",
          "file": "https://cdn.cerealapis.com/post/77e086c5-bf0b-4e37-a326-bf52e2e77b8b/upload-1671728514954.webp",
          "collections": {},
          "type1": "rainbow",
          "access": "public",
          "slug": "circle-the-sky",
          "createdAt": "2022-10-27T21:25:13.924Z",
          "bgImage": "https://cdn.cerealapis.com/post/f6bd03b7-41bd-4c00-afde-ec9d447cac97/upload-1682805694020.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T12:30:00.000Z",
          "rarity": "holo-rare",
          "runeType": "invoke",
          "description": "``` embed\nNexus up to <span class=\"elestrals-font\">X</span><span class=\"elestrals-font\">X</span>. If you do, you can draw a card.\n```",
          "id": "image__914c63e9-547d-4500-ba02-aff0996c6969",
          "excerpt": "BS1-102",
          "tags": [],
          "author": "06f6337e-75f8-4837-a14b-bb73e880382e",
          "title": "Circle the Sky",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/696727cb-6e52-4f00-b2b5-b5ee9b967d3e/upload-1666823423451.png",
              "category": "Artist",
              "slug": "giovanni-n4nin4ni-aguilar",
              "bgImage": "https://cdn.cerealapis.com/author/49850f39-8072-4bd7-8776-15508c33f3d7/upload-1666823423402.png",
              "id": "06f6337e-75f8-4837-a14b-bb73e880382e",
              "videoCount": 53,
              "title": "Giovanni Aguilar"
          }
      },
      {
          "lastUpdated": "2023-04-29T22:01:49.408Z",
          "file": "https://cdn.cerealapis.com/post/7c3395b7-7386-4f20-aa7d-7123f2dd08dc/upload-1671728529504.webp",
          "collections": {},
          "type1": "rainbow",
          "access": "public",
          "slug": "ambrosia",
          "createdAt": "2022-10-27T21:25:35.231Z",
          "bgImage": "https://cdn.cerealapis.com/post/ca21076d-7935-462f-a6ab-90f719732684/upload-1682805708520.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T12:35:00.000Z",
          "rarity": "holo-rare",
          "runeType": "invoke",
          "description": "``` embed\nReturn up to <span class=\"elestrals-font\">X</span><span class=\"elestrals-font\">X</span><span class=\"elestrals-font\">X</span> from your Underworld to your Spirit Deck.\n```",
          "id": "image__632cca6b-2098-423c-998e-24915fa71bea",
          "excerpt": "BS1-103",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "title": "Ambrosia",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/36b0d22a-0621-4520-aee1-3683b75f575c/upload-1671728543822.webp",
          "collections": {},
          "type1": "rainbow",
          "type2": "rainbow",
          "type3": "rainbow",
          "bgImage": "https://cdn.cerealapis.com/post/0b57aa50-ad98-45ef-8c39-ecc306f2f12f/upload-1682805764844.webp",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "rarity": "holo-rare",
          "id": "image__057950ca-c943-4bd4-9abe-69b513def2cc",
          "tags": [],
          "author": "19eb7b3b-3b39-464e-9a88-427e8f289558",
          "lastUpdated": "2023-04-29T22:02:45.810Z",
          "access": "public",
          "slug": "rise-from-the-ashes",
          "createdAt": "2022-10-27T21:26:22.628Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T12:40:00.000Z",
          "runeType": "invoke",
          "description": "Target an Elestral in your Underworld and Special Cast it in Attack Position.",
          "excerpt": "BS1-104",
          "title": "Rise from the Ashes",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/29f7dfd3-2003-4e21-932b-31d442bb668c/upload-1666966185555.png",
              "category": "Artist",
              "slug": "elite4",
              "id": "19eb7b3b-3b39-464e-9a88-427e8f289558",
              "videoCount": 42,
              "title": "Élite4"
          }
      },
      {
          "lastUpdated": "2022-12-22T18:27:02.421Z",
          "file": "https://cdn.cerealapis.com/post/8df98d71-da79-49dc-aa61-37e742b269eb/upload-1671728571301.webp",
          "collections": {},
          "type1": "rainbow",
          "type2": "rainbow",
          "access": "public",
          "slug": "drops-of-lethe",
          "createdAt": "2022-10-27T21:26:50.533Z",
          "bgImage": "https://cdn.cerealapis.com/post/6e2426fa-a3ad-4a63-a9d5-6dfcf646ec35/upload-1671728570322.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T12:45:00.000Z",
          "rarity": "common",
          "runeType": "invoke",
          "description": "Reveal any number of cards from your hand, then shuffle them into your deck and draw that many cards plus one.",
          "id": "image__d63db2a2-abe3-4b7f-baa9-208f045ddf32",
          "excerpt": "BS1-105",
          "tags": [],
          "author": "19eb7b3b-3b39-464e-9a88-427e8f289558",
          "title": "Drops of Lethe",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/29f7dfd3-2003-4e21-932b-31d442bb668c/upload-1666966185555.png",
              "category": "Artist",
              "slug": "elite4",
              "id": "19eb7b3b-3b39-464e-9a88-427e8f289558",
              "videoCount": 42,
              "title": "Élite4"
          }
      },
      {
          "lastUpdated": "2022-12-31T02:11:21.120Z",
          "file": "https://cdn.cerealapis.com/post/9c58d221-925f-415f-a430-3d7ff36ab055/upload-1671728558236.webp",
          "collections": {},
          "type1": "rainbow",
          "access": "public",
          "slug": "neclace-of-harmonia",
          "createdAt": "2022-10-27T21:27:28.870Z",
          "bgImage": "https://cdn.cerealapis.com/post/05c772ca-e62b-425d-a381-63b7e9f8db4c/upload-1671728557281.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T12:50:00.000Z",
          "rarity": "common",
          "runeType": "invoke",
          "description": "Discard a card from your hand in order to discard a random card from an opponent's hand and send the top 2 cards of their deck to the Underworld.",
          "id": "image__58a1b7b6-8a5e-42f9-8512-e52fea3f4bd5",
          "excerpt": "BS1-106",
          "featured": false,
          "tags": [],
          "author": "75489aa3-accc-4053-ab04-30a1d49d13bb",
          "title": "Golden Apple of Discord",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/292635bf-0ef8-46f5-8583-dad865383c11/upload-1666719837086.png",
              "category": "Artist",
              "slug": "amalry",
              "bgImage": "https://cdn.cerealapis.com/author/080d7ae5-d84a-4885-a0d2-3443fa849520/upload-1666719837080.png",
              "id": "75489aa3-accc-4053-ab04-30a1d49d13bb",
              "videoCount": 18,
              "title": "Amalry"
          }
      },
      {
          "lastUpdated": "2022-12-31T02:13:18.244Z",
          "file": "https://cdn.cerealapis.com/post/c12369ac-9d78-4692-b0ee-3c7f8025ac72/upload-1671728590814.webp",
          "collections": {},
          "type1": "rainbow",
          "type2": "rainbow",
          "access": "public",
          "slug": "golden-apple-of-discord",
          "createdAt": "2022-10-27T21:27:53.305Z",
          "bgImage": "https://cdn.cerealapis.com/post/ef5a7010-872a-4012-ba0d-0a118c3b50d6/upload-1671728589196.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T12:55:00.000Z",
          "rarity": "uncommon",
          "runeType": "artifact",
          "description": "``` embed\nThe Empowered Elestral gets +5 <span class=\"elestrals-font\">J</span>. During their End Phase the controller of the Empowered Elestral must Expend <span class=\"elestrals-font\">X</span><span class=\"elestrals-font\">X</span>. If they cannot, this Necklace of Harmonia is destroyed.\n```",
          "id": "image__94b1d94a-1c30-4dc0-8fbe-86e722bb3774",
          "excerpt": "BS1-107",
          "tags": [],
          "author": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
          "title": "Necklace of Harmonia",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4c88d483-9e81-4854-942f-97df6f95299e/upload-1666824047475.png",
              "category": "Artist",
              "slug": "phoebe-the-last-shaymin-sumner-twisk",
              "bgImage": "https://cdn.cerealapis.com/author/a0c334f7-fbb6-426d-88ca-2915f35213e4/upload-1666824047501.png",
              "id": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
              "videoCount": 33,
              "title": "Phoebe Sumner-Twisk"
          }
      },
      {
          "lastUpdated": "2023-04-29T22:03:01.121Z",
          "file": "https://cdn.cerealapis.com/post/9a994eed-afa8-4c40-b44b-c902f141816b/upload-1671728603502.webp",
          "collections": {},
          "type1": "rainbow",
          "access": "public",
          "slug": "altar-of-stars",
          "createdAt": "2022-10-27T21:28:15.993Z",
          "bgImage": "https://cdn.cerealapis.com/post/2a25d70e-cb27-4382-b696-9c01d5a31d74/upload-1682805780238.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T13:00:00.000Z",
          "rarity": "holo-rare",
          "runeType": "counter",
          "description": "``` embed\nNexus <span class=\"elestrals-font\">X</span>. If you do, you can change the Position of an Elestral.\n```",
          "id": "image__965563cb-5303-44d8-acbf-f4984dd37941",
          "excerpt": "BS1-108",
          "tags": [],
          "author": "15c176d6-97df-497d-aef3-15f737c972a9",
          "title": "Altar of Stars",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b516689-1021-4652-b56a-bfbef2e05441/upload-1666822702354.png",
              "category": "Artist",
              "slug": "dennis-artworks218-fuhrmann",
              "bgImage": "https://cdn.cerealapis.com/author/90b70451-0371-4db5-beab-5d46f38e07bd/upload-1666822702346.png",
              "id": "15c176d6-97df-497d-aef3-15f737c972a9",
              "videoCount": 22,
              "title": "Dennis Fuhrmann"
          }
      },
      {
          "lastUpdated": "2023-04-29T22:03:13.728Z",
          "file": "https://cdn.cerealapis.com/post/a972d72f-c6d7-4759-89e5-ae87187d46ab/upload-1671728618257.webp",
          "collections": {},
          "type1": "rainbow",
          "type2": "rainbow",
          "access": "public",
          "slug": "pandoras-box",
          "createdAt": "2022-10-27T21:28:36.996Z",
          "bgImage": "https://cdn.cerealapis.com/post/402d9765-5ecf-46e5-b47c-0c322ddfe857/upload-1682805792809.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T13:05:00.000Z",
          "rarity": "holo-rare",
          "runeType": "counter",
          "description": "This Pandora's Box can only be Cast when an opponent activates an effect that Searches for or Casts a card from their deck. Negate that effect and that opponent must send the top 2 cards from their deck to their Underworld.",
          "id": "image__25704cc7-3dd0-4bcd-ae21-4100d469946a",
          "excerpt": "BS1-109",
          "tags": [],
          "author": "962db316-931a-4cb5-bdd1-9330d8486272",
          "title": "Pandora's Box",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b314a3d-73f8-4458-b16c-b821c4f915f3/upload-1666823695029.png",
              "category": "Artist",
              "slug": "lucas-kosta-costa",
              "bgImage": "https://cdn.cerealapis.com/author/3ebe38de-02f2-496d-a11f-337103ee7347/upload-1666823695026.png",
              "id": "962db316-931a-4cb5-bdd1-9330d8486272",
              "videoCount": 20,
              "title": "Lucas Costa"
          }
      },
      {
          "lastUpdated": "2022-12-31T02:21:59.720Z",
          "file": "https://cdn.cerealapis.com/post/d44419b5-d664-4223-a65a-f5a3b8b0ca98/upload-1671728641565.webp",
          "collections": {},
          "type1": "rainbow",
          "access": "public",
          "slug": "shield-of-achilles",
          "createdAt": "2022-10-27T21:28:53.893Z",
          "bgImage": "https://cdn.cerealapis.com/post/a93324c0-6dd0-4337-b51d-79286a474cf0/upload-1671728639737.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T13:10:00.000Z",
          "rarity": "uncommon",
          "runeType": "counter",
          "description": "``` embed\nThis Shield of Achilles can only be Cast after an opponent's Elestral declares an attack. Expend <span class=\"elestrals-font\">X</span> equal to that Elestral's Enchantment Cost in order to negate that attack and return that Elestral to their hand.\n```",
          "id": "image__2585e2f0-c784-4ce7-8471-4555b4b7e110",
          "excerpt": "BS1-110",
          "tags": [],
          "author": "19eb7b3b-3b39-464e-9a88-427e8f289558",
          "title": "Shield of Achilles",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/29f7dfd3-2003-4e21-932b-31d442bb668c/upload-1666966185555.png",
              "category": "Artist",
              "slug": "elite4",
              "id": "19eb7b3b-3b39-464e-9a88-427e8f289558",
              "videoCount": 42,
              "title": "Élite4"
          }
      },
      {
          "lastUpdated": "2022-12-22T18:28:02.605Z",
          "file": "https://cdn.cerealapis.com/post/0e2d475f-5496-4558-8861-03cc4e837590/upload-1671728654830.webp",
          "collections": {},
          "type1": "rainbow",
          "type2": "rainbow",
          "access": "public",
          "slug": "charons-obol",
          "createdAt": "2022-10-27T21:29:16.571Z",
          "bgImage": "https://cdn.cerealapis.com/post/61d0f41e-b012-4b45-a99c-de1f6beffe07/upload-1671728653803.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T13:15:00.000Z",
          "rarity": "common",
          "runeType": "counter",
          "description": "Until the End Phase, when an Elestral is destroyed in battle you can draw a card.",
          "id": "image__9e134f01-00c9-4892-8ae8-e94fb00ae4db",
          "excerpt": "BS1-111",
          "tags": [],
          "author": "962db316-931a-4cb5-bdd1-9330d8486272",
          "title": "Charon's Obol",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b314a3d-73f8-4458-b16c-b821c4f915f3/upload-1666823695029.png",
              "category": "Artist",
              "slug": "lucas-kosta-costa",
              "bgImage": "https://cdn.cerealapis.com/author/3ebe38de-02f2-496d-a11f-337103ee7347/upload-1666823695026.png",
              "id": "962db316-931a-4cb5-bdd1-9330d8486272",
              "videoCount": 20,
              "title": "Lucas Costa"
          }
      },
      {
          "lastUpdated": "2022-12-22T18:28:12.323Z",
          "file": "https://cdn.cerealapis.com/post/4d968aaf-e4f7-4b9c-b477-7e16db4e55ba/upload-1671728668543.webp",
          "collections": {},
          "type1": "rainbow",
          "type2": "rainbow",
          "access": "public",
          "slug": "helios-chariot-ride",
          "createdAt": "2022-10-27T21:29:40.815Z",
          "bgImage": "https://cdn.cerealapis.com/post/a3902f70-7433-4509-8ae3-6327fb8a4669/upload-1671728667434.webp",
          "postStatus": "published",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T13:20:00.000Z",
          "rarity": "common",
          "runeType": "counter",
          "description": "This Helios’ Chariot Ride can only be Cast during the Draw Phase. Opponents cannot Cast Runes this turn.",
          "id": "image__a6de1122-bf57-44ac-b8c3-b45590d9f866",
          "excerpt": "BS1-112",
          "tags": [],
          "author": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
          "title": "Helios' Chariot Ride",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4c88d483-9e81-4854-942f-97df6f95299e/upload-1666824047475.png",
              "category": "Artist",
              "slug": "phoebe-the-last-shaymin-sumner-twisk",
              "bgImage": "https://cdn.cerealapis.com/author/a0c334f7-fbb6-426d-88ca-2915f35213e4/upload-1666824047501.png",
              "id": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
              "videoCount": 33,
              "title": "Phoebe Sumner-Twisk"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/230dccba-d7c8-43d1-843a-e029cb450696/upload-1671728682675.webp",
          "collections": {},
          "type1": "earth",
          "type2": "earth",
          "type3": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/0fbbdb3c-f964-40af-a7d9-ea442560a2a1/upload-1682805806736.webp",
          "attack": "11",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "full-art",
          "id": "image__370b5580-bc33-4f69-8acc-1116a5899e38",
          "tags": [],
          "author": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
          "lastUpdated": "2023-04-29T22:03:27.749Z",
          "access": "public",
          "slug": "113",
          "createdAt": "2022-10-28T19:43:05.513Z",
          "defense": "15",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T13:25:00.000Z",
          "subclass2": "behemoth",
          "subclass1": "dryad",
          "description": "``` embed\nYou can Disenchant <span class=\"elestrals-font\">E</span> from this Centaurbor in order to target and destroy a card. As long as Foloi Forest is Enchanted this Centaurbor cannot be targeted by Invoke Runes.\n```",
          "excerpt": "BS1-113",
          "title": "Centaurbor",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/6810b997-e024-4b4b-b43a-4e8864c1e586/upload-1666822760398.png",
              "category": "Artist",
              "slug": "diego-monster",
              "bgImage": "https://cdn.cerealapis.com/author/45070d0c-4dd8-491c-a56a-8a164afa9400/upload-1666822760394.png",
              "id": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
              "videoCount": 17,
              "title": "Diego Monster "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/11d48a29-85a8-4f50-a709-3005bbef8cb2/upload-1671728697535.webp",
          "collections": {},
          "type1": "fire",
          "type2": "fire",
          "type3": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/45310838-9a6d-4591-beda-57413147d30b/upload-1682805826124.webp",
          "attack": "14",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "full-art",
          "id": "image__ec256403-1246-400e-8045-84a045622cf0",
          "tags": [],
          "author": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
          "lastUpdated": "2023-04-29T22:03:47.201Z",
          "access": "public",
          "slug": "114",
          "createdAt": "2022-10-28T19:43:37.545Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T13:30:00.000Z",
          "subclass2": "behemoth",
          "subclass1": "eldritch",
          "description": "``` embed\nWhen an opponent Casts an Elestral you can Expend <span class=\"elestrals-font\">F</span> in order to give it -3 <span class=\"elestrals-font\">O</span> until your next End Phase. If that Elestral’s <span class=\"elestrals-font\">O</span> is reduced to 0 by this effect, it is destroyed.\n```",
          "excerpt": "BS1-114",
          "title": "Trifernal",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/9abbfa57-fadc-42d9-bd52-3907b145d6ff/upload-1662720081319.jpeg",
              "category": "Artist",
              "slug": "jonny-de-oliviera",
              "bgImage": "https://cdn.cerealapis.com/author/d6271a98-e34d-4b1a-843c-45b03f86abb2/upload-1662720081310.jpeg",
              "id": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
              "videoCount": 22,
              "title": "Jonny De Oliviera"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/837403bc-bb0d-4e37-a645-1af0478d3063/upload-1671728718942.webp",
          "collections": {},
          "type1": "fire",
          "type2": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/5e39c8a9-c879-4edd-b3be-077817c268a5/upload-1682805842542.webp",
          "attack": "7",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "full-art",
          "id": "image__9665edcf-45b8-4f66-a4d8-6600c316e37e",
          "tags": [],
          "author": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
          "lastUpdated": "2023-04-29T22:04:03.593Z",
          "access": "public",
          "slug": "115",
          "createdAt": "2022-10-28T19:47:28.220Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T13:35:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "behemoth",
          "description": "As long as this Leonite is in Attack Position players cannot Cast Invoke Runes.",
          "excerpt": "BS1-115",
          "title": "Leonite",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/6810b997-e024-4b4b-b43a-4e8864c1e586/upload-1666822760398.png",
              "category": "Artist",
              "slug": "diego-monster",
              "bgImage": "https://cdn.cerealapis.com/author/45070d0c-4dd8-491c-a56a-8a164afa9400/upload-1666822760394.png",
              "id": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
              "videoCount": 17,
              "title": "Diego Monster "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/1f8bb86b-4fce-4bd4-8a41-498037cb3ef4/upload-1671728740558.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/008f1912-98c3-4ee7-b034-89a9c6a13324/upload-1682805860241.webp",
          "attack": "3",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "full-art",
          "id": "image__6aa3b32a-22be-4bd1-b3d2-15ed316e6cf0",
          "tags": [],
          "author": "19eb7b3b-3b39-464e-9a88-427e8f289558",
          "lastUpdated": "2023-04-29T22:04:21.359Z",
          "access": "public",
          "slug": "116",
          "createdAt": "2022-10-28T19:49:51.709Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T13:40:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "avian",
          "description": "``` embed\nWhen this Emphyrix is destroyed in battle or by an opponent's card effect you can Expend <span class=\"elestrals-font\">F</span>. If you do, during this turn's End Phase you can Special Cast it.\n```",
          "excerpt": "BS1-116",
          "title": "Emphyrix",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/29f7dfd3-2003-4e21-932b-31d442bb668c/upload-1666966185555.png",
              "category": "Artist",
              "slug": "elite4",
              "id": "19eb7b3b-3b39-464e-9a88-427e8f289558",
              "videoCount": 42,
              "title": "Élite4"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/fa4285e9-f718-4121-a791-fbc9a2bee3e7/upload-1671728762002.webp",
          "collections": {},
          "type1": "water",
          "type2": "water",
          "type3": "water",
          "bgImage": "https://cdn.cerealapis.com/post/9d2c32ed-333a-4d3b-a329-0782ca688d29/upload-1682805881848.webp",
          "attack": "6",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "full-art",
          "id": "image__b964315a-4d83-46a9-a842-403d7c7a3fa7",
          "tags": [],
          "author": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
          "lastUpdated": "2023-04-29T22:04:42.831Z",
          "access": "public",
          "slug": "117",
          "createdAt": "2022-10-28T19:50:11.432Z",
          "defense": "14",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T13:45:00.000Z",
          "subclass2": "dragon",
          "subclass1": "oceanic",
          "description": "``` embed\nThis Majesea gets +1 <span class=\"elestrals-font\">J</span> for each Enchanting <span class=\"elestrals-font\">W</span> on your field. <span class=\"elestrals-font\">W</span>-Enchanted Elestrals cannot be targeted by an opponent’s Elestral effect.\n```",
          "excerpt": "BS1-117",
          "title": "Majesea",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4c88d483-9e81-4854-942f-97df6f95299e/upload-1666824047475.png",
              "category": "Artist",
              "slug": "phoebe-the-last-shaymin-sumner-twisk",
              "bgImage": "https://cdn.cerealapis.com/author/a0c334f7-fbb6-426d-88ca-2915f35213e4/upload-1666824047501.png",
              "id": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
              "videoCount": 33,
              "title": "Phoebe Sumner-Twisk"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/7b99986e-8f74-455a-8bc0-3366eff16d57/upload-1671728776745.webp",
          "collections": {},
          "type1": "thunder",
          "type2": "thunder",
          "type3": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/0e3ed8ff-0ad7-4cc8-9f23-a618afaa1b59/upload-1682805898293.webp",
          "attack": "8",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "full-art",
          "id": "image__273789d9-ee92-4732-9466-17ab04f1971c",
          "tags": [],
          "author": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
          "lastUpdated": "2023-04-29T22:04:59.256Z",
          "access": "public",
          "slug": "118",
          "createdAt": "2022-10-28T19:50:29.558Z",
          "defense": "12",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T13:50:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "avian",
          "description": "``` embed\nThis Ohmperial gets +1 <span class=\"elestrals-font\">J</span> for each <span class=\"elestrals-font\">T</span> in your Spirit Deck. You can Expend <span class=\"elestrals-font\">T</span> in order to target and destroy a card.\n```",
          "excerpt": "BS1-118",
          "title": "Ohmperial",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/9abbfa57-fadc-42d9-bd52-3907b145d6ff/upload-1662720081319.jpeg",
              "category": "Artist",
              "slug": "jonny-de-oliviera",
              "bgImage": "https://cdn.cerealapis.com/author/d6271a98-e34d-4b1a-843c-45b03f86abb2/upload-1662720081310.jpeg",
              "id": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
              "videoCount": 22,
              "title": "Jonny De Oliviera"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/db462ce9-059d-4af2-87eb-a196337fcda1/upload-1671728794181.webp",
          "collections": {},
          "type1": "thunder",
          "type2": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/a0a28649-b86c-47bf-9a13-a30fd10175e7/upload-1682805916038.webp",
          "attack": "7",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "full-art",
          "id": "image__7f79a9a8-037f-4d59-8aa6-e5d3eea4c8de",
          "tags": [],
          "author": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
          "lastUpdated": "2023-04-29T22:05:17.037Z",
          "access": "public",
          "slug": "119",
          "createdAt": "2022-10-28T19:50:48.615Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T13:55:00.000Z",
          "subclass2": "behemoth",
          "subclass1": "eldritch",
          "description": "This Sonicore’s Cast cannot be chained by Counter Runes. As long as this Sonicore is in Attack Position players cannot Cast Counter Runes.",
          "excerpt": "BS1-119",
          "title": "Sonicore",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/6810b997-e024-4b4b-b43a-4e8864c1e586/upload-1666822760398.png",
              "category": "Artist",
              "slug": "diego-monster",
              "bgImage": "https://cdn.cerealapis.com/author/45070d0c-4dd8-491c-a56a-8a164afa9400/upload-1666822760394.png",
              "id": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
              "videoCount": 17,
              "title": "Diego Monster "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/ce332d3e-bab6-49b6-9554-c5121e106298/upload-1671728808692.webp",
          "collections": {},
          "type1": "wind",
          "type2": "wind",
          "type3": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/1ff96079-8566-4054-9689-285f5068e55d/upload-1682805931717.webp",
          "attack": "9",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "full-art",
          "id": "image__bce1ea32-7b6d-40b8-97f7-a6f02b73e5ef",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2023-04-29T22:05:32.657Z",
          "access": "public",
          "slug": "120",
          "createdAt": "2022-10-28T19:51:18.655Z",
          "defense": "5",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T14:00:00.000Z",
          "subclass1": "insectoid",
          "description": "``` embed\nThis Stratomoth gets +1 <span class=\"elestrals-font\">J</span> for each Nimbug and Cirrucoon on your field and in your Underworld. You can Expend <span class=\"elestrals-font\">A</span> in order to return each of your opponent's Runes to their hand.\n```",
          "excerpt": "BS1-120",
          "title": "Stratomoth",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/4c7fa8e9-261d-4b04-b765-008dee023617/upload-1671728823847.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/69ae2a4d-f5fd-4602-87d1-0fd15ca4325e/upload-1682805947161.webp",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "alternative-art",
          "id": "image__3e43a415-1845-465a-a0ee-c67526fd44a8",
          "tags": [],
          "author": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
          "lastUpdated": "2023-04-29T22:05:48.088Z",
          "access": "public",
          "slug": "121",
          "createdAt": "2022-10-28T19:51:50.011Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T14:05:00.000Z",
          "subclass2": "golem",
          "subclass1": "insectoid",
          "description": "``` embed\nEarth Spirit <span class=\"elestrals-font\">E</span>\n```",
          "excerpt": "BS1-121",
          "title": "Teratlas",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/9abbfa57-fadc-42d9-bd52-3907b145d6ff/upload-1662720081319.jpeg",
              "category": "Artist",
              "slug": "jonny-de-oliviera",
              "bgImage": "https://cdn.cerealapis.com/author/d6271a98-e34d-4b1a-843c-45b03f86abb2/upload-1662720081310.jpeg",
              "id": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
              "videoCount": 22,
              "title": "Jonny De Oliviera"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/91d9a485-1bae-4df4-ab1c-b6855de46bf1/upload-1671728837472.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/467e51db-fc70-43a4-9bf6-b36cf4a59b27/upload-1682805973821.webp",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "alternative-art",
          "id": "image__d2d4147c-124b-4942-bb52-7c004102a3b0",
          "tags": [],
          "author": "8194695f-8d92-491e-b87b-c977a16a317e",
          "lastUpdated": "2023-04-29T22:06:14.919Z",
          "access": "public",
          "slug": "122",
          "createdAt": "2022-10-28T19:52:12.339Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T14:10:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "dragon",
          "description": "``` embed\nFire Spirit <span class=\"elestrals-font\">F</span>\n```",
          "excerpt": "BS1-122",
          "title": "Vipyro",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/a7719a4e-ee26-4bf7-8839-2887320a2a0a/upload-1666823882654.png",
              "category": "Artist",
              "slug": "luan-m-resende",
              "bgImage": "https://cdn.cerealapis.com/author/7d33a277-e7a3-423f-8661-3fdc94b69e7d/upload-1666823882721.png",
              "id": "8194695f-8d92-491e-b87b-c977a16a317e",
              "videoCount": 20,
              "title": "Luan M. Resende"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/acc9e611-13c8-457b-b2d2-ba6634ec35fb/upload-1671728859284.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/3368f657-a137-4d6e-be3f-f6ba6d1d6ec0/upload-1682805988243.webp",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "alternative-art",
          "id": "image__435403f1-356c-41d7-b735-6033e1b39746",
          "tags": [],
          "author": "962db316-931a-4cb5-bdd1-9330d8486272",
          "lastUpdated": "2023-04-29T22:06:29.340Z",
          "access": "public",
          "slug": "123",
          "createdAt": "2022-10-28T19:52:40.613Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T14:15:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "oceanic",
          "description": "``` embed\nWater Spirit <span class=\"elestrals-font\">W</span>\n```",
          "excerpt": "BS1-123",
          "title": "Leviaphin",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b314a3d-73f8-4458-b16c-b821c4f915f3/upload-1666823695029.png",
              "category": "Artist",
              "slug": "lucas-kosta-costa",
              "bgImage": "https://cdn.cerealapis.com/author/3ebe38de-02f2-496d-a11f-337103ee7347/upload-1666823695026.png",
              "id": "962db316-931a-4cb5-bdd1-9330d8486272",
              "videoCount": 20,
              "title": "Lucas Costa"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/3aee6303-325b-416a-9124-e909b568e4b2/upload-1671728874693.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/8fb59399-e813-49ce-9289-d1ad05d213ae/upload-1682806061535.webp",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "alternative-art",
          "id": "image__9d105c7c-e88b-4e34-ba0b-b87d2e867f27",
          "tags": [],
          "author": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
          "lastUpdated": "2023-04-29T22:07:42.487Z",
          "access": "public",
          "slug": "124",
          "createdAt": "2022-10-28T19:52:59.975Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T14:20:00.000Z",
          "subclass2": "dragon",
          "subclass1": "archaic",
          "description": "``` embed\nThunder Spirit <span class=\"elestrals-font\">T</span>\n```",
          "excerpt": "BS1-124",
          "title": "Zaptor",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4c88d483-9e81-4854-942f-97df6f95299e/upload-1666824047475.png",
              "category": "Artist",
              "slug": "phoebe-the-last-shaymin-sumner-twisk",
              "bgImage": "https://cdn.cerealapis.com/author/a0c334f7-fbb6-426d-88ca-2915f35213e4/upload-1666824047501.png",
              "id": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
              "videoCount": 33,
              "title": "Phoebe Sumner-Twisk"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/97b65ee2-f6e5-4082-9d1f-6a762b354c71/upload-1671728890180.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/3448f7a4-faef-49ec-b27b-c12c4cabf1c5/upload-1682806093669.webp",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "alternative-art",
          "id": "image__7d034314-829c-4721-a69a-5335ab31e458",
          "tags": [],
          "author": "962db316-931a-4cb5-bdd1-9330d8486272",
          "lastUpdated": "2023-04-29T22:08:14.633Z",
          "access": "public",
          "slug": "125",
          "createdAt": "2022-10-28T19:53:49.592Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T14:25:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "brute",
          "description": "``` embed\nWind Spirit <span class=\"elestrals-font\">A</span>\n```",
          "excerpt": "BS1-125",
          "title": "Lycarus",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b314a3d-73f8-4458-b16c-b821c4f915f3/upload-1666823695029.png",
              "category": "Artist",
              "slug": "lucas-kosta-costa",
              "bgImage": "https://cdn.cerealapis.com/author/3ebe38de-02f2-496d-a11f-337103ee7347/upload-1666823695026.png",
              "id": "962db316-931a-4cb5-bdd1-9330d8486272",
              "videoCount": 20,
              "title": "Lucas Costa"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/8660f797-9cd6-4fff-b53b-5140f56bd242/upload-1671728986258.webp",
          "collections": {},
          "type1": "wind",
          "type2": "wind",
          "type3": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/d6f60aa9-4747-45fe-b0b5-fc9a7be16354/upload-1682806414982.webp",
          "attack": "15",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "stellar-rare",
          "id": "image__7c62ec58-b6bf-4f71-8d87-bdaa58f7e445",
          "tags": [],
          "author": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
          "lastUpdated": "2023-04-29T22:13:36.185Z",
          "access": "public",
          "slug": "penterror",
          "createdAt": "2022-11-15T09:13:28.612Z",
          "defense": "8",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T14:30:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "dragon",
          "description": "When this Penterror is destroyed by an opponent’s card effect you can Special Cast any number of Hydrake and Twindra from your hand or deck in Attack Position.",
          "excerpt": "XX/10",
          "title": "Penterror",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/6810b997-e024-4b4b-b43a-4e8864c1e586/upload-1666822760398.png",
              "category": "Artist",
              "slug": "diego-monster",
              "bgImage": "https://cdn.cerealapis.com/author/45070d0c-4dd8-491c-a56a-8a164afa9400/upload-1666822760394.png",
              "id": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
              "videoCount": 17,
              "title": "Diego Monster "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/d2c86a41-e120-4929-8c80-0e0a6ea5d7bd/upload-1671729020514.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/7e316a1a-e56d-4ea8-bf58-8b4e343b8745/upload-1682806529642.webp",
          "attack": "4",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "stellar-rare",
          "id": "image__02614964-2925-437a-b4df-2627c2bb79d7",
          "tags": [],
          "author": "88185685-0ac3-4a6c-b976-0aa8dc3403fe",
          "lastUpdated": "2023-04-29T22:15:31.076Z",
          "access": "public",
          "slug": "vysveris",
          "createdAt": "2022-11-15T09:17:01.998Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T14:35:00.000Z",
          "subclass1": "insectoid",
          "description": "``` embed\nThis Vysceris gains +1 <span class=\"elestrals-font\">J</span> for each Vysceris on the field. When this Vysceris destroys an Elestral in battle you can Special Cast a Vysceris from your hand or deck in Defense Position.\n```",
          "excerpt": "XX/99",
          "title": "Vysceris",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/f881a1cf-d017-4d34-b833-9c5dc9fe7eab/upload-1666823969818.png",
              "category": "Artist",
              "slug": "marcel-bromojumbo-romo",
              "bgImage": "https://cdn.cerealapis.com/author/aa6415a1-f484-4a75-988b-80db7af2fe56/upload-1666823969899.png",
              "id": "88185685-0ac3-4a6c-b976-0aa8dc3403fe",
              "videoCount": 14,
              "title": "Marcel Romo "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/4df1e019-4c96-41c2-9014-548616e202b6/upload-1671728903647.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/23c4cb45-ab86-4f3c-9cc0-bb32b8d9c6b4/upload-1682806234756.webp",
          "attack": "3",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "stellar-rare",
          "id": "image__21ce640a-ea79-45b0-923a-e1c2e2f26c1f",
          "tags": [],
          "author": "19eb7b3b-3b39-464e-9a88-427e8f289558",
          "lastUpdated": "2023-04-29T22:10:35.815Z",
          "access": "public",
          "slug": "emphyrix",
          "createdAt": "2022-11-15T09:23:55.774Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T14:40:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "avian",
          "description": "``` embed\nWhen this Emphyrix is destroyed in battle or by an opponent's card effect you can Expend <span class=\"elestrals-font\">F</span>. If you do, during this turn's End Phase you can Special Cast it.\n```",
          "excerpt": "XX/99",
          "title": "Emphyrix",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/29f7dfd3-2003-4e21-932b-31d442bb668c/upload-1666966185555.png",
              "category": "Artist",
              "slug": "elite4",
              "id": "19eb7b3b-3b39-464e-9a88-427e8f289558",
              "videoCount": 42,
              "title": "Élite4"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/0156f2db-899c-4e36-a72c-257db8a657cb/upload-1671728918525.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/f7920fe8-4804-4993-a85d-6df8eb888767/upload-1682806347722.webp",
          "attack": "1",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "stellar-rare",
          "id": "image__58ac7820-5a1e-4a2e-b303-42b8621347e0",
          "tags": [],
          "author": "ca4bfd8c-3ead-4ec2-bc37-426c1dca7f8c",
          "lastUpdated": "2023-04-29T22:12:28.748Z",
          "access": "public",
          "slug": "moustacean",
          "createdAt": "2022-11-15T12:05:09.584Z",
          "defense": "6",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T14:45:00.000Z",
          "subclass1": "oceanic",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">W</span> in order to swap this Moustacean’s <span class=\"elestrals-font\">J</span> with its <span class=\"elestrals-font\">O</span> until the End Phase. This effect can also be activated when an opponent Normal Casts an Elestral.\n```",
          "excerpt": "XX/99",
          "title": "Moustacean",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/978cea0e-1e6e-4304-b95b-830e7208d206/upload-1666822212654.JPG",
              "category": "Artist",
              "slug": "william-bilsu-parks",
              "bgImage": "https://cdn.cerealapis.com/author/461307f5-955b-418f-9a08-155807a74d59/upload-1666822212691.JPG",
              "id": "ca4bfd8c-3ead-4ec2-bc37-426c1dca7f8c",
              "videoCount": 12,
              "title": "William Parks"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/36509850-aa86-4c41-8200-23e0f0a057b3/upload-1671729006168.webp",
          "collections": {},
          "type1": "thunder",
          "type2": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/42e1223e-8d07-49e4-bf04-81b3058c78c7/upload-1682806472951.webp",
          "attack": "7",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "stellar-rare",
          "id": "image__dad3ad61-d325-4264-b45f-199f1cadba0e",
          "tags": [],
          "author": "8194695f-8d92-491e-b87b-c977a16a317e",
          "lastUpdated": "2023-04-29T22:14:34.043Z",
          "access": "public",
          "slug": "sonicore",
          "createdAt": "2022-11-15T12:07:19.321Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T14:50:00.000Z",
          "subclass2": "behemoth",
          "subclass1": "eldritch",
          "description": "This Sonicore’s Cast cannot be chained by Counter Runes. As long as this Sonicore is in Attack Position players cannot Cast Counter Runes.",
          "excerpt": "XX/99",
          "title": "Sonicore",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/a7719a4e-ee26-4bf7-8839-2887320a2a0a/upload-1666823882654.png",
              "category": "Artist",
              "slug": "luan-m-resende",
              "bgImage": "https://cdn.cerealapis.com/author/7d33a277-e7a3-423f-8661-3fdc94b69e7d/upload-1666823882721.png",
              "id": "8194695f-8d92-491e-b87b-c977a16a317e",
              "videoCount": 20,
              "title": "Luan M. Resende"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/a8b3d507-8af5-4c92-a847-7386bac0a416/upload-1671728956947.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/aafa6d12-fb9f-4f70-878a-3cd61ecd5195/upload-1682806398457.webp",
          "attack": "1",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "stellar-rare",
          "id": "image__062e8fa5-ad0c-41c5-b0c6-583de125cc08",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2023-04-29T22:13:19.488Z",
          "access": "public",
          "slug": "nimbug",
          "createdAt": "2022-11-15T12:15:45.388Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T14:55:00.000Z",
          "subclass1": "insectoid",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">A</span> in order to Special Ascend this Nimbug to Cirrucoon from your hand or deck.\n```",
          "excerpt": "XX/99",
          "title": "Nimbug",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/bbdec4c7-5b8f-482f-9676-f1471149bdac/upload-1690630481789.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/a0af5058-ca4a-43ab-b8a2-2871f085ce94/upload-1690630481475.webp",
          "attack": "4",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "stellar-rare",
          "id": "image__8a031bc9-f619-4682-bdf6-e82f61ee5e70",
          "tags": [],
          "author": "06f6337e-75f8-4837-a14b-bb73e880382e",
          "lastUpdated": "2023-07-29T11:34:42.430Z",
          "access": "public",
          "slug": "secret-stellar-1",
          "defense": "3",
          "createdAt": "2023-01-23T19:13:10.976Z",
          "postStatus": "published",
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T15:00:00.000Z",
          "subclass1": "dryad",
          "description": "``` embed\nYou can Nexus up to <span class=\"elestrals-font\">E</span><span class=\"elestrals-font\">E</span>. When you do, you can target and destroy a Rune.\n```",
          "excerpt": "XX/99",
          "title": "Equilynx",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/696727cb-6e52-4f00-b2b5-b5ee9b967d3e/upload-1666823423451.png",
              "category": "Artist",
              "slug": "giovanni-n4nin4ni-aguilar",
              "bgImage": "https://cdn.cerealapis.com/author/49850f39-8072-4bd7-8776-15508c33f3d7/upload-1666823423402.png",
              "id": "06f6337e-75f8-4837-a14b-bb73e880382e",
              "videoCount": 53,
              "title": "Giovanni Aguilar"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/0ca722f5-0b2c-46d9-93e7-29c1ed10e848/upload-1690630477929.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/54612c15-a593-4ff1-8b00-b2d7aa804ac1/upload-1690630477589.webp",
          "attack": "2",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "stellar-rare",
          "id": "image__fcda0c16-44c2-45f6-94e7-cae94a4663c8",
          "tags": [],
          "author": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
          "lastUpdated": "2023-07-29T11:34:38.669Z",
          "access": "public",
          "slug": "secret-stellar-2",
          "defense": "4",
          "createdAt": "2023-01-23T19:13:26.014Z",
          "postStatus": "published",
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T15:05:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "aquatic",
          "description": "``` embed\nYou can Nexus up to <span class=\"elestrals-font\">W</span><span class=\"elestrals-font\">W</span>. When you do, you can draw a card for each <span class=\"elestrals-font\">W</span> that a card receives this way.\n```",
          "excerpt": "XX/99",
          "title": "Apheros",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4c88d483-9e81-4854-942f-97df6f95299e/upload-1666824047475.png",
              "category": "Artist",
              "slug": "phoebe-the-last-shaymin-sumner-twisk",
              "bgImage": "https://cdn.cerealapis.com/author/a0c334f7-fbb6-426d-88ca-2915f35213e4/upload-1666824047501.png",
              "id": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
              "videoCount": 33,
              "title": "Phoebe Sumner-Twisk"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/f9ea7071-f392-4a44-b574-f8aa5d2511aa/upload-1690630474835.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/311bfa76-fbce-43d6-a3d5-9e0bab3c8b7c/upload-1690630474207.webp",
          "attack": "3",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "stellar-rare",
          "id": "image__5fc64f26-23a9-4d48-b9ec-5e7feabce68b",
          "tags": [],
          "author": "962db316-931a-4cb5-bdd1-9330d8486272",
          "lastUpdated": "2023-07-29T11:34:35.470Z",
          "access": "public",
          "slug": "secrat-stellar-3",
          "defense": "2",
          "createdAt": "2023-01-23T19:13:42.702Z",
          "postStatus": "published",
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T15:10:00.000Z",
          "subclass1": "avian",
          "description": "``` embed\nYou can Nexus up to <span class=\"elestrals-font\">A</span><span class=\"elestrals-font\">A</span>. When you do, an opponent must Expend <span class=\"elestrals-font\">X</span><span class=\"elestrals-font\">X</span>.\n```",
          "excerpt": "XX/99",
          "title": "Exaltair",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b314a3d-73f8-4458-b16c-b821c4f915f3/upload-1666823695029.png",
              "category": "Artist",
              "slug": "lucas-kosta-costa",
              "bgImage": "https://cdn.cerealapis.com/author/3ebe38de-02f2-496d-a11f-337103ee7347/upload-1666823695026.png",
              "id": "962db316-931a-4cb5-bdd1-9330d8486272",
              "videoCount": 20,
              "title": "Lucas Costa"
          }
      },
  
      {
          "file": "https://cdn.cerealapis.com/post/0220ebd6-ab2e-44ae-b9ef-f8d1578b60b2/upload-1671750088227.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/a543898e-55f6-42e2-bec6-949097e41641/upload-1671750087147.webp",
          "attack": "3",
          "series": "f3053e75-dc35-416a-aa96-ee0f65714a50",
          "bgColor": "#B7C03A",
          "rarity": "common",
          "id": "image__5af7263c-8d48-4675-b081-4b4cc962371c",
          "tags": [],
          "author": "ca4bfd8c-3ead-4ec2-bc37-426c1dca7f8c",
          "quantity": "3",
          "lastUpdated": "2023-07-25T18:09:16.461Z",
          "access": "public",
          "slug": "sproutyr",
          "createdAt": "2022-11-11T14:46:34.172Z",
          "defense": "5",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-02T04:00:00.000Z",
          "subclass1": "dryad",
          "description": "When you Normal Cast this Sproutyr you can Search your deck for an Artifact and add it to your hand.",
          "excerpt": "SD01-01",
          "title": "Sproutyr",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/2f9a95ab-3001-4470-a2c1-e9c50e1f68df/upload-1668278599478.png",
              "access": "public",
              "category": "Card Set",
              "slug": "centaurbor-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/2f1203f3-94eb-4e6a-bf68-8e5578b4d0e9/upload-1668393661775.png",
              "id": "f3053e75-dc35-416a-aa96-ee0f65714a50",
              "videoCount": 26,
              "title": "Centaurbor Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/978cea0e-1e6e-4304-b95b-830e7208d206/upload-1666822212654.JPG",
              "category": "Artist",
              "slug": "william-bilsu-parks",
              "bgImage": "https://cdn.cerealapis.com/author/461307f5-955b-418f-9a08-155807a74d59/upload-1666822212691.JPG",
              "id": "ca4bfd8c-3ead-4ec2-bc37-426c1dca7f8c",
              "videoCount": 12,
              "title": "William Parks"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/413879da-7048-4533-9ef1-8c754d188b72/upload-1671750124743.webp",
          "collections": {},
          "type1": "earth",
          "type2": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/a75cab66-4e58-4b8c-a965-2bf327182c32/upload-1671750123590.webp",
          "attack": "5",
          "series": "f3053e75-dc35-416a-aa96-ee0f65714a50",
          "bgColor": "#B7C03A",
          "rarity": "uncommon",
          "id": "image__55717dca-c6d2-4694-911d-778ab7c4fa40",
          "tags": [],
          "author": "ca4bfd8c-3ead-4ec2-bc37-426c1dca7f8c",
          "quantity": "2",
          "lastUpdated": "2023-07-25T18:09:31.909Z",
          "access": "public",
          "slug": "sproutyr",
          "createdAt": "2022-11-11T14:47:51.277Z",
          "defense": "7",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-02T04:05:00.000Z",
          "subclass1": "dryad",
          "description": "When this Satymber is Empowered by your Artifact and destroys an Elestral in battle you can Nexus the Spirits from that Artifact to this Satymber and Special Ascend it to Centaurbor from your hand or deck.",
          "excerpt": "SD01-02",
          "title": "Satymber",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/2f9a95ab-3001-4470-a2c1-e9c50e1f68df/upload-1668278599478.png",
              "access": "public",
              "category": "Card Set",
              "slug": "centaurbor-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/2f1203f3-94eb-4e6a-bf68-8e5578b4d0e9/upload-1668393661775.png",
              "id": "f3053e75-dc35-416a-aa96-ee0f65714a50",
              "videoCount": 26,
              "title": "Centaurbor Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/978cea0e-1e6e-4304-b95b-830e7208d206/upload-1666822212654.JPG",
              "category": "Artist",
              "slug": "william-bilsu-parks",
              "bgImage": "https://cdn.cerealapis.com/author/461307f5-955b-418f-9a08-155807a74d59/upload-1666822212691.JPG",
              "id": "ca4bfd8c-3ead-4ec2-bc37-426c1dca7f8c",
              "videoCount": 12,
              "title": "William Parks"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/36ba25c4-b4b5-4879-a6dc-da67a8a63371/upload-1671750149277.webp",
          "collections": {},
          "type1": "earth",
          "type2": "earth",
          "type3": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/e2c05d01-0140-4e25-9f27-ebc9d822aee1/upload-1682806109961.webp",
          "attack": "11",
          "series": "f3053e75-dc35-416a-aa96-ee0f65714a50",
          "bgColor": "#B7C03A",
          "rarity": "holo-rare",
          "id": "image__6e83f7ab-fb5c-4842-98b4-1c487ce1ee28",
          "tags": [],
          "author": "ca4bfd8c-3ead-4ec2-bc37-426c1dca7f8c",
          "quantity": "1",
          "lastUpdated": "2023-07-25T18:10:07.259Z",
          "access": "public",
          "slug": "centaurbor",
          "createdAt": "2022-11-11T14:49:29.485Z",
          "defense": "15",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-02T04:10:00.000Z",
          "subclass2": "behemoth",
          "subclass1": "dryad",
          "description": "``` embed\nYou can Disenchant <span class=\"elestrals-font\">E</span> from this Centaurbor in order to target and destroy a card. As long as Foloi Forest is Enchanted this Centaurbor cannot be targeted by Invoke Runes.\n```",
          "excerpt": "SD01-03",
          "title": "Centaurbor",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/2f9a95ab-3001-4470-a2c1-e9c50e1f68df/upload-1668278599478.png",
              "access": "public",
              "category": "Card Set",
              "slug": "centaurbor-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/2f1203f3-94eb-4e6a-bf68-8e5578b4d0e9/upload-1668393661775.png",
              "id": "f3053e75-dc35-416a-aa96-ee0f65714a50",
              "videoCount": 26,
              "title": "Centaurbor Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/978cea0e-1e6e-4304-b95b-830e7208d206/upload-1666822212654.JPG",
              "category": "Artist",
              "slug": "william-bilsu-parks",
              "bgImage": "https://cdn.cerealapis.com/author/461307f5-955b-418f-9a08-155807a74d59/upload-1666822212691.JPG",
              "id": "ca4bfd8c-3ead-4ec2-bc37-426c1dca7f8c",
              "videoCount": 12,
              "title": "William Parks"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/3661ad4d-c77b-4988-bb4a-33e6e50813bf/upload-1671750239881.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/acb544a1-f29d-4f41-9f79-4ed8f65229b5/upload-1671750238638.webp",
          "attack": "1",
          "series": "f3053e75-dc35-416a-aa96-ee0f65714a50",
          "bgColor": "#B7C03A",
          "rarity": "uncommon",
          "id": "image__85ae1f21-b906-4bec-8428-5e2db13e4bae",
          "tags": [],
          "author": "19eb7b3b-3b39-464e-9a88-427e8f289558",
          "quantity": "2",
          "lastUpdated": "2023-07-25T18:29:21.778Z",
          "access": "public",
          "slug": "girafflora",
          "createdAt": "2022-11-11T14:56:53.530Z",
          "defense": "4",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-02T04:25:00.000Z",
          "subclass1": "dryad",
          "description": "``` embed\nWhen you Normal Cast this Girafflora you can return an Elestral with Enchantment Cost <span class=\"elestrals-font\">E</span> from your Underworld to your hand.\n```",
          "excerpt": "SD01-06",
          "title": "Girafflora",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/2f9a95ab-3001-4470-a2c1-e9c50e1f68df/upload-1668278599478.png",
              "access": "public",
              "category": "Card Set",
              "slug": "centaurbor-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/2f1203f3-94eb-4e6a-bf68-8e5578b4d0e9/upload-1668393661775.png",
              "id": "f3053e75-dc35-416a-aa96-ee0f65714a50",
              "videoCount": 26,
              "title": "Centaurbor Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/29f7dfd3-2003-4e21-932b-31d442bb668c/upload-1666966185555.png",
              "category": "Artist",
              "slug": "elite4",
              "id": "19eb7b3b-3b39-464e-9a88-427e8f289558",
              "videoCount": 42,
              "title": "Élite4"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/681259ec-01f1-43a3-aa47-08af8e1f467d/upload-1671751044003.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/f6169f09-b3f8-4f7b-a438-21fd5c653574/upload-1682806490913.webp",
          "attack": "3",
          "series": "f3053e75-dc35-416a-aa96-ee0f65714a50",
          "bgColor": "#B7C03A",
          "rarity": "stellar-rare",
          "id": "image__e0ace1ab-e9e9-498d-a16a-d3432e78bce8",
          "tags": [],
          "author": "5717b454-98e6-4b9c-bad3-fc1bfefa4d26",
          "lastUpdated": "2023-07-25T18:21:28.441Z",
          "access": "public",
          "slug": "sproutyr",
          "createdAt": "2022-11-15T12:16:29.304Z",
          "defense": "5",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-02T04:55:00.000Z",
          "subclass1": "dryad",
          "description": "When you Normal Cast this Sproutyr you can Search your deck for an Artifact and add it to your hand.",
          "excerpt": "XX/99",
          "title": "Sproutyr",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/2f9a95ab-3001-4470-a2c1-e9c50e1f68df/upload-1668278599478.png",
              "access": "public",
              "category": "Card Set",
              "slug": "centaurbor-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/2f1203f3-94eb-4e6a-bf68-8e5578b4d0e9/upload-1668393661775.png",
              "id": "f3053e75-dc35-416a-aa96-ee0f65714a50",
              "videoCount": 26,
              "title": "Centaurbor Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/e0fa7325-1c5d-450c-a14b-e200e191f99b/upload-1666822059355.png",
              "category": "Artist",
              "slug": "francis-adam-adam-fegarido",
              "bgImage": "https://cdn.cerealapis.com/author/adfeb389-b9f3-44c4-8cad-6375cd20b30f/upload-1666822060202.png",
              "id": "5717b454-98e6-4b9c-bad3-fc1bfefa4d26",
              "videoCount": 6,
              "title": "Francis Fegarido "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/89533a12-9bd4-4246-ad4a-2fdbb53e0cc1/upload-1671750695164.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/7b674ac8-90d5-4cb3-833a-431eab233ee1/upload-1671750693972.webp",
          "series": "f3053e75-dc35-416a-aa96-ee0f65714a50",
          "bgColor": "#B7C03A",
          "rarity": "rare",
          "id": "image__ea4600de-def9-4814-bd55-13f66c8d1904",
          "tags": [],
          "author": "15c176d6-97df-497d-aef3-15f737c972a9",
          "quantity": "2",
          "lastUpdated": "2023-07-25T18:17:01.464Z",
          "access": "public",
          "slug": "demeter",
          "createdAt": "2022-11-11T15:13:15.216Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-02T05:15:00.000Z",
          "runeType": "divine",
          "description": "``` embed\nWhen you Cast this Demeter you can Enchant up to <span class=\"elestrals-font\">E</span><span class=\"elestrals-font\">E</span><span class=\"elestrals-font\">E</span> to it. You can Disenchant <span class=\"elestrals-font\">E</span> from this Demeter in order to give +3 <span class=\"elestrals-font\">J</span> and +3 <span class=\"elestrals-font\">O</span> to an Elestral until the End Phase.\n```",
          "excerpt": "SD01-16",
          "title": "Demeter",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/2f9a95ab-3001-4470-a2c1-e9c50e1f68df/upload-1668278599478.png",
              "access": "public",
              "category": "Card Set",
              "slug": "centaurbor-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/2f1203f3-94eb-4e6a-bf68-8e5578b4d0e9/upload-1668393661775.png",
              "id": "f3053e75-dc35-416a-aa96-ee0f65714a50",
              "videoCount": 26,
              "title": "Centaurbor Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b516689-1021-4652-b56a-bfbef2e05441/upload-1666822702354.png",
              "category": "Artist",
              "slug": "dennis-artworks218-fuhrmann",
              "bgImage": "https://cdn.cerealapis.com/author/90b70451-0371-4db5-beab-5d46f38e07bd/upload-1666822702346.png",
              "id": "15c176d6-97df-497d-aef3-15f737c972a9",
              "videoCount": 22,
              "title": "Dennis Fuhrmann"
          }
      },
      {
          "quantity": "2",
          "lastUpdated": "2023-07-25T18:18:26.028Z",
          "file": "https://cdn.cerealapis.com/post/2da065cc-7907-4633-8fc2-b1df2f2f01f3/upload-1671750896940.webp",
          "collections": {},
          "type1": "rainbow",
          "access": "public",
          "slug": "poison-tipped-arrow",
          "createdAt": "2022-11-11T15:25:33.411Z",
          "bgImage": "https://cdn.cerealapis.com/post/f6c21c80-264f-416e-9439-cb5d3681f7ae/upload-1671750895859.webp",
          "postStatus": "published",
          "series": "f3053e75-dc35-416a-aa96-ee0f65714a50",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-02T05:45:00.000Z",
          "rarity": "rare",
          "runeType": "counter",
          "description": "``` embed\nThis Poison Tipped Arrow can only be Cast when an opponent Casts an Elestral. Target it and give it -2 <span class=\"elestrals-font\">J</span> until the End Phase. During this turn's End Phase you can Expend <span class=\"elestrals-font\">X</span> equal to its Enchantment Cost in order to destroy it.\n```",
          "id": "image__759b9bd0-ee57-4c1c-a0f3-47fe0883fca9",
          "excerpt": "SD01-22",
          "tags": [],
          "author": "7a5cbe37-98d0-46e5-8f84-93398fa88214",
          "title": "Poison Tipped Arrow",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/2f9a95ab-3001-4470-a2c1-e9c50e1f68df/upload-1668278599478.png",
              "access": "public",
              "category": "Card Set",
              "slug": "centaurbor-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/2f1203f3-94eb-4e6a-bf68-8e5578b4d0e9/upload-1668393661775.png",
              "id": "f3053e75-dc35-416a-aa96-ee0f65714a50",
              "videoCount": 26,
              "title": "Centaurbor Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/d54f297d-ac0b-4595-acfb-1d5bb448c7ad/upload-1666824216088.jpg",
              "category": "Artist",
              "slug": "villiam-boom",
              "bgImage": "https://cdn.cerealapis.com/author/3bd2fb84-40fe-4db4-b7ad-7ace9d264ce8/upload-1666824216015.jpg",
              "id": "7a5cbe37-98d0-46e5-8f84-93398fa88214",
              "videoCount": 10,
              "title": "Villiam Boom "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/37e09aea-910a-4f2f-87b6-0973f5a39cf1/upload-1671751245447.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/dfe6831c-459b-48de-a887-6e29871306a1/upload-1671751244346.webp",
          "attack": "1",
          "series": "795e2c41-5df5-4d6b-985d-135fdb2c03b5",
          "bgColor": "#C63E2C",
          "rarity": "common",
          "id": "image__b59db1be-e57a-471c-be6b-351451149161",
          "tags": [],
          "author": "06f6337e-75f8-4837-a14b-bb73e880382e",
          "quantity": "2",
          "lastUpdated": "2023-07-25T18:30:37.342Z",
          "access": "public",
          "slug": "hummbust",
          "createdAt": "2022-11-11T16:30:30.585Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-03T04:25:00.000Z",
          "subclass1": "insectoid",
          "description": "When a player Casts a Rune an opponent must send the top 2 cards from their deck to their Underworld. This Hummbust's effect can be activated up to 4 times in a turn.",
          "excerpt": "SD02-06",
          "title": "Hummbust",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/1fa4ef0c-c53e-4750-b3e7-90ec3fdc1143/upload-1668278611817.png",
              "access": "public",
              "category": "Card Set",
              "slug": "trifernal-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/554bc5fc-3ba7-41d6-8fa7-52d058d6c029/upload-1668393741140.png",
              "id": "795e2c41-5df5-4d6b-985d-135fdb2c03b5",
              "videoCount": 26,
              "title": "Trifernal Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/696727cb-6e52-4f00-b2b5-b5ee9b967d3e/upload-1666823423451.png",
              "category": "Artist",
              "slug": "giovanni-n4nin4ni-aguilar",
              "bgImage": "https://cdn.cerealapis.com/author/49850f39-8072-4bd7-8776-15508c33f3d7/upload-1666823423402.png",
              "id": "06f6337e-75f8-4837-a14b-bb73e880382e",
              "videoCount": 53,
              "title": "Giovanni Aguilar"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/4a9ae0bb-2116-4013-a46a-68b0970bee6d/upload-1671751974785.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/2b4cf37b-675a-4d6a-89ad-85eb28ccb05b/upload-1682806380511.webp",
          "attack": "5",
          "series": "795e2c41-5df5-4d6b-985d-135fdb2c03b5",
          "bgColor": "#C63E2C",
          "rarity": "stellar-rare",
          "id": "image__5bc2f209-9b35-48ce-812e-e30579c79b5e",
          "tags": [],
          "author": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
          "lastUpdated": "2023-07-25T18:33:00.764Z",
          "access": "public",
          "slug": "necruff",
          "createdAt": "2022-11-15T09:20:14.214Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-03T05:00:00.000Z",
          "subclass2": "brute",
          "subclass1": "eldritch",
          "description": "``` embed\nWhen this Necruff destroys an opponent’s Elestral in battle that opponent must Expend <span class=\"elestrals-font\">X</span>.\n```",
          "excerpt": "XX/99",
          "title": "Necruff",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/1fa4ef0c-c53e-4750-b3e7-90ec3fdc1143/upload-1668278611817.png",
              "access": "public",
              "category": "Card Set",
              "slug": "trifernal-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/554bc5fc-3ba7-41d6-8fa7-52d058d6c029/upload-1668393741140.png",
              "id": "795e2c41-5df5-4d6b-985d-135fdb2c03b5",
              "videoCount": 26,
              "title": "Trifernal Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/9abbfa57-fadc-42d9-bd52-3907b145d6ff/upload-1662720081319.jpeg",
              "category": "Artist",
              "slug": "jonny-de-oliviera",
              "bgImage": "https://cdn.cerealapis.com/author/d6271a98-e34d-4b1a-843c-45b03f86abb2/upload-1662720081310.jpeg",
              "id": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
              "videoCount": 22,
              "title": "Jonny De Oliviera"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/3011e131-889b-41b2-867b-33d850375751/upload-1671751605980.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/56b6a093-4f60-46dd-891c-f3518ea6927a/upload-1671751604882.webp",
          "series": "795e2c41-5df5-4d6b-985d-135fdb2c03b5",
          "bgColor": "#C63E2C",
          "rarity": "rare",
          "id": "image__0b299c60-89bf-48f9-93f6-a8b59839abbd",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "quantity": "2",
          "lastUpdated": "2023-07-25T18:26:37.177Z",
          "access": "public",
          "slug": "hephaestus",
          "createdAt": "2022-11-11T16:51:52.422Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-03T05:15:00.000Z",
          "runeType": "divine",
          "description": "``` embed\nWhen you Cast this Hephaestus you can Enchant up to <span class=\"elestrals-font\">F</span><span class=\"elestrals-font\">F</span><span class=\"elestrals-font\">F</span> to it. You can Disenchant <span class=\"elestrals-font\">F</span> from this Hephaestus in order to Search your deck for an Artifact and add it to your hand.\n```",
          "excerpt": "SD02-16",
          "title": "Hephaestus",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/1fa4ef0c-c53e-4750-b3e7-90ec3fdc1143/upload-1668278611817.png",
              "access": "public",
              "category": "Card Set",
              "slug": "trifernal-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/554bc5fc-3ba7-41d6-8fa7-52d058d6c029/upload-1668393741140.png",
              "id": "795e2c41-5df5-4d6b-985d-135fdb2c03b5",
              "videoCount": 26,
              "title": "Trifernal Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/acf5e218-d033-44bc-943d-a4ffc0bbc8c3/upload-1671751722977.webp",
          "collections": {},
          "type1": "rainbow",
          "type2": "rainbow",
          "bgImage": "https://cdn.cerealapis.com/post/55252fee-7b18-4aa6-80d5-a99bc14e6de2/upload-1671751721465.webp",
          "series": "795e2c41-5df5-4d6b-985d-135fdb2c03b5",
          "rarity": "common",
          "id": "image__e89139cb-5a59-4152-b828-d0cb28290bc7",
          "tags": [],
          "author": "962db316-931a-4cb5-bdd1-9330d8486272",
          "quantity": "2",
          "lastUpdated": "2023-07-25T18:27:48.250Z",
          "access": "public",
          "slug": "gorgons-gaze",
          "createdAt": "2022-11-11T17:02:11.638Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-03T05:40:00.000Z",
          "runeType": "counter",
          "description": "Target an Elestral. Until the End Phase that Elestral’s effect is negated and it cannot receive Enchantments, attack, Nexus, or Ascend.",
          "excerpt": "SD02-21",
          "title": "Gorgon's Gaze",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/1fa4ef0c-c53e-4750-b3e7-90ec3fdc1143/upload-1668278611817.png",
              "access": "public",
              "category": "Card Set",
              "slug": "trifernal-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/554bc5fc-3ba7-41d6-8fa7-52d058d6c029/upload-1668393741140.png",
              "id": "795e2c41-5df5-4d6b-985d-135fdb2c03b5",
              "videoCount": 26,
              "title": "Trifernal Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b314a3d-73f8-4458-b16c-b821c4f915f3/upload-1666823695029.png",
              "category": "Artist",
              "slug": "lucas-kosta-costa",
              "bgImage": "https://cdn.cerealapis.com/author/3ebe38de-02f2-496d-a11f-337103ee7347/upload-1666823695026.png",
              "id": "962db316-931a-4cb5-bdd1-9330d8486272",
              "videoCount": 20,
              "title": "Lucas Costa"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/0b4ebdcc-95bf-4d1d-83b3-5a91add13ebc/upload-1671752192120.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/118f7c5b-7055-4c0f-ae1a-e97c35ac7ac1/upload-1671752191019.webp",
          "attack": "1",
          "series": "4fbddd21-3d70-4fa1-b87e-7eae8d206850",
          "bgColor": "#6997C4",
          "rarity": "common",
          "id": "image__60ecf368-a5e6-4c7a-a7be-0a6377847559",
          "tags": [],
          "author": "75489aa3-accc-4053-ab04-30a1d49d13bb",
          "quantity": "2",
          "lastUpdated": "2022-12-22T23:55:44.121Z",
          "access": "public",
          "slug": "oystress",
          "createdAt": "2022-11-11T15:49:24.907Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-04T04:35:00.000Z",
          "subclass1": "oceanic",
          "description": "``` embed\nThis Oystress gets +1 <span class=\"elestrals-font\">O</span> for each Enchanting <span class=\"elestrals-font\">W</span> on the field.\n```",
          "excerpt": "SD03-08",
          "title": "Oystress",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7ce742ba-237c-493c-b22a-43410985a64e/upload-1668278622677.png",
              "access": "public",
              "category": "Card Set",
              "slug": "majesea-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/0b02827b-42e3-4500-b7dd-ac86b3923e73/upload-1668393802538.png",
              "id": "4fbddd21-3d70-4fa1-b87e-7eae8d206850",
              "videoCount": 26,
              "title": "Majesea Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/292635bf-0ef8-46f5-8583-dad865383c11/upload-1666719837086.png",
              "category": "Artist",
              "slug": "amalry",
              "bgImage": "https://cdn.cerealapis.com/author/080d7ae5-d84a-4885-a0d2-3443fa849520/upload-1666719837080.png",
              "id": "75489aa3-accc-4053-ab04-30a1d49d13bb",
              "videoCount": 18,
              "title": "Amalry"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/b50e8052-ef0f-442e-9989-2e43e28a8964/upload-1671753550688.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/019ec81f-25cd-4f13-a3c3-7d7f7d4a02b1/upload-1682806456860.webp",
          "attack": "2",
          "series": "4fbddd21-3d70-4fa1-b87e-7eae8d206850",
          "bgColor": "#6997C4",
          "rarity": "stellar-rare",
          "id": "image__f8756816-be14-48aa-bbb9-14d93a49abd0",
          "tags": [],
          "author": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
          "lastUpdated": "2023-07-25T18:36:59.730Z",
          "access": "public",
          "slug": "sluggle",
          "createdAt": "2022-11-15T09:24:46.122Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-04T05:05:00.000Z",
          "subclass1": "oceanic",
          "description": "``` embed\nWhen you Cast this Sluggle you can return up to <span class=\"elestrals-font\">W</span><span class=\"elestrals-font\">W</span><span class=\"elestrals-font\">W</span> from your Underworld to your Spirit Deck.\n```",
          "excerpt": "XX/99",
          "title": "Sluggle",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7ce742ba-237c-493c-b22a-43410985a64e/upload-1668278622677.png",
              "access": "public",
              "category": "Card Set",
              "slug": "majesea-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/0b02827b-42e3-4500-b7dd-ac86b3923e73/upload-1668393802538.png",
              "id": "4fbddd21-3d70-4fa1-b87e-7eae8d206850",
              "videoCount": 26,
              "title": "Majesea Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4c88d483-9e81-4854-942f-97df6f95299e/upload-1666824047475.png",
              "category": "Artist",
              "slug": "phoebe-the-last-shaymin-sumner-twisk",
              "bgImage": "https://cdn.cerealapis.com/author/a0c334f7-fbb6-426d-88ca-2915f35213e4/upload-1666824047501.png",
              "id": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
              "videoCount": 33,
              "title": "Phoebe Sumner-Twisk"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/e2993de5-29d1-4261-9eb8-e6d215331ae2/upload-1671752430284.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/84851c38-d998-43ca-9d4f-e3dcc37d5a6b/upload-1671752429167.webp",
          "series": "4fbddd21-3d70-4fa1-b87e-7eae8d206850",
          "bgColor": "#6997C4",
          "rarity": "rare",
          "id": "image__9e0c7197-2929-4d71-94e4-0fbaecbfc45f",
          "tags": [],
          "author": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
          "quantity": "2",
          "lastUpdated": "2022-12-22T23:56:58.286Z",
          "access": "public",
          "slug": "poseidon",
          "createdAt": "2022-11-11T16:02:32.047Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-04T17:15:00.000Z",
          "runeType": "divine",
          "description": "``` embed\nWhen you Cast this Poseidon you can Enchant up to <span class=\"elestrals-font\">W</span><span class=\"elestrals-font\">W</span><span class=\"elestrals-font\">W</span> to it. You can Nexus <span class=\"elestrals-font\">W</span> from this Poseidon.\n```",
          "excerpt": "SD03-16",
          "title": "Poseidon",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7ce742ba-237c-493c-b22a-43410985a64e/upload-1668278622677.png",
              "access": "public",
              "category": "Card Set",
              "slug": "majesea-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/0b02827b-42e3-4500-b7dd-ac86b3923e73/upload-1668393802538.png",
              "id": "4fbddd21-3d70-4fa1-b87e-7eae8d206850",
              "videoCount": 26,
              "title": "Majesea Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4c88d483-9e81-4854-942f-97df6f95299e/upload-1666824047475.png",
              "category": "Artist",
              "slug": "phoebe-the-last-shaymin-sumner-twisk",
              "bgImage": "https://cdn.cerealapis.com/author/a0c334f7-fbb6-426d-88ca-2915f35213e4/upload-1666824047501.png",
              "id": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
              "videoCount": 33,
              "title": "Phoebe Sumner-Twisk"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/d846424a-ba4b-4942-a620-6e616590bc57/upload-1671753647079.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/bde700c2-c4d8-44d8-b021-f027d5728eef/upload-1671753646037.webp",
          "attack": "4",
          "series": "17b980b0-ed10-46f0-93ed-d73581c07487",
          "bgColor": "#E3AB21",
          "rarity": "common",
          "id": "image__f3278be8-6cca-457f-90a1-be8b2e1070b7",
          "tags": [],
          "author": "3f76add4-01c2-46e4-9e95-e7dd024030ec",
          "quantity": "2",
          "lastUpdated": "2023-07-25T18:37:43.800Z",
          "access": "public",
          "slug": "toxion",
          "createdAt": "2022-11-11T17:18:13.470Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-05T04:15:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "insectoid",
          "description": "``` embed\nWhen this Toxion receives 1 or more <span class=\"elestrals-font\">T</span> your opponents must Expend <span class=\"elestrals-font\">X</span>.\n```",
          "excerpt": "SD04-04",
          "title": "Toxion",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/6ebfa067-9271-4bf7-8941-95e1dbfed92c/upload-1668278963149.png",
              "access": "public",
              "category": "Card Set",
              "slug": "ohmperial-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/d942d4f9-fbf8-4742-9fa4-385fade7d3f7/upload-1668393877355.png",
              "id": "17b980b0-ed10-46f0-93ed-d73581c07487",
              "videoCount": 26,
              "title": "Ohmperial Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/1d07656d-9d0b-49e1-89d7-d3b871923872/upload-1666823795124.png",
              "category": "Artist",
              "slug": "luis-luisbrain-gonzalez",
              "bgImage": "https://cdn.cerealapis.com/author/f793b36f-a52f-4f33-9c99-c0cd8750cec5/upload-1666823794879.png",
              "id": "3f76add4-01c2-46e4-9e95-e7dd024030ec",
              "videoCount": 3,
              "title": "Luis González"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/a14d2cb3-1eeb-4c09-b624-01da74a07389/upload-1671753670993.webp",
          "collections": {},
          "type1": "thunder",
          "type2": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/4eed90c2-efe7-46c1-885d-88cb34769acd/upload-1671753669794.webp",
          "attack": "6",
          "series": "17b980b0-ed10-46f0-93ed-d73581c07487",
          "bgColor": "#E3AB21",
          "rarity": "uncommon",
          "id": "image__a4634282-3c53-4f71-be75-f7fd948ce2fb",
          "tags": [],
          "author": "3f76add4-01c2-46e4-9e95-e7dd024030ec",
          "quantity": "2",
          "lastUpdated": "2023-07-25T18:37:53.546Z",
          "access": "public",
          "slug": "galvenom",
          "createdAt": "2022-11-11T17:18:28.508Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-05T04:20:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "insectoid",
          "description": "``` embed\nYou can expend <span class=\"elestrals-font\">T</span><span class=\"elestrals-font\">T</span><span class=\"elestrals-font\">T</span> in order to target and destroy 2 cards on the field.\n```",
          "excerpt": "SD04-05",
          "title": "Galvenom",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/6ebfa067-9271-4bf7-8941-95e1dbfed92c/upload-1668278963149.png",
              "access": "public",
              "category": "Card Set",
              "slug": "ohmperial-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/d942d4f9-fbf8-4742-9fa4-385fade7d3f7/upload-1668393877355.png",
              "id": "17b980b0-ed10-46f0-93ed-d73581c07487",
              "videoCount": 26,
              "title": "Ohmperial Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/1d07656d-9d0b-49e1-89d7-d3b871923872/upload-1666823795124.png",
              "category": "Artist",
              "slug": "luis-luisbrain-gonzalez",
              "bgImage": "https://cdn.cerealapis.com/author/f793b36f-a52f-4f33-9c99-c0cd8750cec5/upload-1666823794879.png",
              "id": "3f76add4-01c2-46e4-9e95-e7dd024030ec",
              "videoCount": 3,
              "title": "Luis González"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/b6484806-c2cd-449e-a766-9b1e9903b849/upload-1671753810815.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/f58dd22f-abf8-4e68-be8b-e37d4ead77b0/upload-1671753809347.webp",
          "attack": "2",
          "series": "17b980b0-ed10-46f0-93ed-d73581c07487",
          "bgColor": "#E3AB21",
          "rarity": "uncommon",
          "id": "image__e8007d2a-06e1-4dd8-950b-18c25374a45b",
          "tags": [],
          "author": "8194695f-8d92-491e-b87b-c977a16a317e",
          "quantity": "2",
          "lastUpdated": "2023-07-25T18:39:30.897Z",
          "access": "public",
          "slug": "boombatt",
          "createdAt": "2022-11-11T17:32:42.164Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-05T04:45:00.000Z",
          "subclass1": "eldritch",
          "description": "``` embed\nYou can Disenchant <span class=\"elestrals-font\">T</span> from this Boombatt in order to target and destroy an opponent’s Elestral.\n```",
          "excerpt": "SD04-10",
          "title": "Boombatt",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/6ebfa067-9271-4bf7-8941-95e1dbfed92c/upload-1668278963149.png",
              "access": "public",
              "category": "Card Set",
              "slug": "ohmperial-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/d942d4f9-fbf8-4742-9fa4-385fade7d3f7/upload-1668393877355.png",
              "id": "17b980b0-ed10-46f0-93ed-d73581c07487",
              "videoCount": 26,
              "title": "Ohmperial Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/a7719a4e-ee26-4bf7-8839-2887320a2a0a/upload-1666823882654.png",
              "category": "Artist",
              "slug": "luan-m-resende",
              "bgImage": "https://cdn.cerealapis.com/author/7d33a277-e7a3-423f-8661-3fdc94b69e7d/upload-1666823882721.png",
              "id": "8194695f-8d92-491e-b87b-c977a16a317e",
              "videoCount": 20,
              "title": "Luan M. Resende"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/5cbf25d0-edc3-454b-80ce-7bfd50fd75eb/upload-1671754318490.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/a2bd27ec-63ed-4777-a445-b0f01a46854f/upload-1682806439371.webp",
          "attack": "2",
          "series": "17b980b0-ed10-46f0-93ed-d73581c07487",
          "bgColor": "#E3AB21",
          "rarity": "stellar-rare",
          "id": "image__22b0d263-fc5e-4e2b-afde-72bf56e7d3f4",
          "tags": [],
          "author": "8194695f-8d92-491e-b87b-c977a16a317e",
          "lastUpdated": "2023-07-25T18:43:23.766Z",
          "access": "public",
          "slug": "quackle",
          "createdAt": "2022-11-15T12:13:57.616Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-05T05:10:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "avian",
          "description": "``` embed\nWhen you Cast this Quackle you can return up to <span class=\"elestrals-font\">T</span><span class=\"elestrals-font\">T</span><span class=\"elestrals-font\">T</span> from your Underworld to your Spirit Deck.\n```",
          "excerpt": "XX/99",
          "title": "Quackle",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/6ebfa067-9271-4bf7-8941-95e1dbfed92c/upload-1668278963149.png",
              "access": "public",
              "category": "Card Set",
              "slug": "ohmperial-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/d942d4f9-fbf8-4742-9fa4-385fade7d3f7/upload-1668393877355.png",
              "id": "17b980b0-ed10-46f0-93ed-d73581c07487",
              "videoCount": 26,
              "title": "Ohmperial Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/a7719a4e-ee26-4bf7-8839-2887320a2a0a/upload-1666823882654.png",
              "category": "Artist",
              "slug": "luan-m-resende",
              "bgImage": "https://cdn.cerealapis.com/author/7d33a277-e7a3-423f-8661-3fdc94b69e7d/upload-1666823882721.png",
              "id": "8194695f-8d92-491e-b87b-c977a16a317e",
              "videoCount": 20,
              "title": "Luan M. Resende"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/c185eab0-5241-4bf9-ba85-e20c439c3ecf/upload-1671753982417.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/fa297bfc-baaa-4af8-8374-868fcc5781c1/upload-1671753981342.webp",
          "series": "17b980b0-ed10-46f0-93ed-d73581c07487",
          "bgColor": "#E3AB21",
          "rarity": "rare",
          "id": "image__04b2ced8-95f6-4eb3-bdd3-88c21ab65012",
          "tags": [],
          "author": "36eb909a-5835-4f59-b317-e02082ee59db",
          "quantity": "2",
          "lastUpdated": "2023-07-25T18:41:27.902Z",
          "access": "public",
          "slug": "zeus",
          "createdAt": "2022-11-11T17:42:34.485Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-05T05:15:00.000Z",
          "runeType": "divine",
          "description": "``` embed\nWhen you Cast this Zeus you can Enchant up to <span class=\"elestrals-font\">T</span><span class=\"elestrals-font\">T</span><span class=\"elestrals-font\">T</span> to it. You can Disenchant <span class=\"elestrals-font\">T</span> from this Zeus in order to target an opponent’s Elestral and give it -3 <span class=\"elestrals-font\">J</span> and -3 <span class=\"elestrals-font\">O</span> until the End Phase.\n```",
          "excerpt": "SD04-16",
          "title": "Zeus",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/6ebfa067-9271-4bf7-8941-95e1dbfed92c/upload-1668278963149.png",
              "access": "public",
              "category": "Card Set",
              "slug": "ohmperial-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/d942d4f9-fbf8-4742-9fa4-385fade7d3f7/upload-1668393877355.png",
              "id": "17b980b0-ed10-46f0-93ed-d73581c07487",
              "videoCount": 26,
              "title": "Ohmperial Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/155a9d71-dff3-4efc-965b-766a55dc62b3/upload-1666824091421.jpg",
              "category": "Artist",
              "slug": "tori",
              "bgImage": "https://cdn.cerealapis.com/author/25fe30f4-7c6c-435a-83e4-8e88e6258fc7/upload-1666824091368.jpg",
              "id": "36eb909a-5835-4f59-b317-e02082ee59db",
              "videoCount": 4,
              "title": "Tori "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/bd348f41-9958-4a05-9249-21fb841695eb/upload-1671754358430.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/d4913858-b793-4518-9a55-58ff210c5643/upload-1671754357410.webp",
          "attack": "4",
          "series": "4b9ced0f-2428-4448-a51a-cb6fedd53c57",
          "bgColor": "#ACBCC1",
          "rarity": "common",
          "id": "image__42f45aad-ef0d-42f6-99a5-b495964963bf",
          "tags": [],
          "author": "7a5cbe37-98d0-46e5-8f84-93398fa88214",
          "quantity": "3",
          "lastUpdated": "2023-07-25T18:43:50.334Z",
          "access": "public",
          "slug": "hydrake",
          "createdAt": "2022-11-11T13:21:20.827Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-06T04:00:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "dragon",
          "description": "When this Hydrake is destroyed in battle you can Special Cast a Twindra from your hand or deck in Attack Position.",
          "excerpt": "SD05-01",
          "title": "Hydrake",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/965d6cf1-316a-4e7f-94f7-164afaa5f5ec/upload-1668278710644.png",
              "access": "public",
              "category": "Card Set",
              "slug": "penterror-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/720ab115-dad8-4144-95f0-9794874b450b/upload-1668393855644.png",
              "id": "4b9ced0f-2428-4448-a51a-cb6fedd53c57",
              "videoCount": 26,
              "title": "Penterror Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/d54f297d-ac0b-4595-acfb-1d5bb448c7ad/upload-1666824216088.jpg",
              "category": "Artist",
              "slug": "villiam-boom",
              "bgImage": "https://cdn.cerealapis.com/author/3bd2fb84-40fe-4db4-b7ad-7ace9d264ce8/upload-1666824216015.jpg",
              "id": "7a5cbe37-98d0-46e5-8f84-93398fa88214",
              "videoCount": 10,
              "title": "Villiam Boom "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/266e1bff-395b-4929-9a56-38676abb1f58/upload-1671754382823.webp",
          "collections": {},
          "type1": "wind",
          "type2": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/954d1d59-2325-4102-9073-52ad5f387d74/upload-1671754381824.webp",
          "attack": "5",
          "series": "4b9ced0f-2428-4448-a51a-cb6fedd53c57",
          "bgColor": "#ACBCC1",
          "rarity": "uncommon",
          "id": "image__dfa887ce-430b-475e-8d0f-a43550d32aa1",
          "tags": [],
          "author": "7a5cbe37-98d0-46e5-8f84-93398fa88214",
          "quantity": "2",
          "lastUpdated": "2023-07-25T18:44:34.124Z",
          "access": "public",
          "slug": "twindra",
          "createdAt": "2022-11-11T13:25:10.309Z",
          "defense": "4",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-06T04:05:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "dragon",
          "description": "When this Twindra is destroyed in battle you can Special Cast a Penterror from your hand or deck.",
          "excerpt": "SD05-02",
          "title": "Twindra",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/965d6cf1-316a-4e7f-94f7-164afaa5f5ec/upload-1668278710644.png",
              "access": "public",
              "category": "Card Set",
              "slug": "penterror-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/720ab115-dad8-4144-95f0-9794874b450b/upload-1668393855644.png",
              "id": "4b9ced0f-2428-4448-a51a-cb6fedd53c57",
              "videoCount": 26,
              "title": "Penterror Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/d54f297d-ac0b-4595-acfb-1d5bb448c7ad/upload-1666824216088.jpg",
              "category": "Artist",
              "slug": "villiam-boom",
              "bgImage": "https://cdn.cerealapis.com/author/3bd2fb84-40fe-4db4-b7ad-7ace9d264ce8/upload-1666824216015.jpg",
              "id": "7a5cbe37-98d0-46e5-8f84-93398fa88214",
              "videoCount": 10,
              "title": "Villiam Boom "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/40c55989-7280-457b-b763-fd938e474166/upload-1671754410250.webp",
          "collections": {},
          "type1": "wind",
          "type2": "wind",
          "type3": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/d449a3d5-cb98-4732-adde-d340c53810e6/upload-1682806159784.webp",
          "attack": "15",
          "series": "4b9ced0f-2428-4448-a51a-cb6fedd53c57",
          "bgColor": "#ACBCC1",
          "rarity": "holo-rare",
          "id": "image__1a9b199d-1b6e-4210-a62f-88e78b8c7449",
          "featured": false,
          "tags": [],
          "author": "7a5cbe37-98d0-46e5-8f84-93398fa88214",
          "quantity": "1",
          "lastUpdated": "2023-07-25T18:44:46.231Z",
          "access": "public",
          "slug": "penterror",
          "createdAt": "2022-11-11T13:32:21.705Z",
          "defense": "8",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-06T04:10:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "dragon",
          "description": "When this Penterror is destroyed by an opponent’s card effect you can Special Cast any number of Hydrake and Twindra from your hand or deck in Attack Position.",
          "excerpt": "SD05-03",
          "title": "Penterror",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/965d6cf1-316a-4e7f-94f7-164afaa5f5ec/upload-1668278710644.png",
              "access": "public",
              "category": "Card Set",
              "slug": "penterror-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/720ab115-dad8-4144-95f0-9794874b450b/upload-1668393855644.png",
              "id": "4b9ced0f-2428-4448-a51a-cb6fedd53c57",
              "videoCount": 26,
              "title": "Penterror Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/d54f297d-ac0b-4595-acfb-1d5bb448c7ad/upload-1666824216088.jpg",
              "category": "Artist",
              "slug": "villiam-boom",
              "bgImage": "https://cdn.cerealapis.com/author/3bd2fb84-40fe-4db4-b7ad-7ace9d264ce8/upload-1666824216015.jpg",
              "id": "7a5cbe37-98d0-46e5-8f84-93398fa88214",
              "videoCount": 10,
              "title": "Villiam Boom "
          }
      },
      {
          "quantity": "2",
          "lastUpdated": "2023-07-25T18:51:12.865Z",
          "file": "https://cdn.cerealapis.com/post/ab118fa5-5552-4c12-8612-9e57ab7790b0/upload-1671754809105.webp",
          "collections": {},
          "type1": "rainbow",
          "access": "public",
          "slug": "resting-on-your",
          "createdAt": "2022-11-11T14:21:07.004Z",
          "bgImage": "https://cdn.cerealapis.com/post/677fcc39-f8aa-4788-b967-8d3f9fef53c1/upload-1671754808002.webp",
          "postStatus": "published",
          "series": "4b9ced0f-2428-4448-a51a-cb6fedd53c57",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-06T05:10:00.000Z",
          "rarity": "rare",
          "runeType": "invoke",
          "description": "This Resting on Your Laurels can only be Cast if an opponent has at least two more Enchanting Spirits than you. Target and destroy that opponent's Elestral with the least number of Enchanting Spirits.",
          "id": "image__be195123-08c1-4095-bc0e-08f3b1edfa43",
          "excerpt": "SD05-15",
          "tags": [],
          "author": "fa0d25f0-ce33-48b8-b92f-866d65351ef0",
          "title": "Resting on Your Laurels",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/965d6cf1-316a-4e7f-94f7-164afaa5f5ec/upload-1668278710644.png",
              "access": "public",
              "category": "Card Set",
              "slug": "penterror-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/720ab115-dad8-4144-95f0-9794874b450b/upload-1668393855644.png",
              "id": "4b9ced0f-2428-4448-a51a-cb6fedd53c57",
              "videoCount": 26,
              "title": "Penterror Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4c693cd9-4073-4f0a-a6d2-f1f17d323ce4/upload-1668319602078.png",
              "category": "Creator",
              "slug": "koen-kluijtmans",
              "id": "fa0d25f0-ce33-48b8-b92f-866d65351ef0",
              "videoCount": 3,
              "title": "Koen Kluijtmans "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/776d1382-338f-4163-962c-602e9ca33109/upload-1671755125521.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/7eca2d91-7668-40e0-a3d5-6d402f93fd9b/upload-1682806251582.webp",
          "attack": "4",
          "series": "4b9ced0f-2428-4448-a51a-cb6fedd53c57",
          "bgColor": "#ACBCC1",
          "rarity": "stellar-rare",
          "id": "image__c195501f-ce2f-4751-a021-956b074076f0",
          "tags": [],
          "author": "5717b454-98e6-4b9c-bad3-fc1bfefa4d26",
          "lastUpdated": "2023-07-25T18:54:06.633Z",
          "access": "public",
          "slug": "hydrake",
          "createdAt": "2022-11-15T12:17:28.346Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-06T05:15:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "dragon",
          "description": "When this Hydrake is destroyed in battle you can Special Cast a Twindra from your hand or deck in Attack Position.",
          "excerpt": "XX/99",
          "title": "Hydrake",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/965d6cf1-316a-4e7f-94f7-164afaa5f5ec/upload-1668278710644.png",
              "access": "public",
              "category": "Card Set",
              "slug": "penterror-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/720ab115-dad8-4144-95f0-9794874b450b/upload-1668393855644.png",
              "id": "4b9ced0f-2428-4448-a51a-cb6fedd53c57",
              "videoCount": 26,
              "title": "Penterror Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/e0fa7325-1c5d-450c-a14b-e200e191f99b/upload-1666822059355.png",
              "category": "Artist",
              "slug": "francis-adam-adam-fegarido",
              "bgImage": "https://cdn.cerealapis.com/author/adfeb389-b9f3-44c4-8cad-6375cd20b30f/upload-1666822060202.png",
              "id": "5717b454-98e6-4b9c-bad3-fc1bfefa4d26",
              "videoCount": 6,
              "title": "Francis Fegarido "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/9c052aa3-4a5a-49ff-8e53-341cde5e9d45/upload-1671754858363.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/5727205d-8b91-496b-b60d-e58ddc50547d/upload-1671754856950.webp",
          "series": "4b9ced0f-2428-4448-a51a-cb6fedd53c57",
          "bgColor": "#ACBCC1",
          "rarity": "rare",
          "id": "image__119bc608-13fe-4843-96ac-f93b13865493",
          "tags": [],
          "author": "3067a712-8791-4a4e-a6dd-cc9e4b263e94",
          "quantity": "2",
          "lastUpdated": "2023-07-25T18:51:37.884Z",
          "access": "public",
          "slug": "aeolus",
          "createdAt": "2022-11-11T14:29:04.659Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-06T05:20:00.000Z",
          "runeType": "divine",
          "description": "``` embed\nWhen you Cast this Aeolus you can Enchant up to <span class=\"elestrals-font\">A</span><span class=\"elestrals-font\">A</span><span class=\"elestrals-font\">A</span><span class=\"elestrals-font\">A</span> to it. You can Disenchant <span class=\"elestrals-font\">A</span><span class=\"elestrals-font\">A</span> from this Aeolus in order to Search your deck for an Elestral with Enchantment Cost <span class=\"elestrals-font\">A</span> and add it to your hand.\n```",
          "excerpt": "SD05-17",
          "title": "Aeolus",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/965d6cf1-316a-4e7f-94f7-164afaa5f5ec/upload-1668278710644.png",
              "access": "public",
              "category": "Card Set",
              "slug": "penterror-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/720ab115-dad8-4144-95f0-9794874b450b/upload-1668393855644.png",
              "id": "4b9ced0f-2428-4448-a51a-cb6fedd53c57",
              "videoCount": 26,
              "title": "Penterror Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4a4adc1b-2836-4e52-86af-50a54227c185/upload-1666824162136.png",
              "category": "Artist",
              "slug": "victor-vcreateart-rosales",
              "bgImage": "https://cdn.cerealapis.com/author/5210982f-5535-48b5-884b-cdfb1d03de56/upload-1666824162133.png",
              "id": "3067a712-8791-4a4e-a6dd-cc9e4b263e94",
              "videoCount": 10,
              "title": "Victor Rosales"
          }
      },
  
      {
          "file": "https://cdn.cerealapis.com/post/8660f797-9cd6-4fff-b53b-5140f56bd242/upload-1671728986258.webp",
          "collections": {},
          "type1": "wind",
          "type2": "wind",
          "type3": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/d6f60aa9-4747-45fe-b0b5-fc9a7be16354/upload-1682806414982.webp",
          "attack": "15",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "stellar-rare",
          "id": "image__7c62ec58-b6bf-4f71-8d87-bdaa58f7e445",
          "tags": [],
          "author": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
          "lastUpdated": "2023-04-29T22:13:36.185Z",
          "access": "public",
          "slug": "penterror",
          "createdAt": "2022-11-15T09:13:28.612Z",
          "defense": "8",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T14:30:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "dragon",
          "description": "When this Penterror is destroyed by an opponent’s card effect you can Special Cast any number of Hydrake and Twindra from your hand or deck in Attack Position.",
          "excerpt": "XX/10",
          "title": "Penterror",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/6810b997-e024-4b4b-b43a-4e8864c1e586/upload-1666822760398.png",
              "category": "Artist",
              "slug": "diego-monster",
              "bgImage": "https://cdn.cerealapis.com/author/45070d0c-4dd8-491c-a56a-8a164afa9400/upload-1666822760394.png",
              "id": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
              "videoCount": 17,
              "title": "Diego Monster "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/d2c86a41-e120-4929-8c80-0e0a6ea5d7bd/upload-1671729020514.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/7e316a1a-e56d-4ea8-bf58-8b4e343b8745/upload-1682806529642.webp",
          "attack": "4",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "stellar-rare",
          "id": "image__02614964-2925-437a-b4df-2627c2bb79d7",
          "tags": [],
          "author": "88185685-0ac3-4a6c-b976-0aa8dc3403fe",
          "lastUpdated": "2023-04-29T22:15:31.076Z",
          "access": "public",
          "slug": "vysveris",
          "createdAt": "2022-11-15T09:17:01.998Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T14:35:00.000Z",
          "subclass1": "insectoid",
          "description": "``` embed\nThis Vysceris gains +1 <span class=\"elestrals-font\">J</span> for each Vysceris on the field. When this Vysceris destroys an Elestral in battle you can Special Cast a Vysceris from your hand or deck in Defense Position.\n```",
          "excerpt": "XX/99",
          "title": "Vysceris",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/f881a1cf-d017-4d34-b833-9c5dc9fe7eab/upload-1666823969818.png",
              "category": "Artist",
              "slug": "marcel-bromojumbo-romo",
              "bgImage": "https://cdn.cerealapis.com/author/aa6415a1-f484-4a75-988b-80db7af2fe56/upload-1666823969899.png",
              "id": "88185685-0ac3-4a6c-b976-0aa8dc3403fe",
              "videoCount": 14,
              "title": "Marcel Romo "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/4df1e019-4c96-41c2-9014-548616e202b6/upload-1671728903647.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/23c4cb45-ab86-4f3c-9cc0-bb32b8d9c6b4/upload-1682806234756.webp",
          "attack": "3",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#C63E2C",
          "rarity": "stellar-rare",
          "id": "image__21ce640a-ea79-45b0-923a-e1c2e2f26c1f",
          "tags": [],
          "author": "19eb7b3b-3b39-464e-9a88-427e8f289558",
          "lastUpdated": "2023-04-29T22:10:35.815Z",
          "access": "public",
          "slug": "emphyrix",
          "createdAt": "2022-11-15T09:23:55.774Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T14:40:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "avian",
          "description": "``` embed\nWhen this Emphyrix is destroyed in battle or by an opponent's card effect you can Expend <span class=\"elestrals-font\">F</span>. If you do, during this turn's End Phase you can Special Cast it.\n```",
          "excerpt": "XX/99",
          "title": "Emphyrix",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/29f7dfd3-2003-4e21-932b-31d442bb668c/upload-1666966185555.png",
              "category": "Artist",
              "slug": "elite4",
              "id": "19eb7b3b-3b39-464e-9a88-427e8f289558",
              "videoCount": 42,
              "title": "Élite4"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/0156f2db-899c-4e36-a72c-257db8a657cb/upload-1671728918525.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/f7920fe8-4804-4993-a85d-6df8eb888767/upload-1682806347722.webp",
          "attack": "1",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "stellar-rare",
          "id": "image__58ac7820-5a1e-4a2e-b303-42b8621347e0",
          "tags": [],
          "author": "ca4bfd8c-3ead-4ec2-bc37-426c1dca7f8c",
          "lastUpdated": "2023-04-29T22:12:28.748Z",
          "access": "public",
          "slug": "moustacean",
          "createdAt": "2022-11-15T12:05:09.584Z",
          "defense": "6",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T14:45:00.000Z",
          "subclass1": "oceanic",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">W</span> in order to swap this Moustacean’s <span class=\"elestrals-font\">J</span> with its <span class=\"elestrals-font\">O</span> until the End Phase. This effect can also be activated when an opponent Normal Casts an Elestral.\n```",
          "excerpt": "XX/99",
          "title": "Moustacean",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/978cea0e-1e6e-4304-b95b-830e7208d206/upload-1666822212654.JPG",
              "category": "Artist",
              "slug": "william-bilsu-parks",
              "bgImage": "https://cdn.cerealapis.com/author/461307f5-955b-418f-9a08-155807a74d59/upload-1666822212691.JPG",
              "id": "ca4bfd8c-3ead-4ec2-bc37-426c1dca7f8c",
              "videoCount": 12,
              "title": "William Parks"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/36509850-aa86-4c41-8200-23e0f0a057b3/upload-1671729006168.webp",
          "collections": {},
          "type1": "thunder",
          "type2": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/42e1223e-8d07-49e4-bf04-81b3058c78c7/upload-1682806472951.webp",
          "attack": "7",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#E3AB21",
          "rarity": "stellar-rare",
          "id": "image__dad3ad61-d325-4264-b45f-199f1cadba0e",
          "tags": [],
          "author": "8194695f-8d92-491e-b87b-c977a16a317e",
          "lastUpdated": "2023-04-29T22:14:34.043Z",
          "access": "public",
          "slug": "sonicore",
          "createdAt": "2022-11-15T12:07:19.321Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T14:50:00.000Z",
          "subclass2": "behemoth",
          "subclass1": "eldritch",
          "description": "This Sonicore’s Cast cannot be chained by Counter Runes. As long as this Sonicore is in Attack Position players cannot Cast Counter Runes.",
          "excerpt": "XX/99",
          "title": "Sonicore",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/a7719a4e-ee26-4bf7-8839-2887320a2a0a/upload-1666823882654.png",
              "category": "Artist",
              "slug": "luan-m-resende",
              "bgImage": "https://cdn.cerealapis.com/author/7d33a277-e7a3-423f-8661-3fdc94b69e7d/upload-1666823882721.png",
              "id": "8194695f-8d92-491e-b87b-c977a16a317e",
              "videoCount": 20,
              "title": "Luan M. Resende"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/a8b3d507-8af5-4c92-a847-7386bac0a416/upload-1671728956947.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/aafa6d12-fb9f-4f70-878a-3cd61ecd5195/upload-1682806398457.webp",
          "attack": "1",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "stellar-rare",
          "id": "image__062e8fa5-ad0c-41c5-b0c6-583de125cc08",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2023-04-29T22:13:19.488Z",
          "access": "public",
          "slug": "nimbug",
          "createdAt": "2022-11-15T12:15:45.388Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T14:55:00.000Z",
          "subclass1": "insectoid",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">A</span> in order to Special Ascend this Nimbug to Cirrucoon from your hand or deck.\n```",
          "excerpt": "XX/99",
          "title": "Nimbug",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/bbdec4c7-5b8f-482f-9676-f1471149bdac/upload-1690630481789.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/a0af5058-ca4a-43ab-b8a2-2871f085ce94/upload-1690630481475.webp",
          "attack": "4",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#B7C03A",
          "rarity": "stellar-rare",
          "id": "image__8a031bc9-f619-4682-bdf6-e82f61ee5e70",
          "tags": [],
          "author": "06f6337e-75f8-4837-a14b-bb73e880382e",
          "lastUpdated": "2023-07-29T11:34:42.430Z",
          "access": "public",
          "slug": "secret-stellar-1",
          "createdAt": "2023-01-23T19:13:10.976Z",
          "defense": "3",
          "postStatus": "published",
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T15:00:00.000Z",
          "subclass1": "dryad",
          "description": "``` embed\nYou can Nexus up to <span class=\"elestrals-font\">E</span><span class=\"elestrals-font\">E</span>. When you do, you can target and destroy a Rune.\n```",
          "excerpt": "XX/99",
          "title": "Equilynx",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/696727cb-6e52-4f00-b2b5-b5ee9b967d3e/upload-1666823423451.png",
              "category": "Artist",
              "slug": "giovanni-n4nin4ni-aguilar",
              "bgImage": "https://cdn.cerealapis.com/author/49850f39-8072-4bd7-8776-15508c33f3d7/upload-1666823423402.png",
              "id": "06f6337e-75f8-4837-a14b-bb73e880382e",
              "videoCount": 53,
              "title": "Giovanni Aguilar"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/0ca722f5-0b2c-46d9-93e7-29c1ed10e848/upload-1690630477929.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/54612c15-a593-4ff1-8b00-b2d7aa804ac1/upload-1690630477589.webp",
          "attack": "2",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#6997C4",
          "rarity": "stellar-rare",
          "id": "image__fcda0c16-44c2-45f6-94e7-cae94a4663c8",
          "tags": [],
          "author": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
          "lastUpdated": "2023-07-29T11:34:38.669Z",
          "access": "public",
          "slug": "secret-stellar-2",
          "createdAt": "2023-01-23T19:13:26.014Z",
          "defense": "4",
          "postStatus": "published",
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T15:05:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "aquatic",
          "description": "``` embed\nYou can Nexus up to <span class=\"elestrals-font\">W</span><span class=\"elestrals-font\">W</span>. When you do, you can draw a card for each <span class=\"elestrals-font\">W</span> that a card receives this way.\n```",
          "excerpt": "XX/99",
          "title": "Apheros",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4c88d483-9e81-4854-942f-97df6f95299e/upload-1666824047475.png",
              "category": "Artist",
              "slug": "phoebe-the-last-shaymin-sumner-twisk",
              "bgImage": "https://cdn.cerealapis.com/author/a0c334f7-fbb6-426d-88ca-2915f35213e4/upload-1666824047501.png",
              "id": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
              "videoCount": 33,
              "title": "Phoebe Sumner-Twisk"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/f9ea7071-f392-4a44-b574-f8aa5d2511aa/upload-1690630474835.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/311bfa76-fbce-43d6-a3d5-9e0bab3c8b7c/upload-1690630474207.webp",
          "attack": "3",
          "series": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
          "bgColor": "#ACBCC1",
          "rarity": "stellar-rare",
          "id": "image__5fc64f26-23a9-4d48-b9ec-5e7feabce68b",
          "tags": [],
          "author": "962db316-931a-4cb5-bdd1-9330d8486272",
          "lastUpdated": "2023-07-29T11:34:35.470Z",
          "access": "public",
          "slug": "secrat-stellar-3",
          "createdAt": "2023-01-23T19:13:42.702Z",
          "defense": "2",
          "postStatus": "published",
          "client": "elestrals_image",
          "publishedAt": "2022-01-01T15:10:00.000Z",
          "subclass1": "avian",
          "description": "``` embed\nYou can Nexus up to <span class=\"elestrals-font\">A</span><span class=\"elestrals-font\">A</span>. When you do, an opponent must Expend <span class=\"elestrals-font\">X</span><span class=\"elestrals-font\">X</span>.\n```",
          "excerpt": "XX/99",
          "title": "Exaltair",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/d6551b71-9397-4acb-8920-a9ca011df685/upload-1667710999117.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set",
              "bgImage": "https://cdn.cerealapis.com/series/e670be20-c944-48e2-9317-23711e6bb0f0/upload-1668391674807.jpg",
              "id": "a8a5d75a-8efa-4ff3-b7d3-3ead9e8c6538",
              "videoCount": 135,
              "title": "Base Set"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b314a3d-73f8-4458-b16c-b821c4f915f3/upload-1666823695029.png",
              "category": "Artist",
              "slug": "lucas-kosta-costa",
              "bgImage": "https://cdn.cerealapis.com/author/3ebe38de-02f2-496d-a11f-337103ee7347/upload-1666823695026.png",
              "id": "962db316-931a-4cb5-bdd1-9330d8486272",
              "videoCount": 20,
              "title": "Lucas Costa"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/681259ec-01f1-43a3-aa47-08af8e1f467d/upload-1671751044003.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/f6169f09-b3f8-4f7b-a438-21fd5c653574/upload-1682806490913.webp",
          "attack": "3",
          "series": "f3053e75-dc35-416a-aa96-ee0f65714a50",
          "bgColor": "#B7C03A",
          "rarity": "stellar-rare",
          "id": "image__e0ace1ab-e9e9-498d-a16a-d3432e78bce8",
          "tags": [],
          "author": "5717b454-98e6-4b9c-bad3-fc1bfefa4d26",
          "lastUpdated": "2023-07-25T18:21:28.441Z",
          "access": "public",
          "slug": "sproutyr",
          "createdAt": "2022-11-15T12:16:29.304Z",
          "defense": "5",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-02T04:55:00.000Z",
          "subclass1": "dryad",
          "description": "When you Normal Cast this Sproutyr you can Search your deck for an Artifact and add it to your hand.",
          "excerpt": "XX/99",
          "title": "Sproutyr",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/2f9a95ab-3001-4470-a2c1-e9c50e1f68df/upload-1668278599478.png",
              "access": "public",
              "category": "Card Set",
              "slug": "centaurbor-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/2f1203f3-94eb-4e6a-bf68-8e5578b4d0e9/upload-1668393661775.png",
              "id": "f3053e75-dc35-416a-aa96-ee0f65714a50",
              "videoCount": 26,
              "title": "Centaurbor Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/e0fa7325-1c5d-450c-a14b-e200e191f99b/upload-1666822059355.png",
              "category": "Artist",
              "slug": "francis-adam-adam-fegarido",
              "bgImage": "https://cdn.cerealapis.com/author/adfeb389-b9f3-44c4-8cad-6375cd20b30f/upload-1666822060202.png",
              "id": "5717b454-98e6-4b9c-bad3-fc1bfefa4d26",
              "videoCount": 6,
              "title": "Francis Fegarido "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/4a9ae0bb-2116-4013-a46a-68b0970bee6d/upload-1671751974785.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/2b4cf37b-675a-4d6a-89ad-85eb28ccb05b/upload-1682806380511.webp",
          "attack": "5",
          "series": "795e2c41-5df5-4d6b-985d-135fdb2c03b5",
          "bgColor": "#C63E2C",
          "rarity": "stellar-rare",
          "id": "image__5bc2f209-9b35-48ce-812e-e30579c79b5e",
          "tags": [],
          "author": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
          "lastUpdated": "2023-07-25T18:33:00.764Z",
          "access": "public",
          "slug": "necruff",
          "createdAt": "2022-11-15T09:20:14.214Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-03T05:00:00.000Z",
          "subclass2": "brute",
          "subclass1": "eldritch",
          "description": "``` embed\nWhen this Necruff destroys an opponent’s Elestral in battle that opponent must Expend <span class=\"elestrals-font\">X</span>.\n```",
          "excerpt": "XX/99",
          "title": "Necruff",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/1fa4ef0c-c53e-4750-b3e7-90ec3fdc1143/upload-1668278611817.png",
              "access": "public",
              "category": "Card Set",
              "slug": "trifernal-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/554bc5fc-3ba7-41d6-8fa7-52d058d6c029/upload-1668393741140.png",
              "id": "795e2c41-5df5-4d6b-985d-135fdb2c03b5",
              "videoCount": 26,
              "title": "Trifernal Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/9abbfa57-fadc-42d9-bd52-3907b145d6ff/upload-1662720081319.jpeg",
              "category": "Artist",
              "slug": "jonny-de-oliviera",
              "bgImage": "https://cdn.cerealapis.com/author/d6271a98-e34d-4b1a-843c-45b03f86abb2/upload-1662720081310.jpeg",
              "id": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
              "videoCount": 22,
              "title": "Jonny De Oliviera"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/b50e8052-ef0f-442e-9989-2e43e28a8964/upload-1671753550688.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/019ec81f-25cd-4f13-a3c3-7d7f7d4a02b1/upload-1682806456860.webp",
          "attack": "2",
          "series": "4fbddd21-3d70-4fa1-b87e-7eae8d206850",
          "bgColor": "#6997C4",
          "rarity": "stellar-rare",
          "id": "image__f8756816-be14-48aa-bbb9-14d93a49abd0",
          "tags": [],
          "author": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
          "lastUpdated": "2023-07-25T18:36:59.730Z",
          "access": "public",
          "slug": "sluggle",
          "createdAt": "2022-11-15T09:24:46.122Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-04T05:05:00.000Z",
          "subclass1": "oceanic",
          "description": "``` embed\nWhen you Cast this Sluggle you can return up to <span class=\"elestrals-font\">W</span><span class=\"elestrals-font\">W</span><span class=\"elestrals-font\">W</span> from your Underworld to your Spirit Deck.\n```",
          "excerpt": "XX/99",
          "title": "Sluggle",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7ce742ba-237c-493c-b22a-43410985a64e/upload-1668278622677.png",
              "access": "public",
              "category": "Card Set",
              "slug": "majesea-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/0b02827b-42e3-4500-b7dd-ac86b3923e73/upload-1668393802538.png",
              "id": "4fbddd21-3d70-4fa1-b87e-7eae8d206850",
              "videoCount": 26,
              "title": "Majesea Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4c88d483-9e81-4854-942f-97df6f95299e/upload-1666824047475.png",
              "category": "Artist",
              "slug": "phoebe-the-last-shaymin-sumner-twisk",
              "bgImage": "https://cdn.cerealapis.com/author/a0c334f7-fbb6-426d-88ca-2915f35213e4/upload-1666824047501.png",
              "id": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
              "videoCount": 33,
              "title": "Phoebe Sumner-Twisk"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/5cbf25d0-edc3-454b-80ce-7bfd50fd75eb/upload-1671754318490.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/a2bd27ec-63ed-4777-a445-b0f01a46854f/upload-1682806439371.webp",
          "attack": "2",
          "series": "17b980b0-ed10-46f0-93ed-d73581c07487",
          "bgColor": "#E3AB21",
          "rarity": "stellar-rare",
          "id": "image__22b0d263-fc5e-4e2b-afde-72bf56e7d3f4",
          "tags": [],
          "author": "8194695f-8d92-491e-b87b-c977a16a317e",
          "lastUpdated": "2023-07-25T18:43:23.766Z",
          "access": "public",
          "slug": "quackle",
          "createdAt": "2022-11-15T12:13:57.616Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-05T05:10:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "avian",
          "description": "``` embed\nWhen you Cast this Quackle you can return up to <span class=\"elestrals-font\">T</span><span class=\"elestrals-font\">T</span><span class=\"elestrals-font\">T</span> from your Underworld to your Spirit Deck.\n```",
          "excerpt": "XX/99",
          "title": "Quackle",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/6ebfa067-9271-4bf7-8941-95e1dbfed92c/upload-1668278963149.png",
              "access": "public",
              "category": "Card Set",
              "slug": "ohmperial-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/d942d4f9-fbf8-4742-9fa4-385fade7d3f7/upload-1668393877355.png",
              "id": "17b980b0-ed10-46f0-93ed-d73581c07487",
              "videoCount": 26,
              "title": "Ohmperial Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/a7719a4e-ee26-4bf7-8839-2887320a2a0a/upload-1666823882654.png",
              "category": "Artist",
              "slug": "luan-m-resende",
              "bgImage": "https://cdn.cerealapis.com/author/7d33a277-e7a3-423f-8661-3fdc94b69e7d/upload-1666823882721.png",
              "id": "8194695f-8d92-491e-b87b-c977a16a317e",
              "videoCount": 20,
              "title": "Luan M. Resende"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/776d1382-338f-4163-962c-602e9ca33109/upload-1671755125521.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/7eca2d91-7668-40e0-a3d5-6d402f93fd9b/upload-1682806251582.webp",
          "attack": "4",
          "series": "4b9ced0f-2428-4448-a51a-cb6fedd53c57",
          "bgColor": "#ACBCC1",
          "rarity": "stellar-rare",
          "id": "image__c195501f-ce2f-4751-a021-956b074076f0",
          "tags": [],
          "author": "5717b454-98e6-4b9c-bad3-fc1bfefa4d26",
          "lastUpdated": "2023-07-25T18:54:06.633Z",
          "access": "public",
          "slug": "hydrake",
          "createdAt": "2022-11-15T12:17:28.346Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-06T05:15:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "dragon",
          "description": "When this Hydrake is destroyed in battle you can Special Cast a Twindra from your hand or deck in Attack Position.",
          "excerpt": "XX/99",
          "title": "Hydrake",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/965d6cf1-316a-4e7f-94f7-164afaa5f5ec/upload-1668278710644.png",
              "access": "public",
              "category": "Card Set",
              "slug": "penterror-starter-deck",
              "bgImage": "https://cdn.cerealapis.com/series/720ab115-dad8-4144-95f0-9794874b450b/upload-1668393855644.png",
              "id": "4b9ced0f-2428-4448-a51a-cb6fedd53c57",
              "videoCount": 26,
              "title": "Penterror Starter Deck"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/e0fa7325-1c5d-450c-a14b-e200e191f99b/upload-1666822059355.png",
              "category": "Artist",
              "slug": "francis-adam-adam-fegarido",
              "bgImage": "https://cdn.cerealapis.com/author/adfeb389-b9f3-44c4-8cad-6375cd20b30f/upload-1666822060202.png",
              "id": "5717b454-98e6-4b9c-bad3-fc1bfefa4d26",
              "videoCount": 6,
              "title": "Francis Fegarido "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/ad03320d-b33a-48ed-a07d-dea45a199ba0/upload-1671729461341.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/91c2e04a-6657-4b05-b835-825ffd9e6e7d/upload-1682806503603.webp",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#B7C03A",
          "rarity": "stellar-rare",
          "id": "image__20e238fc-b539-4753-a486-b28e26233112",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2023-04-29T22:15:04.687Z",
          "access": "public",
          "slug": "teratlas",
          "createdAt": "2022-11-15T18:36:26.759Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T04:00:00.000Z",
          "subclass2": "golem",
          "subclass1": "insectoid",
          "description": "``` embed\nEarth Spirit <span class=\"elestrals-font\">E</span>\n```",
          "excerpt": "PR1-01",
          "title": "Teratlas",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/cb99eabe-9116-48f7-bdad-b1bc8592078c/upload-1671729483218.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/3e8df3b6-6293-4470-bc27-729c50cd7c4a/upload-1682806516022.webp",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#C63E2C",
          "rarity": "stellar-rare",
          "id": "image__85f95053-8f89-4a11-9e03-97772f2c6550",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2023-04-29T22:15:17.103Z",
          "access": "public",
          "slug": "vipyro",
          "createdAt": "2022-11-15T18:37:55.753Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T04:05:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "dragon",
          "description": "``` embed\nFire Spirit <span class=\"elestrals-font\">F</span>\n```",
          "excerpt": "PR1-02",
          "title": "Vipyro",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/71999242-3542-4662-a6e5-73caac824213/upload-1671729498391.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/0430ea50-bb94-4b60-8e6c-520f9b5e81bb/upload-1682806270189.webp",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#6997C4",
          "rarity": "stellar-rare",
          "id": "image__b0abd38e-d633-4365-aa1e-7ee2a498aded",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2023-04-29T22:11:11.273Z",
          "access": "public",
          "slug": "leviaphin",
          "createdAt": "2022-11-15T18:39:50.158Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T04:10:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "oceanic",
          "description": "``` embed\nWater Spirit <span class=\"elestrals-font\">W</span>\n```",
          "excerpt": "PR1-03",
          "title": "Leviaphin",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/38e81136-0a6a-48db-8743-2d6a68819ead/upload-1671729514889.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/b25359bf-d01c-4909-9ff6-0717feddae7f/upload-1682806544299.webp",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#E3AB21",
          "rarity": "stellar-rare",
          "id": "image__5ea64820-2d61-424b-a18c-4ae5cf2e9f1d",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2023-04-29T22:15:45.838Z",
          "access": "public",
          "slug": "zaptor",
          "createdAt": "2022-11-15T18:41:26.775Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T04:15:00.000Z",
          "subclass2": "dragon",
          "subclass1": "archaic",
          "description": "``` embed\nThunder Spirit <span class=\"elestrals-font\">T</span>\n```",
          "excerpt": "PR1-04",
          "title": "Zaptor",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/042ac960-5614-4b66-9f25-6d871d9e9d5a/upload-1671729529718.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/796e2056-5abe-4d58-a2fc-23e1aacc7e1a/upload-1682806323005.webp",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#ACBCC1",
          "rarity": "stellar-rare",
          "id": "image__ad268812-020d-4ee4-b902-beb6d281ddf5",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2023-04-29T22:12:04.130Z",
          "access": "public",
          "slug": "lycarus",
          "createdAt": "2022-11-15T18:42:37.820Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T04:20:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "brute",
          "description": "``` embed\nWind Spirit <span class=\"elestrals-font\">A</span>\n```",
          "excerpt": "PR1-05",
          "title": "Lycarus",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/668b3a59-32a9-4a19-9e01-69b9ddd40f89/upload-1671729708622.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/7befc528-7bc7-46dc-98af-ee1c52965f3f/upload-1682804444393.webp",
          "attack": "1",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#B7C03A",
          "rarity": "stellar-rare",
          "id": "image__ad3a701d-3b18-45af-a562-1f0db264053d",
          "tags": [],
          "author": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
          "lastUpdated": "2023-04-29T21:40:45.641Z",
          "access": "public",
          "slug": "pandicine",
          "createdAt": "2022-11-15T21:40:15.242Z",
          "defense": "4",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T04:25:00.000Z",
          "subclass1": "brute",
          "description": "``` embed\nYou can Disenchant <span class=\"elestrals-font\">E</span> from this Pandicine in order to return an Invoke Rune from your Underworld to your hand.\n```",
          "excerpt": "PR1-06",
          "title": "Pandicine",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/9abbfa57-fadc-42d9-bd52-3907b145d6ff/upload-1662720081319.jpeg",
              "category": "Artist",
              "slug": "jonny-de-oliviera",
              "bgImage": "https://cdn.cerealapis.com/author/d6271a98-e34d-4b1a-843c-45b03f86abb2/upload-1662720081310.jpeg",
              "id": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
              "videoCount": 22,
              "title": "Jonny De Oliviera"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/d722af16-6c21-4d21-837c-eea32ce0b521/upload-1671729748456.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/128f5dee-4972-4700-b9f1-3753effdfcbf/upload-1682804491185.webp",
          "attack": "4",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#E3AB21",
          "rarity": "stellar-rare",
          "id": "image__34f35b2e-f848-4c89-8b9d-e21ff8feb546",
          "tags": [],
          "author": "3067a712-8791-4a4e-a6dd-cc9e4b263e94",
          "lastUpdated": "2023-04-29T21:41:32.245Z",
          "access": "public",
          "slug": "sparkitt",
          "createdAt": "2022-11-17T20:33:48.312Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T04:30:00.000Z",
          "subclass2": "archaic",
          "subclass1": "brute",
          "description": "``` embed\nYou can Nexus up to <span class=\"elestrals-font\">T</span><span class=\"elestrals-font\">T</span>. When you do you can look at an opponent’s hand and discard a card.\n```",
          "excerpt": "PR1-07",
          "title": "Sparkitt",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4a4adc1b-2836-4e52-86af-50a54227c185/upload-1666824162136.png",
              "category": "Artist",
              "slug": "victor-vcreateart-rosales",
              "bgImage": "https://cdn.cerealapis.com/author/5210982f-5535-48b5-884b-cdfb1d03de56/upload-1666824162133.png",
              "id": "3067a712-8791-4a4e-a6dd-cc9e4b263e94",
              "videoCount": 10,
              "title": "Victor Rosales"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/b139591f-94a8-468d-9023-f926e3b9a03a/upload-1671729891330.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/3ec25ea1-266c-4ac4-9bd6-1503daf8571f/upload-1682804596678.webp",
          "attack": "5",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#ACBCC1",
          "rarity": "stellar-rare",
          "id": "image__c39bd6fa-17b0-43e5-b51e-477613b94190",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2023-04-29T21:43:17.920Z",
          "access": "public",
          "slug": "soarlet",
          "createdAt": "2022-11-23T23:03:50.024Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T04:45:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "brute",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">A</span> in order to target and change the Position of an Elestral unless it is <span class=\"elestrals-font\">A</span>-Enchanted.\n```",
          "excerpt": "PR1-10",
          "title": "Soarlet",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/69b871df-1bfa-4301-a743-b367c4c19d41/upload-1671729954250.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/028f6212-5f02-4137-9824-636a516bda05/upload-1682804641610.webp",
          "attack": "2",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#E3AB21",
          "rarity": "stellar-rare",
          "id": "image__56178d5a-5475-48a4-8fbe-f5bdf2a62d5c",
          "tags": [],
          "author": "06f6337e-75f8-4837-a14b-bb73e880382e",
          "lastUpdated": "2023-04-29T21:44:03.037Z",
          "access": "public",
          "slug": "astrabbit",
          "createdAt": "2022-11-23T23:06:55.169Z",
          "defense": "5",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T04:50:00.000Z",
          "subclass1": "ethereal",
          "description": "``` embed\nWhen this Astrabbit receives 1 or more <span class=\"elestrals-font\">T</span> you can look at the top 3 cards of your deck, then add 1 of them to your hand and shuffle the rest into your deck.\n```",
          "excerpt": "PR1-11",
          "title": "Astrabbit",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/696727cb-6e52-4f00-b2b5-b5ee9b967d3e/upload-1666823423451.png",
              "category": "Artist",
              "slug": "giovanni-n4nin4ni-aguilar",
              "bgImage": "https://cdn.cerealapis.com/author/49850f39-8072-4bd7-8776-15508c33f3d7/upload-1666823423402.png",
              "id": "06f6337e-75f8-4837-a14b-bb73e880382e",
              "videoCount": 53,
              "title": "Giovanni Aguilar"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/143f0f0d-ced9-4b1b-b1b4-888d800b4ec7/upload-1671730026097.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/266da375-9722-459e-95a0-0e4a119c34df/upload-1682804761720.webp",
          "attack": "3",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#C63E2C",
          "rarity": "stellar-rare",
          "id": "image__1bd5e446-f86d-445f-82d0-60a9a37fd2c3",
          "tags": [],
          "author": "ca4bfd8c-3ead-4ec2-bc37-426c1dca7f8c",
          "lastUpdated": "2023-04-29T21:46:02.959Z",
          "access": "public",
          "slug": "warmite",
          "createdAt": "2022-11-23T23:37:22.969Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T05:00:00.000Z",
          "subclass1": "insectoid",
          "description": "``` embed\nYou can target an opponent’s Elestral and give it -4 <span class=\"elestrals-font\">J</span> until the End Phase.\n```",
          "hideCardOnCreator": true,
          "excerpt": "PR1-13",
          "title": "Warmite",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/978cea0e-1e6e-4304-b95b-830e7208d206/upload-1666822212654.JPG",
              "category": "Artist",
              "slug": "william-bilsu-parks",
              "bgImage": "https://cdn.cerealapis.com/author/461307f5-955b-418f-9a08-155807a74d59/upload-1666822212691.JPG",
              "id": "ca4bfd8c-3ead-4ec2-bc37-426c1dca7f8c",
              "videoCount": 12,
              "title": "William Parks"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/2a9722b1-cf62-432d-8e8b-57f8cc142246/upload-1671730098887.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/84ed8d79-eb2f-4cbb-88ee-3c3bc3e1b618/upload-1682804887524.webp",
          "attack": "2",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#E3AB21",
          "rarity": "stellar-rare",
          "id": "image__fb9bf628-3576-4b72-b164-f63807eb21ab",
          "tags": [],
          "author": "962db316-931a-4cb5-bdd1-9330d8486272",
          "lastUpdated": "2023-04-29T21:48:08.702Z",
          "access": "public",
          "slug": "raiceros",
          "createdAt": "2022-11-24T19:18:09.854Z",
          "defense": "4",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T05:15:00.000Z",
          "subclass1": "brute",
          "description": "``` embed\nAs long as this Raiceros is Enchanted Elestrals cannot attack the turn they are Cast unless they are <span class=\"elestrals-font\">T</span>-Enchanted.\n```",
          "hideCardOnCreator": false,
          "excerpt": "PR1-16",
          "title": "Raiceros",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b314a3d-73f8-4458-b16c-b821c4f915f3/upload-1666823695029.png",
              "category": "Artist",
              "slug": "lucas-kosta-costa",
              "bgImage": "https://cdn.cerealapis.com/author/3ebe38de-02f2-496d-a11f-337103ee7347/upload-1666823695026.png",
              "id": "962db316-931a-4cb5-bdd1-9330d8486272",
              "videoCount": 20,
              "title": "Lucas Costa"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/e725a034-093f-4c11-a7fd-2aa3fcf1861c/upload-1671730121713.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/5f1908ca-1e07-4f14-bb27-7573eaafad4f/upload-1682804929390.webp",
          "attack": "2",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#E3AB21",
          "rarity": "stellar-rare",
          "id": "image__a4171323-b7a1-487e-af5a-ac343361c5e1",
          "tags": [],
          "author": "8194695f-8d92-491e-b87b-c977a16a317e",
          "lastUpdated": "2023-04-29T21:48:50.828Z",
          "access": "public",
          "slug": "boombatt",
          "createdAt": "2022-12-11T22:13:47.664Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T05:20:00.000Z",
          "subclass1": "eldritch",
          "description": "``` embed\nYou can Disenchant <span class=\"elestrals-font\">T</span> from this Boombatt in order to target and destroy an opponent’s Elestral.\n```",
          "excerpt": "PR1-17",
          "title": "Boombatt",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/a7719a4e-ee26-4bf7-8839-2887320a2a0a/upload-1666823882654.png",
              "category": "Artist",
              "slug": "luan-m-resende",
              "bgImage": "https://cdn.cerealapis.com/author/7d33a277-e7a3-423f-8661-3fdc94b69e7d/upload-1666823882721.png",
              "id": "8194695f-8d92-491e-b87b-c977a16a317e",
              "videoCount": 20,
              "title": "Luan M. Resende"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/88e4d6a5-2a81-474b-831e-e8f3021fc2d6/upload-1671730144807.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/00db0bde-8490-4131-bcae-90711df443a5/upload-1682804996498.webp",
          "attack": "2",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#B7C03A",
          "rarity": "stellar-rare",
          "id": "image__798ea33f-5828-4eef-9d53-adcaecd51564",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2023-04-29T21:49:57.645Z",
          "access": "public",
          "slug": "drataya",
          "createdAt": "2022-11-26T23:56:41.030Z",
          "defense": "4",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T05:25:00.000Z",
          "subclass2": "dragon",
          "subclass1": "dryad",
          "description": "When a player Casts Ambrosia you can draw 2 cards.",
          "hideCardOnCreator": true,
          "excerpt": "PR1-18",
          "title": "Drataya",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/006df18f-0fda-48fe-ba0f-69f34c3fdd4a/upload-1671730184518.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/f8ccf921-b425-4394-97e7-18881cf40a01/upload-1682805080673.webp",
          "attack": "2",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#6997C4",
          "rarity": "stellar-rare",
          "id": "image__078488e9-7ad8-4e58-8b89-fad758a3d7d6",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2023-04-29T21:51:22.089Z",
          "access": "public",
          "slug": "foamee",
          "createdAt": "2022-11-27T00:02:21.938Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T05:35:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "oceanic",
          "description": "``` embed\nWhen this Foamee is destroyed in battle you can Special Cast an Elestral with Enchantment Cost <span class=\"elestrals-font\">W</span> from your deck in Defense Position.\n```",
          "hideCardOnCreator": false,
          "excerpt": "PR1-20",
          "title": "Foamee",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/c0bc1007-80dd-422a-b584-4808301d0185/upload-1671730202284.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/7433e9c0-9e32-41b9-afa8-182cd261622c/upload-1682805116879.webp",
          "attack": "1",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#6997C4",
          "rarity": "stellar-rare",
          "id": "image__c018fa50-2a52-4b41-8e8b-2c9264b44948",
          "tags": [],
          "author": "ca4bfd8c-3ead-4ec2-bc37-426c1dca7f8c",
          "lastUpdated": "2023-04-29T21:51:58.081Z",
          "access": "public",
          "slug": "tadpuff",
          "createdAt": "2022-12-01T13:53:57.228Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T05:40:00.000Z",
          "subclass1": "aquatic",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">W</span> in order to Special Cast this Tadpuff from your hand in Attack Position with <span class=\"elestrals-font\">W</span>.\n```",
          "excerpt": "PR1-21",
          "title": "Tadpuff",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/978cea0e-1e6e-4304-b95b-830e7208d206/upload-1666822212654.JPG",
              "category": "Artist",
              "slug": "william-bilsu-parks",
              "bgImage": "https://cdn.cerealapis.com/author/461307f5-955b-418f-9a08-155807a74d59/upload-1666822212691.JPG",
              "id": "ca4bfd8c-3ead-4ec2-bc37-426c1dca7f8c",
              "videoCount": 12,
              "title": "William Parks"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/a4a325a0-0b54-4083-9f07-1143d5cad73a/upload-1671730238877.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/4b040958-ce65-4555-b1f9-924a30c7bc0f/upload-1682805184335.webp",
          "attack": "3",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#C63E2C",
          "rarity": "stellar-rare",
          "id": "image__41e25a82-59f4-4055-9ef1-98c4f570def7",
          "tags": [],
          "author": "36eb909a-5835-4f59-b317-e02082ee59db",
          "lastUpdated": "2023-04-29T21:53:06.016Z",
          "access": "public",
          "slug": "kindleo",
          "createdAt": "2022-12-07T09:29:04.014Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T05:50:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "brute",
          "description": "``` embed\nYou can Nexus up to <span class=\"elestrals-font\">F</span><span class=\"elestrals-font\">F</span>. When you do, you can target and destroy an opponent’s Elestral.\n```",
          "excerpt": "PR1-23",
          "title": "Kindleo",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/155a9d71-dff3-4efc-965b-766a55dc62b3/upload-1666824091421.jpg",
              "category": "Artist",
              "slug": "tori",
              "bgImage": "https://cdn.cerealapis.com/author/25fe30f4-7c6c-435a-83e4-8e88e6258fc7/upload-1666824091368.jpg",
              "id": "36eb909a-5835-4f59-b317-e02082ee59db",
              "videoCount": 4,
              "title": "Tori "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/2867de3f-6eab-4e0e-992c-22873746d7c0/upload-1671730907291.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/97cc7e38-222c-4c25-817b-42741d0bdaaf/upload-1682805236765.webp",
          "attack": "2",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#B7C03A",
          "rarity": "stellar-rare",
          "id": "image__0f42a939-1a96-45ef-aeb0-796da5a495c0",
          "tags": [],
          "author": "06f6337e-75f8-4837-a14b-bb73e880382e",
          "lastUpdated": "2023-04-29T21:53:58.210Z",
          "access": "public",
          "slug": "rummagem",
          "createdAt": "2022-12-22T17:41:47.864Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T05:55:00.000Z",
          "subclass2": "brute",
          "subclass1": "golem",
          "description": "``` embed\nWhen you Normal Cast this Rummagem you can Search your deck for an Elestral with Enchantment Cost <span class=\"elestrals-font\">E</span> and add it to your hand.\n```",
          "excerpt": "PR1-24",
          "title": "Rummagem",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/696727cb-6e52-4f00-b2b5-b5ee9b967d3e/upload-1666823423451.png",
              "category": "Artist",
              "slug": "giovanni-n4nin4ni-aguilar",
              "bgImage": "https://cdn.cerealapis.com/author/49850f39-8072-4bd7-8776-15508c33f3d7/upload-1666823423402.png",
              "id": "06f6337e-75f8-4837-a14b-bb73e880382e",
              "videoCount": 53,
              "title": "Giovanni Aguilar"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/6c566791-e649-49c7-a78b-53cf3fa462a0/upload-1671731030085.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/2332113d-7c95-4731-ae1b-410902d1240c/upload-1682805274747.webp",
          "attack": "3",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#6997C4",
          "rarity": "stellar-rare",
          "id": "image__aa7d377d-7fb4-4f9f-86ca-32d99e35e5c6",
          "tags": [],
          "author": "8194695f-8d92-491e-b87b-c977a16a317e",
          "lastUpdated": "2023-04-29T21:54:36.214Z",
          "access": "public",
          "slug": "verutaqua",
          "createdAt": "2022-12-22T17:43:50.789Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T06:00:00.000Z",
          "subclass1": "oceanic",
          "description": "When this Verutaqua enters battle with a Defense Position Elestral you can negate that Elestral’s effect and destroy it. If you do this Verutaqua deals no damage this battle.",
          "excerpt": "PR1-25",
          "title": "Verutaqua",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/a7719a4e-ee26-4bf7-8839-2887320a2a0a/upload-1666823882654.png",
              "category": "Artist",
              "slug": "luan-m-resende",
              "bgImage": "https://cdn.cerealapis.com/author/7d33a277-e7a3-423f-8661-3fdc94b69e7d/upload-1666823882721.png",
              "id": "8194695f-8d92-491e-b87b-c977a16a317e",
              "videoCount": 20,
              "title": "Luan M. Resende"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/1e16c41e-3c5b-474e-95b0-39c5a7406a1d/upload-1671731119371.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/f52eabe2-c2c3-44f4-a698-d7f6087d9064/upload-1682805288570.webp",
          "attack": "1",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#C63E2C",
          "rarity": "stellar-rare",
          "id": "image__175a5d5b-0ebc-4bd8-935f-332c75af1cb8",
          "tags": [],
          "author": "962db316-931a-4cb5-bdd1-9330d8486272",
          "lastUpdated": "2023-04-29T21:54:49.756Z",
          "access": "public",
          "slug": "ignectar",
          "createdAt": "2022-12-22T17:45:19.990Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T06:05:00.000Z",
          "subclass1": "insectoid",
          "description": "``` embed\nWhen you Normal Cast this Ignectar you can Special Cast up to 2 Ignectar from your hand or deck in Attack Position with <span class=\"elestrals-font\">F</span>. If you do you must skip your next Battle Phase.\n```",
          "excerpt": "PR1-26",
          "title": "Ignectar",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b314a3d-73f8-4458-b16c-b821c4f915f3/upload-1666823695029.png",
              "category": "Artist",
              "slug": "lucas-kosta-costa",
              "bgImage": "https://cdn.cerealapis.com/author/3ebe38de-02f2-496d-a11f-337103ee7347/upload-1666823695026.png",
              "id": "962db316-931a-4cb5-bdd1-9330d8486272",
              "videoCount": 20,
              "title": "Lucas Costa"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/0e06a511-9851-404b-9a7c-88dac739fce8/upload-1671731209631.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/295c85cd-f5bc-49ac-9cb8-4a0022c36c2b/upload-1685717315188.webp",
          "attack": "1",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#C63E2C",
          "rarity": "stellar-rare",
          "id": "image__faa9fd2b-8221-4f31-a6b8-795a52b8124d",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2023-06-02T14:48:37.108Z",
          "access": "public",
          "slug": "urscout",
          "createdAt": "2022-12-22T17:46:50.270Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T06:10:00.000Z",
          "description": "When this Urscout is destroyed in battle can Special Cast an [URSA] that costs 1 Spirit from your hand or deck.",
          "excerpt": "PR1-27",
          "title": "Urscout",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/210ea550-a195-488e-bc9f-f630cff0b8b2/upload-1671731286487.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/f854dd28-d05f-4060-bb3c-b7cd0945b676/upload-1685717334635.webp",
          "attack": "2",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#C63E2C",
          "rarity": "stellar-rare",
          "id": "image__eaf40720-3127-4df7-912f-103e90be7c66",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2023-06-02T14:48:56.117Z",
          "access": "public",
          "slug": "ursmog",
          "createdAt": "2022-12-22T17:48:07.067Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T06:15:00.000Z",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">F</span> in order to Special Cast an [URSA] Elestral that costs 1 Spirit from your hand.\n```",
          "excerpt": "PR1-28",
          "title": "Ursmog",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/b0539038-3fd3-42e5-88b6-4ff871ac8f90/upload-1671731369244.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/54d63c86-3658-48a1-9e4f-d05f4564ff74/upload-1685717353401.webp",
          "attack": "3",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#C63E2C",
          "rarity": "stellar-rare",
          "id": "image__692b3db0-5a29-4368-ae7b-ecdc6edd745f",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2023-06-02T14:49:14.657Z",
          "access": "public",
          "slug": "ursear",
          "createdAt": "2022-12-22T17:49:29.830Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T06:20:00.000Z",
          "description": "When this Ursear destroys an Elestral in battle you can Special Cast an [URSA] Elestral that costs 1 Spirit from your hand or deck.",
          "excerpt": "PR1-29",
          "title": "Ursear",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
  
      {
          "file": "https://cdn.cerealapis.com/post/41cb2f01-28a4-423d-ac7f-29711022647f/upload-1671729049183.webp",
          "collections": {},
          "type1": "earth",
          "type2": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/d4f783a2-98bb-42cb-b345-3efd3457cae9/upload-1682804200409.webp",
          "attack": "7",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#B7C03A",
          "rarity": "alternative-art",
          "id": "image__817a413f-a1dc-4e5a-8440-d11e59e492fb",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2023-04-29T21:36:41.530Z",
          "access": "public",
          "slug": "artist-collection-2",
          "createdAt": "2022-11-16T05:02:58.394Z",
          "defense": "8",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T04:00:00.000Z",
          "subclass2": "dragon",
          "subclass1": "dryad",
          "description": "When a player Casts Ambrosia or Golden Apple of Discord you can target and destroy a card.",
          "excerpt": "AC1-01",
          "title": "Ladogon",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/3a92a2a5-4f3f-400e-a72a-565dfec19993/upload-1671729062396.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/43898cf3-2c02-4d53-8035-fa326bcae418/upload-1682804221998.webp",
          "attack": "4",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#B7C03A",
          "rarity": "alternative-art",
          "id": "image__3cb9f3ea-5806-402f-95b2-24df4048f15a",
          "tags": [],
          "author": "75489aa3-accc-4053-ab04-30a1d49d13bb",
          "lastUpdated": "2023-04-29T21:37:03.267Z",
          "access": "public",
          "slug": "equilynx",
          "createdAt": "2022-11-19T01:44:59.094Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T04:05:00.000Z",
          "subclass1": "dryad",
          "description": "``` embed\nYou can Nexus up to <span class=\"elestrals-font\">E</span><span class=\"elestrals-font\">E</span>. When you do, you can target and destroy a Rune.\n```",
          "hideCardOnCreator": false,
          "excerpt": "AC1-02",
          "title": "Equilynx",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/292635bf-0ef8-46f5-8583-dad865383c11/upload-1666719837086.png",
              "category": "Artist",
              "slug": "amalry",
              "bgImage": "https://cdn.cerealapis.com/author/080d7ae5-d84a-4885-a0d2-3443fa849520/upload-1666719837080.png",
              "id": "75489aa3-accc-4053-ab04-30a1d49d13bb",
              "videoCount": 18,
              "title": "Amalry"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/831ab62e-5d91-43b8-9986-9fb86133e78d/upload-1671729075533.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/30b9ef2f-398d-462a-8a97-1d6f067690ff/upload-1682804239600.webp",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#B7C03A",
          "rarity": "alternative-art",
          "id": "image__09a95e29-6a7d-477a-8d9c-c803b2be1893",
          "tags": [],
          "author": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
          "lastUpdated": "2023-04-29T21:37:20.823Z",
          "access": "public",
          "slug": "artist-collection-10",
          "createdAt": "2022-11-16T05:09:52.762Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T04:10:00.000Z",
          "runeType": "divine",
          "description": "``` embed\nWhen you Cast this Demeter you can Enchant up to <span class=\"elestrals-font\">E</span><span class=\"elestrals-font\">E</span><span class=\"elestrals-font\">E</span> to it. You can Disenchant <span class=\"elestrals-font\">E</span> from this Demeter in order to give +3 <span class=\"elestrals-font\">J</span> and +3 <span class=\"elestrals-font\">O</span> to an Elestral until the End Phase.\n```",
          "excerpt": "AC1-03",
          "sentEmail": true,
          "title": "Demeter",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4c88d483-9e81-4854-942f-97df6f95299e/upload-1666824047475.png",
              "category": "Artist",
              "slug": "phoebe-the-last-shaymin-sumner-twisk",
              "bgImage": "https://cdn.cerealapis.com/author/a0c334f7-fbb6-426d-88ca-2915f35213e4/upload-1666824047501.png",
              "id": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
              "videoCount": 33,
              "title": "Phoebe Sumner-Twisk"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/e197e149-33e8-4c99-88a0-82cefe1ee88e/upload-1671729090918.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/1f073900-9715-4146-bbe6-4f544d29b3b4/upload-1682804375385.webp",
          "attack": "3",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#C63E2C",
          "rarity": "alternative-art",
          "id": "image__9bb965b7-87e1-41ba-9c72-11b56dff5c6b",
          "tags": [],
          "author": "3067a712-8791-4a4e-a6dd-cc9e4b263e94",
          "lastUpdated": "2023-04-29T21:39:36.386Z",
          "access": "public",
          "slug": "artist-collection-3",
          "createdAt": "2022-11-16T05:03:37.292Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T04:15:00.000Z",
          "subclass1": "eldritch",
          "description": "``` embed\nWhen this Sinder receives 1 or more <span class=\"elestrals-font\">F</span> your opponents must send the top 3 cards from their deck to their Underworld.\n```",
          "excerpt": "AC1-04",
          "title": "Sinder",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4a4adc1b-2836-4e52-86af-50a54227c185/upload-1666824162136.png",
              "category": "Artist",
              "slug": "victor-vcreateart-rosales",
              "bgImage": "https://cdn.cerealapis.com/author/5210982f-5535-48b5-884b-cdfb1d03de56/upload-1666824162133.png",
              "id": "3067a712-8791-4a4e-a6dd-cc9e4b263e94",
              "videoCount": 10,
              "title": "Victor Rosales"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/a4a262b4-0e1a-4993-83c1-44cdba411dd2/upload-1671729107835.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/2a490d8c-6e1e-465b-8b41-8ff608566916/upload-1682804409716.webp",
          "attack": "2",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#6997C4",
          "rarity": "alternative-art",
          "id": "image__b3af6e5f-c865-4294-a661-095d46502689",
          "tags": [],
          "author": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
          "lastUpdated": "2023-04-29T21:40:10.820Z",
          "access": "public",
          "slug": "artist-collection-5",
          "createdAt": "2022-11-16T05:04:54.941Z",
          "defense": "5",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T04:20:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "oceanic",
          "description": "``` embed\nAs long as this Galaxea is Enchanted swap each <span class=\"elestrals-font\">W</span>-Enchanted Elestral’s <span class=\"elestrals-font\">J</span> with its <span class=\"elestrals-font\">O</span>.\n```",
          "excerpt": "AC1-05",
          "title": "Galaxea",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/6810b997-e024-4b4b-b43a-4e8864c1e586/upload-1666822760398.png",
              "category": "Artist",
              "slug": "diego-monster",
              "bgImage": "https://cdn.cerealapis.com/author/45070d0c-4dd8-491c-a56a-8a164afa9400/upload-1666822760394.png",
              "id": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
              "videoCount": 17,
              "title": "Diego Monster "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/0f65dc9b-5ada-4805-986f-0602500f8c6b/upload-1671734776440.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/26476d7c-6315-4c51-8c49-30bcbb825baf/upload-1682804460065.webp",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#E3AB21",
          "rarity": "alternative-art",
          "id": "image__33e05b3f-603c-45bb-94f2-d602c516ce13",
          "tags": [],
          "author": "19eb7b3b-3b39-464e-9a88-427e8f289558",
          "lastUpdated": "2023-04-29T21:41:01.157Z",
          "access": "public",
          "slug": "artist-collection-1",
          "createdAt": "2022-11-16T05:02:02.019Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T04:25:00.000Z",
          "subclass2": "dragon",
          "subclass1": "archaic",
          "description": "``` embed\nThunder Spirit <span class=\"elestrals-font\">T</span>\n```",
          "excerpt": "AC1-06",
          "title": "Zaptor",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/29f7dfd3-2003-4e21-932b-31d442bb668c/upload-1666966185555.png",
              "category": "Artist",
              "slug": "elite4",
              "id": "19eb7b3b-3b39-464e-9a88-427e8f289558",
              "videoCount": 42,
              "title": "Élite4"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/cc8857b2-8288-4605-b991-b60133ad537a/upload-1671729137785.webp",
          "collections": {},
          "type1": "thunder",
          "type2": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/e8ea35ca-e493-435e-a162-c0381f502870/upload-1682804472709.webp",
          "attack": "7",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#E3AB21",
          "rarity": "alternative-art",
          "id": "image__6ebc0615-4c2e-4aec-9890-8b61f66accec",
          "tags": [],
          "author": "8194695f-8d92-491e-b87b-c977a16a317e",
          "lastUpdated": "2023-04-29T21:41:13.728Z",
          "access": "public",
          "slug": "artist-collection-6",
          "createdAt": "2022-11-16T05:05:37.596Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T04:30:00.000Z",
          "subclass2": "behemoth",
          "subclass1": "eldritch",
          "description": "This Sonicore’s Cast cannot be chained by Counter Runes. As long as this Sonicore is in Attack Position players cannot Cast Counter Runes.",
          "excerpt": "AC1-07",
          "title": "Sonicore",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/a7719a4e-ee26-4bf7-8839-2887320a2a0a/upload-1666823882654.png",
              "category": "Artist",
              "slug": "luan-m-resende",
              "bgImage": "https://cdn.cerealapis.com/author/7d33a277-e7a3-423f-8661-3fdc94b69e7d/upload-1666823882721.png",
              "id": "8194695f-8d92-491e-b87b-c977a16a317e",
              "videoCount": 20,
              "title": "Luan M. Resende"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/0567fb74-b08a-4c3d-8da6-a9b8fee307a9/upload-1671729152887.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/09c9cdac-40a2-4299-8fb9-7bf1328d64ae/upload-1682804516241.webp",
          "attack": "4",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#E3AB21",
          "rarity": "alternative-art",
          "id": "image__16455dd9-4cae-45bf-8b4f-25360c7220f3",
          "tags": [],
          "author": "962db316-931a-4cb5-bdd1-9330d8486272",
          "lastUpdated": "2023-04-29T21:41:57.282Z",
          "access": "public",
          "slug": "artist-collection-7",
          "createdAt": "2022-11-16T05:06:31.894Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T04:35:00.000Z",
          "subclass1": "ethereal",
          "description": "When you Normal Cast this Jolten you can Search your deck for a Stadium Rune and add it to your hand.",
          "excerpt": "AC1-08",
          "title": "Jolten",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b314a3d-73f8-4458-b16c-b821c4f915f3/upload-1666823695029.png",
              "category": "Artist",
              "slug": "lucas-kosta-costa",
              "bgImage": "https://cdn.cerealapis.com/author/3ebe38de-02f2-496d-a11f-337103ee7347/upload-1666823695026.png",
              "id": "962db316-931a-4cb5-bdd1-9330d8486272",
              "videoCount": 20,
              "title": "Lucas Costa"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/8eeb11b9-cfd3-472d-9232-488f6548b804/upload-1671729166065.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/e7982ccd-2ae2-4e5f-97d4-496aa58737da/upload-1682804573191.webp",
          "attack": "5",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#ACBCC1",
          "rarity": "alternative-art",
          "id": "image__7f31ed04-e447-4648-9a51-defa87c13fee",
          "tags": [],
          "author": "06f6337e-75f8-4837-a14b-bb73e880382e",
          "lastUpdated": "2023-04-29T21:42:54.057Z",
          "access": "public",
          "slug": "artist-collection-8",
          "createdAt": "2022-11-16T05:09:07.966Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T04:40:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "brute",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">A</span> in order to target and change the Position of an Elestral unless it is <span class=\"elestrals-font\">A</span>-Enchanted.\n```",
          "excerpt": "AC1-09",
          "title": "Soarlet",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/696727cb-6e52-4f00-b2b5-b5ee9b967d3e/upload-1666823423451.png",
              "category": "Artist",
              "slug": "giovanni-n4nin4ni-aguilar",
              "bgImage": "https://cdn.cerealapis.com/author/49850f39-8072-4bd7-8776-15508c33f3d7/upload-1666823423402.png",
              "id": "06f6337e-75f8-4837-a14b-bb73e880382e",
              "videoCount": 53,
              "title": "Giovanni Aguilar"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/a9bb96fe-6861-469a-941c-9176cc311d0d/upload-1671729187227.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/d449f54f-deac-4975-9541-1025ddb027fc/upload-1682804584644.webp",
          "attack": "3",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#ACBCC1",
          "rarity": "alternative-art",
          "id": "image__d686ac57-645c-48db-a6a2-f6c5c687e003",
          "tags": [],
          "author": "5717b454-98e6-4b9c-bad3-fc1bfefa4d26",
          "lastUpdated": "2023-04-29T21:43:05.692Z",
          "access": "public",
          "slug": "artist-collection-9",
          "createdAt": "2022-11-16T05:09:33.868Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T04:45:00.000Z",
          "subclass1": "avian",
          "description": "``` embed\nYou can Nexus up to <span class=\"elestrals-font\">A</span><span class=\"elestrals-font\">A</span>. When you do, an opponent must Expend <span class=\"elestrals-font\">X</span><span class=\"elestrals-font\">X</span>.\n```",
          "excerpt": "AC1-10",
          "title": "Exaltair",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/e0fa7325-1c5d-450c-a14b-e200e191f99b/upload-1666822059355.png",
              "category": "Artist",
              "slug": "francis-adam-adam-fegarido",
              "bgImage": "https://cdn.cerealapis.com/author/adfeb389-b9f3-44c4-8cad-6375cd20b30f/upload-1666822060202.png",
              "id": "5717b454-98e6-4b9c-bad3-fc1bfefa4d26",
              "videoCount": 6,
              "title": "Francis Fegarido "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/e2e92b6b-4aea-4c67-9e5f-56d812b5bd8a/upload-1671729222029.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/3e12d327-ffd6-44dd-8c18-ee112abcd650/upload-1682804654560.webp",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#C63E2C",
          "rarity": "alternative-art",
          "id": "image__22430c88-6aba-419f-a708-22970a4a69b9",
          "featured": false,
          "tags": [],
          "author": "ca4bfd8c-3ead-4ec2-bc37-426c1dca7f8c",
          "lastUpdated": "2023-04-29T21:44:15.610Z",
          "access": "public",
          "slug": "vipyro",
          "createdAt": "2022-11-19T01:43:06.193Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T04:50:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "dragon",
          "description": "``` embed\nFire Spirit <span class=\"elestrals-font\">F</span>\n```",
          "hideCardOnCreator": false,
          "excerpt": "AC1-11",
          "title": "Vipyro",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/978cea0e-1e6e-4304-b95b-830e7208d206/upload-1666822212654.JPG",
              "category": "Artist",
              "slug": "william-bilsu-parks",
              "bgImage": "https://cdn.cerealapis.com/author/461307f5-955b-418f-9a08-155807a74d59/upload-1666822212691.JPG",
              "id": "ca4bfd8c-3ead-4ec2-bc37-426c1dca7f8c",
              "videoCount": 12,
              "title": "William Parks"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/44da5cd8-3e21-4dc9-a4ff-a60e829103a5/upload-1671729233643.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/6487d643-dcd4-4c39-a057-b48612b40a3e/upload-1682804714732.webp",
          "attack": "1",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#6997C4",
          "rarity": "alternative-art",
          "id": "image__22ad8b7f-50e8-457f-99a4-b9682dc57331",
          "tags": [],
          "author": "97b096f2-2e19-4307-b713-e1284d53d95a",
          "lastUpdated": "2023-04-29T21:45:15.708Z",
          "access": "public",
          "slug": "artist-collection-4",
          "createdAt": "2022-11-16T05:04:19.010Z",
          "defense": "6",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T04:55:00.000Z",
          "subclass1": "oceanic",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">W</span> in order to swap this Moustacean’s <span class=\"elestrals-font\">J</span> with its <span class=\"elestrals-font\">O</span> until the End Phase. This effect can also be activated when an opponent Normal Casts an Elestral.\n```",
          "excerpt": "AC1-12",
          "title": "Moustacean",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/467d46d5-e665-454e-a475-c4e5c05d66a9/upload-1668277303015.png",
              "category": "Creator",
              "slug": "yassir",
              "bgImage": "https://cdn.cerealapis.com/author/653d391b-7589-499f-bcc9-9fdd23e54b72/upload-1668277302973.png",
              "id": "97b096f2-2e19-4307-b713-e1284d53d95a",
              "videoCount": 1,
              "title": "Yassir"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/f149c1d3-7f02-4482-bd7a-94d8477a1980/upload-1671729254581.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/6c837542-59ae-4036-8137-f656d04511f3/upload-1682804746079.webp",
          "attack": "2",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#E3AB21",
          "rarity": "alternative-art",
          "id": "image__f40e8164-b797-4bde-90fb-151c9e24cb25",
          "tags": [],
          "author": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
          "lastUpdated": "2023-04-29T21:45:47.198Z",
          "access": "public",
          "slug": "quackle",
          "createdAt": "2022-11-19T01:47:37.279Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T05:00:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "avian",
          "description": "``` embed\nWhen you Cast this Quackle you can return up to <span class=\"elestrals-font\">T</span><span class=\"elestrals-font\">T</span><span class=\"elestrals-font\">T</span> from your Underworld to your Spirit Deck.\n```",
          "hideCardOnCreator": false,
          "excerpt": "AC1-13",
          "title": "Quackle",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/9abbfa57-fadc-42d9-bd52-3907b145d6ff/upload-1662720081319.jpeg",
              "category": "Artist",
              "slug": "jonny-de-oliviera",
              "bgImage": "https://cdn.cerealapis.com/author/d6271a98-e34d-4b1a-843c-45b03f86abb2/upload-1662720081310.jpeg",
              "id": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
              "videoCount": 22,
              "title": "Jonny De Oliviera"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/d31e4b68-3c06-42ec-ad74-cc731f7de184/upload-1671729269719.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/54c8b972-7f59-45e4-8bc1-d8c7282a638a/upload-1682804785450.webp",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#ACBCC1",
          "rarity": "alternative-art",
          "id": "image__65f0404d-3f3c-4eb4-851a-1d04ed21068b",
          "tags": [],
          "author": "36eb909a-5835-4f59-b317-e02082ee59db",
          "lastUpdated": "2023-04-29T21:46:26.528Z",
          "access": "public",
          "slug": "lycarus",
          "createdAt": "2022-11-19T01:46:20.058Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T05:05:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "brute",
          "description": "``` embed\nWind Spirit <span class=\"elestrals-font\">A</span>\n```",
          "hideCardOnCreator": false,
          "excerpt": "AC1-14",
          "title": "Lycarus",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/155a9d71-dff3-4efc-965b-766a55dc62b3/upload-1666824091421.jpg",
              "category": "Artist",
              "slug": "tori",
              "bgImage": "https://cdn.cerealapis.com/author/25fe30f4-7c6c-435a-83e4-8e88e6258fc7/upload-1666824091368.jpg",
              "id": "36eb909a-5835-4f59-b317-e02082ee59db",
              "videoCount": 4,
              "title": "Tori "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/9ab09974-1e30-4352-847d-e1b606cf1ec7/upload-1671729283300.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/52c71465-189e-4785-8532-51f067f93e8a/upload-1682804824974.webp",
          "attack": "3",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#ACBCC1",
          "rarity": "alternative-art",
          "id": "image__3d9f4277-c1e7-4bc1-b181-9482e9499c6d",
          "tags": [],
          "author": "3f76add4-01c2-46e4-9e95-e7dd024030ec",
          "lastUpdated": "2023-04-29T21:47:06.348Z",
          "access": "public",
          "slug": "carryon",
          "createdAt": "2022-11-19T01:50:38.648Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T05:10:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "insectoid",
          "description": "When this Carryon is destroyed in battle you can target the Elestral that destroyed it, then take control of that Elestral and move it to your Elestral Row until the End Phase.",
          "hideCardOnCreator": false,
          "excerpt": "AC1-15",
          "title": "Carryon",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/1d07656d-9d0b-49e1-89d7-d3b871923872/upload-1666823795124.png",
              "category": "Artist",
              "slug": "luis-luisbrain-gonzalez",
              "bgImage": "https://cdn.cerealapis.com/author/f793b36f-a52f-4f33-9c99-c0cd8750cec5/upload-1666823794879.png",
              "id": "3f76add4-01c2-46e4-9e95-e7dd024030ec",
              "videoCount": 3,
              "title": "Luis González"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/5c39bcce-9409-4692-b58f-bff629be5105/upload-1671729296830.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/5876a62c-a1c8-46bd-9426-d357d9d6389e/upload-1682804875111.webp",
          "attack": "3",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#B7C03A",
          "rarity": "alternative-art",
          "id": "image__3ad9546a-b4ac-46d0-8594-69cdb073c4ad",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2023-04-29T21:47:56.130Z",
          "access": "public",
          "slug": "sproutyr",
          "createdAt": "2022-11-23T23:12:13.392Z",
          "defense": "5",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T05:15:00.000Z",
          "subclass1": "dryad",
          "description": "When you Normal Cast this Sproutyr you can Search your deck for an Artifact and add it to your hand.",
          "hideCardOnCreator": false,
          "excerpt": "AC1-16",
          "title": "Sproutyr",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/f2d2c585-7235-497a-a6f6-76ba5683fbf2/upload-1671729310324.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/f978efdb-0bca-48c4-8b4d-5c40962f55ab/upload-1682804907766.webp",
          "attack": "5",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#C63E2C",
          "rarity": "alternative-art",
          "id": "image__2bacef95-973a-4dfa-aafc-7eb535fd4910",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2023-04-29T21:48:29.828Z",
          "access": "public",
          "slug": "necruff",
          "createdAt": "2022-11-23T23:14:42.445Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T05:20:00.000Z",
          "subclass2": "brute",
          "subclass1": "eldritch",
          "description": "``` embed\nWhen this Necruff destroys an opponent’s Elestral in battle that opponent must Expend <span class=\"elestrals-font\">X</span>.\n```",
          "hideCardOnCreator": false,
          "excerpt": "AC1-17",
          "title": "Necruff",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/696a6e65-7bae-4570-b942-0412678a66b7/upload-1671729323682.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/9236ca9a-43fe-4cc8-a1e3-00ee394e2997/upload-1682804970333.webp",
          "attack": "2",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#6997C4",
          "rarity": "alternative-art",
          "id": "image__3faea582-a379-42c0-bce9-14b412495118",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2023-04-29T21:49:31.400Z",
          "access": "public",
          "slug": "sluggle",
          "createdAt": "2022-11-23T23:16:58.191Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T05:25:00.000Z",
          "subclass1": "oceanic",
          "description": "``` embed\nWhen you Cast this Sluggle you can return up to <span class=\"elestrals-font\">W</span><span class=\"elestrals-font\">W</span><span class=\"elestrals-font\">W</span> from your Underworld to your Spirit Deck.\n```",
          "hideCardOnCreator": false,
          "excerpt": "AC1-18",
          "title": "Sluggle",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/0460f121-0235-4607-87d4-e6fe3e9340ac/upload-1671729337685.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/bd77306e-51d2-4b9f-b1af-50fb18dfc7b7/upload-1682805041535.webp",
          "attack": "2",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#E3AB21",
          "rarity": "alternative-art",
          "id": "image__f33f9c0a-6e26-45da-bbf0-24be5480a90e",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2023-04-29T21:50:42.718Z",
          "access": "public",
          "slug": "quackle",
          "createdAt": "2022-11-23T23:18:59.471Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T05:30:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "avian",
          "description": "``` embed\nWhen you Cast this Quackle you can return up to <span class=\"elestrals-font\">T</span><span class=\"elestrals-font\">T</span><span class=\"elestrals-font\">T</span> from your Underworld to your Spirit Deck.\n```",
          "hideCardOnCreator": false,
          "excerpt": "AC1-19",
          "title": "Quackle",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/023675c1-cc75-4684-8c54-087c8b7bd6a8/upload-1671729359927.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/0727d78f-2f6e-4456-a80b-05a28a75f67c/upload-1682805055947.webp",
          "attack": "4",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#ACBCC1",
          "rarity": "alternative-art",
          "id": "image__0dea96a3-57e0-490f-b4bf-104b0551f173",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2023-04-29T21:50:56.986Z",
          "access": "public",
          "slug": "hydrake",
          "createdAt": "2022-11-23T23:20:39.115Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T05:35:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "dragon",
          "description": "When this Hydrake is destroyed in battle you can Special Cast a Twindra from your hand or deck in Attack Position.",
          "hideCardOnCreator": false,
          "excerpt": "AC1-20",
          "title": "Hydrake",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/30b08053-fc14-4748-aaee-e8f45ad267bd/upload-1671729375309.webp",
          "collections": {},
          "type1": "earth",
          "type2": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/3faf7b0d-0da5-4717-974e-aa7b7b945a8e/upload-1682805098728.webp",
          "attack": "6",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#B7C03A",
          "rarity": "alternative-art",
          "id": "image__545f2dd4-0761-4f76-9042-5a2c23834ed3",
          "tags": [],
          "author": "15c176d6-97df-497d-aef3-15f737c972a9",
          "lastUpdated": "2023-04-29T21:51:39.822Z",
          "access": "public",
          "slug": "sakurasaur",
          "createdAt": "2022-11-29T20:38:13.315Z",
          "defense": "8",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T05:40:00.000Z",
          "subclass2": "dryad",
          "subclass1": "archaic",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">E</span> in order to draw 2 cards.\n```",
          "excerpt": "AC1-21",
          "title": "Sakurasaur",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b516689-1021-4652-b56a-bfbef2e05441/upload-1666822702354.png",
              "category": "Artist",
              "slug": "dennis-artworks218-fuhrmann",
              "bgImage": "https://cdn.cerealapis.com/author/90b70451-0371-4db5-beab-5d46f38e07bd/upload-1666822702346.png",
              "id": "15c176d6-97df-497d-aef3-15f737c972a9",
              "videoCount": 22,
              "title": "Dennis Fuhrmann"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/1a2d5bff-032d-410d-8178-608406505af1/upload-1671729389715.webp",
          "collections": {},
          "type1": "earth",
          "type2": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/a35b23ed-cb13-42ff-8902-fe6f064b1d61/upload-1682805163099.webp",
          "attack": "4",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#B7C03A",
          "rarity": "alternative-art",
          "id": "image__24b61d2e-e29a-450c-b822-3f1b4b7ae21e",
          "tags": [],
          "author": "88185685-0ac3-4a6c-b976-0aa8dc3403fe",
          "lastUpdated": "2023-04-29T21:52:44.105Z",
          "access": "public",
          "slug": "scavagem",
          "createdAt": "2022-11-29T20:37:02.170Z",
          "defense": "6",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T05:45:00.000Z",
          "subclass2": "behemoth",
          "subclass1": "golem",
          "description": "``` embed\nWhen you Ascend into this Scavagem you can Expend <span class=\"elestrals-font\">E</span> in order to Search your deck for up to 2 cards with Enchantment Cost <span class=\"elestrals-font\">E</span> or <span class=\"elestrals-font\">E</span><span class=\"elestrals-font\">E</span> and add them to your hand.\n```",
          "excerpt": "AC1-22",
          "title": "Scavagem",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/f881a1cf-d017-4d34-b833-9c5dc9fe7eab/upload-1666823969818.png",
              "category": "Artist",
              "slug": "marcel-bromojumbo-romo",
              "bgImage": "https://cdn.cerealapis.com/author/aa6415a1-f484-4a75-988b-80db7af2fe56/upload-1666823969899.png",
              "id": "88185685-0ac3-4a6c-b976-0aa8dc3403fe",
              "videoCount": 14,
              "title": "Marcel Romo "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/43bd0f1a-002a-4d26-9a78-865ff01b2b22/upload-1671729403163.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/c1e8bba2-85e9-46cc-b82d-5820efc92045/upload-1682805199292.webp",
          "attack": "3",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#B7C03A",
          "rarity": "alternative-art",
          "id": "image__ed6103a6-df69-4657-813d-507561615544",
          "tags": [],
          "author": "ecb243ac-0c18-4061-9598-5a3b3da7e8bf",
          "lastUpdated": "2023-04-29T21:53:20.398Z",
          "access": "public",
          "slug": "tectaurus",
          "createdAt": "2022-11-29T20:35:10.933Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T05:50:00.000Z",
          "subclass2": "behemoth",
          "subclass1": "golem",
          "description": "``` embed\nThis Tectaurus gets +1 <span class=\"elestrals-font\">J</span> for each Enchanting <span class=\"elestrals-font\">E</span> on the field.\n```",
          "excerpt": "AC1-23",
          "title": "Tectaurus",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/afa0ef97-2446-4ba0-86f2-70f380e86363/upload-1666822843305.png",
              "category": "Artist",
              "slug": "dragonith",
              "bgImage": "https://cdn.cerealapis.com/author/69a6a8f4-b3ad-481c-a2a6-dea8e9811f46/upload-1666822843306.png",
              "id": "ecb243ac-0c18-4061-9598-5a3b3da7e8bf",
              "videoCount": 5,
              "title": "Dragonith "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/a141760f-ab1c-4f01-84fe-138e09a652ac/upload-1671729417291.webp",
          "collections": {},
          "type1": "water",
          "type2": "water",
          "bgImage": "https://cdn.cerealapis.com/post/ba602915-0f48-4558-b919-067d805f9531/upload-1682805218662.webp",
          "attack": "5",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#6997C4",
          "rarity": "alternative-art",
          "id": "image__782d98b4-746c-49c8-a1ce-c75ebfab2e3a",
          "tags": [],
          "author": "19eb7b3b-3b39-464e-9a88-427e8f289558",
          "lastUpdated": "2023-04-29T21:53:39.733Z",
          "access": "public",
          "slug": "krakking",
          "createdAt": "2022-11-29T20:40:50.966Z",
          "defense": "8",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T05:55:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "oceanic",
          "description": "When you Cast this Krakking you can target up to 2 unoccupied slots in an opponent’s Elestral Row. Those Elestral slots cannot be used as long as this Krakking is Enchanted.",
          "excerpt": "AC1-24",
          "title": "Krakking",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/29f7dfd3-2003-4e21-932b-31d442bb668c/upload-1666966185555.png",
              "category": "Artist",
              "slug": "elite4",
              "id": "19eb7b3b-3b39-464e-9a88-427e8f289558",
              "videoCount": 42,
              "title": "Élite4"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/9a2853a9-c47c-4f52-99d9-241bbd430e19/upload-1671729434096.webp",
          "collections": {},
          "type1": "thunder",
          "type2": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/31483212-f148-46d2-a083-b20b12eecda8/upload-1682805257879.webp",
          "attack": "6",
          "series": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
          "bgColor": "#E3AB21",
          "rarity": "alternative-art",
          "id": "image__8ed18ec9-c893-4a6e-b9d1-9a6b22f3d1ab",
          "tags": [],
          "author": "7a5cbe37-98d0-46e5-8f84-93398fa88214",
          "lastUpdated": "2023-04-29T21:54:19.419Z",
          "access": "public",
          "slug": "lycavolt",
          "createdAt": "2022-11-29T20:39:24.327Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-07T06:00:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "behemoth",
          "description": "``` embed\nThis Lycavolt gets +2 <span class=\"elestrals-font\">J</span> for each Ampup in your Underworld. When this Lycavolt destroys an opponent’s Elestral in battle you can look at that opponent's hand and discard a card from it.\n```",
          "excerpt": "AC1-25",
          "title": "Lycavolt",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/7250e4f8-a344-4b51-baf2-8f6dd2e3ec58/upload-1668701441480.png",
              "access": "public",
              "category": "Card Set",
              "slug": "artist-collection",
              "bgImage": "https://cdn.cerealapis.com/series/dfd17450-868a-46f2-8745-95c4b9214854/upload-1668701441259.jpg",
              "id": "5a617d42-5057-49ca-8aaa-2d1c92474ff4",
              "videoCount": 25,
              "title": "Artist Collection"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/d54f297d-ac0b-4595-acfb-1d5bb448c7ad/upload-1666824216088.jpg",
              "category": "Artist",
              "slug": "villiam-boom",
              "bgImage": "https://cdn.cerealapis.com/author/3bd2fb84-40fe-4db4-b7ad-7ace9d264ce8/upload-1666824216015.jpg",
              "id": "7a5cbe37-98d0-46e5-8f84-93398fa88214",
              "videoCount": 10,
              "title": "Villiam Boom "
          }
      },
  
      {
          "file": "https://cdn.cerealapis.com/post/ad03320d-b33a-48ed-a07d-dea45a199ba0/upload-1671729461341.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/91c2e04a-6657-4b05-b835-825ffd9e6e7d/upload-1682806503603.webp",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#B7C03A",
          "rarity": "stellar-rare",
          "id": "image__20e238fc-b539-4753-a486-b28e26233112",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2023-04-29T22:15:04.687Z",
          "access": "public",
          "slug": "teratlas",
          "createdAt": "2022-11-15T18:36:26.759Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T04:00:00.000Z",
          "subclass2": "golem",
          "subclass1": "insectoid",
          "description": "``` embed\nEarth Spirit <span class=\"elestrals-font\">E</span>\n```",
          "excerpt": "PR1-01",
          "title": "Teratlas",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/cb99eabe-9116-48f7-bdad-b1bc8592078c/upload-1671729483218.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/3e8df3b6-6293-4470-bc27-729c50cd7c4a/upload-1682806516022.webp",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#C63E2C",
          "rarity": "stellar-rare",
          "id": "image__85f95053-8f89-4a11-9e03-97772f2c6550",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2023-04-29T22:15:17.103Z",
          "access": "public",
          "slug": "vipyro",
          "createdAt": "2022-11-15T18:37:55.753Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T04:05:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "dragon",
          "description": "``` embed\nFire Spirit <span class=\"elestrals-font\">F</span>\n```",
          "excerpt": "PR1-02",
          "title": "Vipyro",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/71999242-3542-4662-a6e5-73caac824213/upload-1671729498391.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/0430ea50-bb94-4b60-8e6c-520f9b5e81bb/upload-1682806270189.webp",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#6997C4",
          "rarity": "stellar-rare",
          "id": "image__b0abd38e-d633-4365-aa1e-7ee2a498aded",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2023-04-29T22:11:11.273Z",
          "access": "public",
          "slug": "leviaphin",
          "createdAt": "2022-11-15T18:39:50.158Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T04:10:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "oceanic",
          "description": "``` embed\nWater Spirit <span class=\"elestrals-font\">W</span>\n```",
          "excerpt": "PR1-03",
          "title": "Leviaphin",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/38e81136-0a6a-48db-8743-2d6a68819ead/upload-1671729514889.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/b25359bf-d01c-4909-9ff6-0717feddae7f/upload-1682806544299.webp",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#E3AB21",
          "rarity": "stellar-rare",
          "id": "image__5ea64820-2d61-424b-a18c-4ae5cf2e9f1d",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2023-04-29T22:15:45.838Z",
          "access": "public",
          "slug": "zaptor",
          "createdAt": "2022-11-15T18:41:26.775Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T04:15:00.000Z",
          "subclass2": "dragon",
          "subclass1": "archaic",
          "description": "``` embed\nThunder Spirit <span class=\"elestrals-font\">T</span>\n```",
          "excerpt": "PR1-04",
          "title": "Zaptor",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/042ac960-5614-4b66-9f25-6d871d9e9d5a/upload-1671729529718.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/796e2056-5abe-4d58-a2fc-23e1aacc7e1a/upload-1682806323005.webp",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#ACBCC1",
          "rarity": "stellar-rare",
          "id": "image__ad268812-020d-4ee4-b902-beb6d281ddf5",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2023-04-29T22:12:04.130Z",
          "access": "public",
          "slug": "lycarus",
          "createdAt": "2022-11-15T18:42:37.820Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T04:20:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "brute",
          "description": "``` embed\nWind Spirit <span class=\"elestrals-font\">A</span>\n```",
          "excerpt": "PR1-05",
          "title": "Lycarus",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/668b3a59-32a9-4a19-9e01-69b9ddd40f89/upload-1671729708622.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/7befc528-7bc7-46dc-98af-ee1c52965f3f/upload-1682804444393.webp",
          "attack": "1",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#B7C03A",
          "rarity": "stellar-rare",
          "id": "image__ad3a701d-3b18-45af-a562-1f0db264053d",
          "tags": [],
          "author": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
          "lastUpdated": "2023-04-29T21:40:45.641Z",
          "access": "public",
          "slug": "pandicine",
          "createdAt": "2022-11-15T21:40:15.242Z",
          "defense": "4",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T04:25:00.000Z",
          "subclass1": "brute",
          "description": "``` embed\nYou can Disenchant <span class=\"elestrals-font\">E</span> from this Pandicine in order to return an Invoke Rune from your Underworld to your hand.\n```",
          "excerpt": "PR1-06",
          "title": "Pandicine",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/9abbfa57-fadc-42d9-bd52-3907b145d6ff/upload-1662720081319.jpeg",
              "category": "Artist",
              "slug": "jonny-de-oliviera",
              "bgImage": "https://cdn.cerealapis.com/author/d6271a98-e34d-4b1a-843c-45b03f86abb2/upload-1662720081310.jpeg",
              "id": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
              "videoCount": 22,
              "title": "Jonny De Oliviera"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/d722af16-6c21-4d21-837c-eea32ce0b521/upload-1671729748456.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/128f5dee-4972-4700-b9f1-3753effdfcbf/upload-1682804491185.webp",
          "attack": "4",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#E3AB21",
          "rarity": "stellar-rare",
          "id": "image__34f35b2e-f848-4c89-8b9d-e21ff8feb546",
          "tags": [],
          "author": "3067a712-8791-4a4e-a6dd-cc9e4b263e94",
          "lastUpdated": "2023-04-29T21:41:32.245Z",
          "access": "public",
          "slug": "sparkitt",
          "createdAt": "2022-11-17T20:33:48.312Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T04:30:00.000Z",
          "subclass2": "archaic",
          "subclass1": "brute",
          "description": "``` embed\nYou can Nexus up to <span class=\"elestrals-font\">T</span><span class=\"elestrals-font\">T</span>. When you do you can look at an opponent’s hand and discard a card.\n```",
          "excerpt": "PR1-07",
          "title": "Sparkitt",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4a4adc1b-2836-4e52-86af-50a54227c185/upload-1666824162136.png",
              "category": "Artist",
              "slug": "victor-vcreateart-rosales",
              "bgImage": "https://cdn.cerealapis.com/author/5210982f-5535-48b5-884b-cdfb1d03de56/upload-1666824162133.png",
              "id": "3067a712-8791-4a4e-a6dd-cc9e4b263e94",
              "videoCount": 10,
              "title": "Victor Rosales"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/5a23006f-fa05-45b1-bfb3-2d023ca804cc/upload-1671729780455.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/dda6de64-645f-413d-8a46-d7bb86820f80/upload-1682804539418.webp",
          "attack": "4",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#B7C03A",
          "rarity": "full-art",
          "id": "image__59fa498d-637e-48df-9e66-eb2595e878a4",
          "tags": [],
          "author": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
          "lastUpdated": "2023-04-29T21:42:20.420Z",
          "access": "public",
          "slug": "vysceris",
          "createdAt": "2022-11-19T01:33:28.703Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T04:35:00.000Z",
          "subclass1": "insectoid",
          "description": "``` embed\nThis Vysceris gains +1 <span class=\"elestrals-font\">J</span> for each Vysceris on the field. When this Vysceris destroys an Elestral in battle you can Special Cast a Vysceris from your hand or deck in Defense Position.\n```",
          "hideCardOnCreator": false,
          "excerpt": "PR1-08",
          "title": "Vysceris",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/6810b997-e024-4b4b-b43a-4e8864c1e586/upload-1666822760398.png",
              "category": "Artist",
              "slug": "diego-monster",
              "bgImage": "https://cdn.cerealapis.com/author/45070d0c-4dd8-491c-a56a-8a164afa9400/upload-1666822760394.png",
              "id": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
              "videoCount": 17,
              "title": "Diego Monster "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/8a0477a5-2611-427e-8aa5-1a9c43cfff29/upload-1671729861123.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/3bd769ef-6bd1-42b2-9b3d-c088772118b4/upload-1682804561338.webp",
          "attack": "1",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#B7C03A",
          "rarity": "full-art",
          "id": "image__a7555f47-2019-4a4e-ba68-c1ffc4f01c7c",
          "tags": [],
          "author": "06f6337e-75f8-4837-a14b-bb73e880382e",
          "lastUpdated": "2023-04-29T21:42:42.539Z",
          "access": "public",
          "slug": "pandicine",
          "createdAt": "2022-11-22T08:39:44.851Z",
          "defense": "4",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T04:40:00.000Z",
          "subclass1": "brute",
          "description": "``` embed\nYou can Disenchant <span class=\"elestrals-font\">E</span> from this Pandicine in order to return an Invoke Rune from your Underworld to your hand.\n```",
          "excerpt": "PR1-09",
          "title": "Pandicine",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/696727cb-6e52-4f00-b2b5-b5ee9b967d3e/upload-1666823423451.png",
              "category": "Artist",
              "slug": "giovanni-n4nin4ni-aguilar",
              "bgImage": "https://cdn.cerealapis.com/author/49850f39-8072-4bd7-8776-15508c33f3d7/upload-1666823423402.png",
              "id": "06f6337e-75f8-4837-a14b-bb73e880382e",
              "videoCount": 53,
              "title": "Giovanni Aguilar"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/b139591f-94a8-468d-9023-f926e3b9a03a/upload-1671729891330.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/3ec25ea1-266c-4ac4-9bd6-1503daf8571f/upload-1682804596678.webp",
          "attack": "5",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#ACBCC1",
          "rarity": "stellar-rare",
          "id": "image__c39bd6fa-17b0-43e5-b51e-477613b94190",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2023-04-29T21:43:17.920Z",
          "access": "public",
          "slug": "soarlet",
          "createdAt": "2022-11-23T23:03:50.024Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T04:45:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "brute",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">A</span> in order to target and change the Position of an Elestral unless it is <span class=\"elestrals-font\">A</span>-Enchanted.\n```",
          "excerpt": "PR1-10",
          "title": "Soarlet",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/69b871df-1bfa-4301-a743-b367c4c19d41/upload-1671729954250.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/028f6212-5f02-4137-9824-636a516bda05/upload-1682804641610.webp",
          "attack": "2",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#E3AB21",
          "rarity": "stellar-rare",
          "id": "image__56178d5a-5475-48a4-8fbe-f5bdf2a62d5c",
          "tags": [],
          "author": "06f6337e-75f8-4837-a14b-bb73e880382e",
          "lastUpdated": "2023-04-29T21:44:03.037Z",
          "access": "public",
          "slug": "astrabbit",
          "createdAt": "2022-11-23T23:06:55.169Z",
          "defense": "5",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T04:50:00.000Z",
          "subclass1": "ethereal",
          "description": "``` embed\nWhen this Astrabbit receives 1 or more <span class=\"elestrals-font\">T</span> you can look at the top 3 cards of your deck, then add 1 of them to your hand and shuffle the rest into your deck.\n```",
          "excerpt": "PR1-11",
          "title": "Astrabbit",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/696727cb-6e52-4f00-b2b5-b5ee9b967d3e/upload-1666823423451.png",
              "category": "Artist",
              "slug": "giovanni-n4nin4ni-aguilar",
              "bgImage": "https://cdn.cerealapis.com/author/49850f39-8072-4bd7-8776-15508c33f3d7/upload-1666823423402.png",
              "id": "06f6337e-75f8-4837-a14b-bb73e880382e",
              "videoCount": 53,
              "title": "Giovanni Aguilar"
          }
      },
      {
          "lastUpdated": "2023-04-29T21:44:54.558Z",
          "file": "https://cdn.cerealapis.com/post/346c4edb-28f4-4964-aa75-02be3b4a0a56/upload-1671729987563.webp",
          "collections": {},
          "type1": "rainbow",
          "access": "public",
          "slug": "circle-the-sky",
          "createdAt": "2022-11-26T13:32:25.699Z",
          "bgImage": "https://cdn.cerealapis.com/post/f3532930-94cb-4953-86b6-54d7968e8bd0/upload-1682804693498.webp",
          "postStatus": "published",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T04:55:00.000Z",
          "rarity": "alternative-art",
          "runeType": "invoke",
          "description": "``` embed\nNexus up to <span class=\"elestrals-font\">X</span><span class=\"elestrals-font\">X</span>. If you do, you can draw a card.\n```",
          "hideCardOnCreator": false,
          "id": "image__2303d0b6-6c30-49e9-81f5-19cb715a06b1",
          "excerpt": "PR1-12",
          "tags": [],
          "author": "06f6337e-75f8-4837-a14b-bb73e880382e",
          "title": "Circle the Sky",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/696727cb-6e52-4f00-b2b5-b5ee9b967d3e/upload-1666823423451.png",
              "category": "Artist",
              "slug": "giovanni-n4nin4ni-aguilar",
              "bgImage": "https://cdn.cerealapis.com/author/49850f39-8072-4bd7-8776-15508c33f3d7/upload-1666823423402.png",
              "id": "06f6337e-75f8-4837-a14b-bb73e880382e",
              "videoCount": 53,
              "title": "Giovanni Aguilar"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/143f0f0d-ced9-4b1b-b1b4-888d800b4ec7/upload-1671730026097.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/266da375-9722-459e-95a0-0e4a119c34df/upload-1682804761720.webp",
          "attack": "3",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#C63E2C",
          "rarity": "stellar-rare",
          "id": "image__1bd5e446-f86d-445f-82d0-60a9a37fd2c3",
          "tags": [],
          "author": "ca4bfd8c-3ead-4ec2-bc37-426c1dca7f8c",
          "lastUpdated": "2023-04-29T21:46:02.959Z",
          "access": "public",
          "slug": "warmite",
          "createdAt": "2022-11-23T23:37:22.969Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T05:00:00.000Z",
          "subclass1": "insectoid",
          "description": "``` embed\nYou can target an opponent’s Elestral and give it -4 <span class=\"elestrals-font\">J</span> until the End Phase.\n```",
          "hideCardOnCreator": true,
          "excerpt": "PR1-13",
          "title": "Warmite",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/978cea0e-1e6e-4304-b95b-830e7208d206/upload-1666822212654.JPG",
              "category": "Artist",
              "slug": "william-bilsu-parks",
              "bgImage": "https://cdn.cerealapis.com/author/461307f5-955b-418f-9a08-155807a74d59/upload-1666822212691.JPG",
              "id": "ca4bfd8c-3ead-4ec2-bc37-426c1dca7f8c",
              "videoCount": 12,
              "title": "William Parks"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/8cec567f-c911-4406-a990-15ea96721273/upload-1671730043436.webp",
          "collections": {},
          "type1": "thunder",
          "type2": "thunder",
          "type3": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/9657af4c-f63b-4bcc-a792-ee93238b4cbd/upload-1682804808142.webp",
          "attack": "13",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#E3AB21",
          "rarity": "full-art",
          "id": "image__c85d70b9-323e-4acb-aa63-36d9dee7361d",
          "tags": [],
          "author": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
          "lastUpdated": "2023-04-29T21:46:49.216Z",
          "access": "public",
          "slug": "voltempest",
          "createdAt": "2022-11-26T23:49:49.842Z",
          "defense": "10",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T05:05:00.000Z",
          "subclass2": "behemoth",
          "subclass1": "avian",
          "description": "``` embed\nYou can Disenchant <span class=\"elestrals-font\">T</span> or <span class=\"elestrals-font\">A</span> from this Voltempest to force an opponent to Expend <span class=\"elestrals-font\">X</span><span class=\"elestrals-font\">X</span><span class=\"elestrals-font\">X</span>. As long as Zeus is Enchanted this Voltempest cannot be targeted by an opponent’s card effect.\n```",
          "excerpt": "PR1-14",
          "title": "Voltempest",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/4c88d483-9e81-4854-942f-97df6f95299e/upload-1666824047475.png",
              "category": "Artist",
              "slug": "phoebe-the-last-shaymin-sumner-twisk",
              "bgImage": "https://cdn.cerealapis.com/author/a0c334f7-fbb6-426d-88ca-2915f35213e4/upload-1666824047501.png",
              "id": "e8556b02-ebce-471e-afae-5ec9cab2f2cd",
              "videoCount": 33,
              "title": "Phoebe Sumner-Twisk"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/f0600cd2-3db3-46ac-9ed2-dc870ed54de4/upload-1671730065028.webp",
          "collections": {},
          "type1": "water",
          "type2": "water",
          "bgImage": "https://cdn.cerealapis.com/post/0ee00d21-63da-4b6b-a9c4-805f9c9ecda5/upload-1682804856831.webp",
          "attack": "5",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#6997C4",
          "rarity": "full-art",
          "id": "image__7c3c3a21-44b2-4e9f-a39c-583e8a6d9add",
          "tags": [],
          "author": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
          "lastUpdated": "2023-04-29T21:47:37.970Z",
          "access": "public",
          "slug": "krakking",
          "createdAt": "2022-11-26T23:47:48.590Z",
          "defense": "8",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T05:10:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "oceanic",
          "description": "When you Cast this Krakking you can target up to 2 unoccupied slots in an opponent’s Elestral Row. Those Elestral slots cannot be used as long as this Krakking is Enchanted.",
          "excerpt": "PR1-15",
          "title": "Krakking",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/6810b997-e024-4b4b-b43a-4e8864c1e586/upload-1666822760398.png",
              "category": "Artist",
              "slug": "diego-monster",
              "bgImage": "https://cdn.cerealapis.com/author/45070d0c-4dd8-491c-a56a-8a164afa9400/upload-1666822760394.png",
              "id": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
              "videoCount": 17,
              "title": "Diego Monster "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/2a9722b1-cf62-432d-8e8b-57f8cc142246/upload-1671730098887.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/84ed8d79-eb2f-4cbb-88ee-3c3bc3e1b618/upload-1682804887524.webp",
          "attack": "2",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#E3AB21",
          "rarity": "stellar-rare",
          "id": "image__fb9bf628-3576-4b72-b164-f63807eb21ab",
          "tags": [],
          "author": "962db316-931a-4cb5-bdd1-9330d8486272",
          "lastUpdated": "2023-04-29T21:48:08.702Z",
          "access": "public",
          "slug": "raiceros",
          "createdAt": "2022-11-24T19:18:09.854Z",
          "defense": "4",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T05:15:00.000Z",
          "subclass1": "brute",
          "description": "``` embed\nAs long as this Raiceros is Enchanted Elestrals cannot attack the turn they are Cast unless they are <span class=\"elestrals-font\">T</span>-Enchanted.\n```",
          "hideCardOnCreator": false,
          "excerpt": "PR1-16",
          "title": "Raiceros",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b314a3d-73f8-4458-b16c-b821c4f915f3/upload-1666823695029.png",
              "category": "Artist",
              "slug": "lucas-kosta-costa",
              "bgImage": "https://cdn.cerealapis.com/author/3ebe38de-02f2-496d-a11f-337103ee7347/upload-1666823695026.png",
              "id": "962db316-931a-4cb5-bdd1-9330d8486272",
              "videoCount": 20,
              "title": "Lucas Costa"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/e725a034-093f-4c11-a7fd-2aa3fcf1861c/upload-1671730121713.webp",
          "collections": {},
          "type1": "thunder",
          "bgImage": "https://cdn.cerealapis.com/post/5f1908ca-1e07-4f14-bb27-7573eaafad4f/upload-1682804929390.webp",
          "attack": "2",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#E3AB21",
          "rarity": "stellar-rare",
          "id": "image__a4171323-b7a1-487e-af5a-ac343361c5e1",
          "tags": [],
          "author": "8194695f-8d92-491e-b87b-c977a16a317e",
          "lastUpdated": "2023-04-29T21:48:50.828Z",
          "access": "public",
          "slug": "boombatt",
          "createdAt": "2022-12-11T22:13:47.664Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T05:20:00.000Z",
          "subclass1": "eldritch",
          "description": "``` embed\nYou can Disenchant <span class=\"elestrals-font\">T</span> from this Boombatt in order to target and destroy an opponent’s Elestral.\n```",
          "excerpt": "PR1-17",
          "title": "Boombatt",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/a7719a4e-ee26-4bf7-8839-2887320a2a0a/upload-1666823882654.png",
              "category": "Artist",
              "slug": "luan-m-resende",
              "bgImage": "https://cdn.cerealapis.com/author/7d33a277-e7a3-423f-8661-3fdc94b69e7d/upload-1666823882721.png",
              "id": "8194695f-8d92-491e-b87b-c977a16a317e",
              "videoCount": 20,
              "title": "Luan M. Resende"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/88e4d6a5-2a81-474b-831e-e8f3021fc2d6/upload-1671730144807.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/00db0bde-8490-4131-bcae-90711df443a5/upload-1682804996498.webp",
          "attack": "2",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#B7C03A",
          "rarity": "stellar-rare",
          "id": "image__798ea33f-5828-4eef-9d53-adcaecd51564",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2023-04-29T21:49:57.645Z",
          "access": "public",
          "slug": "drataya",
          "createdAt": "2022-11-26T23:56:41.030Z",
          "defense": "4",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T05:25:00.000Z",
          "subclass2": "dragon",
          "subclass1": "dryad",
          "description": "When a player Casts Ambrosia you can draw 2 cards.",
          "hideCardOnCreator": true,
          "excerpt": "PR1-18",
          "title": "Drataya",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/416b7de7-572d-4fa0-90c5-c36a1092952d/upload-1671730165509.webp",
          "collections": {},
          "type1": "wind",
          "type2": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/0a325d63-e8fa-4a0e-9961-777aa1f714b0/upload-1682805026361.webp",
          "attack": "7",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#ACBCC1",
          "rarity": "full-art",
          "id": "image__7202ae2a-8890-49bc-a146-bb6755d1e9aa",
          "tags": [],
          "author": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
          "lastUpdated": "2023-04-29T21:50:27.559Z",
          "access": "public",
          "slug": "glydesdale",
          "createdAt": "2022-11-27T00:03:38.352Z",
          "defense": "5",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T05:30:00.000Z",
          "subclass1": "ethereal",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">A</span> in order to give this Glydesdale +5 <span class=\"elestrals-font\">J</span> until the End Phase. As long as this Glydesdale is Empowered by Aeromare it can attack twice each Battle Phase.\n```",
          "hideCardOnCreator": true,
          "excerpt": "PR1-19",
          "sentEmail": true,
          "title": "Glydesdale",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/6810b997-e024-4b4b-b43a-4e8864c1e586/upload-1666822760398.png",
              "category": "Artist",
              "slug": "diego-monster",
              "bgImage": "https://cdn.cerealapis.com/author/45070d0c-4dd8-491c-a56a-8a164afa9400/upload-1666822760394.png",
              "id": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
              "videoCount": 17,
              "title": "Diego Monster "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/006df18f-0fda-48fe-ba0f-69f34c3fdd4a/upload-1671730184518.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/f8ccf921-b425-4394-97e7-18881cf40a01/upload-1682805080673.webp",
          "attack": "2",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#6997C4",
          "rarity": "stellar-rare",
          "id": "image__078488e9-7ad8-4e58-8b89-fad758a3d7d6",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2023-04-29T21:51:22.089Z",
          "access": "public",
          "slug": "foamee",
          "createdAt": "2022-11-27T00:02:21.938Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T05:35:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "oceanic",
          "description": "``` embed\nWhen this Foamee is destroyed in battle you can Special Cast an Elestral with Enchantment Cost <span class=\"elestrals-font\">W</span> from your deck in Defense Position.\n```",
          "hideCardOnCreator": false,
          "excerpt": "PR1-20",
          "title": "Foamee",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/c0bc1007-80dd-422a-b584-4808301d0185/upload-1671730202284.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/7433e9c0-9e32-41b9-afa8-182cd261622c/upload-1682805116879.webp",
          "attack": "1",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#6997C4",
          "rarity": "stellar-rare",
          "id": "image__c018fa50-2a52-4b41-8e8b-2c9264b44948",
          "tags": [],
          "author": "ca4bfd8c-3ead-4ec2-bc37-426c1dca7f8c",
          "lastUpdated": "2023-04-29T21:51:58.081Z",
          "access": "public",
          "slug": "tadpuff",
          "createdAt": "2022-12-01T13:53:57.228Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T05:40:00.000Z",
          "subclass1": "aquatic",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">W</span> in order to Special Cast this Tadpuff from your hand in Attack Position with <span class=\"elestrals-font\">W</span>.\n```",
          "excerpt": "PR1-21",
          "title": "Tadpuff",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/978cea0e-1e6e-4304-b95b-830e7208d206/upload-1666822212654.JPG",
              "category": "Artist",
              "slug": "william-bilsu-parks",
              "bgImage": "https://cdn.cerealapis.com/author/461307f5-955b-418f-9a08-155807a74d59/upload-1666822212691.JPG",
              "id": "ca4bfd8c-3ead-4ec2-bc37-426c1dca7f8c",
              "videoCount": 12,
              "title": "William Parks"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/a1eaeecd-f0f2-4750-9902-ab6e23c81df2/upload-1671730221110.webp",
          "collections": {},
          "type1": "frost",
          "bgImage": "https://cdn.cerealapis.com/post/8a671d6c-bc14-49c2-8eef-1677c86d6666/upload-1682805144764.webp",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#CBC8DE",
          "rarity": "common",
          "id": "image__a52e1d44-964d-4879-be71-5d7e13c116c8",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2023-04-29T21:52:26.121Z",
          "access": "public",
          "slug": "flurrmine",
          "createdAt": "2022-12-01T19:04:58.084Z",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T05:45:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "brute",
          "description": "``` embed\nFrost Spirit <span class=\"elestrals-font\">I</span>\n```\n<br>\n<br>\n",
          "excerpt": "PR1-22",
          "title": "Flurrmine",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/a4a325a0-0b54-4083-9f07-1143d5cad73a/upload-1671730238877.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/4b040958-ce65-4555-b1f9-924a30c7bc0f/upload-1682805184335.webp",
          "attack": "3",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#C63E2C",
          "rarity": "stellar-rare",
          "id": "image__41e25a82-59f4-4055-9ef1-98c4f570def7",
          "tags": [],
          "author": "36eb909a-5835-4f59-b317-e02082ee59db",
          "lastUpdated": "2023-04-29T21:53:06.016Z",
          "access": "public",
          "slug": "kindleo",
          "createdAt": "2022-12-07T09:29:04.014Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T05:50:00.000Z",
          "subclass2": "ethereal",
          "subclass1": "brute",
          "description": "``` embed\nYou can Nexus up to <span class=\"elestrals-font\">F</span><span class=\"elestrals-font\">F</span>. When you do, you can target and destroy an opponent’s Elestral.\n```",
          "excerpt": "PR1-23",
          "title": "Kindleo",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/155a9d71-dff3-4efc-965b-766a55dc62b3/upload-1666824091421.jpg",
              "category": "Artist",
              "slug": "tori",
              "bgImage": "https://cdn.cerealapis.com/author/25fe30f4-7c6c-435a-83e4-8e88e6258fc7/upload-1666824091368.jpg",
              "id": "36eb909a-5835-4f59-b317-e02082ee59db",
              "videoCount": 4,
              "title": "Tori "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/2867de3f-6eab-4e0e-992c-22873746d7c0/upload-1671730907291.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/97cc7e38-222c-4c25-817b-42741d0bdaaf/upload-1682805236765.webp",
          "attack": "2",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#B7C03A",
          "rarity": "stellar-rare",
          "id": "image__0f42a939-1a96-45ef-aeb0-796da5a495c0",
          "tags": [],
          "author": "06f6337e-75f8-4837-a14b-bb73e880382e",
          "lastUpdated": "2023-04-29T21:53:58.210Z",
          "access": "public",
          "slug": "rummagem",
          "createdAt": "2022-12-22T17:41:47.864Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T05:55:00.000Z",
          "subclass2": "brute",
          "subclass1": "golem",
          "description": "``` embed\nWhen you Normal Cast this Rummagem you can Search your deck for an Elestral with Enchantment Cost <span class=\"elestrals-font\">E</span> and add it to your hand.\n```",
          "excerpt": "PR1-24",
          "title": "Rummagem",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/696727cb-6e52-4f00-b2b5-b5ee9b967d3e/upload-1666823423451.png",
              "category": "Artist",
              "slug": "giovanni-n4nin4ni-aguilar",
              "bgImage": "https://cdn.cerealapis.com/author/49850f39-8072-4bd7-8776-15508c33f3d7/upload-1666823423402.png",
              "id": "06f6337e-75f8-4837-a14b-bb73e880382e",
              "videoCount": 53,
              "title": "Giovanni Aguilar"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/6c566791-e649-49c7-a78b-53cf3fa462a0/upload-1671731030085.webp",
          "collections": {},
          "type1": "water",
          "bgImage": "https://cdn.cerealapis.com/post/2332113d-7c95-4731-ae1b-410902d1240c/upload-1682805274747.webp",
          "attack": "3",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#6997C4",
          "rarity": "stellar-rare",
          "id": "image__aa7d377d-7fb4-4f9f-86ca-32d99e35e5c6",
          "tags": [],
          "author": "8194695f-8d92-491e-b87b-c977a16a317e",
          "lastUpdated": "2023-04-29T21:54:36.214Z",
          "access": "public",
          "slug": "verutaqua",
          "createdAt": "2022-12-22T17:43:50.789Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T06:00:00.000Z",
          "subclass1": "oceanic",
          "description": "When this Verutaqua enters battle with a Defense Position Elestral you can negate that Elestral’s effect and destroy it. If you do this Verutaqua deals no damage this battle.",
          "excerpt": "PR1-25",
          "title": "Verutaqua",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/a7719a4e-ee26-4bf7-8839-2887320a2a0a/upload-1666823882654.png",
              "category": "Artist",
              "slug": "luan-m-resende",
              "bgImage": "https://cdn.cerealapis.com/author/7d33a277-e7a3-423f-8661-3fdc94b69e7d/upload-1666823882721.png",
              "id": "8194695f-8d92-491e-b87b-c977a16a317e",
              "videoCount": 20,
              "title": "Luan M. Resende"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/1e16c41e-3c5b-474e-95b0-39c5a7406a1d/upload-1671731119371.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/f52eabe2-c2c3-44f4-a698-d7f6087d9064/upload-1682805288570.webp",
          "attack": "1",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#C63E2C",
          "rarity": "stellar-rare",
          "id": "image__175a5d5b-0ebc-4bd8-935f-332c75af1cb8",
          "tags": [],
          "author": "962db316-931a-4cb5-bdd1-9330d8486272",
          "lastUpdated": "2023-04-29T21:54:49.756Z",
          "access": "public",
          "slug": "ignectar",
          "createdAt": "2022-12-22T17:45:19.990Z",
          "defense": "2",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T06:05:00.000Z",
          "subclass1": "insectoid",
          "description": "``` embed\nWhen you Normal Cast this Ignectar you can Special Cast up to 2 Ignectar from your hand or deck in Attack Position with <span class=\"elestrals-font\">F</span>. If you do you must skip your next Battle Phase.\n```",
          "excerpt": "PR1-26",
          "title": "Ignectar",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/3b314a3d-73f8-4458-b16c-b821c4f915f3/upload-1666823695029.png",
              "category": "Artist",
              "slug": "lucas-kosta-costa",
              "bgImage": "https://cdn.cerealapis.com/author/3ebe38de-02f2-496d-a11f-337103ee7347/upload-1666823695026.png",
              "id": "962db316-931a-4cb5-bdd1-9330d8486272",
              "videoCount": 20,
              "title": "Lucas Costa"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/0e06a511-9851-404b-9a7c-88dac739fce8/upload-1671731209631.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/295c85cd-f5bc-49ac-9cb8-4a0022c36c2b/upload-1685717315188.webp",
          "attack": "1",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#C63E2C",
          "rarity": "stellar-rare",
          "id": "image__faa9fd2b-8221-4f31-a6b8-795a52b8124d",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2023-06-02T14:48:37.108Z",
          "access": "public",
          "slug": "urscout",
          "createdAt": "2022-12-22T17:46:50.270Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T06:10:00.000Z",
          "description": "When this Urscout is destroyed in battle can Special Cast an [URSA] that costs 1 Spirit from your hand or deck.",
          "excerpt": "PR1-27",
          "title": "Urscout",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/210ea550-a195-488e-bc9f-f630cff0b8b2/upload-1671731286487.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/f854dd28-d05f-4060-bb3c-b7cd0945b676/upload-1685717334635.webp",
          "attack": "2",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#C63E2C",
          "rarity": "stellar-rare",
          "id": "image__eaf40720-3127-4df7-912f-103e90be7c66",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2023-06-02T14:48:56.117Z",
          "access": "public",
          "slug": "ursmog",
          "createdAt": "2022-12-22T17:48:07.067Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T06:15:00.000Z",
          "description": "``` embed\nYou can Expend <span class=\"elestrals-font\">F</span> in order to Special Cast an [URSA] Elestral that costs 1 Spirit from your hand.\n```",
          "excerpt": "PR1-28",
          "title": "Ursmog",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/b0539038-3fd3-42e5-88b6-4ff871ac8f90/upload-1671731369244.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/54d63c86-3658-48a1-9e4f-d05f4564ff74/upload-1685717353401.webp",
          "attack": "3",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#C63E2C",
          "rarity": "stellar-rare",
          "id": "image__692b3db0-5a29-4368-ae7b-ecdc6edd745f",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2023-06-02T14:49:14.657Z",
          "access": "public",
          "slug": "ursear",
          "createdAt": "2022-12-22T17:49:29.830Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T06:20:00.000Z",
          "description": "When this Ursear destroys an Elestral in battle you can Special Cast an [URSA] Elestral that costs 1 Spirit from your hand or deck.",
          "excerpt": "PR1-29",
          "title": "Ursear",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/d0aac848-d9b5-4a84-9306-1967c56aa81a/upload-1671731506314.webp",
          "collections": {},
          "type1": "fire",
          "bgImage": "https://cdn.cerealapis.com/post/2de88158-7119-4306-a5ab-116c838d2ab7/upload-1685717391189.webp",
          "attack": "12",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#C63E2C",
          "rarity": "full-art",
          "id": "image__428484d7-36dc-4b88-8399-ab537749db9a",
          "tags": [],
          "author": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
          "lastUpdated": "2023-06-02T14:49:52.230Z",
          "access": "public",
          "slug": "majursa",
          "createdAt": "2022-12-22T17:51:46.915Z",
          "defense": "12",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T06:25:00.000Z",
          "description": "``` embed\nThis Majursa gets +2 <span class=\"elestrals-font\">J</span> for each other [URSA] Elestral on your field. Your [URSA] Elestrals that cost 1 Spirit cannot be attacked and cannot be targeted by an opponent’s card effect.\n```",
          "excerpt": "PR1-30",
          "title": "Majursa",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/6810b997-e024-4b4b-b43a-4e8864c1e586/upload-1666822760398.png",
              "category": "Artist",
              "slug": "diego-monster",
              "bgImage": "https://cdn.cerealapis.com/author/45070d0c-4dd8-491c-a56a-8a164afa9400/upload-1666822760394.png",
              "id": "978a4bc4-bf04-4d33-af63-fbc60b5c78b8",
              "videoCount": 17,
              "title": "Diego Monster "
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/c901a033-8a19-45ea-bd0f-4f0df08397cb/upload-1671731595850.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/68c13b44-0b96-41c8-bd04-b35c0457309a/upload-1682805373721.webp",
          "attack": "4",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#ACBCC1",
          "rarity": "alternative-art",
          "id": "image__415071d9-8970-4364-a68d-5fbcaa0182d5",
          "tags": [],
          "author": "8921dd7f-10ad-4640-aae1-172e76d64974",
          "lastUpdated": "2023-04-29T21:56:14.906Z",
          "access": "public",
          "slug": "hydrake",
          "createdAt": "2022-12-22T17:53:16.534Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T06:30:00.000Z",
          "subclass2": "eldritch",
          "subclass1": "dragon",
          "description": "When this Hydrake is destroyed in battle you can Special Cast a Twindra from your hand or deck in Attack Position.",
          "excerpt": "PR1-31",
          "title": "Hydrake",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/00a0cc24-5cf5-44ec-a7c8-6765aa9f51f1/upload-1666822612816.png",
              "slug": "daniel-mosby-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/e4a2eca0-b137-4fca-9177-c7d65e0d1114/upload-1666822612803.png",
              "id": "8921dd7f-10ad-4640-aae1-172e76d64974",
              "videoCount": 58,
              "title": "Daniel Mosby"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/8d45ee54-fbe3-43b0-bbf5-5fb41fc26139/upload-1671731738828.webp",
          "collections": {},
          "type1": "frost",
          "bgImage": "https://cdn.cerealapis.com/post/957371bf-24c0-45ca-b653-114a883e2e87/upload-1682805409709.webp",
          "attack": "2",
          "series": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
          "bgColor": "#CBC8DE",
          "rarity": "rare",
          "id": "image__82053e11-daf1-4816-946b-63723c9f7f93",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2023-04-29T21:56:50.628Z",
          "access": "public",
          "slug": "chillalotl",
          "createdAt": "2022-12-22T17:55:39.419Z",
          "defense": "3",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2022-01-08T06:35:00.000Z",
          "subclass1": "ethereal",
          "description": "When you Cast this Chillalotl you can target up to 2 Facedown Runes. As long as this Chillalotl is Enchanted, the targeted Runes cannot be Cast.",
          "excerpt": "PR1-32",
          "title": "Chillalotl",
          "type": "image",
          "seriesData": {
              "logoImage": "https://cdn.cerealapis.com/series/fdd05325-b2f9-407c-b1c4-2a610a683f8b/upload-1668701507999.png",
              "access": "public",
              "category": "Card Set",
              "slug": "base-set-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/28fea879-2c59-4ad1-a7d9-50be073d5295/upload-1668701508011.jpg",
              "id": "4bb2ee68-92be-47e7-82f3-bfd45302ef68",
              "videoCount": 32,
              "title": "Base Set Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
  
      {
          "file": "https://cdn.cerealapis.com/post/51dabbfc-cd4e-4674-9c5b-ec204b73f9a1/upload-1669572441362.webp",
          "collections": {},
          "type1": "wind",
          "bgImage": "https://cdn.cerealapis.com/post/73c709ca-8649-4c5d-a3ba-a610953cd35e/upload-1670448414953.webp",
          "attack": "1",
          "series": "b212cb49-a9e6-4aab-93b2-f67682e848ad",
          "bgColor": "#ACBCC1",
          "rarity": "common",
          "id": "image__0dddd6b6-efc4-4be0-a8d0-f56e57b8aa5b",
          "tags": [],
          "author": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
          "lastUpdated": "2023-02-01T21:26:37.197Z",
          "access": "public",
          "slug": "nimbug",
          "createdAt": "2022-11-11T18:11:29.717Z",
          "defense": "1",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2020-12-31T04:00:00.000Z",
          "subclass1": "insectoid",
          "description": "``` embed\nThank you for being one of the first to play Elestrals!\n\nYou can Expend <span class=\"elestrals-font\">A</span> in order to Special Ascend this Nimbug to Cirrucoon from your hand or deck.\n```",
          "excerpt": "Prototype Promo",
          "title": "Nimbug",
          "type": "image",
          "seriesData": {
              "access": "public",
              "category": "Card Set",
              "slug": "prototype-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/8f0db6fc-cb43-429f-9281-fb42c67b37e7/upload-1668189790587.jpg",
              "id": "b212cb49-a9e6-4aab-93b2-f67682e848ad",
              "videoCount": 2,
              "title": "Prototype Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/c40e49a6-396e-4565-a0c0-a5e7b180e544/upload-1662716131457.jpeg",
              "category": "Creator",
              "slug": "daniel-reyes",
              "bgImage": "https://cdn.cerealapis.com/author/fca7744e-ff5d-4236-a581-6e4fd3f05191/upload-1662716131446.jpeg",
              "id": "1397f9c8-9944-4334-bfab-2ceb10e52e5c",
              "videoCount": 95,
              "title": "Daniel Reyes"
          }
      },
      {
          "file": "https://cdn.cerealapis.com/post/cc8992b1-cfeb-4d46-a60a-0519d6a418c0/upload-1670448356590.webp",
          "collections": {},
          "type1": "earth",
          "bgImage": "https://cdn.cerealapis.com/post/770c14f1-7dcb-489f-a2fc-42fb35f6e306/upload-1670448432436.webp",
          "attack": "1",
          "series": "b212cb49-a9e6-4aab-93b2-f67682e848ad",
          "bgColor": "#B7C03A",
          "rarity": "rare",
          "id": "image__7fab43b0-a0d9-4c95-a500-478abe3b693d",
          "tags": [],
          "author": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
          "lastUpdated": "2022-12-07T21:50:14.574Z",
          "access": "public",
          "slug": "pandicine",
          "createdAt": "2022-11-11T18:11:42.510Z",
          "defense": "4",
          "postStatus": "published",
          "overrideSeries": true,
          "client": "elestrals_image",
          "publishedAt": "2021-12-31T04:05:00.000Z",
          "subclass1": "brute",
          "description": "``` embed\nYou can Disenchant <span class=\"elestrals-font\">E</span> from this Pandicine in order to return an Invoke Rune from your Underworld to your hand.\n```",
          "excerpt": "Prototype Promo",
          "title": "Pandicine",
          "type": "image",
          "seriesData": {
              "access": "public",
              "category": "Card Set",
              "slug": "prototype-promo-cards",
              "bgImage": "https://cdn.cerealapis.com/series/8f0db6fc-cb43-429f-9281-fb42c67b37e7/upload-1668189790587.jpg",
              "id": "b212cb49-a9e6-4aab-93b2-f67682e848ad",
              "videoCount": 2,
              "title": "Prototype Promo Cards"
          },
          "authorData": {
              "logoImage": "https://cdn.cerealapis.com/author/9abbfa57-fadc-42d9-bd52-3907b145d6ff/upload-1662720081319.jpeg",
              "category": "Artist",
              "slug": "jonny-de-oliviera",
              "bgImage": "https://cdn.cerealapis.com/author/d6271a98-e34d-4b1a-843c-45b03f86abb2/upload-1662720081310.jpeg",
              "id": "5b7551d3-20a8-4243-9778-c42aa17f3b79",
              "videoCount": 22,
              "title": "Jonny De Oliviera"
          }
      }
  
]