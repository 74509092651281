import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { cards, upcomingcards } from './cards.js'
import FIRE from './fire.png'
import WATER from './water.png'
import EARTH from './earth.png'
import WIND from './wind.png'
import THUNDER from './thunder.png'
import INVOKE from './invoke.png'
import COUNTER from './counter.png'
import DIVINE from './divine.png'
import STADIUM from './stadium.png'
import EQUIP from './equip.png'
import SORT from './descending.png'
import ARROW from './right-arrow.png'
import DISK from './diskette.png'
import CARDBG from './cardBG.webp'
import axios from 'axios'

const Dropdown = ({ trigger, menu }) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className="dropdown">
      {React.cloneElement(trigger, {
        onClick: handleOpen,
      })}
      {open ? (
        <ul className="menu">
          {menu.map((menuItem, index) => (
            <li key={index} className="menu-item">
              {React.cloneElement(menuItem, {
                onClick: () => {
                  menuItem.props.onClick();
                  setOpen(false);
                },
              })}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

function App() {
  const [ highlight, setHighlight ] = useState()
  const [ decklist, setDecklist ] = useState([])
  const [ sidelist, setSidelist ] = useState([])
  const [ searchByName, setSearchByName ] = useState('')
  const [ searchByEffect, setSearchByEffect ] = useState('')
  const [ searchSubtype, setSearchSubtype ] = useState('')
  const [ searchAttackMin, setSearchAttackMin ] = useState('')
  const [ searchAttackMax, setSearchAttackMax ] = useState('')
  const [ searchDefenceMin, setSearchDefenceMin ] = useState('')
  const [ searchDefenceMax, setSearchDefenceMax ] = useState('')
  const [ searchCostMin, setSearchCostMin ] = useState('')
  const [ searchCostMax, setSearchCostMax ] = useState('')
  const [ addToSideMode, setAddToSideMode ] = useState(false)

  const [ searchEarth, setSearchEarth ] = useState(false)
  const [ searchFire, setSearchFire ] = useState(false)
  const [ searchWater, setSearchWater ] = useState(false)
  const [ searchThunder, setSearchThunder ] = useState(false)
  const [ searchWind, setSearchWind ] = useState(false)

  const [ searchInvoke, setSearchInvoke ] = useState(false)
  const [ searchEquip, setSearchEquip ] = useState(false)
  const [ searchDivine, setSearchDivine ] = useState(false)
  const [ searchStadium, setSearchStadium ] = useState(false)
  const [ searchCounter, setSearchCounter ] = useState(false)

  const [ selectedOwnDeck, setSelectedOwnDeck ] = useState('#1')
  const [ deckName, setDeckName ] = useState('')
  const [ deckId, setDeckId ] = useState('')
  const [ deckLink, setDeckLink ] = useState('')

  const [ showLogin, setShowLogin ] = useState(false)
  const [ userData, setUserData ] = useState({})
  const [ typedLoginEmail, setTypedLoginEmail ] = useState('')
  const [ passwordSent, setPasswordSent ] = useState(false)
  const [ typedLoginPassword, setTypedLoginPassword ] = useState('')

  const resetLoginStuff = () => {
    setShowLogin(false)
    setTypedLoginEmail('')
    setPasswordSent(false)
    setTypedLoginPassword('')
  }

  const [ loadingDeck, setLoadingDeck ] = useState(true)
  const [ savingDeck, setSavingDeck ] = useState(false)
  
  useEffect(() => {
    let newUserData = JSON.parse(localStorage.getItem('userData') || '{}')
    setUserData(newUserData)
  }, [])
  /*{
    ['#1']: 'b9yd1r690v',
    ['#2']: '86e057i4wa'
  }*/

  const [ loggedInUsername, setLoggedInUsername ] = useState('')
  const [ loggedInPassword, setLoggedInPassword ] = useState('')

  const [ showImport, setShowImport ] = useState(false)
  const [ importText, setImportText ] = useState('')

  const [ decklistSearchTitleFilter, setDecklistSearchTitleFilter ] = useState('')
  const [ allDecks, setAllDecks ] = useState([])

  const [ showUpcoming, setShowUpcoming ] = useState(false)
  const [ recentlySavedDeck, setRecentlySavedDeck ] = useState(false)

  const deckBase = Array.from({ length: 40 })
  const spiritsBase = Array.from({ length: 20 })
  const sideBase = Array.from({ length: 12 })
  const deckSpirits = decklist.filter(_ => _.title === 'Lycarus' || _.title === 'Zaptor' || _.title === 'Vipyro' || _.title === 'Leviaphin' || _.title === 'Teratlas' || _.title === 'Flurrmine')
  const deckWithoutSpirits = decklist.filter(_ => !(_.title === 'Lycarus' || _.title === 'Zaptor' || _.title === 'Vipyro' || _.title === 'Leviaphin' || _.title === 'Teratlas' || _.title === 'Flurrmine'))

  const filledDeck = deckBase.map((_, index) => deckWithoutSpirits[index] || 'EMPTY')
  const filledSpirits = spiritsBase.map((_, index) => deckSpirits[index] || 'EMPTY')
  const filledSide = sideBase.map((_, index) => sidelist[index] || 'EMPTY')

  useEffect(() => {
    const pathName = window.location.pathname 
    const path = pathName && pathName.substring(1)
    if (path) {
      setLoadingDeck(true)
      axios({
        method: 'post',
        url: 'https://t9abnoik7e.execute-api.us-east-1.amazonaws.com/default/elenex-requests',
        data: {
          requestId: 'GET_DECK',
          savedDeckId: path
        }
      }).then(response => {
        console.log('Got deck.') 
        const data = response.data.Item
        if (data) {
          setDeckId(data.id)
          setDeckName(data.deckname)
          setDeckLink(window.location.href)
          setDecklist(data.decklist)
          setSidelist(data.sidelist)
        }
        setLoadingDeck(false)
      })
      .catch(e => {
        console.log('Error: ', e) 
        setLoadingDeck(false)
      });
    } else {
      setLoadingDeck(false)
    }
  }, [])

  const hasDeckNumber = (deckId === userData['#1'] ? '1' : deckId === userData['#2'] ? '2' : deckId === userData['#3'] ? '3' : deckId === userData['#4'] ? '4' : deckId === userData['#5'] ? '5' : deckId === userData['#6'] ? '6' : ((new URLSearchParams(window.location.search)).get('d'))) || ''
  useEffect(() => {
    if (!hasDeckNumber) {    
      setLoadingDeck(true)
      axios({
        method: 'post',
        url: 'https://t9abnoik7e.execute-api.us-east-1.amazonaws.com/default/elenex-requests',
        data: {
          requestId: 'GET_PUBLISHED_DECKS',
        }
      }).then(response => {
        console.log('Got all decks.', response) 
        const data = response.data
        if (data) {
          setAllDecks(data.Items)
        }
        setLoadingDeck(false)
      })
      .catch(e => {
        console.log('Error: ', e) 
        setLoadingDeck(false)
      });
    }
  }, [])

  useEffect(() => {
    const origin = window.location.origin + '/'
    const href = window.location.href
    if (origin === href) {
      window.location.href = window.location.href + (JSON.parse(localStorage.getItem('userData') || '{}')['#1'] || '?d=1')
    }
  }, [])

  return (
    <div className="App">      

      <div style={{ display: 'flex' }}>        
        <div style={{ flex: 1, /*maxWidth: 300,*/ maxWidth: '40vh', minWidth: 140 }}>
          <div className="card-highlight-container">        
            <img src={ highlight ? `https://www.elestrals.com/_next/image?url=${highlight.bgImage}&w=750&q=1` : CARDBG } className="card-highlight" alt={ highlight ? highlight.slug : 'bg' } />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginTop: -15, justifyContent: 'center', paddingLeft: 4 }}>
            <div className="element-button" onClick={() => {
              window.location.href = window.location.origin + '/' + 'all'
            }} style={{ flex: 1, paddingTop: 4, paddingBottom: 6, margin: 2, backgroundColor: !hasDeckNumber ? 'rgba(120,220,120,0.5)' : 'rgba(150,200,250,0.1)', maxWidth: '100%' }}>
              <p className="small-text">{ 'Browse All Decklists' } </p>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 0, justifyContent: 'center', paddingLeft: 4 }}>
            <div className="element-button" onClick={() => { window.location.href = (window.location.origin + '/' + (userData['#1'] || '?d=1')) }} style={{ flex: 1, paddingTop: 3, paddingBottom: 7, margin: 1, backgroundColor: ((deckId === userData['#1']) || ((new URLSearchParams(window.location.search)).get('d') === '1')) ? 'rgba(150,250,150,0.5)' : 'rgba(250,250,250,0.05)', maxWidth: '70%' }}>
              <p className="small-text">{ ((((deckId === userData['#1']) || ((new URLSearchParams(window.location.search)).get('d') === '1')) ? (deckName || 'Own Deck 1').substring(0,10) + ' ' : JSON.parse(localStorage.getItem('userData') || '{}')['#1_title'] || 'Own Deck 1' + ' ') + '')  } </p>
            </div>
            <div className="element-button" onClick={() => { window.location.href = (window.location.origin + '/' + (userData['#2'] || '?d=2')) }} style={{ flex: 1, paddingTop: 3, paddingBottom: 7, margin: 1, backgroundColor: ((deckId === userData['#2']) || ((new URLSearchParams(window.location.search)).get('d') === '2')) ? 'rgba(150,250,150,0.5)' : 'rgba(250,250,250,0.05)', maxWidth: '70%' }}>
            <p className="small-text">{ (((deckId === userData['#2']) || ((new URLSearchParams(window.location.search)).get('d') === '2')) ? (deckName || 'Own Deck 2').substring(0,10) + ' ' : JSON.parse(localStorage.getItem('userData') || '{}')['#2_title'] || 'Own Deck 2' + ' ') + '' } </p>
            </div>
            <div className="element-button" onClick={() => { window.location.href = (window.location.origin + '/' + (userData['#3'] || '?d=3')) }} style={{ flex: 1, paddingTop: 3, paddingBottom: 7, margin: 1, backgroundColor: ((deckId === userData['#3']) || ((new URLSearchParams(window.location.search)).get('d') === '3')) ? 'rgba(150,250,150,0.5)' : 'rgba(250,250,250,0.05)', maxWidth: '70%' }}>
            <p className="small-text">{ (((deckId === userData['#3']) || ((new URLSearchParams(window.location.search)).get('d') === '3')) ? (deckName || 'Own Deck 3').substring(0,10) + ' ' : JSON.parse(localStorage.getItem('userData') || '{}')['#3_title'] || 'Own Deck 3' + ' ') + '' } </p>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingLeft: 4 }}>
          <div className="element-button" onClick={() => { window.location.href = (window.location.origin + '/' + (userData['#4'] || '?d=4')) }} style={{ flex: 1, paddingTop: 3, paddingBottom: 7, margin: 1, backgroundColor: ((deckId === userData['#4']) || ((new URLSearchParams(window.location.search)).get('d') === '4')) ? 'rgba(150,250,150,0.5)' : 'rgba(250,250,250,0.05)', maxWidth: '70%' }}>
            <p className="small-text">{ (((deckId === userData['#4']) || ((new URLSearchParams(window.location.search)).get('d') === '4')) ? (deckName || 'Own Deck 4').substring(0,10) + ' ' : JSON.parse(localStorage.getItem('userData') || '{}')['#4_title'] || 'Own Deck 4' + ' ') + '' } </p>
            </div>
            <div className="element-button" onClick={() => { window.location.href = (window.location.origin + '/' + (userData['#5'] || '?d=5')) }} style={{ flex: 1, paddingTop: 3, paddingBottom: 7, margin: 1, backgroundColor: ((deckId === userData['#5']) || ((new URLSearchParams(window.location.search)).get('d') === '5')) ? 'rgba(150,250,150,0.5)' : 'rgba(250,250,250,0.05)', maxWidth: '70%' }}>
            <p className="small-text">{ (((deckId === userData['#5']) || ((new URLSearchParams(window.location.search)).get('d') === '5')) ? (deckName || 'Own Deck 5').substring(0,10) + ' ' : JSON.parse(localStorage.getItem('userData') || '{}')['#5_title'] || 'Own Deck 5' + ' ') + '' } </p>
            </div>
            <div className="element-button" onClick={() => { window.location.href = (window.location.origin + '/' + (userData['#6'] || '?d=6')) }} style={{ flex: 1, paddingTop: 3, paddingBottom: 7, margin: 1, backgroundColor: ((deckId === userData['#6']) || ((new URLSearchParams(window.location.search)).get('d') === '6')) ? 'rgba(150,250,150,0.5)' : 'rgba(250,250,250,0.05)', maxWidth: '70%' }}>
            <p className="small-text">{ ((((deckId === userData['#6']) || ((new URLSearchParams(window.location.search)).get('d') === '6')) ? (deckName || 'Own Deck 6').substring(0,10) + ' ' : JSON.parse(localStorage.getItem('userData') || '{}')['#6_title'] || 'Own Deck 6' + ' ') + '') } </p>
            </div>
          </div>

          { true && (
          <div style={{ display: 'flex', flexDirection: 'column', padding: '4%', paddingTop: 5, paddingBottom: 0 }}>      
            <div className="searchrow" style={{ display: 'flex', flexDirection: 'row', marginTop: 2, marginBottom: 0 }}>
              <div className="searchtitle"><span className="searchtitletext">Title:</span></div>
              <div className="searchcontent"><input type="text" style={hasDeckNumber ? { width: 200 } : { width: 200, outlineWidth: 0, opacity: 0.5, userSelect: 'all',  }} placeholder="Name your deck" value={deckName} onChange={(e) => !hasDeckNumber ? {} : setDeckName(e.target.value)} /></div>
            </div>   
            <div className="searchrow" style={{ display: 'flex', flexDirection: 'row', marginTop: 1, marginBottom: 10 }}>
              <div className="searchtitle"><span className="searchtitletext">Link:</span></div>
              <div className="searchcontent"><input type="text" style={{ width: 200, outlineWidth: 0, opacity: 0.5, userSelect: 'all',  }} placeholder={"-"} value={deckLink} onChange={(e) => {}} /></div>
            </div>    
            { !hasDeckNumber && (  
            <p className="small-text">{ 'Copy the link and press "Import" while editing an own deck to copy this decklist.' } </p> )}
            { hasDeckNumber && (               
            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 3, paddingBottom: 0 }}>  
              <div className="element-button" onClick={() => {
                setSavingDeck(true)
                axios({
                  method: 'post',
                  url: 'https://t9abnoik7e.execute-api.us-east-1.amazonaws.com/default/elenex-requests',
                  data: {
                    requestId: 'SAVE_DECK',
                    deckname: deckName,
                    decklist: decklist.map(_ => ({ deckId: _.deckId, bgImage: _.bgImage, file: _.file, description: _.description, excerpt: _.excerpt, id: _.id, rarity: _.rarity, slug: _.slug, subclass1: _.subclass1, subclass2: _.subclass2, subclass3: _.subclass3, title: _.title, attack: _.attack, defense: _.defense, type: _.type, type1: _.type1, type2: _.type2, type3: _.type3, runeType: _.runeType })),
                    sidelist: sidelist.map(_ => ({ deckId: _.deckId, bgImage: _.bgImage, file: _.file, description: _.description, excerpt: _.excerpt, id: _.id, rarity: _.rarity, slug: _.slug, subclass1: _.subclass1, subclass2: _.subclass2, subclass3: _.subclass3, title: _.title, attack: _.attack, defense: _.defense, type: _.type, type1: _.type1, type2: _.type2, type3: _.type3, runeType: _.runeType }))
                  }
                }).then(response => {
                  console.log('Saved deck.') 
                  const data = response.data                  
                  if (data) {
                    const deckNumber = (deckId === userData['#1'] ? '1' : deckId === userData['#2'] ? '2' : deckId === userData['#3'] ? '3' : deckId === userData['#4'] ? '4' : deckId === userData['#5'] ? '5' : deckId === userData['#6'] ? '6' : ((new URLSearchParams(window.location.search)).get('d'))) || ''
                    if (deckNumber) {
                      let newUserData = { ...userData }
                      newUserData['#'+deckNumber] = data.savedDeckId
                      newUserData['#'+deckNumber+'_title'] = deckName
                      localStorage.setItem('userData', JSON.stringify(newUserData));
                      newUserData = JSON.parse(localStorage.getItem('userData') || '{}')  
                      setUserData(newUserData)                         
                    }
                    //window.location.replace(data.savedDeckId)
                    window.history.replaceState(null, null, data.savedDeckId)                    
                    //setRecentlySavedDeck(window.location.href)
                    //setDeckLink(window.location.href)
                    window.location.href = (window.location.origin + '/' + data.savedDeckId)
                  }
                  //setSavingDeck(false)
                })
                .catch(e => {
                  console.log('Error: ', e)
                  setSavingDeck(false)
                });
              }} style={{ flex: 1, paddingTop: 3, paddingBottom: 7, margin: 1, backgroundColor: showUpcoming ? 'rgba(250,120,120,0.3)' : 'rgba(250,250,250,0.05)', maxWidth: '70%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={ DISK } style={{ height: 12, width: 12, objectFit: 'contain', marginRight: 5 }} alt={ 'elestrals-logo' } />
                  <p className="small-text">{ 'Save' } </p>
                </div>              
              </div>              
              <div className="element-button" onClick={() => {
                setLoadingDeck(true)
                axios({
                  method: 'post',
                  url: 'https://t9abnoik7e.execute-api.us-east-1.amazonaws.com/default/elenex-requests',
                  data: {
                    requestId: 'PUBLISH_DECK',
                    deckname: deckName,
                    decklist: decklist.map(_ => ({ deckId: _.deckId, bgImage: _.bgImage, file: _.file, description: _.description, excerpt: _.excerpt, id: _.id, rarity: _.rarity, slug: _.slug, subclass1: _.subclass1, subclass2: _.subclass2, subclass3: _.subclass3, title: _.title, attack: _.attack, defense: _.defense, type: _.type, type1: _.type1, type2: _.type2, type3: _.type3, runeType: _.runeType })),
                    sidelist: sidelist.map(_ => ({ deckId: _.deckId, bgImage: _.bgImage, file: _.file, description: _.description, excerpt: _.excerpt, id: _.id, rarity: _.rarity, slug: _.slug, subclass1: _.subclass1, subclass2: _.subclass2, subclass3: _.subclass3, title: _.title, attack: _.attack, defense: _.defense, type: _.type, type1: _.type1, type2: _.type2, type3: _.type3, runeType: _.runeType }))
                  }
                }).then(response => {
                  console.log('Published deck.') 
                  const data = response.data;                  
                  if (data) {                
                    window.history.replaceState(null, null, data.savedDeckId)                                        
                    window.location.href = (window.location.origin + '/' + data.savedDeckId)
                  }
                  //setSavingDeck(false)
                })
                .catch(e => {
                  console.log('Error: ', e)
                  setLoadingDeck(false)
                });
              }} style={{ flex: 1, paddingTop: 3, paddingBottom: 7, margin: 1, backgroundColor: showUpcoming ? 'rgba(250,120,120,0.3)' : 'rgba(250,250,250,0.05)', maxWidth: '70%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={ DISK } style={{ height: 12, width: 12, objectFit: 'contain', marginRight: 5 }} alt={ 'elestrals-logo' } />
                  <p className="small-text">{ 'Publish' } </p>
                </div>              
              </div>                        
            </div> )}
            { hasDeckNumber && (
            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 0, paddingBottom: 0 }}>  
              <div className="element-button" onClick={() => { setShowImport(true) }} style={{ flex: 1, paddingTop: 3, paddingBottom: 7, margin: 1, backgroundColor: showUpcoming ? 'rgba(250,120,120,0.3)' : 'rgba(250,250,250,0.05)', maxWidth: '70%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={ DISK } style={{ height: 12, width: 12, objectFit: 'contain', marginRight: 5 }} alt={ 'elestrals-logo' } />
                  <p className="small-text">{ 'Import' } </p>
                </div>              
              </div>              
              <div className="element-button" onClick={() => {
                let newDeck = JSON.parse(JSON.stringify(decklist))
                newDeck.sort((a, b) => a.excerpt > b.excerpt ? 1 : -1)
                newDeck.sort((a, b) => (a.runeType && !b.runeType) ? 0 : -1)
                setDecklist(newDeck)
  
                let newSideDeck = JSON.parse(JSON.stringify(sidelist))
                newSideDeck.sort((a, b) => a.excerpt > b.excerpt ? 1 : -1)
                newSideDeck.sort((a, b) => (a.runeType && !b.runeType) ? 0 : -1)
                setSidelist(newSideDeck)
              }} style={{ flex: 1, paddingTop: 3, paddingBottom: 7, margin: 1, backgroundColor: showUpcoming ? 'rgba(250,120,120,0.3)' : 'rgba(250,250,250,0.05)', maxWidth: '70%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={ SORT } style={{ height: 12, width: 12, objectFit: 'contain', marginRight: 5 }} alt={ 'elestrals-logo' } />
                  <p className="small-text">{ 'Sort' } </p>
                </div>              
              </div>                        
            </div> )}
            <div>        
              <img src={ 'https://cdn.cerealapis.com/settings/3fc6f2fc-a62a-498f-9cb8-b99521605a62/upload-1666625656254.png' } style={{ opacity: 0.8, paddingTop: '0%', height: '100%', width: '70%', objectFit: 'contain' }} alt={ 'elestrals-logo' } />            
            </div> 
            <div style={{ flex: 1, height: 20, width: 20, marginTop: 10 }} /> 
            {/*     
            <p className="small-text">{ "To store your decks and account on the server press the button below. Logged in users can also publish and rate decks." } </p>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 5 }}>
              <div className="element-button" onClick={() => {
                resetLoginStuff()
                setShowLogin(true)
              }} style={{ flex: 1, paddingTop: 3, paddingBottom: 7, margin: 1, backgroundColor: showUpcoming ? 'rgba(250,120,120,0.3)' : 'rgba(250,250,250,0.05)', maxWidth: '70%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={ DISK } style={{ height: 12, width: 12, objectFit: 'contain', marginRight: 5 }} alt={ 'elestrals-logo' } />
                  <p className="small-text">{ 'Login / Register' } </p>
                </div>              
              </div>   
            </div> */}
          </div> )}

          { false &&  !!recentlySavedDeck && (
          <div>
            <div style={{ paddingTop: 8, paddingBottom: 2 }}><span style={{ fontSize: 12 }}>Saved deck as: </span></div>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }}><span style={{ fontSize: 8, userSelect: 'all', lineBreak: 'anywhere' }}>{ recentlySavedDeck }</span></div>
          </div> )}          
        </div>
        <div style={{ flex: 2, maxWidth: '92vh', padding: 6, paddingTop: 0, minWidth: 280, paddingBottom: 0, paddingRight: 8, backgroundColor: 'rgba(22,22,22,1)',  border: '3px solid rgba(40,40,40,1)' }}>                                
          {/*
          <div className="deck-list-bg">
            { Array.from({ length: 60 }).map((card, index) => (
              <div key={'bg' + index} className="card-container-bg">
              </div>
            )) }
          </div> */}
          <div className="deck-list" style={{ paddingTop: 4 }}>
            { filledDeck.map((card, index) => (                
              <div key={'deck' + index} className="deckcard-bg">
                { !!card && card !== 'EMPTY' && (
                <div className="card-container" onMouseEnter={() => { setHighlight(card) }} onClick={() => {
                  if (!hasDeckNumber) {
                    return
                  }
                  setDecklist([ ...decklist ].filter(_ => _.deckId !== card.deckId))
                  }}>
                  <img src={
                    `https://www.elestrals.com/_next/image?url=${card.bgImage}&w=750&q=1`
                  } className="card-img" style={{  }} alt={ card.slug } />
                </div> )}
                { card === 'EMPTY' && (
                    <div className="card-container empty">
                      <img src={                        
                        CARDBG
                      } className="card-img empty" style={{ opacity: 0.3 }} alt={ 'no card' } />
                    </div>
                  ) }    
              </div>
            )) }
          </div>  

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="press-button" style={{ flex: 1.26, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '1.5%' }}>
              <div style={{ flex: 1 }}>
                <div style={{ cursor: 'pointer', opacity: addToSideMode ? 0.3 : 1  }} className="press-button" onClick={() => {
                  setAddToSideMode(!addToSideMode)
                }}>   
                  <span style={{ color: 'rgba(255,255,255,0.5)' }}>Elestrals & Runes</span>
                </div>
              </div>
              <div style={{ cursor: 'pointer', opacity: addToSideMode ? 0.3 : 1  }} className="press-button" onClick={() => {
                  setAddToSideMode(!addToSideMode)
                }}>   
                <span style={{ color: 'rgba(255,255,255,0.5)' }}>Spirits</span>
              </div>
            </div>  
 
            <div style={{ cursor: 'pointer', flex: 0.2, maxHeight: 80 }} />

            <div style={{ paddingTop: '1.7%', paddingBottom: '2.5%', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            
              <div style={{ cursor: 'pointer', opacity: addToSideMode ? 1 : 0.3  }} className="press-button" onClick={() => {
                  setAddToSideMode(!addToSideMode)
                }}>   
                  <span style={{ color: 'rgba(255,255,255,0.5)', paddingLeft: 5 }}>Side Deck</span>
                </div>
              <div className="side-list" style={{ flex: 1 }}>
                { filledSide.map((card, index) => (
                  <div key={'bg' + index} className="deckcard-bg">
                    <div style={{ margin: 1, height: '100%' }}>                
                      { !!card && card !== 'EMPTY' && (
                      <div className="card-container" onMouseEnter={() => { setHighlight(card) }} onClick={() => {
                        if (!hasDeckNumber) {
                          return
                        }
                        setSidelist([ ...sidelist ].filter(_ => _.deckId !== card.deckId))
                        }}>
                        <img src={
                          `https://www.elestrals.com/_next/image?url=${card.bgImage}&w=750&q=1`
                        } className="card-img" style={{  }} alt={ card.slug } />
                      </div> )}    
                      { card === 'EMPTY' && (
                        <div className="card-container empty">
                          <img src={ CARDBG } className="card-img empty" style={{ opacity: 0.18 }} alt={ 'no card' } />
                        </div>
                      ) }                
                    </div>
                  </div>
                )) }
              </div> 
            </div>
          </div> 

          <div className="deck-list" style={{ paddingTop: '0%' }}>
            { filledSpirits.map((card, index) => (
              <div key={'bg' + index} className="deckcard-bg">
                <div style={{ margin: 1, height: '100%' }}>                
                  { !!card && card !== 'EMPTY' && (
                  <div className="card-container" onMouseEnter={() => { setHighlight(card) }} onClick={() => {
                    if (!hasDeckNumber) {
                      return
                    }
                    setDecklist([ ...decklist ].filter(_ => _.deckId !== card.deckId))
                    }}>
                    <img src={
                      `https://www.elestrals.com/_next/image?url=${card.bgImage}&w=750&q=1`
                    } className="card-img" style={{  }} alt={ card.slug } />
                  </div> )}    
                  { card === 'EMPTY' && (
                    <div className="card-container empty">
                      <img src={ CARDBG } className="card-img empty" style={{ opacity: 0.3 }} alt={ 'no card' } />
                    </div>
                  ) }                
                </div>
              </div>
            )) }
          </div>                            
        </div>

        { !hasDeckNumber && (
        <div style={{ flex: 1, maxHeight: '100vh', overflow: 'hidden', maxWidth: '60vh', minWidth: 200 }}>  
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <h1>Elestrals Decklists</h1>
            <div className="searchrow" style={{ display: 'flex', flexDirection: 'row', width: '70%' }}>
              <div className="searchtitle"><span className="searchtitletext">Title:</span></div>
              <div className="searchcontent"><input type="text" placeholder="Search by title" value={decklistSearchTitleFilter} onChange={(e) => setDecklistSearchTitleFilter(e.target.value)} /></div>
            </div>
            <div className="card-list-scroll" style={{ width: '80%', marginTop: 10 }}>
              { allDecks && [ ...allDecks ].filter(_ => _.deckname.toLowerCase().includes(decklistSearchTitleFilter.toLowerCase())).map((decklist, decklistIndex) => {
                return (
                  <div key={decklist.id} className="" style={{ width: '100%', marginTop: 0 }}>
                    <div className="element-button" style={{ width: '100%', backgroundColor: decklist.id === deckId ? 'rgba(120,250,200,0.5)' : decklistIndex % 2 !== 0 ? 'rgba(255,255,255,0.02)' : 'rgba(255,255,255,0)', paddingTop: 5, paddingBottom: 7 }}>
                      <a style={{ textDecoration: 'none' }} href={'/' + decklist.id}>
                        <p style={{ fontSize: 12, width: '100%', cursor: 'pointer', color: 'white' }}>{ decklist.deckname }<p style={{ fontSize: 8, color: 'rgba(255,255,255,0.3)' }}>{ decklist.id }</p></p>
                      </a>
                    </div>
                  </div>
                )
              }) }              
            </div>
          </div>
        </div> )}   
            
        { hasDeckNumber && (
        <div style={{ flex: 1, maxHeight: '100vh', overflow: 'hidden', maxWidth: '60vh', minWidth: 200 }}>     
          <div style={{ padding: 10, paddingBottom: 0, paddingTop: 10 }}>       
          <div className="searchrow" style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="searchtitle"><span className="searchtitletext">Name:</span></div>
            <div className="searchcontent"><input type="text" placeholder="Name" value={searchByName} onChange={(e) => setSearchByName(e.target.value)} /></div>
          </div>
          <div className="searchrow" style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="searchtitle"><span className="searchtitletext">Effect:</span></div>
            <div className="searchcontent"><input type="text" placeholder="Effect" value={searchByEffect} onChange={(e) => setSearchByEffect(e.target.value)} /></div>
          </div>
          <div className="searchrow" style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="searchtitle"><span className="searchtitletext">Attack:</span></div>
            <div className="searchcontent">
              <div style={{  }}>
                <input className="smallinput" type="number" placeholder="Min" value={searchAttackMin} onChange={(e) => setSearchAttackMin(e.target.value)} />
              </div>
              <div style={{  }}>
                <input className="smallinput" type="number" placeholder="Max" value={searchAttackMax} onChange={(e) => setSearchAttackMax(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="searchrow" style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="searchtitle"><span className="searchtitletext">Defence:</span></div>
            <div className="searchcontent">
              <div style={{  }}>
                <input className="smallinput" type="number" placeholder="Min" value={searchDefenceMin} onChange={(e) => setSearchDefenceMin(e.target.value)} />
              </div>
              <div style={{  }}>
                <input className="smallinput" type="number" placeholder="Max" value={searchDefenceMax} onChange={(e) => setSearchDefenceMax(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="searchrow" style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="searchtitle"><span className="searchtitletext">Cost:</span></div>
            <div className="searchcontent">
              <div style={{  }}>
                <input className="smallinput" type="number" placeholder="Min" value={searchCostMin} onChange={(e) => setSearchCostMin(e.target.value)} />
              </div>
              <div style={{  }}>
                <input className="smallinput" type="number" placeholder="Max" value={searchCostMax} onChange={(e) => setSearchCostMax(e.target.value)} />
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 13, paddingBottom: 3 }}>          
            <div className="element-button" onClick={() => { setShowUpcoming(!showUpcoming) }} style={{ flex: 1, paddingTop: 3, paddingBottom: 7, backgroundColor: showUpcoming ? 'rgba(250,120,120,0.3)' : 'rgba(250,250,250,0.05)', maxWidth: '70%' }}>
              <p className="small-text">{ showUpcoming ? 'Press to hide Frostfall cards' : 'Press here to see Frostfall cards' } </p>
              <p className="small-text dark">Note! Sorry, Filtering frostfall cards not yet functional</p>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 13, paddingBottom: 3, height: 30 }}>
            <div className="element-button" onClick={() => { setSearchInvoke(!searchInvoke) }} style={{ flex: 1 }}><img src={ INVOKE } style={{ height: '100%', widht: '100%', objectFit: 'contain', opacity: searchInvoke ? 1 : 0.4 }} /></div>
            <div className="element-button" onClick={() => { setSearchEquip(!searchEquip) }} style={{ flex: 1 }}><img src={ EQUIP } style={{ height: '100%', widht: '100%', objectFit: 'contain', opacity: searchEquip ? 1 : 0.4 }} /></div>
            <div className="element-button" onClick={() => { setSearchDivine(!searchDivine) }} style={{ flex: 1 }}><img src={ DIVINE } style={{ height: '100%', widht: '100%', objectFit: 'contain', opacity: searchDivine ? 1 : 0.4 }} /></div>
            <div className="element-button" onClick={() => { setSearchStadium(!searchStadium) }} style={{ flex: 1 }}><img src={ STADIUM } style={{ height: '100%', widht: '100%', objectFit: 'contain', opacity: searchStadium ? 1 : 0.4 }} /></div>
            <div className="element-button" onClick={() => { setSearchCounter(!searchCounter) }} style={{ flex: 1 }}><img src={ COUNTER } style={{ height: '100%', widht: '100%', objectFit: 'contain', opacity: searchCounter ? 1 : 0.4 }} /></div>
          </div>  
  
          <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 5, paddingBottom: 6, height: 40 }}>
            <div className="element-button" onClick={() => { setSearchEarth(!searchEarth) }} style={{ flex: 1 }}><img src={ EARTH } style={{ height: '100%', widht: '100%', objectFit: 'contain', opacity: searchEarth ? 1 : 0.3 }} /></div>
            <div className="element-button" onClick={() => { setSearchFire(!searchFire) }} style={{ flex: 1 }}><img src={ FIRE } style={{ height: '100%', widht: '100%', objectFit: 'contain', opacity: searchFire ? 1 : 0.3 }} /></div>
            <div className="element-button" onClick={() => { setSearchWater(!searchWater) }} style={{ flex: 1 }}><img src={ WATER } style={{ height: '100%', widht: '100%', objectFit: 'contain', opacity: searchWater ? 1 : 0.3 }} /></div>
            <div className="element-button" onClick={() => { setSearchThunder(!searchThunder) }} style={{ flex: 1 }}><img src={ THUNDER } style={{ height: '100%', widht: '100%', objectFit: 'contain', opacity: searchThunder ? 1 : 0.3 }} /></div>
            <div className="element-button" onClick={() => { setSearchWind(!searchWind) }} style={{ flex: 1 }}><img src={ WIND } style={{ height: '100%', widht: '100%', objectFit: 'contain', opacity: searchWind ? 1 : 0.3 }} /></div>
          </div>  
          </div>  

          <div className="card-list-scroll">
            <div className="card-list">
            { upcomingcards
                .filter(_ => showUpcoming)
                /*.filter(_ => _.id !== 'image__55717dca-c6d2-4694-911d-778ab7c4fa40')
                .filter(_ => _.id !== 'image__e8007d2a-06e1-4dd8-950b-18c25374a45b')
                .filter(_ => _.excerpt !== 'Prototype Promo')
                .filter(_ => _.rarity !== 'full-art')
                .filter(_ => _.rarity !== 'stellar-rare')
                .filter(_ => _.rarity !== 'alternative-art')
                .filter(_ => !searchInvoke || _.runeType === 'invoke')
                .filter(_ => !searchEquip || _.runeType === 'artifact')
                .filter(_ => !searchDivine || _.runeType === 'divine')
                .filter(_ => !searchStadium || _.runeType === 'stadium')
                .filter(_ => !searchCounter || _.runeType === 'counter')
                .filter(_ => !searchEarth || _.type1 === 'earth' || _.type2 === 'earth' || _.type3 === 'earth')
                .filter(_ => !searchFire || _.type1 === 'fire' || _.type2 === 'fire' || _.type3 === 'fire')
                .filter(_ => !searchWater || _.type1 === 'water' || _.type2 === 'water' || _.type3 === 'water')
                .filter(_ => !searchThunder || _.type1 === 'thunder' || _.type2 === 'thunder' || _.type3 === 'thunder')
                .filter(_ => !searchWind || _.type1 === 'wind' || _.type2 === 'wind' || _.type3 === 'wind')
                .filter(_ => (searchCostMin === '') || ((Number(searchCostMin) === 1) && _.type1) || ((Number(searchCostMin) === 2) && _.type2) || ((Number(searchCostMin) === 3) && _.type3) || ((Number(searchCostMin) <= 0)))                
                .filter(_ => (searchCostMax === '') || ((Number(searchCostMax) === 2) && !_.type3) || ((Number(searchCostMax) === 1) && !_.type2) || ((Number(searchCostMax) === 0) && !_.type1) || ((Number(searchCostMax) >= 3)))                
                .filter(_ => (searchAttackMin === '') || (Number(_.attack) >= (Number(searchAttackMin) || 0)))
                .filter(_ => (searchAttackMax === '') || (Number(_.attack) <= (Number(searchAttackMax) || 1000)))
                .filter(_ => (searchDefenceMin === '') || (Number(_.defense) >= (Number(searchDefenceMin) || 0)))
                .filter(_ => (searchDefenceMax === '') || (Number(_.defense) <= (Number(searchDefenceMax) || 1000)))
                .filter(_ => !searchByName || _.title.toLowerCase().includes(searchByName.toLowerCase()))
                .filter(_ => !searchByEffect || _.description.toLowerCase().includes(searchByEffect.toLowerCase()))
                */
                .map((card, index) => (
                <div key={'Frostfall' + card.slug} className="card-container" onMouseEnter={() => { setHighlight(card) }} onClick={() => {
                  if (!hasDeckNumber) {
                    return
                  }
                  const isSpirit = card.title === 'Lycarus' || card.title === 'Zaptor' || card.title === 'Vipyro' || card.title === 'Leviaphin' || card.title === 'Teratlas' || card.title === 'Flurrmine';                  
                  if (addToSideMode) {
                    if (sidelist.length === 12) {
                      return
                    }
                    setSidelist([ ...sidelist, { ...card, deckId: Math.floor(Math.random()*10000000) } ]) 
                    return
                  }
                  if (filledSpirits.filter(_ => _ !== 'EMPTY').length === 20 && isSpirit) {
                    return
                  }
                  if (filledDeck.filter(_ => _ !== 'EMPTY').length === 40 && !isSpirit) {
                    return
                  }
                  setDecklist([ ...decklist, { ...card, deckId: Math.floor(Math.random()*10000000) } ])
                }}>
                  <img src={
                    //card.bgImage
                    `https://www.elestrals.com/_next/image?url=${card.bgImage}&w=750&q=1`
                  } className="card-img cardpool-img" style={{  }} alt={ card.slug } />
                </div>
              )) }
              { cards
                .filter(_ => !showUpcoming)
                //.filter(_ => _.id !== 'image__55717dca-c6d2-4694-911d-778ab7c4fa40')
                .filter(_ => _.id !== 'image__e8007d2a-06e1-4dd8-950b-18c25374a45b')
                .filter(_ => _.excerpt !== 'Prototype Promo')
                .filter(_ => _.rarity !== 'full-art')
                .filter(_ => _.rarity !== 'stellar-rare')
                .filter(_ => _.rarity !== 'alternative-art')
                .filter(_ => !searchInvoke || _.runeType === 'invoke')
                .filter(_ => !searchEquip || _.runeType === 'artifact')
                .filter(_ => !searchDivine || _.runeType === 'divine')
                .filter(_ => !searchStadium || _.runeType === 'stadium')
                .filter(_ => !searchCounter || _.runeType === 'counter')
                .filter(_ => !searchEarth || _.type1 === 'earth' || _.type2 === 'earth' || _.type3 === 'earth')
                .filter(_ => !searchFire || _.type1 === 'fire' || _.type2 === 'fire' || _.type3 === 'fire')
                .filter(_ => !searchWater || _.type1 === 'water' || _.type2 === 'water' || _.type3 === 'water')
                .filter(_ => !searchThunder || _.type1 === 'thunder' || _.type2 === 'thunder' || _.type3 === 'thunder')
                .filter(_ => !searchWind || _.type1 === 'wind' || _.type2 === 'wind' || _.type3 === 'wind')
                .filter(_ => (searchCostMin === '') || ((Number(searchCostMin) === 1) && _.type1) || ((Number(searchCostMin) === 2) && _.type2) || ((Number(searchCostMin) === 3) && _.type3) || ((Number(searchCostMin) <= 0)))                
                .filter(_ => (searchCostMax === '') || ((Number(searchCostMax) === 2) && !_.type3) || ((Number(searchCostMax) === 1) && !_.type2) || ((Number(searchCostMax) === 0) && !_.type1) || ((Number(searchCostMax) >= 3)))                
                .filter(_ => (searchAttackMin === '') || (Number(_.attack) >= (Number(searchAttackMin) || 0)))
                .filter(_ => (searchAttackMax === '') || (Number(_.attack) <= (Number(searchAttackMax) || 1000)))
                .filter(_ => (searchDefenceMin === '') || (Number(_.defense) >= (Number(searchDefenceMin) || 0)))
                .filter(_ => (searchDefenceMax === '') || (Number(_.defense) <= (Number(searchDefenceMax) || 1000)))
                .filter(_ => !searchByName || _.title.toLowerCase().includes(searchByName.toLowerCase()))
                .filter(_ => !searchByEffect || _.description.toLowerCase().includes(searchByEffect.toLowerCase()))
                .map((card, index) => (
                <div key={'id:' + card.id} className="card-container" style={{ position: 'relative' }} onMouseEnter={() => { setHighlight(card) }} onClick={() => {
                  if (!hasDeckNumber) {
                    return
                  }
                  const isSpirit = card.title === 'Lycarus' || card.title === 'Zaptor' || card.title === 'Vipyro' || card.title === 'Leviaphin' || card.title === 'Teratlas' || card.title === 'Flurrmine'
                  if (addToSideMode) {
                    if (sidelist.length === 12) {
                      return
                    }
                    setSidelist([ ...sidelist, { ...card, deckId: Math.floor(Math.random()*10000000) } ]) 
                    return
                  }
                  if (filledSpirits.filter(_ => _ !== 'EMPTY').length === 20 && isSpirit) {
                    return
                  }
                  if (filledDeck.filter(_ => _ !== 'EMPTY').length === 40 && !isSpirit) {
                    return
                  }
                  setDecklist([ ...decklist, { ...card, deckId: Math.floor(Math.random()*10000000) } ]) 
                }}>
                  { card.id === 'image__55717dca-c6d2-4694-911d-778ab7c4fa40' && (
                  <div className="show-on-hover" style={{ position: 'absolute', textAlign: 'center', marginLeft: 16, marginTop: 20, maxWidth: 50 }}><p style={{  fontSize: 6, fontWeight: 'bold', color: 'rgba(255,255,255,0.5)', textAlign: 'left' }}>Really?</p></div> ) }
                  <img src={
                    //card.bgImage
                    `https://www.elestrals.com/_next/image?url=${card.bgImage}&w=750&q=1`
                  } className="card-img cardpool-img" style={{  }} alt={ card.slug } />
                </div>
              )) }              
              <div style={{ width: '100%', userSelect: 'none' }}><img src={CARDBG} style={{ width: '100%', opacity: 0 }} /></div>
            </div>
          </div>
        </div> )}
      </div>

      { showImport && (
      <div style={{ position: 'fixed', height: '100vh', width: '100vw', flexDirection: 'column', backgroundColor: 'rgba(0,0,0,0.75)', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <h1>Import a decklist</h1>
        { true && (
        <div>
          <div className="searchrow" style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="searchcontent"><input type="text" placeholder="Link to the decklist" value={importText} onChange={(e) => setImportText(e.target.value)} /></div>
          </div>        
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="element-button" onClick={() => {
              setLoadingDeck(true)
              const importTextSplit = importText.split('/')
              axios({
                method: 'post',
                url: 'https://t9abnoik7e.execute-api.us-east-1.amazonaws.com/default/elenex-requests',
                data: {
                  requestId: 'GET_DECK',
                  savedDeckId: importTextSplit.reverse()[0],
                }
              }).then(response => {
                console.log('Got deck.') 
                const data = response.data.Item
                if (data) {
                  //setDeckId(data.id)
                  setDeckName(data.deckname)
                  //setDeckLink(window.location.href)
                  setDecklist(data.decklist)
                  setSidelist(data.sidelist)
                }
                setLoadingDeck(false)
                setShowImport(false)
              })
              .catch(e => {
                console.log('Error: ', e) 
                setLoadingDeck(false)
                setShowImport(false)
              })
            }} style={{ flex: 1, paddingTop: 3, paddingBottom: 7, backgroundColor: showUpcoming ? 'rgba(250,120,120,0.3)' : 'rgba(50,50,50,1)', maxWidth: '95%', margin: 2 }}>
              <p className="small-text">{ 'Import decklist' } </p>
            </div>
          </div>           
        </div> )}

        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
          <div className="element-button" onClick={() => {            
            setShowImport(false)
            }} style={{ flex: 1, padding: 10, paddingTop: 3, paddingBottom: 7, backgroundColor: showUpcoming ? 'rgba(250,120,120,0.3)' : 'rgba(150,50,50,1)', maxWidth: '95%', margin: 2 }}>
            <p className="small-text">{ 'Cancel' } </p>
          </div> 
        </div> 
        
      </div> )}
      
      { showLogin && (
      <div style={{ position: 'fixed', height: '100vh', width: '100vw', flexDirection: 'column', backgroundColor: 'rgba(0,0,0,0.75)', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <h1>Login / Register</h1>
        { !passwordSent && (
        <div>
          <div className="searchrow" style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="searchcontent"><input type="text" placeholder="Email address" value={typedLoginEmail} onChange={(e) => setTypedLoginEmail(e.target.value)} /></div>
          </div>        
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="element-button" onClick={() => { setPasswordSent(true) }} style={{ flex: 1, paddingTop: 3, paddingBottom: 7, backgroundColor: showUpcoming ? 'rgba(250,120,120,0.3)' : 'rgba(50,50,50,1)', maxWidth: '95%', margin: 2 }}>
              <p className="small-text">{ 'Send a single-use password' } </p>
            </div>
          </div>           
        </div> )}
        
        { passwordSent && (
        <div>
          <div className="searchrow" style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="searchcontent"><input type="text" placeholder="Password (Check email)" value={typedLoginPassword} onChange={(e) => setTypedLoginPassword(e.target.value)} /></div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="element-button" onClick={() => {
              resetLoginStuff()
              setShowLogin(false)
              }} style={{ flex: 1, paddingTop: 3, paddingBottom: 7, backgroundColor: showUpcoming ? 'rgba(250,120,120,0.3)' : 'rgba(50,50,50,1)', maxWidth: '95%', margin: 2 }}>
              <p className="small-text">{ 'Confirm password' } </p>
            </div> 
          </div>           
        </div> )}

        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
          <div className="element-button" onClick={() => {
            resetLoginStuff()
            setShowLogin(false)
            }} style={{ flex: 1, padding: 10, paddingTop: 3, paddingBottom: 7, backgroundColor: showUpcoming ? 'rgba(250,120,120,0.3)' : 'rgba(150,50,50,1)', maxWidth: '95%', margin: 2 }}>
            <p className="small-text">{ 'Cancel' } </p>
          </div> 
        </div> 
        
      </div> )}

      { loadingDeck && (
      <div style={{ position: 'fixed', height: '100vh', width: '100vw', backgroundColor: 'rgba(0,0,0,0.75)', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <h1>Loading...</h1>
      </div> )}

      { savingDeck && (
      <div style={{ position: 'fixed', height: '100vh', width: '100vw', backgroundColor: 'rgba(0,0,0,0.75)', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <h1>Saving...</h1>
      </div> )}
    </div>
  );
}

export default App;
